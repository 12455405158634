import React from 'react'

export default ({ fill = 'white', className, ...style }) => (
    <svg
        style={{ fill, ...style }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="5 5 90 90"
        className={className}>
        <g>
            <path d="M68.5,78.8v-4.4H53h-4.4V70V58.9v-1.8c-1.7-0.3-3.4-0.4-5.2-0.4C27.1,56.7,14,67.6,14,81h54.8   C68.6,80.4,68.5,79.6,68.5,78.8z"></path>
        </g>
        <circle cx="44.2" cy="34.6" r="17.7"></circle>
        <path d="M74.3,81.1c0.5,0,1.1-0.3,1.8-0.9l13.5-12.7c1.8-1.7,1.8-4.4,0-6.1L76.2,48.6c-0.7-0.6-1.3-1-1.8-1c-0.8,0-1.4,0.8-1.4,2.3  v8.9H53V70h19.9v8.9C72.9,80.3,73.5,81.1,74.3,81.1z"></path>
    </svg>
)
