import React from 'react';

export default ({ fill = '#788188', size = 30 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    height={size}
    width={size}
    viewBox="0 0 30 30"
    id="close"
  >
    <path
      d="M4 8l4-4 8 8 8-8 4 4-8 8 8 8-4 4-8-8-8 8-4-4 8-8z"
      fill={fill}
      id="Close"
    />
  </svg>
);
