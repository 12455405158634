export const MIN_HEIGHT = 350
const MIN_WIDTH = 350
const VERTICAL_MARGIN = 30
const COLUMN_COUNT = 3
const COLUMN_COUNT_LARGE_SCREEN = 4
const LEFT_MARGIN = 0

export const get2DCoordinatesFromArrayIndex = ({
    columnCount,
    index,
    columnWidth,
    rowHeights = [],
}) => {
    let rowIndex = Math.floor(index / columnCount)
    let colIndex = index % columnCount
    const leftEdgeMargin = colIndex === 0 ? LEFT_MARGIN : 0
    let x = columnWidth * colIndex + leftEdgeMargin
    let y = calculateYLocation({ rowHeights, rowIndex })
    return { x, y, rowIndex, colIndex }
}

//TODO: Fix with dynamic heights
export const getArrayIndexFrom2DCoordinates = ({
    columnCount,
    columnWidth,
    rowHeights = [],
    itemsCount,
    x,
    y,
    projectFolderWidth = 0,
    removeNoFromItemsCount = 2,
}) => {
    const targetColumnIdx = Math.floor((x - projectFolderWidth) / columnWidth)
    let targetRowIdx

    let _y = y

    for (let i = 0; i < rowHeights.length; i++) {
        if (_y < rowHeights[i]) {
            targetRowIdx = i
            break
        }
        _y -= rowHeights[i] + VERTICAL_MARGIN
    }

    const targetIndex = columnCount * targetRowIdx + targetColumnIdx

    return {
        targetIndex: Math.min(targetIndex, itemsCount - removeNoFromItemsCount),
        targetColumnIdx,
        targetRowIdx,
    }
}

export const getGridConfig = (width, height) => {
    if (width > 1500) {
        return {
            columnWidth: (width - 350 - 80) / 4,
            columnCount: COLUMN_COUNT_LARGE_SCREEN,
        }
    }
    if (width > 1200)
        return {
            columnWidth: (width - 350 - 80) / 3,
            columnCount: COLUMN_COUNT,
        }
    if (width > 960)
        return {
            columnWidth: (width - 350 - 80) / 2,
            columnCount: 2,
        }

    if (width > 768) {
        return {
            columnWidth: width - 410,
            columnCount: 1,
        }
    }

    return {
        columnWidth: width - 30,
        columnCount: 1,
    }
}

const _getCurrentColumnCount = () => {
    return getGridConfig(window.innerWidth).columnCount
}

export const getMaxHeightByRow = ({ children, order }) => {
    let heights = []
    if (!order || !order.current) return []
    order.current.forEach((childIndex, i) => {
        const child = children[childIndex]
        if (!child || !child.current) return

        const textHeight = [...child.current.querySelectorAll('textarea')].reduce(
            (acc, cur) => cur.getBoundingClientRect().height + acc,
            0,
        )

        const image = child.current.querySelectorAll('.image-container')
        const imageHeight = image && image[0] ? image[0].getBoundingClientRect().height : 0

        const height = imageHeight + 96 + textHeight

        const rowIndex = Math.floor(i / _getCurrentColumnCount())
        heights[rowIndex] = Math.max(heights[rowIndex] || 0, height)
    })

    return heights
}

export const calculateYLocation = ({ rowIndex, rowHeights = [] }) => {
    let offsetTop = 20

    for (let i = 0; i < rowIndex; i++) {
        offsetTop += (rowHeights[i] || MIN_HEIGHT) + VERTICAL_MARGIN
    }

    return offsetTop
}

export const getColumnIndex = index => {
    const colCount = _getCurrentColumnCount()
    return index % colCount
}
