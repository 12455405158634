import React from 'react'
import { exists } from '../Auth/lib/cognito'
import { StyledButton, LabeledButton, StyledInput, StyledLink } from './styled'
import Modal from '../common/Modal'

function isEmpty(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object
}

function reducer(state, action) {
    switch (action.type) {
        case 'change':
            if (!state.fields.hasOwnProperty(action.field)) {
                throw new Error(
                    `Invalid field name ${action.field}. Either use the correct field or initialize "${action.field}" in initialState`,
                )
            }
            return {
                fields: { ...state.fields, [action.field]: action.value },
                dirty: true,
            }
        default:
            throw new Error(`Invalid action type ${action.type}`)
    }
}

/**
 * @module MyAccount
 */
function MyAccount({
    user: { firstName, lastName, email },
    cognitoUser,
    userLoading,
    history,
    updateUser,
    deleteUserAccount,
}) {
    const [state, dispatch] = React.useReducer(reducer, {
        fields: {
            firstName,
            lastName,
            email,
        },
        dirty: false,
    })
    const isSocialUser = !exists({ email })
    const isEmailVerifed = cognitoUser.email_verified === 'true'
    const isLoading = userLoading || typeof isSocialUser === 'undefined'
    const updateField = ({ field }) => ({ target: { value } }) =>
        dispatch({ type: 'change', field, value })

    return (
        <Modal
            isCancelable
            isCloseable
            isProcessing={isLoading}
            title="Edit Account"
            subtitle="Select a text box to make changes.">
            <form className="content">
                <fieldset disabled={isLoading}>
                    <div className="profile__row text m-b-sm">
                        <StyledInput
                            name="firstName"
                            type="text"
                            value={state.fields.firstName || ''}
                            onChange={updateField({ field: 'firstName' })}
                            label="First Name"
                        />
                    </div>
                    <div className="profile__row text m-b-sm">
                        <StyledInput
                            name="lastName"
                            type="text"
                            value={state.fields.lastName || ''}
                            onChange={updateField({ field: 'lastName' })}
                            label="Last Name"
                        />
                    </div>
                    {!isEmpty(cognitoUser) && !isEmailVerifed && (
                        <LabeledButton
                            className="m-b-sm profile__row-warning"
                            onClick={e => {
                                e.preventDefault()
                                history.replace({
                                    pathname: `/profile/verify-email`,
                                    state: {
                                        modal: true,
                                        closeable: true,
                                        oldEmail: email,
                                    },
                                })
                            }}>
                            {email}
                            <label className="profile__label">Verify Email</label>
                        </LabeledButton>
                    )}
                    <LabeledButton
                        className="m-b-sm"
                        disabled={isSocialUser}
                        onClick={e => {
                            e.preventDefault()
                            history.replace({
                                pathname: `/profile/email`,
                                state: {
                                    modal: true,
                                    closeable: true,
                                    returnTo: history.location.pathname,
                                },
                            })
                        }}>
                        {email}
                        <label className="profile__label">Change Email</label>
                    </LabeledButton>
                    <LabeledButton
                        className="m-b-sm"
                        disabled={isSocialUser}
                        onClick={e => {
                            e.preventDefault()
                            history.replace({
                                pathname: `/profile/password`,
                                state: {
                                    modal: true,
                                    closeable: true,
                                    returnTo: history.location.pathname,
                                },
                            })
                        }}>
                        ***********
                        <label className="profile__label">Change Password</label>
                    </LabeledButton>
                </fieldset>
            </form>
            {isSocialUser ? (
                <p className="text-center">
                    Your email and password are linked to a social media account and cannot be
                    edited.
                </p>
            ) : null}
            <StyledButton
                primary={+true}
                fullwidth={+true}
                className="m-t-md"
                disabled={!state.dirty || isLoading}
                onClick={e => {
                    e.preventDefault()
                    updateUser(state.fields)
                }}>
                {isLoading ? 'Loading...' : 'Save Changes'}
            </StyledButton>
            <StyledLink
                deleteaccountlink={+true}
                button={+true}
                fullwidth={+true}
                className="m-t-sm"
                to={{
                    pathname: '/profile/delete-account',
                    state: { modal: true, closeable: true },
                }}>
                {isLoading ? 'Loading...' : 'Delete Account'}
            </StyledLink>
        </Modal>
    )
}

export default MyAccount
