import { compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import LogRocket from 'logrocket'

import rootReducer from './reducers'
import sagas from './sagas'

export default function configureStore(initialState) {
    // Ignore `CHANGE_FORM` actions in the logger, since they fire after every keystroke
    const sagaMiddleware = createSagaMiddleware()
    const middleware =
        process.env.NODE_ENV === 'production'
            ? applyMiddleware(
                  sagaMiddleware,
                  LogRocket.reduxMiddleware({
                      stateSanitizer: function(state) {
                          return {
                              ...state,
                              scenes: undefined,
                          }
                      },
                  }),
              )
            : applyMiddleware(sagaMiddleware)
    const store = compose(middleware)(createStore)(rootReducer, initialState)
    // remove to disable redux store in chrome
    // const composeEnhancers =
    //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    // const store = composeEnhancers(middleware)(createStore)(
    //   rootReducer,
    //   initialState
    // );
    // end here
    sagaMiddleware.run(sagas)

    return store
}
