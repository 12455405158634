import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ProjectDelete from '../components/projects/projectDelete'
import actions from '../actions'

class ProjectDeleteContainer extends React.Component {
    confirm = () => {
        let projectId = this.props.location.pathname.split(/\//).pop()
        this.props.deleteProject(projectId)
    }

    render() {
        return (
            <ProjectDelete
                {...this.props}
                confirm={this.confirm}
                cancel={() => this.props.history.goBack()}
            />
        )
    }
}

ProjectDeleteContainer.propTypes = {
    deleteProject: PropTypes.func,
    location: PropTypes.object,
    project: PropTypes.object,
    returnTo: PropTypes.string,
}

export default withRouter(
    connect(
        ({ project }) => {
            return {
                project,
            }
        },
        {
            ...actions.projects,
        },
    )(ProjectDeleteContainer),
)
