import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { Field, reduxForm } from 'redux-form'
import Members from './members'
import Loader from '../../common/loader'
import $ from 'jquery'
import CopyPasteInput from '../../CopyPasteInput'
import { isProjectPublic } from '../../../utils/permissions'
import { isValidEmail } from '../../../utils'
import constants from '../../../constants/Collaborators'
import Modal from '../../common/Modal'
import { StyledButton, StyledInput, StyledLink } from './styled'
import PLAN from '../../../constants/Plan'

const FREE = 'free'
const canInviteCollaborators = [
    FREE,
    PLAN.PLAN_MONTHLY,
    PLAN.PLAN_YEARLY,
    // PLAN.SOLO_PLAN_MONTH,
    // PLAN.SOLO_PLAN_YEAR,
    PLAN.PRO_PLAN_MONTH,
    PLAN.PRO_PLAN_YEAR,
    PLAN.BUSINESS_PLAN_MONTH,
    PLAN.BUSINESS_PLAN_YEAR,
]

const showCollaboratorLimitInfoFor = [PLAN.PRO_PLAN_MONTH, PLAN.PRO_PLAN_YEAR]

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <input {...input} placeholder={label} type={type} className="form-control" />
        {touched && error && <div className="text-danger text-center m-t-xs">{error}</div>}
    </div>
)

class Invite extends Component {
    static propTypes = {
        cancel: PropTypes.func,
        confirm: PropTypes.func,
        error: PropTypes.object,
        fields: PropTypes.array,
        handleSubmit: PropTypes.func,
        makeProjectPrivate: PropTypes.func,
        makeProjectPublic: PropTypes.func,
        submitting: PropTypes.bool,
        members: PropTypes.array,
        reset: PropTypes.func,
        pristine: PropTypes.bool,
        project: PropTypes.object,
        permissions: PropTypes.object,
        team: PropTypes.object,
    }

    memberExist = email => {
        const { members } = this.props
        return !!members.find(({ user }) => user.email === email)
    }

    constructor(props) {
        super(props)
        this.state = { submitting: false, canSubmit: false, error: null }
        this.emailRef = React.createRef()
    }

    componentWillReceiveProps(newProps) {
        this.resetForm(newProps, this.props)
    }

    validate = value => {
        if (!isValidEmail(value)) {
            this.setState({ ...this.state, error: 'enter a valid email address' })
            return false
        }
        if (window.$USER && window.$USER.email === value) {
            this.setState({ ...this.state, error: 'You own the project' })
        } else {
            this.setState({ ...this.state, error: null })
            return true
        }
        return false
    }

    resetForm = (newProps, oldProps) => {
        let { submitSucceeded, members } = newProps
        if (submitSucceeded && members && oldProps.members.length !== members.length) {
            this.emailRef.current.value = ''
            oldProps.reset()
            this.setState({ submitting: false, canSubmit: false })
        }
    }

    handleChange = ev => {
        ev.preventDefault()
        if (
            Boolean(this.emailRef.current.value) &&
            this.validate(this.emailRef.current.value.trim()) &&
            !this.state.canSubmit
        ) {
            this.setState({ canSubmit: true })
        } else if (!Boolean(this.emailRef.current.value) && this.state.canSubmit) {
            this.setState({ canSubmit: false })
        }
    }

    getProjectMembers = () => {
        let projectMembers = []
        if (!R.isEmpty(this.props.members)) {
            projectMembers = R.pipe(R.map(R.prop('user')), R.pluck('email'))(this.props.members)
        }
        return projectMembers
    }
    getTeamMembers = () => {
        let teamMembers = []
        if (!R.isEmpty(this.props.team)) {
            teamMembers = R.pipe(
                R.path(['members']),
                R.pipe(R.map(R.prop('user')), R.pluck('email')),
            )(this.props.team)
        } else {
            teamMembers.push(this.props.user.email)
        }
        return teamMembers
    }
    getCollaboratorsExcludingTeamMembers = (projectMembers = [], teamMembers = []) => {
        if (R.isEmpty(projectMembers)) {
            projectMembers = this.getProjectMembers()
        }
        if (R.isEmpty(teamMembers)) {
            teamMembers = this.getTeamMembers()
        }
        let collaboratorsExcludingTeamMembers = []
        if (projectMembers.length) {
            collaboratorsExcludingTeamMembers = R.difference(projectMembers, teamMembers)
        }
        return collaboratorsExcludingTeamMembers
    }
    submit = () => {
        const email = this.emailRef.current?.value?.trim()
        if (this.memberExist(email.email))
            return window.notify.error('This member already exists in the project')
        this.setState({ submitting: false })
        if (this.state.submitting) {
            return
        }
        let { paymentCycle, paymentGateway } = this.props.user
        if (!R.includes(paymentCycle, canInviteCollaborators)) {
            window.$N.error('Your subscription plan does not support collaborator')
            this.setState({ submitting: false })
            return
        }
        if (R.isEmpty(paymentGateway)) {
            if (this.props.team) {
                paymentCycle = this.props.team.teamPaymentCycle
            }
        }
        const projectMembers = this.getProjectMembers()
        if (R.includes(email, projectMembers)) {
            window.$N.error('User already a member of the project')
            this.setState({ submitting: false })
            return
        }
        if (constants[`collaboratorLimit_${paymentCycle}`] !== constants.UNLIMITED_COLLABORATORS) {
            const teamMembers = this.getTeamMembers()
            if (!R.includes(email, teamMembers)) {
                const collaboratorsExcludingTeamMembers = this.getCollaboratorsExcludingTeamMembers()
                // Check if collaboratorsExcludingTeamMembers excluding the users from the OWNER'S team exceeds the collaborators limit
                if (
                    collaboratorsExcludingTeamMembers.length >=
                    constants[`collaboratorLimit_${paymentCycle}`]
                ) {
                    window.$N.error('Collaborators limit exceeded.')
                    this.setState({ submitting: false })
                    return
                }
            }
        }
        this.setState({ submitting: true })

        this.props.confirm({ email })
    }

    render() {
        const { handleSubmit, pristine, permissions } = this.props

        let content

        let isOwner = permissions && permissions?.owner
        let isEditor = permissions && permissions?.edit

        // Loading
        if (this.props.members === null) {
            content = (
                <div className="text-center">
                    <Loader message="Loading Members" className="inverse pos-rlt" />
                </div>
            )
        } else {
            const { user, members, team } = this.props
            const paymentCycle = !R.isEmpty(team) ? team.teamPaymentCycle : user.paymentCycle
            const showCollaboratorLimit = showCollaboratorLimitInfoFor.includes(paymentCycle)
            const allowCollaboratorsInvite = canInviteCollaborators.includes(paymentCycle)
            content = (
                <div>
                    {/* FORM FOR OWNERS */}
                    {(isOwner || isEditor) && (
                        <>
                            <div className="clearfix">
                                <div className="profile__row text m-b-md">
                                    <StyledInput
                                        ref={this.emailRef}
                                        autoFocus
                                        name="email"
                                        type="text"
                                        className={`_lr-hide text-white profile__value ${
                                            this.state.error ? 'invalid' : ''
                                        }`}
                                        disabled={!allowCollaboratorsInvite}
                                        onChange={this.handleChange}
                                        label={
                                            this.state.error ? this.state.error : 'Email Address'
                                        }
                                    />
                                </div>
                                <StyledButton
                                    primary
                                    fullwidth
                                    onClick={handleSubmit(this.submit)}
                                    setGrayBackground={!allowCollaboratorsInvite}
                                    disabled={
                                        !this.state.canSubmit ||
                                        this.state.submitting ||
                                        this.state.error ||
                                        !allowCollaboratorsInvite
                                    }>
                                    {this.state.submitting ? 'Sending Invite ...' : 'Send Invite'}
                                </StyledButton>
                            </div>
                            {(showCollaboratorLimit || !allowCollaboratorsInvite) && (
                                <div className="form-group m-t-lg text-center text-italic font-bold text-md">
                                    {!allowCollaboratorsInvite && isOwner
                                        ? `Your current subscription plan doesn't support collaborators.`
                                        : `You've invited ${
                                              this.getCollaboratorsExcludingTeamMembers().length
                                          } out of ${
                                              constants[`collaboratorLimit_${paymentCycle}`]
                                          } available collaborators.`}
                                    {isOwner && (
                                        <StyledLink
                                            to={{
                                                pathname: '/billing',
                                                state: {
                                                    modal: true,
                                                    showUpgradeForm: true,
                                                },
                                            }}>
                                            {!allowCollaboratorsInvite
                                                ? 'Upgrade Plan'
                                                : 'Add More'}
                                        </StyledLink>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    {!isOwner && !isEditor ? (
                        <div className="text-center m-b-md">
                            Only project owners can manage team members.
                        </div>
                    ) : (
                        // EXISITNG MEMBERS
                        <Members
                            {...R.pick(
                                [
                                    'members',
                                    'deleteMember',
                                    'updateMemberPermission',
                                    'permissions',
                                ],
                                this.props,
                            )}
                            isOwner={isOwner}
                            isEditor={isEditor}
                            hasTeam={!R.isEmpty(this.props.team)}
                            teamMembers={this.getTeamMembers()}
                        />
                    )}

                    {isEditor && (
                        <div className="form-group">
                            {isProjectPublic(this.props.project) ? (
                                <div className="public-link">
                                    <CopyPasteInput
                                        value={`${window.origin}/public-projects/${this.props.project.id}`}
                                    />
                                    {isOwner && (
                                        <StyledButton
                                            primary
                                            fullwidth
                                            onClick={this.props.makeProjectPrivate}>
                                            Make Project Private
                                        </StyledButton>
                                    )}
                                </div>
                            ) : (
                                isOwner && (
                                    <StyledButton
                                        primary
                                        fullwidth
                                        onClick={this.props.makeProjectPublic}>
                                        Create Public Link
                                    </StyledButton>
                                )
                            )}
                        </div>
                    )}
                </div>
            )
        }

        return (
            <Modal
                isCloseable
                title="Invite Collaborators"
                subtitle={
                    isOwner
                        ? 'Add, edit or remove users on this project.'
                        : isEditor
                        ? 'Add users on this project.'
                        : null
                }>
                <div className="content">{content}</div>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'inviteCollaborator',
    fields: ['email'],
    // validate,
})(Invite)
