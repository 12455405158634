import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../common/Modal'

import { StyledButton } from './styled'

export default class ProjectDelete extends Component {
    static propTypes = {
        cancel: PropTypes.func,
        confirm: PropTypes.func,
    }

    constructor(props) {
        super(props)
        this.state = { processing: false }
        this.delete = this.delete.bind(this)
    }

    delete() {
        if (this.state.processing) {
            return
        }
        this.setState({ processing: true })
        this.props.confirm()
    }

    render() {
        return (
            <Modal
                isCloseable={!this.state.processing}
                isCancelable={!this.state.processing}
                wrapperClassName="scene-delete-dialog"
                wrapperTitleClassName="text-lg font-bold m-b-xs"
                title="Delete Project?"
                subtitle="This project will be permanently removed.">
                <div className="content">
                    <div className="text-center">
                        <StyledButton
                            danger
                            fullwidth
                            disabled={this.state.processing}
                            className="m-b-xs"
                            onClick={() => this.delete()}>
                            {this.state.processing ? 'Deleting project ...' : 'Delete'}
                        </StyledButton>
                    </div>
                </div>
            </Modal>
        )
    }
}
