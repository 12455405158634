import * as R from 'ramda'
import * as types from '../constants/ActionTypes'
import * as helpers from './helpers'

function removeImageFromAlbum(state, url) {
    return R.remove(R.indexOf(url, state), 1, state)
}

export function album(state = [], { type, payload }) {
    switch (type) {
        case types.FETCH_PROJECT_BY_ID_RESP:
            return payload.album
        case types.FETCH_PROJECT_ALBUM_RESP:
            return payload
        case types.UPDATE_ROUTE:
            return helpers.activeProjectView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.teamPlanView(payload.pathname) ||
                helpers.CancelSubscriptionView(payload.pathname) ||
                helpers.profileView(payload.pathname)
                ? state
                : null
        case types.DELETE_IMAGE_IN_PROJECT_RESP:
            return removeImageFromAlbum(state, payload)
        default:
            return state
    }
}
