import { createAction } from 'redux-actions';

import {
  STATUS_LOADING,
  STATUS_SUCCESS,
  STATUS_FAIL,
} from '../constants/ActionTypes';

export const statusLoading = createAction(STATUS_LOADING);
export const statusSuccess = createAction(STATUS_SUCCESS);
export const statusFail = createAction(STATUS_FAIL);
