import PropTypes from 'prop-types';
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import config from '../../config';

class FacebookAuth extends React.Component {
  onResponse = response => {
    if (!response.error) {
      this.props.authenticate({
        idp: 'FACEBOOK',
        payload: response,
      });
    } else {
      window.$N.error(response.error);
    }
  };

  render() {
    return (
      <FacebookLogin
        appId={config.facebookAppId}
        callback={this.onResponse}
        cssClass="btn plot-btn plot-btn-facebook"
        fields="first_name,last_name,email"
      />
    );
  }
}

FacebookAuth.propTypes = {
  authenticate: PropTypes.func,
};

FacebookAuth.displayName = 'FacebookAuth';

export default FacebookAuth;
