import * as types from '../constants/ActionTypes';
import { createAction } from 'redux-actions';

/**
 * Dispatch when the SketchAPI is ready for interaction
 */
export const sketchIsReady = createAction(types.SKETCH_IS_READY);

/**
 * Dispatch when the current scene should be saved
 */
export const saveSceneAssets = createAction(types.SAVE_SCENE_ASSETS);
