import React from 'react';

export default ({ fill = 'white', className, ...style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    x="0px"
    y="0px"
    style={{ fill, ...style }}
    className={className}
  >
    <path d="M6,34a8,8,0,0,0,8,8V82A16,16,0,0,0,30,98H70A16,16,0,0,0,86,82V42a8,8,0,0,0,8-8V26a8,8,0,0,0-8-8H70V10a8,8,0,0,0-8-8H38a8,8,0,0,0-8,8v8H14a8,8,0,0,0-8,8ZM78,82a8,8,0,0,1-8,8H30a8,8,0,0,1-8-8V42H78ZM38,10H62v8H38ZM14,26H86v8H14Z" />
    <path d="M34,82a4,4,0,0,0,4-4V54a4,4,0,0,0-8,0V78A4,4,0,0,0,34,82Z" />
    <path d="M50,82a4,4,0,0,0,4-4V54a4,4,0,0,0-8,0V78A4,4,0,0,0,50,82Z" />
    <path d="M66,82a4,4,0,0,0,4-4V54a4,4,0,0,0-8,0V78A4,4,0,0,0,66,82Z" />
  </svg>
);
