import { call, put, fork, select } from 'redux-saga/effects'
import { takeLatest, delay } from 'redux-saga'

import config from '../config'
import * as api from './api'

import * as userActions from '../constants/UserAccountConstant'
import {
    deleteUserAccountInit,
    deleteUserAccountFail,
    deleteUserAccountSuccess,
} from '../actions/UserAccountAction'

import { logout } from '../actions/SessionActions'

const getUser = state => state.user.user || state.session.user

function* watchDeleteUserAccount() {
    yield* takeLatest(userActions.DELETE_USER_ACCOUNT, deleteUserAccount)
}

function* deleteUserAccount({ payload }) {
    const user = yield select(getUser)
    const URL = `${config.apiUrl}/users/${user.id}`
    yield put(deleteUserAccountInit())

    let { error, response } = yield call(() => api.deleteResource(URL))
    if (error) {
        yield put(deleteUserAccountFail())
        window.notify.error(window.window.$N.MESSAGES.DELETE_USER_ACCOUNT_ERR, error)
        return
    }
    window.$N.info('Account deleted successfully. Logging out!!!')
    yield put(deleteUserAccountSuccess())
    yield put(logout())
}

export default function* rootSaga() {
    yield fork(watchDeleteUserAccount)
}
