import React from 'react'
import styled from 'styled-components/macro'

const DropdownItemWrapper = styled('div')(props => ({
    'textAlign': 'right',
    'cursor': 'pointer',
    'padding': '.4em .5em',
    'color': 'white',
    'display': 'flex',
    'flexDirection': 'row',
    '&:hover': {
        backgroundColor: '#646b73',
    },
    'backgroundColor': props.highlighted && '#646b73',
}))

export default ({ icon: Icon, value, highlighted, name, style: IconStyle }) => (
    <DropdownItemWrapper highlighted={highlighted}>
        {Icon ? (
            <i
                style={
                    IconStyle
                        ? { ...IconStyle }
                        : {
                              marginRight: '.7em',
                              width: '1.3em',
                          }
                }>
                {' '}
                <Icon />{' '}
            </i>
        ) : null}
        <div>{name || value}</div>
    </DropdownItemWrapper>
)
