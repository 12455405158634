import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import actions from '../actions'
import PublicProject from '../components/PublicProject'
import Loader from '../components/common/loader'

/**
 * @module PublicProjectContainer
 */
class PublicProjectContainer extends React.Component {
    componentWillMount() {
        let projectId = this.props.match && this.props.match.params.projectId
        if (projectId && !this.props.project) {
            this.props.fetchPublicProjectById(projectId)
        }
    }

    render() {
        return this.props.project ? (
            <PublicProject {...this.props} />
        ) : (
            <Loader message="Loading Project" />
        )
    }
}

PublicProjectContainer.propTypes = {
    fetchPublicProjectById: PropTypes.func,
    params: PropTypes.object,
    project: PropTypes.object,
}

export default withRouter(
    connect(
        state => {
            let { project, scenes, permissions } = state

            return {
                hasMultipleEditors: false,
                project,
                scenes,
                allowDrag: permissions && (permissions.edit || permissions.owner),
            }
        },
        {
            ...actions.scenes,
            ...actions.projects,
            ...actions.comments,
        },
    )(PublicProjectContainer),
)
