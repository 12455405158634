import styled, { css } from 'styled-components'

import CommonStyledButton from '../common/styled/StyledButton'
import CommonStyledInput from '../common/styled/StyledInput'
import CommonStyledLink from '../common/styled/StyledLink'

export const StyledButton = styled(CommonStyledButton)`
    cursor: pointer;
`
export const StyledInput = styled(CommonStyledInput)``
export const StyledLink = styled(CommonStyledLink)`
    ${props =>
        props.deleteaccountlink &&
        css`
            background-color: gray;
            color: #9fa3ae;
            &:hover {
                color: #fff;
                background-color: #9fa3ae;
            }
        `}
`

export const LabeledButton = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    width: 100%;
    padding: 13px 20px;
    border: 2px solid #686f75;
    background-color: transparent;
    border-radius: 4px;
    &:hover:not(:disabled) {
        border: 2px solid #a0a7ae;
        background-color: #a0a7ae;
        color: white;
    }
    &:hover:not(:disabled) label {
        color: white;
    }
    &:not(:disabled) label {
        color: #686f75;
    }
    ${props =>
        props.disabled &&
        css`
            border: none;
            padding: 15px 22px;
            background-color: #2e3434;
            color: #6a7376;
        `}
    & label {
        right: 0;
        bottom: 0;
        margin-right: 6px;
        margin-bottom: 6px;
        font-size: 0.7em;
        position: absolute;
        text-align: right;
        color: #686f75;
        text-transform: uppercase;
        font-weight: bold;
    }
`
