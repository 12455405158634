import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from '../actions';
import Header from '../components/Header';

class HeaderContainer extends Component {
  route = route => {
    this.props.history.push(route);
  };

  render() {
    return <Header {...this.props} route={this.route.bind(this)} />;
  }
}

HeaderContainer.propTypes = {};

export default withRouter(
  connect(
    R.pick(['params', 'project', 'user']),
    {
      ...actions.session,
      ...actions.projects,
    }
  )(HeaderContainer)
);
