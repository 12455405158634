// https://github.com/aws/amazon-cognito-identity-js

import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails,
} from 'amazon-cognito-identity-js'

const poolData = {
    UserPoolId: 'us-west-2_z1bIzX7U3', // Your user pool id here
    ClientId: '68831npk52vapuds5lc1i8v06g', // Your client id here
}

const withRetryLowerCaseEmail = func => async options => {
    try {
        const res = await func(options)
        return res
    } catch (e) {
        return func({ ...options, email: options.email.toLowerCase() })
    }
}

const userPool = new CognitoUserPool(poolData)

const register = ({ email, firstName, lastName, password, providerCoupon }) => {
    const attributeList = [
        new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
        new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
        new CognitoUserAttribute({ Name: 'email', Value: email.toLowerCase() }),
    ]
    if (providerCoupon) {
        attributeList.push(
            new CognitoUserAttribute({ Name: 'custom:coupon', Value: providerCoupon }),
        )
    }

    return new Promise((fulfill, reject) => {
        userPool.signUp(email.toLowerCase(), password, attributeList, null, (err, result) => {
            if (err) {
                reject(err)
            } else {
                fulfill(result)
            }
        })
    })
}

const getSession = user => {
    if (!user) {
        return
    }
    return new Promise((res, rej) => {
        user.getSession((err, session) => {
            if (err) {
                rej(err)
            }
            res(session)
        })
    })
}

const signin = withRetryLowerCaseEmail(({ email, password, authenticate, retried = false }) => {
    return new Promise((fulfill, reject) => {
        const userData = {
            Username: email,
            Pool: userPool,
        }

        // create an `AuthenticationDetails` Cognito object filled with the email+password
        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        })

        const cognitoUser = new CognitoUser(userData)
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: result => {
                authenticate({
                    idp: 'COGNITO',
                    payload: {
                        idtoken: result.getIdToken().getJwtToken(),
                        email: email,
                    },
                })

                fulfill(result)
            },
            onFailure: err => {
                reject(err)
            },

            newPasswordRequired: function(userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified

                // Get these details and call
                cognitoUser.completeNewPasswordChallenge('Intens0!', userAttributes, this)
            },
        })
    })
})

const exists = ({ email }) => userPool.getCurrentUser() !== null

const getUserAttributes = async () => {
    const cognitoUser = userPool.getCurrentUser()
    await getSession(cognitoUser)
    return new Promise((res, rej) => {
        cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
                rej(err)
            }
            res(
                attributes?.reduce(
                    (acc, attr) => ({ ...acc, [attr.getName()]: attr.getValue() }),
                    {},
                ),
            )
        })
    })
}

const verifyAttribute = async ({ email, attribute, code }) => {
    const cognitoUser = userPool.getCurrentUser()

    await getSession(cognitoUser)
    return new Promise((fulfill, reject) => {
        cognitoUser.verifyAttribute(attribute, code, {
            onSuccess: data => fulfill(data),
            onFailure: err => reject(err),
        })
    })
}

const verify = withRetryLowerCaseEmail(({ email, code }) => {
    return new Promise((fulfill, reject) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        })
        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                reject(err)
            } else {
                fulfill(result)
            }
        })
    })
})

const forgot = withRetryLowerCaseEmail(({ email }) => {
    return new Promise((fulfill, reject) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        })
        cognitoUser.forgotPassword({
            onSuccess: data => {
                // successfully initiated reset password request
                fulfill(data)
            },
            onFailure: err => reject(err),
        })
    })
})

const sendAttributeVerificationCode = async ({ attribute }) => {
    const cognitoUser = userPool.getCurrentUser()
    await getSession(cognitoUser)
    return new Promise((res, rej) => {
        cognitoUser.getAttributeVerificationCode('email', {
            onSuccess: result => res(result),
            onFailure: err => rej(err),
        })
    })
}

const resend = withRetryLowerCaseEmail(({ email }) => {
    return new Promise((fulfill, reject) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        })
        cognitoUser.resendConfirmationCode((err, data) => {
            if (err) {
                return reject(err)
            }
            fulfill(data)
        })
    })
})

const reset = withRetryLowerCaseEmail(({ email, code, password }) => {
    return new Promise((fulfill, reject) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        })
        cognitoUser.confirmPassword(code, password, {
            onSuccess: data => fulfill(data),
            onFailure: err => reject(err),
        })
    })
})

const changePassword = async ({ oldPassword, password }) => {
    const cognitoUser = userPool.getCurrentUser()
    await getSession(cognitoUser)
    return new Promise((fulfill, reject) => {
        cognitoUser.changePassword(oldPassword, password, (err, data) => {
            if (err) {
                return reject(err)
            }
            return fulfill(data)
        })
    })
}

const signout = ({ email }) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
    })
    return new Promise((fulfill, reject) => {
        cognitoUser.signOut()
        fulfill()
    })
}

export {
    signin,
    register,
    verify,
    getUserAttributes,
    sendAttributeVerificationCode,
    verifyAttribute,
    changePassword,
    forgot,
    reset,
    signout,
    resend,
    exists,
}
