const activeProjectView = pathname => /projects\/.+/gi.test(pathname)

const dashboardView = pathname => /projects/gi.test(pathname)

const projectCreateView = pathname => /projects\/create/gi.test(pathname)

const projectDeleteView = pathname => /projects\/delete/gi.test(pathname)

const projectsView = pathname => /projects\?/gi.test(pathname)

const projectLockedView = pathname => /project-locked/gi.test(pathname)

const CancelSubscriptionView = pathname => /cancel-subscription/gi.test(pathname)

const billingView = pathname => /billing/gi.test(pathname)

const billingSuccessView = pathname => /billing\/success/gi.test(pathname)

const teamPlanView = pathname => /teamPlan/gi.test(pathname)

const profileView = pathname => /profile/gi.test(pathname)

const folderCreateView = pathname => /folders\/create/gi.test(pathname)

const folderDeleteView = pathname => /folders\/delete/gi.test(pathname)

const folderRenameView = pathname => /folders\/rename/gi.test(pathname)

const folderShareView = pathname => /folders\/share/gi.test(pathname)

const folderMoveView = pathname => /folders\/move/gi.test(pathname)

export {
    activeProjectView,
    projectsView,
    billingView,
    billingSuccessView,
    profileView,
    dashboardView,
    projectCreateView,
    teamPlanView,
    projectDeleteView,
    folderCreateView,
    folderDeleteView,
    folderRenameView,
    folderShareView,
    folderMoveView,
    projectLockedView,
    CancelSubscriptionView,
}
