export default {
  svg: {
    enableBackground: 'new 0 0 1080 720',
    width: '135px',
  },
  st0: {
    fill: 'none',
    stroke: '#BBBBBB',
    strokeWidth: 18,
    strokeMiterlimit: 10,
  },
  st1: {
    fill: 'none',
    stroke: '#BBBBBB',
    strokeWidth: 14,
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
  },
};
