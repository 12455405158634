import React from 'react'

const FormCheckbox = ({ label, name, className = '', type = 'checkbox', onChange }) => (
    <div className="row form-group checkbox">
        <input
            id={name}
            className={`cp ${className}`}
            type={type}
            name={name}
            onChange={onChange}
        />
        <label htmlFor={name}>{label}</label>
    </div>
)

export default FormCheckbox
