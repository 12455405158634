import { createAction } from 'redux-actions'

import {
    CREATE_FOLDER,
    CREATE_FOLDER_RESP,
    DELETE_FOLDER,
    DELETE_FOLDER_RESP,
    UPDATE_FOLDER,
    UPDATE_FOLDER_RESP,
    FETCH_FOLDERS,
    FETCH_FOLDERS_RESP,
    REORDER_FOLDERS,
    REORDER_FOLDERS_RESP,
    SET_CURRENT_FOLDER_TO,
} from '../constants/Folders'

export const createFolderAction = createAction(CREATE_FOLDER)
export const createFolderRespAction = createAction(CREATE_FOLDER_RESP)
export const deleteFolderAction = createAction(DELETE_FOLDER)
export const deleteFolderRespAction = createAction(DELETE_FOLDER_RESP)
export const updateFolderAction = createAction(UPDATE_FOLDER)
export const updateFolderRespAction = createAction(UPDATE_FOLDER_RESP)
export const fetchFoldersAction = createAction(FETCH_FOLDERS)
export const fetchFoldersRespAction = createAction(FETCH_FOLDERS_RESP)
export const reorderFoldersAction = createAction(REORDER_FOLDERS)
export const reorderFoldersRespAction = createAction(REORDER_FOLDERS_RESP)
export const setCurrentFolderTo = createAction(SET_CURRENT_FOLDER_TO)
