import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Invite from './invite'
import actions from '../../../actions'

class InviteContainer extends React.Component {
    componentWillMount() {
        if (!this.props.members || !this.props.permissions || !this.props.project) {
            this.props.fetchProjectMembers(this.props.projectId)
        }
    }

    confirm = email => {
        this.props.addMemberToProject(email)
    }

    delete = member => {
        this.props.deleteMemberInProject(member)
    }

    makeProjectPublic = () => {
        this.props.updateProject({ visibility: 'public' })
    }

    makeProjectPrivate = () => {
        this.props.updateProject({ visibility: 'private' })
    }

    render = () => {
        return (
            <Invite
                {...this.props}
                makeProjectPublic={this.makeProjectPublic}
                makeProjectPrivate={this.makeProjectPrivate}
                cancel={this.cancel}
                confirm={this.confirm}
                deleteMember={this.delete}
            />
        )
    }
}

InviteContainer.propTypes = {
    addMemberToProject: PropTypes.func,
    deleteMemberInProject: PropTypes.func,
    dispatch: PropTypes.func,
    fetchProjectMembers: PropTypes.func,
    location: PropTypes.object,
    members: PropTypes.array,
    project: PropTypes.object,
    returnTo: PropTypes.string,
    permissions: PropTypes.object,
}

export default withRouter(
    connect(
        (state, ownProps) => {
            const { user: userState, session, project, members, team, permissions } = state
            const projectId = ownProps?.match?.params?.projectId
            const user = userState && userState.loading ? session.user || session : userState.user
            return {
                project,
                members: members,
                permissions,
                team,
                user,
                projectId,
            }
        },
        {
            ...actions.projects,
        },
    )(InviteContainer),
)
