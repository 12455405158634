import * as types from '../constants/ActionTypes';

export default function trial(state = null, { type, payload }) {
  switch (type) {
    case types.SHOW_TRIAL_EXPIRE:
      return payload;
    case types.UPDATE_ROUTE:
      return null;
    default:
      return state;
  }
}
