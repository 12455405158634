import { call, put, fork } from 'redux-saga/effects'
import { takeEvery, takeLatest } from 'redux-saga'
import LogRocket from 'logrocket'
import * as types from '../constants/ActionTypes'
import * as api from './api'
import config from '../config'
import {
    createSession,
    deleteSession,
    fetchUserSuccess,
    fetchUserLoading,
} from '../actions/SessionActions'
import { fetchTeamPlanResp } from '../actions/TeamAction'
import { fetchProductPlansResp } from '../actions/ApplicationActions'
import actions from '../actions'
import { fetchProjects } from '../actions/ProjectActions'
import { exists, getUserAttributes } from '../components/Auth/lib/cognito'
import { all } from 'q'
import * as R from 'ramda'
import PLANS from '../constants/Plan'

const {
    coupons: { applyCouponResp },
    trial: { showTrialExpire },
} = actions

const SESSION_COOKIE = config.sessionCookie

function* getSessionCookie() {
    let data = localStorage.getItem(SESSION_COOKIE)
    try {
        return JSON.parse(data)
    } catch (e) {
        if (data) {
            yield call(deleteSessionRun)
        }
        window.sagaHistory.push('/authenticate/signin')
        return
    }
}

// Delete session
// ---

function* deleteSessionRun() {
    const URL = `${config.apiUrl}/auth/logout`
    // Invalidate session
    localStorage.removeItem(SESSION_COOKIE)

    const { error } = yield call(() => api.post(URL, {}))

    if (error) {
        window.notify.error(error.message)
        return
    }
    if (window.gapi.auth2) {
        var auth2 = window.gapi.auth2.getAuthInstance()
        yield call(() => auth2.signOut())
    }
    yield put(deleteSession())
    yield call(() => window.sagaHistory.push('/authenticate/signin'))
}

function* watchSessionDelete() {
    yield* takeEvery(types.LOGOUT, deleteSessionRun)
}

// Authenticate
// ---

function* authenticateRun({ payload: { payload, idp } }) {
    let URL = ''
    switch (idp) {
        case 'GOOGLE':
            URL = `${config.apiUrl}/auth/google-token-signin`
            break
        case 'COGNITO':
            URL = `${config.apiUrl}/auth/cognito-token-signin`
            break
        default:
            URL = `${config.apiUrl}/auth/facebook-token-signin`
    }
    const token = yield call(() => api.getRecaptchaToken('authenticate'))
    if (!token) {
        window.notify.error(null, 'Unable to verify user authenticity. Please contact support.')
    }

    const { response, error } = yield call(() => api.post(URL, payload, true, token))

    if (error) {
        window.notify.error(error.message)
        return
    } else if (response) {
        // Cognito user attributes
        const isSocialUser = !exists({ email: response.email })
        const cognitoUser = isSocialUser ? {} : yield call(getUserAttributes)

        const newSession = { ...response, cognitoUser }

        const localStorageData = {
            sessionId: response.sessionId,
            userId: response.userId,
        }

        localStorage.setItem(SESSION_COOKIE, JSON.stringify(localStorageData))

        // Set global user
        window.$USER = response.user
        yield put(createSession(newSession))
        yield put(fetchTeamPlanResp(response.user.team || {}))
        yield put(fetchUserSuccess(response.user))
        yield call(() => window.sagaHistory.push('/projects'))
        yield put(showTrialExpire(true))
    }
}

function* watchAuthenticate() {
    yield* takeEvery(types.AUTHENTICATE, authenticateRun)
}

function* watchFetchUser() {
    yield* takeEvery(types.FETCH_USER, fetchUserData)
}

function* fetchUserData({ payload = {} }) {
    yield put(fetchUserLoading())
    let parsedData = yield getSessionCookie()
    const URL = `${config.apiUrl}/users/${parsedData.userId}`
    const { response } = yield call(() => api.get(URL))
    if (process.env.NODE_ENV === 'production') {
        LogRocket.identify(response.id, {
            name: `${response.firstName} ${response.firstName}`,
            email: response.email,
            subscriptionType: 'pro',
        })
    }
    // Set global user
    window.$USER = response

    // Cognito user attributes
    const isSocialUser = !exists({ email: response.email })
    const cognitoUser = isSocialUser ? {} : yield call(getUserAttributes)

    const newSession = { ...parsedData, user: response, cognitoUser }

    if (response.lastPayment === false) {
        window.sagaHistory.replace({
            pathname: 'pastdue',
            state: { modal: true, closeable: false },
        })
    }
    yield [
        put(fetchTeamPlanResp(response.team || {})),
        put(fetchUserSuccess(response)),
        put(createSession(newSession)),
    ]

    if (typeof payload.onDone === 'function') {
        payload.onDone()
    }
}

// Update User
// ---

function* watchUpdateUser() {
    yield* takeLatest(types.UPDATE_USER, updateUser)
}

function* updateUser({ payload }) {
    yield put(fetchUserLoading())
    const recaptchToken = yield call(() => api.getRecaptchaToken('authenticate'))
    if (!recaptchToken) {
        window.notify.error('Unable to verify user authenticity. Please contact support.')
    }
    const URL = `${config.apiUrl}/users/me`
    const { response, error } = yield call(() => api.patch(URL, payload, false, recaptchToken))

    if (error) {
        window.notify.error(error.message)
        return
    }

    const oldSession = yield getSessionCookie()

    // Cognito user attributes
    const isSocialUser = !exists({ email: response.email })
    const cognitoUser = isSocialUser ? {} : yield call(getUserAttributes)

    const newSession = { ...oldSession, user: response, cognitoUser }

    // Set global user
    window.$USER = response
    window.$N.info('User updated')

    yield [put(fetchUserSuccess(response)), put(createSession(newSession))]

    if (typeof payload.onDone === 'function') {
        payload.onDone()
    }
}

// Upgrade
// ---

function* watchUpgrade() {
    yield* takeLatest(types.UPGRADE, upgrade)
}

function* fixCard({ payload }) {
    const { stripeRes, stripeError } = payload

    if (stripeError) {
        payload.onDone(stripeError)
        return
    }

    // Don't send the cardInfo to our API, only Stripe needs that.
    payload.token = stripeRes.id

    const URL = `${config.apiUrl}/users/me/subscription`
    const { response, error } = yield call(() => api.put(URL, payload))
    if (error) {
        payload.onDone(error.message)
        return
    }
    yield put(fetchProjects())
    yield put(fetchUserSuccess(response))
    window.sagaHistory.replace({
        pathname: '/billing',
        state: { modal: true, closeable: true, fixCard: true },
    })
}

function* watchFixCard() {
    yield* takeLatest(types.FIX_CARD, fixCard)
}

function* upgrade({ payload }) {
    const { stripeRes, stripeError } = payload.stripePayload

    if (stripeError) {
        payload.onDone(stripeError)
        return
    }

    // Don't send the cardInfo to our API, only Stripe needs that.
    payload.token = stripeRes.id

    const URL = `${config.apiUrl}/users/me/subscription`
    const { response, error } = yield call(() => api.post(URL, payload))
    if (error) {
        if (error.message.includes('Invalid coupon')) {
            yield put(
                applyCouponResp({
                    message: error.message,
                    valid: false,
                }),
            )
            payload.onDone()
            return
        }

        payload.onDone(error.message)
        return
    }
    yield put(fetchProjects())
    yield put(fetchUserSuccess(response))
    window.sagaHistory.push({
        pathname: '/billing/success',
        state: { modal: true, closeable: true, complete: true },
    })
    payload.onDone()
}

// Cancellation
// ---

function* watchCancelSubscription() {
    yield* takeEvery(types.CANCEL_SUBSCRIPTION, cancelSubscriptionRun)
}

function* cancelSubscriptionRun({ payload }) {
    const URL = `${config.apiUrl}/users/me/subscription`
    const deleteOp = yield call(() => api.deleteResource(URL))
    if (deleteOp.error) {
        window.notify.error(window.$N.MESSAGES.CANCEL_SUBSCRIPTION_ERR, deleteOp.error)
        if (typeof payload.onDone === 'function') {
            payload.onDone()
        }
        return
    }
    yield put(fetchProjects())
    yield put(fetchUserSuccess(deleteOp.response))

    if (typeof payload.onDone === 'function') {
        payload.onDone()
    }
    window.sagaHistory.replace({
        pathname: payload.returnTo.pathname,
        state: { modal: true, closeable: true, ...payload.returnTo.state },
    })
}

function* fetchProductPlans({ payload }) {
    const productPlansURLS = []
    for (const product in payload) {
        productPlansURLS.push(`${config.apiUrl}/products/${payload[product]}`)
    }
    const products = yield all(productPlansURLS.map(url => api.get(url)))
    const error = R.pipe(R.reject(R.isNil), R.uniq, R.isEmpty, R.not)(R.pluck('error', products))
    if (error) {
        window.notify.error('Something went wrong.')
        return
    }

    const response = R.pluck('response', products)
    const productPlans = R.flatten(response)
    R.map(plan => {
        const splitPlanName = plan.plan_name ? plan.plan_name.split('_') : ''
        const planName = R.filter(
            R.includes(R.__, [PLANS.TYPE_SOLO, PLANS.TYPE_PRO, PLANS.TYPE_BUSINESS]),
            splitPlanName,
        ).join('')
        plan['planName'] = planName
        plan['subscriptionPlanName'] = planName + '_' + plan.interval
    }, productPlans)

    yield put(fetchProductPlansResp(productPlans))
}

function* watchFetchProductPlans() {
    yield* takeEvery(types.FETCH_PLANS, fetchProductPlans)
}

// Fork!
// ---

export default function* rootSaga() {
    yield fork(watchAuthenticate)
    yield fork(watchCancelSubscription)
    yield fork(watchSessionDelete)
    yield fork(watchUpgrade)
    yield fork(watchFixCard)
    yield fork(watchFetchUser)
    yield fork(watchUpdateUser)
    yield fork(watchFetchProductPlans)
}
