// PROJECT

export const ADD_MEMBER_TO_PROJECT = 'ADD_MEMBER_TO_PROJECT'
export const ADD_MEMBER_TO_PROJECT_RESP = 'ADD_MEMBER_TO_PROJECT_RESP'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const DUPLICATE_PROJECT = 'DUPLICATE_PROJECT'
export const CREATE_PROJECT_RESP = 'CREATE_PROJECT_RESP'
export const DELETE_IMAGE_IN_PROJECT = 'DELETE_IMAGE_IN_PROJECT'
export const DELETE_IMAGE_IN_PROJECT_RESP = 'DELETE_IMAGE_IN_PROJECT_RESP'
export const DELETE_MEMBER_IN_PROJECT = 'DELETE_MEMBER_TO_PROJECT'
export const DELETE_MEMBER_IN_PROJECT_RESP = 'DELETE_MEMBER_TO_PROJECT_RESP'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_RESP = 'DELETE_PROJECT_RESP'
export const FETCH_PROJECT_ALBUM = 'FETCH_PROJECT_ALBUM'
export const FETCH_PROJECT_ALBUM_ERR = 'FETCH_PROJECT_ALBUM_ERR'
export const FETCH_PROJECT_ALBUM_RESP = 'FETCH_PROJECT_ALBUM_RESP'
export const FETCH_PROJECT_BY_ID = 'FETCH_PROJECT_BY_ID'
export const FETCH_PUBLIC_PROJECT_BY_ID = 'FETCH_PUBLIC_PROJECT_BY_ID'
export const FETCH_PROJECT_BY_ID_RESP = 'FETCH_PROJECT_BY_ID_RESP'
export const FETCH_PROJECT_MEMBERS = 'FETCH_PROJECT_MEMBERS'
export const FETCH_PROJECT_MEMBERS_RESP = 'FETCH_PROJECT_MEMBERS_RESP'
export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECTS_RESP = 'FETCH_PROJECTS_RESP'
export const MAKE_PROJECT_PUBLIC = 'MAKE_PROJECT_PUBLIC'
export const MAKE_PROJECT_PRIVATE = 'MAKE_PROJECT_PRIVATE'
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT'
export const SET_USER_PROJECT_PERMISSIONS = 'SET_USER_PROJECT_PERMISSIONS'
export const SET_PROJECT_FILTER = 'SET_PROJECT_FILTER'
export const SEARCH_PROJECTS_BY_TITLE = 'SEARCH_PROJECTS_BY_TITLE'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATE_PROJECT_RESP = 'UPDATE_PROJECT_RESP'
export const UPLOAD_IMG = 'UPLOAD_IMG'
export const UPLOAD_IMG_RESP = 'UPLOAD_IMG_RESP'
export const UPDATE_MEMBER_PERMISSION = 'UPDATE_MEMBER_PERMISSION'
export const UPDATE_MEMBER_IN_PROJECT_RESP = 'UPDATE_MEMBER_IN_PROJECT_RESP'

// SCENE

export const ACTIVATE_SCENE = 'ACTIVATE_SCENE'
export const CLEAR_ACTIVE_SCENE = 'CLEAR_ACTIVE_SCENE'
export const CREATE_SCENE = 'CREATE_SCENE'
export const CREATE_SCENE_RESP = 'CREATE_SCENE_RESP'
export const SCENE_START_LOADING = 'SCENE_START_LOADING'
export const SCENE_STOP_LOADING = 'SCENE_STOP_LOADING'
export const CREATE_SCENE_BY_SLICE = 'CREATE_SCENE_BY_SLICE'
export const DELETE_SCENE = 'DELETE_SCENE'
export const DELETE_SCENE_RESP = 'DELETE_SCENE_RESP'
export const REORDER_SCENE = 'REORDER_SCENE'
export const REORDER_SCENE_PRE_SYNC = 'REORDER_SCENE_PRE_SYNC'
export const REORDER_SCENE_RESP = 'REORDER_SCENE_RESP'
export const SET_IMAGE = 'SET_IMAGE'
export const UPLOAD_SCENE_IMAGE = 'UPLOAD_SCENE_IMAGE'
export const CLONE_SET_IMAGE = 'CLONE_SET_IMAGE'
export const SET_IMAGE_RESP = 'SET_IMAGE_RESP'
export const UPDATE_SCENE = 'UPDATE_SCENE'
export const UPDATE_SCENE_PRE_SYNC = 'UPDATE_SCENE_PRE_SYNC'
export const UPDATE_SCENE_RESP = 'UPDATE_SCENE_RESP'
export const CLEAR_SCENES_WITH_DIRTY_FLAG = 'CLEAR_SCENES_WITH_DIRTY_FLAG'

// COMMENT

export const CLEAR_COMMENTS_SCENE_ID_FILTER = 'CLEAR_COMMENTS_SCENE_ID_FILTER'
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const CREATE_COMMENT_RESP = 'CREATE_COMMENT_RESP'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const DELETE_COMMENT_RESP = 'DELETE_COMMENT_RESP'
export const FILTER_COMMENTS_BY_SCENE_ID = 'FILTER_COMMENTS_BY_SCENE_ID'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const UPDATE_COMMENT_RESP = 'UPDATE_COMMENT_RESP'

// MISC.

export const AUTHENTICATE = 'AUTHENTICATE'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION_RESP = 'CANCEL_SUBSCRIPTION_RESP'
export const CREATE_SESSION = 'CREATE_SESSION'
export const DELETE_SESSION = 'DELETE_SESSION'
export const LOGOUT = 'LOGOUT'
export const SET_RETURN_TO = 'SET_RETURN_TO'
export const UPDATE_ROUTE = 'UPDATE_ROUTE'
export const UPGRADE = 'UPGRADE'
export const UPGRADE_RESP = 'UPGRADE_RESP'
export const FIX_CARD = 'FIX_CARD'
export const FIX_CARD_RESP = 'FIX_CARD_RESP'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_LOADING = 'FETCH_USER_LOADING'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING'
export const FETCH_PLANS = 'FETCH_PLANS'
export const FETCH_PLANS_RESP = 'FETCH_PLANS_RESP'
export const ACTIVATE_TOOLBAR_TAB = 'ACTIVATE_TOOLBAR_TAB'

// Coupon

export const APPLY_COUPON = 'APPLY_COUPON'
export const APPLY_COUPON_RESP = 'APPLY_COUPON_RESP'
export const DEFAULT_COUPON = 'DEFAULT_COUPON'

// SKETCH

export const TOGGLE_PAINT = 'TOGGLE_PAINT'
export const SKETCH_IS_READY = 'SKETCH_IS_READY'
export const SAVE_SCENE_ASSETS = 'SAVE_SCENE_ASSETS'
export const UPDATE_PAINT_ACTION_STATE = 'UPDATE_PAINT_ACTION_STATE'

// GlobalActivityStatusType

export const STATUS_LOADING = 'STATUS_LOADING'
export const STATUS_SUCCESS = 'STATUS_SUCCESS'
export const STATUS_FAIL = 'STATUS_FAIL'

// focusedScriptType
export const CHANGE_SCENE_SCRIPT_FOCUS = 'CHANGE_SCENE_SCRIPT_FOCUS'

// trial expire
export const SHOW_TRIAL_EXPIRE = 'SHOW_TRIAL_EXPIRE'

// fetchUserPaymentPlan
export const FETCH_SUBSCRIPTION_PLAN = 'FETCH_SUBSCRIPTION_PLAN'
export const FETCH_SUBSCRIPTION_PLAN_RESP = 'FETCH_SUBSCRIPTION_PLAN_RESP'
