export function helpcrunchTrackEvent(customEvent) {
  if (!window.HelpCrunch) {
    console.warn('HelpCrunch not defined on window');
    return;
  }

  window.HelpCrunch('trackEvent', customEvent);
}

export function helpcrunchUpdateUser(obj) {
  if (!window.HelpCrunch) {
    console.warn('HelpCrunch not defined on window');
    return;
  }

  window.HelpCrunch('updateUser', obj);
}

export function helpcrunchUpdateUserData(obj) {
  if (!window.HelpCrunch) {
    console.warn('HelpCrunch not defined on window');
    return;
  }

  window.HelpCrunch('updateUserData', obj);
}
