import * as R from 'ramda'
import { call, fork, put, select } from 'redux-saga/effects'
import { delay, takeEvery, takeLatest } from 'redux-saga'
import uuid from 'uuid'

import * as api from './api'
import config from '../config'
import * as types from '../constants/ActionTypes'
import {
    activateScene,
    clearActiveScene,
    createSceneResp,
    deleteSceneResp,
    reorderScenePreSync,
    reorderSceneResp,
    sceneStartLoading,
    sceneStopLoading,
    setImageResp,
    updateScene,
    updateScenePreSync,
    updateSceneResp,
} from '../actions/SceneActions'
import { statusFail } from '../actions/GlobalActivityStatusActions'
import { fetchProjectAlbumResp } from '../actions/ProjectActions'

import { SCENE_TEMPLATE } from '../constants'

// Funcs
// ----------------

let dirtyScenes = {}

const getProject = state => state.project

const getScenes = state => state.scenes

const SceneFactory = projectId => ({
    clientId: null,
    description: null,
    image: null,
    projectId: projectId,
    status: 'active',
    voiceOver: null,
})

function isFirst(scene, project) {
    return project.sceneOrder.indexOf(scene.id) === 0
}

function scenePosition(scene, project) {
    return R.indexOf(scene.id, project.sceneOrder)
}

const getSceneById = (id, scenes) =>
    R.compose(
        R.head,
        R.filter(x => id === x.id),
    )(scenes)

function combineVoiceOverContent(vo1, ov2) {
    if (vo1 && ov2) {
        return `${vo1}${ov2}`
    } else if (vo1 && !ov2) {
        return vo1
    } else if (!vo1 && ov2) {
        return ov2
    } else {
        return ''
    }
}

// Update Scene
// ----------------

function* watchUpdateScene() {
    yield* takeLatest(types.UPDATE_SCENE, runUpdateScene)
}

// updateProjectScene :: [Scene]
function* runUpdateScene({ payload }) {
    // Update scenes in rdx store
    const { content, field, scene, debounceInterval = 2000 } = payload
    let updatedScene = R.mergeRight(scene, { [field]: content })
    dirtyScenes[updatedScene.id] = updatedScene
    yield put(updateScenePreSync(updatedScene))
    yield delay(debounceInterval)
    yield sync()
}

function* sync() {
    const project = yield select(getProject)

    let scenesToUpdate = R.compose(R.map(R.omit(['number', 'dateCreated'])))(
        Object.values(dirtyScenes),
    )

    let UPDATE_URLS = R.map(
        scene => `${config.apiUrl}/projects/${project.id}/scenes/${scene.id}`,
        scenesToUpdate,
    )

    let args = R.zip(UPDATE_URLS, scenesToUpdate)

    for (var i = args.length - 1; i >= 0; i--) {
        let [UPDATE_URL, payload] = args[i]
        let { error } = yield api.put(UPDATE_URL, payload)
        if (error) {
            window.notify.error(window.window.$N.MESSAGES.SAVE_DIRTY_SCENES_ERR, error)
            yield put(statusFail())
            return
        }
        yield put(updateSceneResp(payload))
    }

    dirtyScenes = {}
}

// Create Scene
// ----------------

function* watchCreateScene() {
    yield* takeEvery(types.CREATE_SCENE, runCreateScene)
}

// runCreateScene :: { position, scene, projectId }
function* runCreateScene({ payload }) {
    let { position, scene, projectId } = payload
    let project = yield select(getProject)

    yield put(sceneStartLoading())
    let URL = !isNaN(position)
        ? `${config.apiUrl}/projects/${projectId || project.id}/scenes?position=${position}`
        : `${config.apiUrl}/projects/${projectId || project.id}/scenes`

    const { response, error } = yield call(() => api.post(URL, scene || SceneFactory(project.id)))

    if (response.error && response.error.includes('Exceeded')) {
        yield put(sceneStopLoading())
        yield call(() =>
            window.sagaHistory.push({
                pathname: '/billing',
                state: { modal: true, closeable: true, upgrade: true },
            }),
        )
        return
    }

    if (error) {
        window.notify.error(window.window.$N.MESSAGES.CREATE_SCENE_ERR, error)
        yield [put(sceneStopLoading()), put(statusFail())]
        return
    }
    // TODO: update rdx state
    yield [put(createSceneResp({ scene: response, position })), put(sceneStopLoading())]
}

// Delete Scene
// ----------------

function* watchDeleteScene() {
    yield* takeEvery(types.DELETE_SCENE, runDeleteScene)
}

// runDeleteScene :: { payload: id }
function* runDeleteScene({ payload }) {
    let [project, scenes] = yield [select(getProject), select(getScenes)]

    let sceneToUpdate, diff, updatedScene
    // Find scene to update
    if (isFirst(payload, project)) {
        sceneToUpdate = getSceneById(project.sceneOrder[1], scenes)
        if (!sceneToUpdate) {
            console.warn('could not find scene to update')
        } else {
            // update voice over field
            diff = {
                voiceOver: combineVoiceOverContent(payload.voiceOver, sceneToUpdate.voiceOver),
            }
        }
    } else {
        let prevSceneId = project.sceneOrder[scenePosition(payload, project) - 1]
        sceneToUpdate = getSceneById(prevSceneId, scenes)
        if (!sceneToUpdate) {
            console.warn('could not find scene to update')
        } else {
            // update voice over field
            diff = {
                voiceOver: combineVoiceOverContent(sceneToUpdate.voiceOver, payload.voiceOver),
            }
        }
    }
    // final put payload
    updatedScene = R.mergeRight(sceneToUpdate, diff)
    // update and delete respectively
    let [updateOp, deleteOp] = yield [
        call(() =>
            api.put(
                `${config.apiUrl}/projects/${project.id}/scenes/${updatedScene.id}`,
                updatedScene,
            ),
        ),
        call(() =>
            api.deleteResource(`${config.apiUrl}/projects/${project.id}/scenes/${payload.id}`),
        ),
    ]
    // Error check
    if (updateOp.error) {
        window.notify.error(window.window.$N.MESSAGES.DELETE_SCENE_ERR, updateOp.error)
        return
    }
    // Error check
    if (deleteOp.error) {
        window.notify.error(window.window.$N.MESSAGES.DELETE_SCENE_ERR, deleteOp.error)
        yield put(statusFail())
        return
    }

    yield call(() => window.sagaHistory.goBack())
    yield [put(updateSceneResp(updatedScene)), put(deleteSceneResp(payload))]
}

// Re-order Scene
// ----------------

function* watchReorderScene() {
    yield* takeEvery(types.REORDER_SCENE, runReorderScene)
}

function* runReorderScene({ payload }) {
    let { sourceSceneId, targetSceneId } = payload
    let project = yield select(getProject)
    let { sceneOrder } = project

    let at = R.findIndex(R.equals(sourceSceneId), sceneOrder)
    let to = R.findIndex(R.equals(targetSceneId), sceneOrder)

    const newOrder = R.insert(to, R.nth(at, sceneOrder), R.remove(at, 1, sceneOrder))

    yield put(reorderScenePreSync(newOrder))
    // API call
    const URL = `${config.apiUrl}/projects/${project.id}/scenes/reorder`
    let { response, error } = yield call(() => api.post(URL, { order: newOrder, position: to }))

    if (R.propOr(false, 'error', response) && response.error.includes('Exceeded')) {
        yield call(() =>
            window.sagaHistory.push({
                pathname: '/billing',
                state: { modal: true, closeable: true, upgrade: true },
            }),
        )
        return
    }

    if (error) {
        window.notify.error(window.window.$N.MESSAGES.RE_ORDER_SCENE_ERR, error)
        yield [put(statusFail()), put(reorderScenePreSync(sceneOrder))]
        return
    }

    yield put(reorderSceneResp(newOrder))
}

// Set Scene Image
// ----------------

function* watchSetImage() {
    yield* takeEvery(types.SET_IMAGE, runSetImage)
}

function* watchRunCloneSetImage() {
    yield* takeEvery(types.CLONE_SET_IMAGE, runCloneSetImage)
}

function* watchRunUploadSceneImage() {
    yield* takeEvery(types.UPLOAD_SCENE_IMAGE, runUploadSceneImage)
}

async function createFile(url, name) {
    try {
        const response = await fetch(url)
        const data = await response.blob()
        return new File([data], name, {
            type: 'image/jpeg',
        })
    } catch (e) {
        console.error(e)
        return null
    }
}

function* runUploadSceneImage({ payload }) {
    const project = yield select(getProject)
    yield put(sceneStartLoading())

    const { error: keyError, response } = yield call(() => api.getUploadKey(project.id))
    if (keyError) {
        window.notify.error(window.$N.MESSAGES.GET_UPLOAD_KEY_ERR, keyError)
        return
    }

    const { uploadFile, ...rest } = payload
    const newImage = { image: `${project.id}/${uploadFile.name}` }
    yield call(() => api.uploadImage(project.id, response, uploadFile))

    const newPayload = { ...rest, ...newImage }
    yield call(() => runSetImage({ payload: newPayload }))

    const { response: album } = yield call(() =>
        api.get(`${config.apiUrl}/projects/${project.id}/album`),
    )

    yield [put(fetchProjectAlbumResp(album)), put(sceneStopLoading())]
}

function* runCloneSetImage({ payload }) {
    const project = yield select(getProject)
    const scenes = yield select(getScenes)

    // Do not clone if image has not be set in a scene yet.
    if (!scenes.some(scene => scene.image === payload.image)) {
        yield call(() => runSetImage({ payload }))
        return
    }
    yield put(sceneStartLoading())

    const { error: keyError, response } = yield call(() => api.getUploadKey(project.id))

    if (keyError) {
        window.notify.error(window.$N.MESSAGES.GET_UPLOAD_KEY_ERR, keyError)
        return
    }
    // Upon setting an image, create a new unique file based on this ID
    const prefix = uuid.v4()
    const originalImageUrl = `${config.s3}/${payload.image}`
    const newImageUrl = `${prefix}.${payload.image
        .split('?')[0]
        .split('.')
        .pop()}`
    const newImages = { image: `${project.id}/${newImageUrl}` }
    const fileCreation = [call(() => createFile(originalImageUrl, newImageUrl))]
    if (payload.sketchJsonUrl) {
        const originalSketchUrl = `${config.s3}/${payload.sketchJsonUrl}`
        const newSketchJsonUrl = `${prefix}.${payload.sketchJsonUrl
            .split('?')[0]
            .split('.')
            .pop()}`
        newImages.sketchJsonUrl = `${project.id}/${newSketchJsonUrl}`
        fileCreation.push(call(() => createFile(originalSketchUrl, newSketchJsonUrl)))
    }
    const files = yield fileCreation

    yield call(() => api.batchUploadImages(project.id, response, files))

    const newPayload = { ...payload, ...newImages }

    yield call(() => runSetImage({ payload: newPayload }))

    const { response: album } = yield call(() =>
        api.get(`${config.apiUrl}/projects/${project.id}/album`),
    )

    yield [put(fetchProjectAlbumResp(album)), put(sceneStopLoading())]
}

function* runSetImage({ payload }) {
    let project = yield select(getProject)
    let { id } = payload
    yield put(sceneStartLoading())

    const URL = `${config.apiUrl}/projects/${project.id}/scenes/${id}`
    const { error } = yield call(() => api.put(URL, payload))

    if (error) {
        window.notify.error(window.window.$N.MESSAGES.SET_SCENE_IMAGE_ERR, error)
        yield put(statusFail())
        return
    }

    yield [put(setImageResp(payload)), put(sceneStopLoading())]
}

// Create Scene by slicing text
// ----------------

function* watchCreateSceneBySlice() {
    yield* takeEvery(types.CREATE_SCENE_BY_SLICE, runCreateSceneBySlice)
}

function* runCreateSceneBySlice({ payload }) {
    let { scene, pieceOne, pieceTwo, position } = payload
    let project = yield select(getProject)

    yield put(sceneStartLoading())
    let newScene = R.mergeRight(SCENE_TEMPLATE, {
        projectId: project.id,
        voiceOver: pieceTwo,
    })
    const CREATE_SCENE_URL = `${config.apiUrl}/projects/${project.id}/scenes?position=${position}`
    // Create new scene
    let createOp = yield call(() => api.post(CREATE_SCENE_URL, newScene))
    if (createOp.error) {
        window.notify.error(window.window.$N.MESSAGES.CREATE_SCENE_VIA_SHORTCUT_ERR, createOp.error)
        yield put(statusFail())
        yield put(sceneStopLoading())
        return
    }

    if (
        R.pathOr(false, ['response', 'error'], createOp) &&
        createOp.response.error.includes('Exceeded')
    ) {
        yield put(sceneStopLoading())
        yield call(() =>
            window.sagaHistory.push({
                pathname: '/billing',
                state: { modal: true, closeable: true, upgrade: true },
            }),
        )
        return
    }
    yield put(
        updateScene({
            content: pieceOne,
            field: 'voiceOver',
            scene: R.omit(['number'], scene),
        }),
    )
    yield put(createSceneResp({ scene: createOp.response, position }))
    yield put(clearActiveScene())
    yield put(activateScene(createOp.response))
    yield put(sceneStopLoading())
}

// Export!
// ----------------

export default function*() {
    yield fork(watchCreateScene)
    yield fork(watchDeleteScene)
    yield fork(watchReorderScene)
    yield fork(watchSetImage)
    yield fork(watchRunCloneSetImage)
    yield fork(watchUpdateScene)
    yield fork(watchCreateSceneBySlice)
    yield fork(watchRunUploadSceneImage)
}
