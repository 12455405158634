import PropTypes from 'prop-types'
import React, { useState } from 'react'
import InlineEdit from './InlineEdit'

import Dropdown from './Dropdown'
import PrintIcon from '../icons/PrintIcon'
import DuplicateIcon from '../icons/DuplicateIcon'
import LeaveProjectIcon from '../icons/LeaveIcon'
import ShareIcon from '../icons/ShareIcon'
import GlobalActivityIndicator from '../components/globalActivityIndicator'
// import LeaveProject from '../components/project/leaveProject'

const HeaderProjectMeta = props => {
    const ITEMS = []
    if (props.permissions && (props.permissions.edit || props.permissions.comment)) {
        ITEMS.push({
            value: 'Print',
            Icon: PrintIcon,
            to: {
                pathname: `/projects/${props.project.id}/print`,
                state: { modal: true, closeable: true },
            },
        })
    }
    if (props.permissions && props.permissions.owner) {
        ITEMS.push(
            {
                value: 'Share',
                Icon: ShareIcon,
                to: {
                    pathname: `/projects/${props.project.id}/invite`,
                    state: { modal: true, closeable: true },
                },
            },
            {
                value: 'Duplicate',
                Icon: DuplicateIcon,
                to: {
                    pathname: `/projects/${props.project.id}/duplicate`,
                    state: { modal: true, closeable: true },
                },
            },
        )
    }
    if (props.permissions && !props.permissions.owner) {
        ITEMS.push({
            value: 'Leave Project',
            Icon: LeaveProjectIcon,
            onClick: () => props.route(`/projects/${props.project.id}/leave-project`),
        })
    }
    return (
        <div className="project-meta">
            <div className="b-b b-primary b-2x outline-none project-meta__info max-width-85">
                {props.permissions.owner ? (
                    <InlineEdit
                        validate={text => !!text.length}
                        text={props.project.title}
                        paramName="title"
                        change={({ title }) => props.updateProject({ title })}
                    />
                ) : (
                    <span
                        style={{
                            alignSelf: 'flex-end',
                            fontSize: '16px',
                            color: '#fff',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            height: '32px',
                            paddingBottom: '10px',
                            maxWidth: '100%',
                        }}>
                        {props.project.title}
                    </span>
                )}
            </div>
            <Dropdown
                items={ITEMS}
                className="project-dropdown project-header-dropdown"
                dropdownButtonStyle="project-header-dropdown__btn"
                dropdownBodyWrapperStyle="project-dropdown__body-wrapper"
                dropdownBodyStyle="project-dropdown__body"
            />
            <GlobalActivityIndicator />
        </div>
    )
}

HeaderProjectMeta.propTypes = {
    project: PropTypes.object,
    updateProject: PropTypes.func,
    permissions: PropTypes.object,
}

export default HeaderProjectMeta
