import styled from 'styled-components/macro'

import CommonStyledButton from '../common/styled/StyledButton'
import CommonStyledInput from '../common/styled/StyledInput'
import CommonStyledLink from '../common/styled/StyledLink'

export const StyledButton = styled(CommonStyledButton)``
export const StyledInput = styled(CommonStyledInput)``
export const StyledLink = styled(CommonStyledLink)``

export const SceneWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: stretch;
    height: 100%;
    margin: 0 14px;
    // width: 92%;
    flex: 1;

    > div:not(.scene__new-scene-container) {
        flex: 1;
    }

    .scene__new-scene-container {
        display: flex;
        flex: 1;
        min-height: 250px;

        @media (max-width: 768px) {
            margin-top: 10vw;
        }
    }

    .scene_new-scene-icon {
        color: #ccc;
        font-size: 18px;
    }

    ${props =>
        props.presentationpreview &&
        `
            textarea {
                border: none;
                color: #000;
                font-size: 18px;
                &:focus {
                    background: none;
                }
            }
        `}

    ${props =>
        props.printing &&
        `
            height: 50%;
            display: block;
            margin: 0;
            
            .scene-inner-container {
                border-color:#eee !important;
            }
  `}
`

export const SceneImageWrapper = styled.div`
    flex: 1 1;
    position: relative;
    svg,
    img,
    .imageloader {
        pointer-events: none;
    }
`

export const SceneNumberWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 19px;
    z-index: 1;
`

export const SceneDescription = styled.div`
    background-color: #fff;
    color: #6b7379;
    width: 100%;

    ${props =>
        props.presentationpreview &&
        `
        min-height: 85px;

        .scene__description-title {
            font-size: 16px;
        }
    `}
`

export const SceneVoiceOverWrapper = styled.div`
    position: relative;

    ${props =>
        props.presentationpreview &&
        `
        .scene__voice-over {
            min-height: 85px;

            .scene__voice-over_title {
                font-size: 16px;
            }
            p {
                white-space: break-spaces;
                font-size: 20px;
            }
        }
    `}
`

export const CreateSceneCard = styled.div`
    border: 2px dashed rgba(0, 0, 0, 0.05);
    background: white;
    position: relative;
    cursor: pointer;
    flex: 1;
    i {
        //The plus icon
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        border: 2px dashed rgba(10, 223, 166, 0.5);
    }
`

export const SceneGutter = styled.div`
    position: absolute;
    padding: 14px 7px;
    border-left: 1px dashed transparent;
    height: 92%;
    cursor: pointer;
    display: table-cell;
    bottom: 14px;
    top: 14px;
    i {
        position: absolute;
        top: 50%;
        left: 9px;
        transform: translateY(-50%);
        display: none;
    }
    &:hover {
        border-right: 1px dashed #ccc;
        i {
            display: block;
        }
    }
`
export const GutterLeft = styled(SceneGutter)`
    left: ${props => (props.leftEdge ? -24 : -29)}px;
`
export const GutterRight = styled(SceneGutter)`
    right: 14px;

    div {
        display: none;
    }
`
