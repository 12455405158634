import * as R from 'ramda'
import * as types from '../constants/ActionTypes'
import { FETCH_FOLDERS_RESP } from '../constants/Folders'
import * as helpers from './helpers'

let sceneOrderLens = R.lensProp('sceneOrder')

const setSceneOrder = R.set(sceneOrderLens)

function updateProjects(state, payload) {
    state = state || []
    return !R.any(R.propEq('id', payload.project.id), state)
        ? R.append(payload.project, state)
        : state
}

function updateSceneOrder(state, { position, scene }) {
    return setSceneOrder(
        R.insert(
            typeof position === 'undefined' ? state.sceneOrder.length : position,
            scene.id,
            state.sceneOrder,
        ),
        state,
    )
}

const fetchProjectsFromFolders = objs => {
    return R.pipe(R.values, R.chain(R.prop('storyboards')))(objs)
}

function updateProjectSceneOrder(state, payload) {
    return setSceneOrder(payload, state)
}

function deleteProject(state, projectId) {
    return R.filter(project => project.id !== projectId, state)
}

function updateSceneOrderOnSceneDelete(state, scene) {
    let sceneOrder = R.remove(state.sceneOrder.indexOf(scene.id), 1, state.sceneOrder)
    return R.mergeRight(state, { sceneOrder })
}

export function project(state = null, { type, payload }) {
    switch (type) {
        case types.FETCH_PROJECT_BY_ID_RESP:
            return payload.project
        case types.SET_ACTIVE_PROJECT:
            return payload
        case types.CREATE_SCENE_RESP:
            return state ? updateSceneOrder(state, payload) : state
        case types.CREATE_PROJECT_RESP:
            return payload
        case types.UPDATE_PROJECT_RESP:
            return payload
        case types.REORDER_SCENE_RESP:
            return updateProjectSceneOrder(state, payload)
        case types.UPDATE_ROUTE:
            return helpers.activeProjectView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.profileView(payload.pathname) ||
                helpers.CancelSubscriptionView(payload.pathname) ||
                helpers.projectDeleteView(payload.pathname) ||
                helpers.folderCreateView(payload.pathname) ||
                helpers.folderDeleteView(payload.pathname) ||
                helpers.folderRenameView(payload.pathname) ||
                helpers.teamPlanView(payload.pathname) ||
                helpers.folderShareView(payload.pathname)
                ? state
                : null
        case types.DELETE_SCENE_RESP:
            return updateSceneOrderOnSceneDelete(state, payload)
        default:
            return state
    }
}

export function permissions(state = null, { type, payload }) {
    switch (type) {
        case types.FETCH_PROJECT_BY_ID_RESP:
            return payload.permissions
        case types.SET_USER_PROJECT_PERMISSIONS:
            return payload
        case types.UPDATE_ROUTE:
            return helpers.activeProjectView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.teamPlanView(payload.pathname) ||
                helpers.CancelSubscriptionView(payload.pathname) ||
                helpers.profileView(payload.pathname) ||
                helpers.projectDeleteView(payload.pathname) ||
                helpers.folderCreateView(payload.pathname) ||
                helpers.folderDeleteView(payload.pathname) ||
                helpers.folderRenameView(payload.pathname) ||
                helpers.folderShareView(payload.pathname)
                ? state
                : null
        default:
            return state
    }
}

export function projects(state = null, { type, payload }) {
    switch (type) {
        // case types.FETCH_PROJECTS_RESP:
        //     return payload
        case FETCH_FOLDERS_RESP:
            return fetchProjectsFromFolders(payload)
        case types.FETCH_PROJECT_BY_ID_RESP:
            return updateProjects(state, payload)
        case types.DELETE_PROJECT_RESP:
            return deleteProject(state, payload)
        default:
            return state
    }
}

export function filter(state = 'Show All', { type, payload }) {
    switch (type) {
        case types.SET_PROJECT_FILTER:
            return payload
        case types.UPDATE_ROUTE:
            if (
                helpers.projectCreateView(payload.pathname) ||
                helpers.projectDeleteView(payload.pathname)
            ) {
                return state
            }
            if (helpers.activeProjectView(payload.pathname)) {
                return 'Show All'
            }
            return helpers.dashboardView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.profileView(payload.pathname)
                ? state
                : 'Show All'
        default:
            return state
    }
}

export function search(state = '', { type, payload }) {
    switch (type) {
        case types.SEARCH_PROJECTS_BY_TITLE:
            return payload
        case types.UPDATE_ROUTE:
            if (helpers.projectCreateView(payload.pathname)) {
                return state
            }
            if (helpers.activeProjectView(payload.pathname)) {
                return ''
            }
            return helpers.dashboardView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.profileView(payload.pathname)
                ? state
                : ''
        default:
            return state
    }
}

export function imageUploading(state = false, { type, _ }) {
    switch (type) {
        // on image upload action, set this to true
        case types.UPLOAD_IMG:
            return true
        // on project album re-fetch turn this off
        case types.FETCH_PROJECT_ALBUM_RESP:
            return false
        default:
            return state
    }
}
