import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import CommentOverlay from '../../containers/CommentOverlayContainer'
import uuid from 'uuid'
import * as R from 'ramda'
import UploadIcon from '../../icons/UploadIcon'

const CommentActionButton = React.forwardRef(({ toggleOpen, setChildOpen, open }, ref) => {
    const [hovered, setHovered] = React.useState(false)
    const onClick = React.useCallback(() => {
        toggleOpen()
        setChildOpen(!open)
    }, [open, toggleOpen, setChildOpen])
    return (
        <div ref={ref} className={`cp inline-block scene-action-button ${open ? 'active' : ''}`}>
            {open || hovered ? (
                <span
                    className="fa-stack fa-lg scene-action-button--icon"
                    onClick={onClick}
                    onMouseOver={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}>
                    <i className="fa fa-stack-2x text-primary" />
                    <i className="fa fa-comment fa-stack-1x text-white" />
                </span>
            ) : (
                <span
                    className="fa-stack fa-lg scene-action-button--icon"
                    onClick={onClick}
                    onMouseOver={() => setHovered(true)}>
                    <i className="fa fa-stack-2x text-light" />
                    <i className="fa fa-comment fa-stack-1x text-white" />
                </span>
            )}
        </div>
    )
})

function useFileDialogCancel({ onFileDialogCancel }) {
    const isFileDialogOpened = React.useRef(false)
    const inputRef = React.useRef()
    React.useEffect(() => {
        let timer
        const handler = () => {
            timer = setTimeout(() => {
                if (isFileDialogOpened.current && !inputRef.current.value.length) {
                    onFileDialogCancel()
                    isFileDialogOpened.current = false
                }
            }, 700)
        }
        window.addEventListener('focus', handler)
        return () => {
            window.removeEventListener('focus', handler)
            clearTimeout(timer)
        }
    }, [onFileDialogCancel])

    return {
        inputRef,
        isFileDialogOpened,
    }
}

const UploadSceneImage = ({ scene, setHovered, setChildOpen, uploadSceneImage }) => {
    // const SUPPORTED_IMG_EXTENSIONS = ['jpg', 'png', 'jpeg']
    const onFileDialogCancel = () => {
        setChildOpen(false)
        setHovered(false)
    }
    const { inputRef, isFileDialogOpened } = useFileDialogCancel({
        onFileDialogCancel,
    })

    const onChange = e => {
        const targetFile = e.target.files[0]
        if (targetFile) {
            const uploadFile = new File(
                [targetFile],
                `${uuid.v4()}.${targetFile.name
                    .split('?')[0]
                    .split('.')
                    .pop()}`,
                {
                    type: targetFile.type,
                },
            )
            uploadSceneImage(R.mergeRight(scene, { uploadFile, sketchJsonUrl: '' }))
        } else {
            window.$N.error(`There was a problem uploading the file.`)
        }
        setChildOpen(false)
        setHovered(false)
    }

    return (
        <div className="icon-link cp inline-block scene-action-button">
            <label
                htmlFor={`upload-scene-image__${scene.id}`}
                style={{ lineHeight: 0 }}
                className="fa-stack fa-lg m-b-none scene-action-button--icon">
                <UploadIcon className="scene-action-button--icon" />
            </label>
            <input
                ref={inputRef}
                accept="image/*"
                id={`upload-scene-image__${scene.id}`}
                type="file"
                onClick={() => {
                    setChildOpen(true)
                    isFileDialogOpened.current = true
                }}
                onBlur={() => {
                    isFileDialogOpened.current = true
                }}
                onChange={onChange}
            />
        </div>
    )
}

const SceneActionButtons = props => {
    const paint = () => {
        props.togglePaint()
        props.activateScene(props.scene)
    }

    const {
        scene,
        show,
        allowDelete,
        permissions: { edit: canEdit, comment: canComment },
        isDragging,
        presentationpreview,
        publicProject,
    } = props

    return show && !isDragging ? (
        <div className="fill noprint">
            <div className="scene-action-container">
                <div className="scene-action-left">
                    {/* COMMENT */}
                    {/* {canComment && !presentationpreview && ( */}
                    {canComment && (
                        <CommentOverlay
                            presentationpreview={presentationpreview}
                            scene={scene}
                            setChildOpen={props.setChildOpen}
                            setHovered={props.setHovered}
                            onCommentSubmit={props.onCommentSubmit}>
                            <CommentActionButton setChildOpen={props.setChildOpen} />
                        </CommentOverlay>
                    )}
                    {/* PAINT */}
                    {canEdit && (
                        <div
                            className="icon-link cp inline-block scene-action-button"
                            onClick={paint}>
                            <span className="fa-stack fa-lg scene-action-button--icon">
                                <i className="fa fa-stack-2x text-primary" />
                                <i className="fa fa-paint-brush fa-stack-1x text-white" />
                            </span>
                        </div>
                    )}
                    {/* UPLOAD */}
                    {/* {canEdit && !presentationpreview && ( */}
                    {canEdit && (
                        <UploadSceneImage
                            show={props.show}
                            setHovered={props.setHovered}
                            uploadSceneImage={props.uploadSceneImage}
                            scene={scene}
                            setChildOpen={props.setChildOpen}
                        />
                    )}
                    {/* Large Preview */}
                    {!presentationpreview && (
                        <Link
                            className="icon-link cp inline-block scene-action-button"
                            onClick={() => props.activateScene(props.scene)}
                            to={{
                                pathname: `/${publicProject ? 'public-projects' : 'projects'}/${
                                    props.project.id
                                }/preview`,
                                // state: { modal: true, closeable: true },
                            }}>
                            <span className="fa-stack fa-lg scene-action-button--icon">
                                <i className="fa fa-stack-2x text-primary" />
                                <i className="fa fa-search-plus fa-stack-1x text-white" />
                            </span>
                        </Link>
                    )}
                </div>
                <div className="scene-action-right">
                    {/* DELETE */}
                    {allowDelete && canEdit && (
                        <Link
                            className="icon-link cp inline-block scene-action-button"
                            onClick={() => props.activateScene(props.scene)}
                            to={{
                                pathname: `/projects/${props.project.id}/scenes/${props.scene.id}/delete`,
                                state: { modal: true, closeable: true },
                            }}>
                            <span className="fa-stack fa-lg scene-action-button--icon delete">
                                <i className="fa fa-stack-2x text-danger" />
                                <i className="fa fa-trash fa-stack-1x text-white" />
                            </span>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    ) : null
}

SceneActionButtons.propTypes = {
    onCommentSubmit: PropTypes.func,
    activateScene: PropTypes.func,
    show: PropTypes.bool,
    allowDelete: PropTypes.bool,
    delete: PropTypes.func,
    permissions: PropTypes.object,
    project: PropTypes.object,
    scene: PropTypes.object,
    togglePaint: PropTypes.func,
}

export default SceneActionButtons
