import * as types from '../constants/ActionTypes';
import config from '../config';

function readSessionFromCookie() {
  let data = localStorage.getItem(config.sessionCookie);
  let parsedData;
  try {
    parsedData = JSON.parse(data);
  } catch (e) {
    return null;
  }
  return parsedData;
}

export default function(state = readSessionFromCookie(), action) {
  switch (action.type) {
    case types.CREATE_SESSION:
      return action.payload;
    case types.DELETE_SESSION:
      return null;
    default:
      return state;
  }
}
