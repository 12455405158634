import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import Comments from '../components/project/comments'
import actions from '../actions'
import { COMMENT_STATUS } from '../constants/Comments'

const epochCreateDate = R.compose(x => +new Date(x), R.head, R.split(/\s/))

const lensDateCreated = R.lensProp('dateCreatedEpoch')

const setDateCreated = R.set(lensDateCreated, epochCreateDate)

const lensSceneNumber = R.lensProp('sceneNumber')

const lensFullName = R.lensProp('fullName')

const fullName = user => {
    if (
        (R.prop('firstName', user) === null && R.prop('lastName', user) === null) ||
        (R.prop('firstName', user) === '' && R.prop('lastName', user) === '')
    ) {
        return 'Anonymous'
    }
    return `${R.prop('firstName', user)} ${R.prop('lastName', user)}`
}

function getComments({ sceneOrder }, comments) {
    // remove comments whose ids don't match those in sceneOrder
    let validComments = R.filter(c => sceneOrder.indexOf(c.sceneId) > -1, comments)
    // Adds scene number
    let sceneNumber = R.compose(R.add(1), R.indexOf(R.__, sceneOrder), R.prop('sceneId'))
    // Adds full name and scene number and date
    let transform = R.compose(
        c => R.set(lensFullName, fullName(c.user), c),
        c => R.set(lensSceneNumber, sceneNumber(c), c),
        setDateCreated,
    )

    let results = R.map(transform, validComments)
        .sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
        .sort((a, b) => a.sceneNumber - b.sceneNumber)

    const parentCommentIdArray = []
    const finalArray = []

    const sortingCommentFunction = (results, n) => {
        for (let i = 0; i < n; i++) {
            if (results[i] && !results[i].replyToComment && parentCommentIdArray.length === 0) {
                finalArray.push(results[i])
                parentCommentIdArray.push(results[i].id)
                results.splice(i, 1)
                sortingCommentFunction(results, n - 1)
            } else {
                const currentParentCommentId = parentCommentIdArray[parentCommentIdArray.length - 1]
                if (
                    results[i] &&
                    results[i].replyToComment &&
                    currentParentCommentId === results[i].replyToComment
                ) {
                    finalArray.push(results[i])
                    parentCommentIdArray.push(results[i].id)
                    results.splice(i, 1)
                    sortingCommentFunction(results, n - 1)
                }
            }
        }
        if (n > 0) {
            if (!parentCommentIdArray.length) {
                return finalArray
            }
            parentCommentIdArray.pop()
            sortingCommentFunction(results, results.length)
        }
    }

    sortingCommentFunction(results, results.length)

    return finalArray
}

class CommentsContainer extends React.Component {
    resolve = comment => {
        let payload = R.mergeRight(comment, { status: COMMENT_STATUS.RESOLVED })
        this.props.updateComment(payload)
    }

    unresolve = comment => {
        let payload = R.mergeRight(comment, { status: COMMENT_STATUS.ACTIVE })
        this.props.updateComment(payload)
    }

    reply = payload => {
        payload.body.trim() && this.props.createComment(payload)
    }

    render = () => {
        let getSceneNumber = R.compose(
            R.add(1),
            R.indexOf(R.__, this.props.project.sceneOrder),
            R.prop('sceneId'),
        )

        return (
            <Comments
                commentRefs={this.props.commentRefs}
                activateScene={this.props.activateScene}
                activeScene={this.props.scene}
                comments={getComments(this.props.project, this.props.comments)}
                commentsFilteredBySceneId={this.props.commentsFilteredBySceneId}
                delete={this.props.deleteComment}
                getSceneNumber={getSceneNumber}
                permissions={this.props.permissions}
                project={this.props.project}
                reply={this.reply}
                resolve={this.resolve}
                session={this.props.session}
                unresolve={this.unresolve}
                scenes={this.props.scenes}
                isProjectPublic={this.props.isProjectPublic}
            />
        )
    }
}

CommentsContainer.propTypes = {
    activateScene: PropTypes.func,
    comments: PropTypes.array,
    commentsFilteredBySceneId: PropTypes.bool,
    createComment: PropTypes.func,
    deleteComment: PropTypes.func,
    dispatch: PropTypes.func,
    permissions: PropTypes.object,
    project: PropTypes.object,
    scene: PropTypes.object,
    scenes: PropTypes.array,
    session: PropTypes.object,
    updateComment: PropTypes.func,
}

export default connect(
    state => {
        return R.pick(
            [
                'comments',
                'project',
                'scene',
                'scenes',
                'session',
                'permissions',
                'commentsFilteredBySceneId',
                'replyToComment',
            ],
            state,
        )
    },
    {
        ...actions.comments,
        ...actions.scenes,
    },
)(CommentsContainer)
