import styled from 'styled-components'

export const toolbarWidth = '350px'

export const ProjectWrapper = styled.div`
    width: 100%;
    position: relative;
    height: calc(100vh - 111px);
`

export const ProjectContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: visible;
    padding-top: 20px;
`

export const GridWrapper = styled.div`
    height: 100%;
    width: calc(100% - 378px);
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;
    @media (max-width: 768px) {
        width: calc(100% - 20px);
    }
`

export const ToolbarWrapper = styled.div`
    position: fixed;
    top: 99px;
    bottom: 0;
    height: calc(100% - 130px);
    right: 30px;
    min-width: ${toolbarWidth};
    z-index: 600;
    @media (max-width: 768px) {
        display: none;
    }
    textarea {
        min-height: auto;
    }
`

export const ScenesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;

    @media (max-width: 1100px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
