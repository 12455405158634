import actions from '../actions'
import Project from '../components/project'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { has, PERMISSIONS } from '../utils/permissions'

import Loader from '../components/common/loader'

const canEdit = m => has(PERMISSIONS.OWNER, m.permissions) || has(PERMISSIONS.EDIT, m.permissions)

const editorCountFromMembers = R.compose(
    R.reduce((r, inc) => (r += inc), 0),
    R.map(m => (canEdit(m) ? 1 : 0)),
)

/**
 * @module ProjectContainer
 */
class ProjectContainer extends React.Component {
    componentDidMount() {
        const projectId = this.props.match.params.projectId
        if (!this.props.project || projectId !== this.props.project.id) {
            this.props.fetchProjectById(projectId)
        }
    }

    render() {
        const projectId = this.props.match.params.projectId
        return this.props.project && projectId === this.props.project.id ? (
            <Project {...this.props} />
        ) : (
            <Loader message="Loading Project" />
        )
    }
}

ProjectContainer.propTypes = {
    fetchProjectById: PropTypes.func,
    params: PropTypes.object,
    project: PropTypes.object,
}

export default withRouter(
    connect(
        state => {
            let {
                project,
                scenes,
                members,
                showPaintCanvas,
                imageUploading,
                comments,
                permissions,
            } = state

            return {
                hasMultipleEditors: editorCountFromMembers(members || []) > 1,
                comments,
                project,
                scenes,
                showPaintCanvas,
                imageUploading,
                allowEdit: permissions && (permissions.edit || permissions.owner),
            }
        },
        {
            ...actions.scenes,
            ...actions.projects,
            ...actions.comments,
        },
    )(ProjectContainer),
)
