import styled, { css } from 'styled-components'
import CommonStyledButton from '../common/styled/StyledButton'

export const toolbarWidth = '350px'

export const StyledButton = styled(CommonStyledButton)``

export const ProjectWrapper = styled.div`
    width: 100%;
    position: relative;
    height: calc(100vh - 111px);
`

export const ProjectContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: visible;
`

export const DndGridWrapper = styled.div`
    height: 100%;
    width: calc(100% - 378px);
    overflow: visible;
    padding-left: 16px;
    @media (max-width: 768px) {
        width: calc(100% - 20px);
    }
`

export const ToolbarWrapper = styled.div`
    position: fixed;
    top: 99px;
    bottom: 0;
    height: calc(100% - 130px);
    right: 30px;
    min-width: ${toolbarWidth};
    z-index: 600;
    @media (max-width: 768px) {
        display: none;
    }
    textarea {
        min-height: auto;
    }
`
