import styled, { css } from 'styled-components'

export const StyledInput = styled.div`
    position: relative;
    width: 100%;
    input {
        background-color: transparent;
        color: #fff;
        width: 100%;
        outline: none;
        margin: 0;
        padding: 13px 20px;
        border: 2px solid #686f75;
        border-radius: 4px;
        &:focus {
            border-color: #0adfa6;
            background-color: #1a5444;
        }
        &:focus + label {
            color: white;
        }
        ${props =>
            props.alignLeft &&
            css`
                text-align: left;
            `}
    }
    label {
        right: 0;
        bottom: 0;
        margin-right: 6px;
        margin-bottom: 6px;
        font-size: 0.7em;
        position: absolute;
        text-align: right;
        color: #686f75;
        text-transform: uppercase;
        font-weight: bold;
    }
`
