import React from 'react'

export default ({ fill = '#788188', className, size = '25px', viewBox = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        height={size}
        width={size}
        className={className}
        viewBox={viewBox}>
        <g>
            <path d="M0 2h25v4H0V2zm0 8h25v4H0v-4zm0 8h25v4H0v-4z" fill={fill} id="Hamburger" />
        </g>
    </svg>
)
