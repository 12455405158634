import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, withRouter } from 'react-router-dom'

import PrintFlow from './PrintFlow'
import Modal from '../common/Modal'
import Scene from '../../components/scene/SceneContainer'
import Script from '../../containers/ScriptContainer'

const PRINT_WINDOW_PARAMS = `toolbar=no,
        location=no,
        directories=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=yes,
        width=${window.screen.width},
        height=${window.screen.height},
        top=0,
        left=0`

/**
 * @module PrintModal
 */

const imageOnlyLayouts = ['four', 'nine']
class PrintModal extends React.Component {
    print = ({ layout, description }) => {
        let { project } = this.props

        const mainCSSFileName = /main\..*\.css/g
        const stylesCSS = [].slice
            .call(window.document.head.getElementsByTagName('link'))
            .find(link => link.href.match(mainCSSFileName))
        if (stylesCSS) {
            stylesCSS.href = stylesCSS.href.match(window.origin)
                ? stylesCSS.href
                : `${window.origin}/${stylesCSS.href}`
        }
        const printStyle =
            process.env.NODE_ENV === 'production'
                ? `<title>${project.title}</title>
<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,200italic,600,700,400' type='text/css'>
<link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/font-awesome/4.6.2/css/font-awesome.min.css'>
<link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css' integrity='sha384-1q8mTJOASx8j1Au+a5WDVnPi2lkFfwwEAa8hDDdjZlpLegxhjVME1fgjWPGmkzs7' crossorigin='anonymous'>
${stylesCSS ? stylesCSS.outerHTML : ''}`
                : window.document.head.outerHTML

        const wordNumberMap = {
            six: 6,
            nine: 9,
            three: 3,
            four: 4,
            one: 1,
        }
        // open window and set content
        let printWindow = window.open('', project.title, PRINT_WINDOW_PARAMS)
        printWindow.document.body.innerHTML = `<html>
                                                ${printStyle}
                                                <div class="print"></div>
                                            </html>`
        render(
            this.renderPrint(layout, wordNumberMap[layout], description),
            printWindow.document.querySelector('.print'),
            () => {
                setTimeout(() => {
                    printWindow.focus()
                    printWindow.print()
                }, 1000)
            },
        )
        this.props.history.goBack()
    }

    returnTo = e => {
        e.stopPropagation()
        this.props.history.goBack()
    }

    renderPrint = (layout, numScenes, description) => (
        <Provider store={window.plotStore}>
            <BrowserRouter>
                <div style={{ height: '100%' }}>
                    <div className="print-title-page vcc text-center cover-page">
                        <div className="vc">
                            <h1 className="text-u-c">{this.props.project.title}</h1>
                            <p className="project-description">{description}</p>
                        </div>
                    </div>
                    {layout === 'text' ? (
                        <Script printing />
                    ) : (
                        <div className={`print-rows ${layout}-print-layout`}>
                            {R.splitEvery(numScenes, this.props.scenes).map(
                                (sceneRow, rowNumber) => (
                                    <div key={`${sceneRow}-${rowNumber}`} className="print-row">
                                        {sceneRow.map((scene, key) => (
                                            <Scene
                                                printing
                                                imageOnly={imageOnlyLayouts.includes(layout)}
                                                key={scene.id || scene.type}
                                                scene={scene}
                                                sceneNumber={rowNumber * numScenes + (key + 1)}
                                                wrapperClasses="scene"
                                            />
                                        ))}
                                    </div>
                                ),
                            )}
                        </div>
                    )}
                </div>
            </BrowserRouter>
        </Provider>
    )

    render = () => {
        return (
            <Modal isCloseable isCancelable>
                <PrintFlow onComplete={this.print} />
            </Modal>
        )
    }
}

PrintModal.propTypes = {
    scenes: PropTypes.array,
    project: PropTypes.object,
    returnTo: PropTypes.string,
}

PrintModal.displayName = 'PrintModal'

export default withRouter(React.memo(PrintModal))
