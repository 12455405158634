import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import actions from '../actions'
import MyAccount from '../components/MyAccount'

export default withRouter(
    connect(
        state => {
            let { session, user: userState } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            const cognitoUser = (session && session.cognitoUser) || {}
            return { user, userLoading: userState.loading, cognitoUser }
        },
        {
            ...actions.session,
            ...actions.userAccountAction,
        },
    )(MyAccount),
)
