import React from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { StyledCloseModalButton } from './styled'

export default React.forwardRef((props, ref) => (
    <StyledCloseModalButton
        presentationpreview={props.presentationpreview}
        ref={ref}
        onClick={props.onClick}>
        <VisuallyHidden>Close modal</VisuallyHidden>
        <i className="close" />
    </StyledCloseModalButton>
))
