import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { splitAtCursor } from '../utils'
import SceneNumber from './scene/SceneNumber'
import TextareaAutosize from 'react-textarea-autosize'

const CustomTextArea = ({
    activateScene,
    createSceneBySlice,
    field,
    slicable,
    sceneNumber,
    isActive,
    permissions,
    scene,
    sceneLoading,
    tag,
    updateScene,
    tabIndex,
    onFocus,
    focusedScriptType,
    focusOnActive,
    innerRef,
}) => {
    let pasting = false
    const keymap = []
    const makeNewScene = React.useRef(false)
    const ref = innerRef || React.createRef()

    useEffect(() => {
        if (focusedScriptType === 'initial') {
            return
        }
        const isFocusedScriptType =
            (focusedScriptType === 'tag' && tag) || (focusedScriptType === 'block' && !tag)
        const justActivated = isActive

        // This helps us focus a scene when its activated, included after a slice
        if (justActivated && focusOnActive && isFocusedScriptType) {
            ref.current.focus()
        }
    }, [isActive])

    const handleKeyUp = ev => {
        if (!slicable) {
            return
        }
        // sceneLoading? no funny business!
        if (ev.keyCode === 17 || ev.keyCode === 13) {
            keymap[ev.keyCode] = false
        }
        if (sceneLoading) {
            return
        }
        if (makeNewScene.current) {
            const [pieceOne, pieceTwo] = splitAtCursor(ev)
            createSceneBySlice({
                pieceOne,
                pieceTwo,
                position: sceneNumber,
                scene: scene,
            })
            makeNewScene.current = false
        }
    }

    const handleKeyDown = ev => {
        if (!slicable) {
            return
        }
        // ctrl + enter should create a new scene
        if (ev.keyCode === 17 || ev.keyCode === 13) {
            keymap[ev.keyCode] = true
        }
        if (sceneLoading) {
            return
        }

        if (keymap[17] && keymap[13]) {
            makeNewScene.current = true
            ev.preventDefault()
            return
        }
    }

    const renderSeparator = () => {
        return <SceneNumber isActive={isActive} number={sceneNumber} />
    }

    const handlePaste = ev => {
        ev.preventDefault()
        var text = ev.clipboardData.getData('text/plain')
        pasting = true
        document.execCommand('insertHTML', false, text)
    }

    /* Toolbar TextArea */
    const renderWithTag = () => {
        let style__active = isActive ? 'active' : ''

        return (
            <div className="m-b-xs text-area" draggable={false}>
                {renderSeparator()}
                <TextareaAutosize
                    ref={ref}
                    value={scene[field] || ''}
                    onChange={e => {
                        // Submit immediately if pasting
                        if (pasting) {
                            updateScene({
                                content: e.target.value,
                                field,
                                scene,
                                debounceInterval: 0,
                            })
                        } else {
                            updateScene({
                                content: e.target.value,
                                field,
                                scene,
                            })
                        }
                    }}
                    onFocus={() => {
                        !scene.locked && activateScene(scene)
                        onFocus()
                    }}
                    onKeyDown={ev => handleKeyDown(ev, field, scene)}
                    onKeyUp={ev => handleKeyUp(ev, field, scene)}
                    readOnly={!permissions.edit || scene.locked}
                    className={`m-l-xs border-none resize-none w100 h100 _lr-hide scene-description outline-none ${
                        !permissions.edit ? 'cp' : ''
                    } text-color-ddd ${style__active}`}
                    spellCheck
                    placeholder={permissions.edit ? 'Enter text here...' : ''}
                />
                <ReactTooltip class="tooltipSuccess" type="success" effect="solid" />
            </div>
        )
    }

    const handleChange = e => {
        // Submit immediately if pasting
        if (pasting) {
            updateScene({
                content: e.target.value,
                field,
                scene,
                debounceInterval: 0,
            })
        } else {
            updateScene({
                content: e.target.value,
                field,
                scene,
            })
        }
    }
    /* Scene TextArea */
    const renderAsBlock = () => {
        let style__active = isActive ? 'active' : ''

        return (
            <TextareaAutosize
                ref={ref}
                value={scene[field] || ''}
                onChange={handleChange}
                onKeyDown={ev => handleKeyDown(ev, field, scene)}
                onKeyUp={ev => handleKeyUp(ev, field, scene)}
                readOnly={!permissions.edit || scene.locked}
                disabled={!permissions.edit || scene.locked}
                onFocus={onFocus}
                tabIndex={tabIndex}
                className={`outline-none border-none w100 h100 resize-none scene-description text-color-ddd ${style__active}`}
                spellCheck
                placeholder={permissions.edit ? 'Enter text here...' : ''}
            />
        )
    }

    return tag ? renderWithTag() : renderAsBlock()
}

CustomTextArea.propTypes = {
    activateScene: PropTypes.func,
    createScene: PropTypes.func,
    createSceneBySlice: PropTypes.func,
    deleteScene: PropTypes.func,
    field: PropTypes.string,
    slicable: PropTypes.bool,
    index: PropTypes.number,
    isActive: PropTypes.bool,
    permissions: PropTypes.object,
    scene: PropTypes.object,
    sceneLoading: PropTypes.bool,
    tag: PropTypes.bool,
    updateScene: PropTypes.func,
    tabIndex: PropTypes.number,
}

CustomTextArea.defaultProps = {
    slicable: true,
    onFocus: () => {},
}

export default React.memo(CustomTextArea)
