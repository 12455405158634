import React from 'react';

export default ({
  fill = 'white',
  enableBackground = 'new 0 0 1024 1024',
  className,
  ...style
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{ fill, enableBackground, ...style }}
    className={className}
  >
    <path d="M66.75,478.4A31.81,31.81,0,0,1,38,461.4c-6.72-12.31-5.25-26.33,3.91-37.52,4.12-5,8.91-10.71,14.29-16.17A128.1,128.1,0,0,0,84.83,362.9c-4.82-3.29-9.71-6.72-14.5-10.41-27-20.83-45.79-43.91-57.4-70.55C2.65,258.37-1.53,234.5.5,211c2-22.93,9.89-45.64,23.52-67.52,25.13-40.35,62.41-69.19,114-88.17,75.23-27.7,151.72-28.89,227.34-3.55,49.8,16.69,87.49,42.71,115.21,79.54,21.88,29.1,32.72,62.75,31.35,97.31s-14.79,67.26-38.81,94.8c-34.22,39.24-80,65.2-139.88,79.35-33.74,8-69.28,10.39-108.51,7.32-39.15,32.49-85.44,54.53-137.61,65.53-4.32.9-8.38,1.48-12.32,2l-2.37.34A38.93,38.93,0,0,1,66.75,478.4ZM256.65,75c-34.91,0-69.78,6.37-104.37,19.1-42.6,15.68-73.07,39-93.15,71.22-20.55,33-23.26,65.71-8.28,100.06,8.75,20.1,23.39,37.86,44.73,54.32,5.27,4.06,10.9,7.88,16.86,11.93,2.77,1.88,5.64,3.83,8.5,5.82l11.73,8.18-3.51,13.87a169.55,169.55,0,0,1-39.48,73c41.58-10.39,78.56-29,110-55.53,2.7-2.27,12.42-9.55,24.53-8.5,36.36,3.18,68.89,1.2,99.43-6,51.21-12.1,89.88-33.78,118.23-66.29,36.18-41.48,38.37-96.45,5.58-140.05C425,126.24,393.79,104.91,352.19,91A299.38,299.38,0,0,0,256.65,75Zm-36,334.72h0Z" />
  </svg>
);
