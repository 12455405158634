import * as types from '../constants/ActionTypes';

export default function focusedScriptType(state = 'initial', action) {
  switch (action.type) {
    case types.CHANGE_SCENE_SCRIPT_FOCUS:
      return action.payload;
    default:
      return state;
  }
}
