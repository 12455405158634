import React from 'react';
import style from './strokeStyle';

export default () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1079 720"
    style={style.svg}
  >
    <g>
      <path
        style={style.st0}
        d="M336.7,215H68.3c-0.2,0-0.3-0.1-0.3-0.3V64.3c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C337,214.9,336.9,215,336.7,215z"
      />
      <path
        style={style.st0}
        d="M336.7,660H68.3c-0.2,0-0.3-0.1-0.3-0.3V509.3c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C337,659.9,336.9,660,336.7,660z"
      />
      <path
        style={style.st0}
        d="M336.7,437.5H68.3c-0.2,0-0.3-0.1-0.3-0.3V286.8c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C337,437.4,336.9,437.5,336.7,437.5z"
      />
    </g>
    <g>
      <path
        style={style.st0}
        d="M676.2,215H407.8c-0.2,0-0.3-0.1-0.3-0.3V64.3c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C676.5,214.9,676.4,215,676.2,215z"
      />
      <path
        style={style.st0}
        d="M676.2,660H407.8c-0.2,0-0.3-0.1-0.3-0.3V509.3c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C676.5,659.9,676.4,660,676.2,660z"
      />
      <path
        style={style.st0}
        d="M676.2,437.5H407.8c-0.2,0-0.3-0.1-0.3-0.3V286.8c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C676.5,437.4,676.4,437.5,676.2,437.5z"
      />
    </g>
    <g>
      <path
        style={style.st0}
        d="M1015.7,215H747.3c-0.2,0-0.3-0.1-0.3-0.3V64.3c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C1016,214.9,1015.9,215,1015.7,215z"
      />
      <path
        style={style.st0}
        d="M1015.7,660H747.3c-0.2,0-0.3-0.1-0.3-0.3V509.3c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C1016,659.9,1015.9,660,1015.7,660z"
      />
      <path
        style={style.st0}
        d="M1015.7,437.5H747.3c-0.2,0-0.3-0.1-0.3-0.3V286.8c0-0.2,0.1-0.3,0.3-0.3h268.4c0.2,0,0.3,0.1,0.3,0.3v150.4
		C1016,437.4,1015.9,437.5,1015.7,437.5z"
      />
    </g>
  </svg>
);
