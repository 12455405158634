import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ImageLoader from 'react-load-image'
import config from '../../config'

export default class projectTileImage extends Component {
    static propTypes = {
        storyboard: PropTypes.object,
    }

    render() {
        let { storyboard } = this.props
        let props = {
            className: 'animated fadeIn',
            style: {
                display: 'block',
            },
        }

        const NoCoverPhoto = (
            <div className="pos-abt fill w100 h100 vcc text-center bg-white">
                <div className="vc text-black">
                    <i>No Cover Photo</i>
                </div>
            </div>
        )

        if (!storyboard.brandLogoURL) {
            return NoCoverPhoto
        }

        return (
            <ImageLoader
                className="cover-image-thumb clear fill _lr-hide"
                src={`${config.s3}/${encodeURI(storyboard.brandLogoURL)}`}>
                <img {...props} alt={`Project "${storyboard.title}"`} />
                {NoCoverPhoto}
                <div className="w100 h100" style={{ backgroundColor: '#DDD' }} />
            </ImageLoader>
        )
    }
}
