import actions from '../actions'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import React from 'react'
import { connect } from 'react-redux'

import Loader from '../components/common/loader'
import ProjectPreview from '../components/ProjectPreviewModal'

/**
 * @module PublicProjectPreviewContainer
 */
class PublicProjectPreviewContainer extends React.PureComponent {
    componentWillMount() {
        let projectId = this.props.match && this.props.match.params.projectId
        if (projectId && !this.props.project) {
            this.props.fetchPublicProjectById(projectId)
        }
    }

    render() {
        return this.props.project ? <ProjectPreview {...this.props} /> : null
    }
}

PublicProjectPreviewContainer.propTypes = {
    fetchProjectById: PropTypes.func,
    params: PropTypes.object,
    project: PropTypes.object,
}

export default withRouter(
    connect(
        state => {
            let { project, scenes, scene } = state
            return {
                project,
                scenes,
                scene,
            }
        },
        {
            ...actions.scenes,
            ...actions.projects,
        },
    )(React.memo(PublicProjectPreviewContainer)),
)
