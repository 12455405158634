import styled from 'styled-components'

export const CommentWrapper = styled.div`
    position: relative;
    border-bottom: 2px solid #eee;
    padding-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    cursor: pointer;
    &.hightlight-comment {
        background-color: #f0f0f0;
        .sb-avatar > div {
            background-color: #0adfa6 !important;
            color: #fff !important;
        }
        &:hover {
            background-color: #f0f0f0;
        }
    }
    &.reply {
        padding-left: 20px;
    }
`

export const CommentContentWrapper = styled.div`
    margin-left: 40px;
    padding-bottom: 15px;
    position: relative;
    padding-left: 15px;
    padding-right: 20px;
`

export const EmptyStateContainer = styled.div`
    padding: 50px 40px 0;
    text-align: center;
    opacity: 0.8;
    .title {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 0;
        margin-bottom: 10px;
    }
    .icon {
        display: inline-block;
        width: 25px;
        height: 25px;
        background: #646b73;
        border-radius: 4px;
        text-align: center;
        line-height: 25px;
        opacity: 0.5;
        margin: 0 2px;

        .fa {
            font-size: 16px;
            color: white;
        }
    }
`
