import { call, put, fork, select } from 'redux-saga/effects'
import { takeLatest, delay } from 'redux-saga'
import * as R from 'ramda'

import { reorderStoryboardsResp, moveStoryboardsResp } from '../actions/ProjectsActions'

import * as api from './api'
import config from '../config'
import { REORDER_STORYBOARDS, MOVE_STORYBOARDS } from '../constants/Projects'
import http from '../utils/http'

function* runReorderStoryboards({ payload }) {
    const {
        currentFolder,
        storyBoards,
        sourceStoryboard,
        targetStoryboard,
        newStoryBoardOrder,
    } = payload

    if (
        currentFolder.folderId === sourceStoryboard.folderId &&
        currentFolder.folderId === targetStoryboard.folderId
    ) {
        const folderId = currentFolder.folderId
        const storyboardsOrder = R.pluck('id', newStoryBoardOrder)

        const URL = `${config.apiUrl}/projects/reorder`
        const { error } = yield call(() => api.patch(URL, { folderId, storyboardsOrder }))

        if (error) {
            window.notify.error(error.message)
            yield put(reorderStoryboardsResp({ currentFolder, storyboardsOrder: storyBoards }))
        } else {
            yield put(
                reorderStoryboardsResp({ currentFolder, storyboardsOrder: newStoryBoardOrder }),
            )
        }
    } else {
        window.notify.error('Something went wrong.')
        yield put(reorderStoryboardsResp({ currentFolder, storyboardsOrder: storyBoards }))
    }
}

function* runMoveStoryboards({ payload }) {
    const URL = `${config.apiUrl}/projects/move`
    const { error, response } = yield call(() => api.put(URL, payload))
    if (response) {
        if (response?.projectsExistingError) {
            window.notify.success('Some of the projects has already been moved.')
        }
        yield put(moveStoryboardsResp(response))
        if (typeof payload.onDone === 'function') {
            payload.onDone()
        }
        window.sagaHistory.push('/projects')
    }
    if (error) {
        window.notify.error(error.message)
        window.sagaHistory.push('/projects')
    }
}

function* watchReorderStoryboards() {
    yield* takeLatest(REORDER_STORYBOARDS, runReorderStoryboards)
}

function* watchMoveStoryboards() {
    yield* takeLatest(MOVE_STORYBOARDS, runMoveStoryboards)
}

export default function*() {
    yield fork(watchReorderStoryboards)
    yield fork(watchMoveStoryboards)
}
