import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import ProjectFolder from './projectFolder'
import Projects from '../projects/ProjectsContainer'
import { ProjectsWrapper } from './styled'

export default function Folders({
    user,
    trialExpired,
    folders,
    currentFolder,
    setCurrentFolderTo,
}) {
    const [storyBoards, setStoryBoards] = useState(null)
    const [projectFolderWidth, setProjectFolderWidth] = useState(null)
    const [showProjectFolder, setShowProjectFolder] = useState(false)

    const handleProjectFolderClick = () =>
        setShowProjectFolder(showProjectFolder => !showProjectFolder)

    useEffect(() => {
        if (!currentFolder) {
            setCurrentFolderTo(folders && folders[0])
        }
    }, [folders])

    // check if window resized to Mobile
    const [width, setWidth] = useState(window.innerWidth)

    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 768 && !showProjectFolder) {
            setProjectFolderWidth(50)
        } else if (window.innerWidth < 1250) {
            setShowProjectFolder(false)
            setProjectFolderWidth(300)
        } else {
            setProjectFolderWidth(350)
        }
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        if (!projectFolderWidth) {
            handleWindowSizeChange()
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    })

    const isMobile = width <= 768
    const isExtraSmallDevice = width <= 500
    // check if window resized to Mobile End

    useEffect(() => {
        if (folders) {
            setStoryBoards(
                R.compose(
                    R.prop('storyboards'),
                    R.find(R.propEq('id', currentFolder?.id)),
                )(folders),
            )
        }
    }, [currentFolder, folders])

    const handleFolderClick = folder => {
        setCurrentFolderTo(folder)
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <ProjectsWrapper>
                <ProjectFolder
                    isMobile={isMobile}
                    handleProjectFolderClick={handleProjectFolderClick}
                    showProjectFolder={showProjectFolder}
                    setProjectFolderWidth={setProjectFolderWidth}
                    currentFolder={currentFolder}
                    folders={folders}
                    handleFolderClick={handleFolderClick}
                />
                <Projects
                    isExtraSmallDevice={isExtraSmallDevice}
                    showProjectFolder={showProjectFolder}
                    projectFolderWidth={projectFolderWidth}
                    currentFolder={currentFolder}
                    storyBoards={storyBoards}
                    user={user}
                />
            </ProjectsWrapper>
        </DndProvider>
    )
}

Folders.propTypes = {
    folders: PropTypes.array,
    user: PropTypes.object,
}
