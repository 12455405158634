import { call, put, fork } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga'
import * as api from './api'
import config from '../config'
import actions from '../actions'
import * as types from '../constants/ActionTypes'

const FOREVER = 'forever'

let {
    coupons: { applyCouponResp },
} = actions

// Delete session
// ----------------

function* watchApplyCoupon() {
    yield* takeLatest(types.APPLY_COUPON, applyCoupon)
}

function* watchDefaultCoupon() {
    yield* takeLatest(types.DEFAULT_COUPON, defaultCoupon)
}

// Response body
// {
//  timesRedeemed: 2,
//  durationInMonths: 1,
//  amountOff: null,
//  maxRedemptions: 99,
//  percentOff: 100,
//  duration: 'repeating',
//  redeemBy: null,
//  id: 'coupon_id',
//  metadata: {}
// }

function* defaultCoupon({ payload }) {
    if (!payload) {
        yield put(
            applyCouponResp({
                id: null,
                discount: null,
                message: null,
                valid: null,
            }),
        )
    } else {
        const URL = `${config.apiUrl}/users/subscriptions/coupons/${payload}`
        const { error, response } = yield call(api.get, URL)
        if (error) {
            yield put(
                applyCouponResp({
                    id: null,
                    discount: null,
                    message: null,
                    valid: null,
                }),
            )
        } else {
            let { id, percentOff, amountOff, durationInMonths, duration } = response
            if (duration !== FOREVER) {
                yield put(
                    applyCouponResp({
                        id: null,
                        discount: null,
                        message: null,
                        valid: null,
                    }),
                )
            } else {
                const discount = percentOff
                    ? `${percentOff}%`
                    : new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                      }).format(amountOff / 100)
                yield put(
                    applyCouponResp({
                        id,
                        discount,
                        message: null,
                        valid: true,
                    }),
                )
            }
        }
    }
}

function* applyCoupon({ payload }) {
    const URL = `${config.apiUrl}/users/subscriptions/coupons/${payload}`
    // Invalidate session
    const { error, response } = yield call(api.get, URL)

    if (error) {
        yield put(
            applyCouponResp({
                id: null,
                discount: null,
                message: error.message,
                valid: false,
            }),
        )
    } else {
        let { id, percentOff, amountOff, durationInMonths, duration } = response
        const messagePrefix = 'Success: '
        const discount = percentOff
            ? `${percentOff}%`
            : new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
              }).format(amountOff / 100)
        const durationSuffix = durationInMonths
            ? ` off for the first ${durationInMonths} months.`
            : ` off ${duration}`
        let message = messagePrefix + discount + durationSuffix
        window.$N.info(message)
        yield put(
            applyCouponResp({
                id,
                discount,
                message,
                valid: true,
            }),
        )
    }
}

// Fork!
// ----------------

export default function* rootSaga() {
    yield fork(watchApplyCoupon)
    yield fork(watchDefaultCoupon)
}
