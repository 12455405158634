import PropTypes from 'prop-types'
import React from 'react'
import Modal from '../common/Modal'
import messages from '../../constants/Messages'
import { StyledButton } from './styled'

const CancelSubscription = ({
    isTeamOwner,
    hasTeam,
    cancelSubscription,
    leaveTeamPlan,
    history,
    isOldPlanSubscriber,
}) => {
    const [processing, setProcessing] = React.useState(false)
    const [finalConfirmation, setFinalConfirmation] = React.useState(false)

    const goBack = () => {
        history.replace({
            pathname: history?.location?.state?.redirectTo || '/billing',
            state: {
                modal: true,
                closable: true,
            },
        })
    }

    const locationState = (hasTeam && isTeamOwner) || !hasTeam ? {} : { cancel: true }
    const returnOnDone = {
        returnTo: {
            pathname: history?.location?.state?.redirectTo || '/billing',
            state: locationState,
        },
        onDone: () => {
            setProcessing(false)
            let action = 'cancelled your subscription.'
            if (hasTeam) {
                action = isTeamOwner ? `deleted your team and ${action}` : 'left the team.'
            }
            window.$N.info(`You have sucessfully ${action}`)
        },
    }
    const cancelSubscriptionHandler = () => {
        if (processing) {
            return
        }
        setProcessing(true)
        if (hasTeam && !isTeamOwner) {
            leaveTeamPlan(returnOnDone)
        } else {
            cancelSubscription(returnOnDone)
        }
    }

    let processingTxt = 'Cancelling subscription'
    if (hasTeam) {
        processingTxt = isTeamOwner ? `Deleting Team...` : 'Leaving Team...'
    }

    let title = 'Cancel Subscription'
    let btnTxt = 'Cancel Subscription'
    let subtitle = messages.DOWNGRADE_OWNER_MESSAGE
    if (hasTeam) {
        title = isTeamOwner ? `Delete Team & ${title}` : 'Are You Sure'
        btnTxt = isTeamOwner ? title : 'Leave Team'
        subtitle = `${
            isTeamOwner ? messages.DOWNGRADE_TEAM_OWNER_MESSAGE : messages.LEAVE_TEAM_SELF_MESSAGE
        }`
    }
    if (finalConfirmation) {
        title = `Wait! You'll lose the best price!`
        btnTxt = 'Cancel Anyway'
        subtitle = `You've been grandfathered in with unlimited access at our lowest subscription price. If you cancel now, you'll lose that forever.`
    }
    return (
        <Modal
            isCancelable={!processing && (finalConfirmation || isTeamOwner || !hasTeam)}
            isCloseable={!processing}
            title={finalConfirmation ? title : `${title}?`}
            subtitle={subtitle}
            goBack={goBack}>
            <div className="content">
                <div className="text-center">
                    {!finalConfirmation && !processing && !isTeamOwner && hasTeam && (
                        <StyledButton
                            primary
                            fullwidth
                            disabled={processing}
                            className={`cp m-b-sm ${
                                processing
                                    ? 'disabled'
                                    : finalConfirmation
                                    ? 'text-u-c'
                                    : 'text-capitalize'
                            }`}
                            onClick={goBack}>
                            {isTeamOwner ? 'Cancel' : 'Nevermind'}
                        </StyledButton>
                    )}
                    <StyledButton
                        danger
                        fullwidth
                        disabled={processing}
                        className={`cp m-b-sm ${
                            processing
                                ? 'disabled'
                                : finalConfirmation
                                ? 'text-u-c'
                                : 'text-capitalize'
                        }`}
                        onClick={() => {
                            isOldPlanSubscriber && !finalConfirmation
                                ? setFinalConfirmation(true)
                                : cancelSubscriptionHandler()
                        }}>
                        {processing ? processingTxt : btnTxt}
                    </StyledButton>
                </div>
            </div>
        </Modal>
    )
}

CancelSubscription.propTypes = {
    isTeamOwner: PropTypes.bool.isRequired,
    hasTeam: PropTypes.bool.isRequired,
    cancelSubscription: PropTypes.func.isRequired,
    leaveTeamPlan: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
}

export default CancelSubscription
