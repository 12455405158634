import React from 'react'

import StyledButton from '../common/styled/StyledButton'

export default ({ hasTeamPlan, createTeamPlan, user, history }) => {
    const [loading, setLoading] = React.useState(false)
    const createTeamPlanHandler = event => {
        event.preventDefault()
        if (hasTeamPlan) {
            history.replace({
                pathname: '/teamPlan',
                state: {
                    modal: true,
                    closeable: true,
                },
            })
        } else {
            setLoading(true)
            const payload = {
                name: `${user.firstName} ${user.lastName}`,
            }
            createTeamPlan(payload)
        }
    }
    return (
        <div className="m-b-sm">
            <StyledButton
                primary
                fullwidth
                disabled={loading}
                onClick={createTeamPlanHandler}
                className="text-u-c">
                {loading ? 'Loading' : hasTeamPlan ? 'Manage Team Members' : 'Create Team'}
            </StyledButton>
        </div>
    )
}
