import PropTypes from 'prop-types'
import React from 'react'
import CopyIcon from '../../icons/CopyIcon'

const useCopyInputText = () => {
    const ref = React.useRef()
    const copy = () => {
        ref.current.select()
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        window.$N.info(window.$N.MESSAGES.COPY_PASTED)
    }
    return [ref, copy]
}

const CopyPasteInput = props => {
    const [copyPasteInputRef, copy] = useCopyInputText()
    return (
        <div className="button-input">
            <input
                {...props}
                readOnly
                type="text"
                ref={copyPasteInputRef}
                style={{ marginRight: -4 }}
            />
            <button onClick={copy}>
                <CopyIcon />
            </button>
        </div>
    )
}

CopyPasteInput.propTypes = {
    value: PropTypes.string,
}

export default CopyPasteInput
