import React from 'react'
import Dropdown from '../Dropdown'
import PropTypes from 'prop-types'
import * as R from 'ramda'

export default class projectFilter extends React.Component {
    static propTypes = {
        filters: PropTypes.array,
        setProjectFilter: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = { title: R.head(props.filters) }
        this.onClick = this.onClick.bind(this)
    }

    onClick(label) {
        this.setState({ title: label })
        this.props.setProjectFilter(label)
    }

    render() {
        const items = this.props.filters.map(item => ({
            value: item,
            onClick: () => this.onClick(item),
        }))
        return (
            <Dropdown
                className="project-dropdown project-filter-dropdown"
                items={items}
                showCurrent
                dropdownButtonStyle="project-filter-dropdown__btn"
                dropdownBodyWrapperStyle="project-dropdown__body-wrapper"
                dropdownBodyStyle="project-dropdown__body"
            />
        )
    }
}
