import React from 'react'

export default ({ fill = 'white', className, size = '37.333', ...style }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        width={size}
        height={size}
        viewBox="-30 -30 160 160"
        style={{ fill, background: 'transparent', ...style }}
        className={className}>
        <g>
            <g>
                <g>
                    <path d="M34.2,33l9.7-9.7v42.8c0,3.4,2.7,6.1,6.1,6.1s6.1-2.7,6.1-6.1V23.2l9.7,9.7c1.2,1.2,2.7,1.8,4.3,1.8     c1.6,0,3.1-0.6,4.3-1.8c2.4-2.4,2.4-6.2,0-8.6L54.3,4.3c-2.4-2.4-6.2-2.4-8.6,0L25.6,24.4c-2.4,2.4-2.4,6.2,0,8.6     C28,35.4,31.8,35.4,34.2,33z"></path>
                    <path d="M86.2,62.8c-3.4,0-6.1,2.7-6.1,6.1v16.5H19.8V68.9c0-3.4-2.7-6.1-6.1-6.1s-6.1,2.7-6.1,6.1v22.6c0,3.4,2.7,6.1,6.1,6.1     h72.5c3.4,0,6.1-2.7,6.1-6.1V68.9C92.3,65.5,89.6,62.8,86.2,62.8z"></path>
                </g>
            </g>
        </g>
    </svg>
)
