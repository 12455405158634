import React from 'react'
import Modal from '../common/Modal'
import { useState } from 'react'
import { StyledButton, StyledInput } from './styled'

export default ({
    teamInfo: { name: oldName, id: teamId },
    userLoading,
    updateTeamPlan,
    history,
}) => {
    const [name, setName] = useState(oldName)
    const [submitting, setSubmitting] = useState(false)

    const goBack = () => {
        history.replace({
            pathname: '/teamPlan',
            state: { modal: true, closeable: true },
        })
    }

    const handleChange = e => setName(e.target.value)

    const handleSubmit = e => {
        e.preventDefault()
        if (name === '') {
            return
        }
        // this shows false,
        if (name.toLowerCase() === oldName.toLowerCase()) {
            goBack()
            return
        }
        setSubmitting(true)
        updateTeamPlan({
            id: teamId,
            name,
            onDone: () => {
                window.$N.info(`Team name was updated`)
                setSubmitting(false)
                goBack()
            },
        })
    }

    return (
        <Modal
            isCancelable
            isCloseable
            wrapperClassName="profile"
            title="New Team Name"
            subtitle="What would you like to change your team name to?"
            goBack={goBack}>
            <form className="content" onSubmit={handleSubmit}>
                <fieldset>
                    <div className="profile__row text m-b">
                        <StyledInput
                            onChange={handleChange}
                            autoFocus
                            disabled={submitting}
                            defaultValue={oldName}
                            name="teamName"
                            className="text-white profile__value"
                            label="Team Name"
                        />
                    </div>
                </fieldset>
                <StyledButton
                    button
                    primary
                    disabled={userLoading || submitting}
                    type="submit"
                    className="wrapper-sm m-t-md m-b-sm block m-l-auto m-r-auto">
                    {userLoading ? 'Loading...' : submitting ? 'Submitting...' : 'Submit'}
                </StyledButton>
            </form>
        </Modal>
    )
}
