import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { register } from './lib/cognito'
import FormInput from './lib/FormInput'
import FormCheckbox from './lib/FormCheckbox'
import SubmitButton from './lib/SubmitButton'
import { isValidEmail } from '../../utils'

const COGNITOEXCEPTION = {
    UsernameExistsException: 'An account already exists with this email address.',
    //    InternalErrorException: '',
    //    InvalidLambdaResponseException: '',
    //    InvalidPasswordException: '',
    //    NotAuthorizedException: '',
    //    ResourceNotFoundException: '',
    //    TooManyRequestsException: '',
    //    ResourceNotFoundException: '',
    UserLambdaValidationException: 'Coupon is not valid or has already been used.',
}

const CognitoRegisterForm = ({ title, description, label, providerRegister, history }) => {
    const [state, setState] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        providerCoupon: '',
        verificationNumber: '',
        policyCheck: false,
        termsCheck: false,
        errorMessage: null,
        policyTermErrorMessage: null,
    })

    const handleChange = e => {
        e.preventDefault()
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value,
            errorMessage: '',
        }))
    }

    const handleCheckbox = e => {
        const { name, checked } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: checked,
            errorMessage: '',
        }))
    }

    const handleSubmit = e => {
        e.preventDefault()

        if (!isValidEmail(state.email)) {
            setState({
                ...state,
                errorMessage: 'Enter a valid email address.',
            })
            return
        }
        if (!state.policyCheck || !state.termsCheck) {
            setState({
                ...state,
                errorMessage:
                    'You must accept the terms and conditions and privacy policy to create an account.',
            })
            return
        }

        setState({ ...state, loading: true })

        const formData = {
            email: state.email?.trim(),
            firstName: state.firstName?.trim(),
            lastName: state.lastName?.trim(),
            password: state.password?.trim(),
        }

        if (providerRegister) formData['providerCoupon'] = state.providerCoupon?.trim()

        register(formData)
            .then(result => {
                setState({ ...state, loading: false })
                history.push('/authenticate/unverified')
                // eslint-disable-next-line no-undef
                $FPROM.trackSignup({ email: state.email, uid: result.userSub })
            })
            .catch(err => {
                // console.error(err)
                const message = COGNITOEXCEPTION[err['name'] || err.message]
                setState({
                    ...state,
                    loading: false,
                    errorMessage: message,
                })
            })
    }

    const canSubmit = () => {
        return providerRegister
            ? state.providerCoupon.length > 0
            : true &&
                  state.email.length > 0 &&
                  state.password.length > 0 &&
                  state.firstName.length > 0 &&
                  state.lastName.length > 0
    }

    return (
        <>
            <h1 className="animated fadeIn sn signup__title">{title}</h1>
            <div className="signup__desc m-b-md">{description}</div>

            <div className="inline-block">
                <form onSubmit={handleSubmit}>
                    <FormInput
                        label="First Name"
                        name="firstName"
                        value={state.firstName}
                        onChange={handleChange}
                    />

                    <FormInput
                        label="Last Name"
                        name="lastName"
                        value={state.lastName}
                        onChange={handleChange}
                    />

                    <FormInput
                        label="Email"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                    />

                    <FormInput
                        label="Password"
                        type="password"
                        name="password"
                        value={state.password}
                        onChange={handleChange}
                    />
                    {providerRegister && (
                        <FormInput
                            label={label}
                            name="providerCoupon"
                            value={state.providerCoupon}
                            onChange={handleChange}
                        />
                    )}

                    <FormCheckbox
                        type="checkbox"
                        name="termsCheck"
                        onChange={handleCheckbox}
                        label={
                            <span>
                                I agree to the{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://theplot.io/terms/">
                                    terms and conditions
                                </a>
                                .
                            </span>
                        }
                    />

                    <FormCheckbox
                        type="checkbox"
                        name="policyCheck"
                        onChange={handleCheckbox}
                        label={
                            <span>
                                I agree to the{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://theplot.io/privacy/">
                                    privacy policy
                                </a>
                                .
                            </span>
                        }
                    />

                    <SubmitButton disabled={!canSubmit()}>
                        {state.loading ? 'Authenticating...' : 'Create Account'}
                    </SubmitButton>
                </form>
            </div>

            <p className="m-t-sm">
                Already have an account? <Link to="/authenticate/signin">Log in</Link>
            </p>

            <div>
                {state.errorMessage && (state.termsCheck || state.policyCheck) ? (
                    <div className="alert alert-danger">{state.errorMessage}</div>
                ) : null}
            </div>
        </>
    )
}

export default withRouter(CognitoRegisterForm)
