import React, { useState } from 'react'

import Modal from '../common/Modal'

import { StyledButton, StyledInput } from './styled'

const DELETE_CONFIRMATION_TEXT = 'DELETE'

const DeleteAccount = ({
    deleteUserAccount,
    goBack,
    cognitoUser,
    user,
    userLoading,
    deleteAccountStatus,
}) => {
    const [finalConfirmation, setFinalConfirmation] = useState(false)
    const [confirmText, setConfirmText] = useState('')
    const [confirmError, setConfirmError] = useState(false)
    const deleteUserAccountHandler = ev => {
        ev.preventDefault()
        if (confirmText === DELETE_CONFIRMATION_TEXT) {
            const payload = {
                cognitoUser,
                dynamoUser: user,
            }
            deleteUserAccount(payload)
        } else {
            setConfirmError(true)
        }
    }
    let subtitle =
        'Permanently delete all of your projects and account data. This cannot be undone.'
    let content = (
        <StyledButton danger fullwidth onClick={() => setFinalConfirmation(true)}>
            {deleteAccountStatus ? 'Deleting Account...' : 'Confirm Delete Account'}
        </StyledButton>
    )
    if (finalConfirmation) {
        subtitle = `Type "${DELETE_CONFIRMATION_TEXT}" to confirm deletion.`
        content = (
            <>
                <div
                    className={`cc-name profile__row text m-b-sm ${
                        confirmError ? 'profile__row-error' : ''
                    }`}>
                    <StyledInput
                        id="confirm-text"
                        autoComplete="off"
                        placeholder={DELETE_CONFIRMATION_TEXT}
                        value={confirmText}
                        onFocus={() => setConfirmError(false)}
                        label="CONFIRM TEXT"
                        className="cc-name _lr-hide text-white profile__value"
                        onChange={e => {
                            setConfirmText(e.target.value)
                        }}
                        autoFocus
                    />
                </div>
                <StyledButton
                    danger
                    fullwidth
                    onClick={deleteUserAccountHandler}
                    disabled={deleteAccountStatus}>
                    {deleteAccountStatus ? 'Deleting Account...' : 'Confirm Delete'}
                </StyledButton>
            </>
        )
    }
    return (
        <Modal
            isCancelable={!deleteAccountStatus}
            isCloseable={!deleteAccountStatus}
            title="Delete Account"
            subtitle={subtitle}
            goBack={goBack}>
            {content}
        </Modal>
    )
}

export default DeleteAccount
