import React, { useState } from 'react'
import Modal from '../common/Modal'
import { StyledButton, StyledInput } from './styled'

const CreateFolder = ({ createFolderAction }) => {
    const [folderName, setFolderName] = useState('')
    const [loading, setLoading] = useState(false)

    const handleFolderNameInput = e => {
        e.preventDefault()
        setFolderName(e.target.value)
    }

    const handleFolderCreate = () => {
        setLoading(true)
        createFolderAction({ folderName: folderName })
    }

    return (
        <Modal title="Create Folder" isCloseable>
            <StyledInput
                placeholder="Enter folder name"
                label="Folder Name"
                value={folderName}
                onChange={handleFolderNameInput}
            />
            <StyledButton
                className="m-t-md"
                primary
                fullwidth
                disabled={loading || !folderName.length}
                onClick={handleFolderCreate}>
                {loading ? 'Creating Folder...' : 'Create Folder'}
            </StyledButton>
        </Modal>
    )
}

export default CreateFolder
