import $ from 'jquery'
import * as R from 'ramda'

export const scrollToScene = sceneId => {
    // Scroll to element
    let scrollTo = $(`[data-sceneid="${sceneId}"]`).get(0)
    if (scrollTo) {
        scrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
}

export const scrollToScriptInToolbar = sceneId => {
    // Scroll to element
    const toolbarScriptEl = $(`[data-script-sceneid="${sceneId}"]`).get(0)
    if (toolbarScriptEl) {
        toolbarScriptEl.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
}

const indexById = xs => R.indexBy(R.prop('id'), xs)

export const orderScenes = (scenes, sceneOrder) => {
    let scenesIndexedById = indexById(scenes)
    let result = R.reduce(
        (result, id) => (scenesIndexedById[id] ? R.append(scenesIndexedById[id], result) : result),
        [],
        sceneOrder,
    )

    result = R.map(
        x =>
            x.image && x.image.indexOf('default-scene') > -1 ? R.mergeRight(x, { image: null }) : x,
        result,
    )
    // Ensure scenes have a unique id
    result = result.filter((scene, index, self) => self.findIndex(t => t.id === scene.id) === index)
    return result.map((item, index) => R.mergeRight({ number: index + 1 }, item))
}
