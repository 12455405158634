import * as types from '../constants/TeamConstant';

export default function team(
    state = { name: '', members: [] },
    { type, payload }
) {
    switch (type) {
        case types.FETCH_TEAM_PLAN_RESP:
            return payload;
        case types.FETCH_USER_BY_EMAIL_RESP:
            return payload;
        case types.CREATE_TEAM_PLAN:
            return state;
        case types.LEAVE_TEAM_PLAN:
            return payload;
        case types.UPDATE_TEAM_PLAN_RESP:
            return payload;
        default:
            return state;
    }
}
