import PropTypes from 'prop-types'
import R, { useEffect } from 'react'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CloseModalButton from './CloseModalButton'
import * as S from './styled'
import { animated, config, useSpring } from 'react-spring'
import { Transition } from 'react-spring/renderprops'

const Modal = ({
    children,
    subtitle,
    title,
    returnTo,
    isCancelable,
    locationState,
    isCloseable,
    noModalHeader,
    isProcessing,
    presentationpreview,
    className,
    ...props
}) => {
    const closeButtonRef = React.createRef()
    const handleKeyDown = event => {
        // ESC key? exit!
        event.stopPropagation()
        if (event.keyCode === 27 && isCloseable) {
            goBack()
        }
    }

    const goBack = e => {
        e && e.preventDefault()

        props.history.location?.state?.redirectBackTo
            ? props.history.replace(props.history.location?.state?.redirectBackTo)
            : props.goBack
            ? props.goBack()
            : props.history.goBack()
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)

        return function() {
            document.removeEventListener('keydown', handleKeyDown)
        }
    })

    return (
        <S.Backdrop presentationpreview={presentationpreview}>
            <Transition
                config={config.stiff}
                items={true}
                from={{ opacity: 0, transform: 'translate(0,-40px)' }}
                enter={{ opacity: 1, transform: 'translate(0,0)' }}>
                {show =>
                    show &&
                    (springProps => (
                        <S.Wrapper
                            subtitle={subtitle}
                            presentationpreview={presentationpreview}
                            className={className}
                            style={
                                presentationpreview
                                    ? { ...springProps, maxWidth: '120vh' }
                                    : springProps
                            }>
                            <div>
                                {isCloseable && (
                                    <CloseModalButton
                                        presentationpreview={presentationpreview}
                                        onClick={goBack}
                                        ref={closeButtonRef}
                                    />
                                )}
                            </div>
                            <div className="modal-wrapper">
                                <div className={!noModalHeader ? 'm-header' : ''}>
                                    {title && (
                                        <div className="title">
                                            <div className="text-white title">{title}</div>
                                            {subtitle && (
                                                <p className="text-light description subtitle">
                                                    <i className="text-muted new-line">
                                                        {subtitle}
                                                    </i>
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {children}
                                <div className="text-center">
                                    {isCancelable && !isProcessing && (
                                        <S.StyledButton
                                            transparent
                                            className="cancel-button"
                                            onClick={goBack}>
                                            Cancel
                                        </S.StyledButton>
                                    )}
                                </div>
                            </div>
                        </S.Wrapper>
                    ))
                }
            </Transition>
        </S.Backdrop>
    )
}

export default React.memo(withRouter(Modal))
