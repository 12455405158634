import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import config from '../../config'
import actions from '../../actions'
import TeamPlan from './TeamPlan'
import { fetchSubscriptionPlan } from '../../actions/SubscriptionPlanActions'
import { teamRole } from '../../utils/permissions'

export default withRouter(
    connect(
        state => {
            let { session, user: userState, team, productPlans, subscriptionPlanInfo } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            return {
                user,
                oldProductId: config.stripeOldProductId,
                productSoloId: config.stripeProductSoloId,
                productProId: config.stripeProductProId,
                productBusinessId: config.stripeProductBusinessId,
                productPlans,
                userLoading: userState.loading,
                paymentGateway: user.paymentGateway,
                userTeam: user.team,
                teamInfo: team,
                isTeamOwner: user && teamRole(user, 'OWNER'),
                subscriptionPlanInfo,
            }
        },
        {
            ...actions.global,
            ...actions.session,
            ...actions.teamPlan,
            fetchSubscriptionPlan,
        },
    )(TeamPlan),
)
