import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { signin } from './lib/cognito'
import FormInput from './lib/FormInput'
import SubmitButton from './lib/SubmitButton'
import SocialButtons from './lib/SocialButtons'

export default withRouter(
    class CognitoSignIn extends React.Component {
        constructor(props) {
            super(props)

            this.state = {
                email: '',
                password: '',
                errorMessage: null,
            }
        }

        componentDidMount() {
            this.props.session && this.props.history.push('/projects')
        }

        handleChange = e => {
            this.setState({
                errorMessage: '',
                [e.target.name]: e.target.value,
            })
        }

        handleSubmit = e => {
            e.preventDefault()

            this.setState({ loading: true })
            signin({
                email: this.state.email,
                password: this.state.password,
                authenticate: this.props.authenticate,
            })
                .then(result => {
                    this.setState({ loading: false })
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        errorMessage: err.message,
                    })
                    if (err.code === 'UserNotConfirmedException') {
                        this.props.history.push('/authenticate/unverified')
                    }
                })
        }

        canSubmit = () => {
            return this.state.email.length > 0 && this.state.password.length > 0
        }

        render() {
            return (
                <div className="registration">
                    <h1 className="animated fadeIn sn signup__title">Welcome Back</h1>
                    <div className="signup__desc m-b-md">Let's get to work.</div>

                    <div className="inline-block">
                        <form onSubmit={this.handleSubmit}>
                            <FormInput
                                label="Email"
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />

                            <FormInput
                                label="Password"
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />

                            <SubmitButton disabled={!this.canSubmit()}>
                                {this.state.loading ? 'Authenticating...' : 'Login'}
                            </SubmitButton>
                        </form>
                    </div>

                    <p className="m-t-sm">
                        <Link to="/authenticate/forgot">Forgot your password?</Link>
                    </p>
                    <p>
                        <Link to="/authenticate/resend">Resend verification code</Link>
                    </p>
                    <p>
                        Don't have an account? <Link to="/authenticate">Try For Free</Link>
                    </p>

                    <div>
                        {this.state.errorMessage ? (
                            <div className="alert alert-danger">{this.state.errorMessage}</div>
                        ) : null}
                    </div>

                    <SocialButtons {...this.props} />
                </div>
            )
        }
    },
)
