import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { cancelSubscription } from '../../../actions/SessionActions'
import { fetchSubscriptionPlan } from '../../../actions/SubscriptionPlanActions'
import { isCancelled } from '../../../utils/permissions'

import PastDue from './PastDue'

const PastDueContainer = ({ subscriptionId, fetchSubscriptionPlan, ...props }) => {
    React.useEffect(() => {
        fetchSubscriptionPlan()
    }, [subscriptionId])
    return <PastDue {...props} subscriptionId={subscriptionId} />
}

export default withRouter(
    connect(
        state => {
            let { session, user: userState, subscriptionPlanInfo } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            return {
                user,
                isCancelled: isCancelled(user),
                subscriptionPlanInfo,
                subscriptionId: user
                    ? user.paymentGateway
                        ? user.paymentGateway.subscriptionId
                        : null
                    : null,
            }
        },
        {
            fetchSubscriptionPlan,
            cancelSubscription,
        },
    )(PastDueContainer),
)
