import React from 'react';
import { connect } from 'react-redux';
import { togglePaint } from '../../actions/ApplicationActions';
import { saveSceneAssets } from '../sketchActions';

/**
 * I am a collection of buttons for managing the PaintContainer
 * I have one button to cancel editing, and one for saving the current scene.
 */
export const PaintActions = connect(
  ({ sketch, savingPaint }) => ({
    hidePaintActions: sketch.hidePaintActions,
    savingPaint,
  }),
  { togglePaint, saveSceneAssets }
)(({ hidePaintActions, savingPaint, togglePaint, saveSceneAssets }) => {
  if (hidePaintActions) {
    return null;
  }

  return (
    <div className="paint-canvas__header vcc">
      <div className="vc text-right">
        {!savingPaint && (
          <div
            className="icon-link cp inline-block m-r-sm"
            onClick={togglePaint}
          >
            <span className="btn btn-default text-u-c">Cancel</span>
          </div>
        )}
        <div className="icon-link cp inline-block" onClick={saveSceneAssets}>
          <span className="btn bg-primary btn-submit text-u-c">
            {savingPaint ? 'Saving...' : 'Save'}
          </span>
        </div>
      </div>
    </div>
  );
});
