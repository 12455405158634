import { createAction } from 'redux-actions';
import {
  FETCH_SUBSCRIPTION_PLAN,
  FETCH_SUBSCRIPTION_PLAN_RESP,
} from '../constants/ActionTypes';

export const fetchSubscriptionPlan = createAction(FETCH_SUBSCRIPTION_PLAN);
export const fetchSubscriptionPlanResp = createAction(
  FETCH_SUBSCRIPTION_PLAN_RESP
);
