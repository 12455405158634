import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledLink = styled(Link)`
    ${props =>
        props.button &&
        css`
        display: inline-block;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: bold;
        line-height: 46px;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        user-select: none;
        background-image: none;
        border-radius: ${props => props.theme.border.plot_default_border_radius};
        color: white;
        border-radius: ${props.theme.border.plot_default_border_radius}
        height: 42px;
    `}
    ${props =>
        props.profile &&
        css`
            padding: 13px 20px;
            height: 50px;
            &:hover {
                color: #fff;
            }
        `}
    ${props =>
        props.primary &&
        css`
            background-color: ${props.theme.color.plot_green};
            &:hover {
                background-color: ${props => props.theme.color.plot_green_hover};
                color: #fff;
            }
            svg {
                width: 15px;
            }
        `}
    ${props =>
        props.danger &&
        css`
            background-color: ${props.theme.color.plot_red};
            &:hover {
                background-color: ${props => props.theme.color.plot_red_hover};
            }
            svg {
                width: 15px;
            }
        `}
    ${props =>
        props.fullwidth &&
        css`
            width: 100%;
        `}
    
    ${props =>
        props.textmuted &&
        css`
            color: #939aa0;
        `}
`
