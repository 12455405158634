import { createAction } from 'redux-actions';

import * as types from '../constants/ActionTypes';

export const createComment = createAction(types.CREATE_COMMENT);
export const createCommentResp = createAction(types.CREATE_COMMENT_RESP);
export const deleteComment = createAction(types.DELETE_COMMENT);
export const deleteCommentResp = createAction(types.DELETE_COMMENT_RESP);
export const updateComment = createAction(types.UPDATE_COMMENT);
export const updateCommentResp = createAction(types.UPDATE_COMMENT_RESP);
