import React from 'react';
import { withRouter } from 'react-router-dom';
import FormInput from './lib/FormInput';
import SubmitButton from './lib/SubmitButton';

import { verify } from './lib/cognito';

export default withRouter(
  class CognitoSignUp extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        errorMessage: null,
        code: '',
        userVerificationStatus: false,
      };
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        !prevState.userVerificationStatus &&
        this.state.userVerificationStatus
      ) {
        this.setState({
          errorMessage: 'Account already verified. Redirecting to signin page.',
        });
        setTimeout(() => {
          this.props.history.push('/authenticate/signin');
        }, 3000);
      }
    }

    handleVerify = e => {
      e.preventDefault();
      this.verify(this.state.code);
    };

    verify = code => {
      const { email } = this.props.match.params;
      return verify({ email, code })
        .then(result => {
          this.props.history.push('/authenticate/signin?verified=1');
        })
        .catch(err => {
          if (err.message.includes('CONFIRMED')) {
            this.setState({ userVerificationStatus: true });
          } else {
            this.setState({ errorMessage: err.message });
            console.error({ err });
          }
        });
    };

    canSubmit = () => {
      return this.state.code.length > 0;
    };

    handleChange = e => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    componentWillMount() {
      const { code } = this.props.match.params;
      if (code) {
        this.verify(code);
      }
    }

    render() {
      return (
        <div className="registration">
          <h1 className="animated fadeIn sn signup__title">Verify Account</h1>
          <div className="signup__desc m-b-lg">
            <div>EMAIL: {this.props.match.params.email}</div>
            <form className="verify-form">
              <FormInput
                label="Code"
                type="text"
                name="code"
                value={this.state.code}
                onChange={this.handleChange}
              />
              <SubmitButton
                onClick={this.handleVerify}
                disabled={!this.canSubmit()}
              >
                Verify
              </SubmitButton>
            </form>
          </div>

          <div>
            {this.state.errorMessage ? (
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
);
