import React from 'react'
import Downshift from 'downshift'
import { Link } from 'react-router-dom'

import DropdownItem from './DropdownItem'
import MenuIcon from '../../icons/MenuIcon'

const ButtonValue = ({ value }) => (
    <React.Fragment>
        <span>{value}</span>
        <span className="caret" style={{ marginLeft: '10px' }} />
    </React.Fragment>
)

export default ({
    items = [],
    showCurrent = false,
    className,
    dropdownButtonStyle,
    dropdownBodyWrapperStyle,
    dropdownBodyStyle,
    style,
    ...downshiftProps
}) => (
    <Downshift itemToString={item => item && item.value} {...downshiftProps}>
        {({
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            isOpen,
            highlightedIndex,
            selectedItem,
        }) => (
            <div style={{ ...style }} className={className}>
                <button
                    className={`${dropdownButtonStyle} ${isOpen ? 'active' : ''}`}
                    {...getToggleButtonProps()}>
                    {showCurrent ? (
                        selectedItem ? (
                            <ButtonValue value={selectedItem.name || selectedItem.value} />
                        ) : (
                            <ButtonValue value={items[0].name || items[0].value} />
                        )
                    ) : (
                        <MenuIcon size="20px" />
                    )}
                </button>
                {isOpen ? (
                    <div className={dropdownBodyWrapperStyle}>
                        <ul {...getMenuProps({ className: dropdownBodyStyle })}>
                            {items.map((item, index) => {
                                const { Icon, value, name, onClick, to, style } = item
                                return onClick ? (
                                    <li
                                        {...getItemProps({
                                            key: value,
                                            index,
                                            item,
                                            onClick,
                                        })}>
                                        <DropdownItem
                                            icon={Icon}
                                            selected={selectedItem === index}
                                            highlighted={highlightedIndex === index}
                                            name={name}
                                            value={value}
                                            style={style}
                                        />
                                    </li>
                                ) : (
                                    <Link
                                        {...getItemProps({
                                            key: value,
                                            index,
                                            item,
                                        })}
                                        to={to}>
                                        <DropdownItem
                                            icon={Icon}
                                            selected={selectedItem === index}
                                            highlighted={highlightedIndex === index}
                                            value={value}
                                            style={style}
                                        />
                                    </Link>
                                )
                            })}
                        </ul>
                    </div>
                ) : null}
            </div>
        )}
    </Downshift>
)
