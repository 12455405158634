import React from 'react';
import { connect } from 'react-redux';
import messages from '../constants/Messages';
import WarningAlertIcon from '../icons/WarningAlert';

const messageDictionary = {
  loading: {
    message: messages.STATUS_LOADING,
    icon: null,
    style: {},
  },
  success: {
    message: messages.STATUS_SUCCESS,
    icon: null,
    style: {},
  },
  failed: {
    message: messages.STATUS_FAIL,
    icon: WarningAlertIcon,
    style: {
      color: '#F05050',
    },
  },
};

const useOnline = () => {
  const [online, setOnline] = React.useState(true);
  React.useEffect(() => {
    const handleOffline = () => setOnline(false);
    const handleOnline = () => setOnline(true);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);
  return online;
};

const Fade = ({ fade, children }) => {
  const [render, setRender] = React.useState(fade);

  React.useEffect(() => {
    if (!fade) setRender(true);
  }, [fade]);

  const handleAnimationEnd = () => {
    if (fade) setRender(false);
  };

  return (
    render && (
      <div
        style={{ animation: `${fade ? 'fadeOut' : 'fadeIn'} 1s` }}
        onAnimationEnd={handleAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

const GlobalActivityIndicator = props => {
  const [activityStatus, setActivityStatus] = React.useState('initial');
  const [fade, setFade] = React.useState(false);
  const online = useOnline();
  const serverError = online && activityStatus === 'failed';

  React.useEffect(() => setActivityStatus(props.activityStatus), [
    props.activityStatus,
  ]);
  React.useEffect(() => setActivityStatus(online ? 'initial' : 'failed'), [
    online,
  ]);

  React.useEffect(() => {
    if (activityStatus === 'success' || serverError) {
      const timer = setTimeout(() => {
        setFade(true);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setFade(false);
    }
  }, [activityStatus, serverError]);

  if (activityStatus === 'initial') {
    return null;
  }

  const Icon = messageDictionary[activityStatus].icon;

  return (
    <Fade fade={fade}>
      <span
        className="activity-indicator"
        style={{ ...messageDictionary[activityStatus].style }}
      >
        {Icon ? (
          <i style={{ width: '1.4em', marginRight: '5px' }}>
            {' '}
            <Icon className="failed" />{' '}
          </i>
        ) : null}
        <div className="activity-indicator-message">
          {messageDictionary[activityStatus].message}
        </div>
      </span>
    </Fade>
  );
};

const mapStateToProps = state => {
  return {
    activityStatus: state.globalActivityStatus,
  };
};

export default connect(mapStateToProps)(GlobalActivityIndicator);
