import React from 'react'
import Modal from '../common/Modal'
import { StyledButton } from './styled'

export default function ProjectDuplicateDelayedNotice({ history }) {
    return (
        <Modal title="Duplication Started...">
            <p className="text-center m-sm">
                Your project is being copied and will appear on your Dashboard when it’s complete.
                This may take several minutes.
            </p>
            <StyledButton
                primary
                onClick={() => {
                    history.push('/projects')
                }}
                className="wrapper-sm m-t-md m-b-sm block m-l-auto m-r-auto">
                Okay
            </StyledButton>
        </Modal>
    )
}
