import * as R from 'ramda'
import { put, select, take } from 'redux-saga/effects'
import uuid from 'uuid'
import { uploadImage } from '../../actions/ProjectActions'
import { setImage } from '../../actions/SceneActions'
import {
    FETCH_PROJECT_ALBUM_RESP,
    SAVE_SCENE_ASSETS,
    TOGGLE_PAINT,
} from '../../constants/ActionTypes'

/**
 * I save the current scene
 * @return {Generator}
 */
export function* sceneSaver() {
    while (true) {
        yield take(SAVE_SCENE_ASSETS)

        const sketch = window.sketch
        const scene = yield select(state => state.scene)
        const canvas = yield sketch.save.canvas({ as: 'blob' })
        const sketchJson = yield sketch.save.json({
            withAssets: true,
            withHistory: true,
        })

        // Get or create an asset ID
        const assetId = scene.image ? R.last(scene.image.split('/')).split('.')[0] : uuid.v1()

        const assetExt = scene.image ? R.last(scene.image.split('/')).split('.')[1] : 'png'

        // Upload the thumbnail and sketchJson
        const imageUrl = `${assetId}.${assetExt}`
        const sketchJsonUrl = `${assetId}.json`
        const jsonBlob = new Blob([JSON.stringify(sketchJson, null, 2)], {
            type: 'application/json',
        })

        const files = [
            {
                blob: canvas,
                name: imageUrl,
            },
            {
                blob: jsonBlob,
                name: sketchJsonUrl,
            },
        ]

        yield put(uploadImage(files))

        // Update the scene!
        let updatedScene = R.mergeRight(scene, {
            image: `${scene.projectId}/${imageUrl}`,
            sketchJsonUrl: `${scene.projectId}/${sketchJsonUrl}`,
        })
        yield put(setImage(updatedScene))

        yield take(FETCH_PROJECT_ALBUM_RESP)
        yield put({ type: TOGGLE_PAINT })
    }
}
