import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState } from 'react'
import ImageLoader from 'react-load-image'
import SceneActionButtons from '../../containers/SceneActionButtonsContainer'
import config from '../../config'
import NoImageIcon from '../../icons/NoImageIcon'
import * as S from './styled'

// Find the current image in the album. There may be a cache buster in the url.
const getImageUrl = R.ifElse(R.complement(R.isNil), R.pipe(R.split('?'), R.head), R.always(''))

const SceneImage = ({
    onCommentSubmit,
    scene,
    children,
    album,
    cloneSetImage,
    activateScene,
    sceneNumber,
    isDragging,
    presentationpreview,
    url,
    publicProject,
    printing,
}) => {
    let { current: dragCounter } = React.useRef(0)
    const [hovered, setHovered] = useState(false)
    const [hasOpenChild, setHasOpenChild] = useState(false)
    const [activateImageDZ, setactivateImageDZ] = useState(false)
    const onImageDragEnter = ev => {
        if (printing) return
        ev.preventDefault()
        if (dragCounter > 1) {
            return
        }
        dragCounter++
        if (!scene.locked && !activateImageDZ) {
            setactivateImageDZ(true)
            return
        }
    }

    const onImageLeave = ev => {
        ev.preventDefault()
        dragCounter--
        if (dragCounter <= 0) {
            setactivateImageDZ(false)
        }
    }

    const onImageDragEnd = ev => {
        ev.preventDefault()
        window.$APP.dragee = null
    }

    const onImageDrop = (ev, scene) => {
        ev.preventDefault()
        if (scene.locked) {
            return
        }

        if (window.$APP.dragee.url) {
            activateScene(scene)
            const obj = {
                image: window.$APP.dragee.url.split('?')[0],
                sketchJsonUrl: window.$APP.dragee.sketchJsonUrl
                    ? window.$APP.dragee.sketchJsonUrl.split('?')[0]
                    : window.$APP.dragee.sketchJsonUrl,
            }
            cloneSetImage(R.mergeRight(scene, obj))
            window.$APP.dragee = null
            dragCounter = 0
            setactivateImageDZ(false)
        }
    }

    const renderImage = () => {
        let url = R.find(R.pipe(getImageUrl, R.flip(R.includes)(getImageUrl(scene.image))))(
            album || [],
        )
        const imageText = activateImageDZ ? (
            <NoImageIcon fill="#0ADFA6" />
        ) : !url ? (
            <NoImageIcon />
        ) : (
            ''
        )
        let showDropZoneContent
        // Image being dropped, show drop zone
        if (activateImageDZ || !url) {
            showDropZoneContent = (
                <div
                    className="fill"
                    style={{ pointerEvents: 'none', top: '20px', bottom: '20px' }}>
                    {imageText}
                </div>
            )
        }
        // Render image
        return (
            <>
                {showDropZoneContent}
                {url ? (
                    <ImageLoader
                        className={`clear cover-image-thumb fill _lr-hide ${
                            activateImageDZ ? 'none' : ''
                        }`}
                        src={`${config.s3}/${encodeURI(url)}`}>
                        <img
                            style={{ display: 'block' }}
                            draggable={false}
                            alt={`Scene ${sceneNumber}`}
                        />
                        <div className="pos-abt fill vcc h100 w100">
                            <div className="pos-rlt vc text-center thumb-text">
                                <i>Whoop! Image load failed!</i>
                            </div>
                        </div>
                        <div className="card-thumbnail-preloader" />
                    </ImageLoader>
                ) : null}
            </>
        )
    }

    const handleImageDrop = e => {
        onImageDrop(e, scene)
    }

    const handleMouseHover = () => {
        setHovered(true)
    }
    const handleMouseLeave = () => {
        if (!hasOpenChild) {
            setTimeout(() => setHovered(false))
        }
    }

    const setChildOpen = hasOpenChild => {
        setHasOpenChild(hasOpenChild)
    }
    const backgroundClass = activateImageDZ ? 'activateImageDZ' : 'bg-default'
    return (
        <S.SceneImageWrapper
            onDrop={handleImageDrop}
            onDragEnter={onImageDragEnter}
            onDragLeave={onImageLeave}
            onDragEnd={onImageDragEnd}
            className={`w100 b-b pos-rlt image-container ${backgroundClass}`}
            onMouseOver={handleMouseHover}
            isDragging={isDragging}
            onMouseLeave={handleMouseLeave}>
            {/* CREATE NEW SCENE IMAGE SPINNER */}
            {children}
            {/* IMAGE */}
            {renderImage()}
            {/* ACTION BUTTONS */}
            {(!printing || presentationpreview) && (
                <SceneActionButtons
                    publicProject={publicProject}
                    presentationpreview={presentationpreview}
                    isDragging={isDragging}
                    show={hovered}
                    setHovered={setHovered}
                    setChildOpen={setChildOpen}
                    onCommentSubmit={onCommentSubmit}
                    scene={scene}
                />
            )}
        </S.SceneImageWrapper>
    )
}

export default React.memo(SceneImage)
