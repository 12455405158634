import React, { useState, useEffect } from 'react'
import { Elements, StripeProvider } from '@stripe/react-stripe-js'
import * as R from 'ramda'

import config from '../../../config'
import Modal from '../../common/Modal'
import CheckoutForm from '../CheckoutForm'

const UpdateCard = () => {
    const [stripe, setStripe] = useState(null)
    useEffect(() => {
        if (R.isNil(stripe)) {
            setStripe(window.Stripe(config.stripeKey))
        }
    }, [])
    return (
        <Modal
            isCloseable={true}
            title="Update Credit Card"
            subtitle="Enter your new credit card information below.">
            <Elements stripe={stripe}>
                <CheckoutForm showSubscriptionPaymentInfo={true} />
            </Elements>
        </Modal>
    )
}

export default UpdateCard
