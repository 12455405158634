import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

export default class Authenticate extends React.Component {
  static propTypes = {
    authenticate: PropTypes.func,
  };

  constructor(props) {
    super(props);
    R.forEach(fn => (this[fn] = this[fn].bind(this)), [
      'onSuccess',
      'onFailure',
    ]);
  }

  componentDidMount() {
    if (window.gapi) {
      window.gapi.signin2.render('g-signin2', {
        scope: 'profile email',
        width: 350,
        height: 42,
        longtitle: true,
        theme: 'dark',
        onsuccess: this.onSuccess,
        onfailure: this.onFailure,
      });
    }
  }

  onSuccess(googleUser) {
    this.props.authenticate({
      idp: 'GOOGLE',
      payload: { idtoken: googleUser.getAuthResponse().id_token },
    });
  }

  onFailure(err) {
    console.error('Google Auth Failed!', err);
  }

  render() {
    return <div id="g-signin2" className="inline-block" />;
  }
}
