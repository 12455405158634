import * as R from 'ramda'
import Q from 'q'
import request from 'superagent'
import Cookies from 'js-cookie'
import { isFreeAccount } from './permissions'

global.Cookies = Cookies

const getUserId = () =>
    window.$APP.state && window.$APP.state.session && window.$APP.state.session.userId

const getSessionId = () =>
    window.$APP.state && window.$APP.state.session && window.$APP.state.session.sessionId

const processError = (error, body) => ({
    error: error.message,
    message: body.error ? body.error : body,
})

const processApiResponse = R.curry((defer, err, resp) => {
    // Not authenticated
    if (err && err.status === 403) {
        if (isFreeAccount(window.$APP.state.session)) {
            window.sagaHistory.push({
                pathname: '/billing',
                state: { modal: true, closeable: true, upgrade: true },
            })
        } else {
            window.sagaHistory.push({
                pathname: '/authenticate',
            })
            defer.reject(processError(err, resp.body))
        }
        return
    } else if (err && err.status === 402) {
        window.sagaHistory.replace({
            pathname: '/pastdue',
            state: { modal: true, closeable: false },
        })
        return
    }
    // Any other error? Just resolve for now
    else if (err) {
        defer.reject(processError(err, resp ? resp.body : err.message))
    }
    // All good!
    else {
        defer.resolve(resp.body)
    }
})

// post :: Bool -> String -> Object -> Promise
function post(url, data, publicCall = false, reCaptchaToken) {
    let d = Q.defer()

    extend(
        publicCall,
        request
            .post(url)
            .send(JSON.stringify(data))
            .set('x-fp', Cookies.get('_fprom_track'))
            .set('Content-Type', 'application/json'),
        processApiResponse(d),
        reCaptchaToken,
    )

    return d.promise
}

// get :: Bool -> String -> Promise
function get(url, publicCall = false, reCaptchaToken) {
    let d = Q.defer()

    extend(publicCall, request.get(url), processApiResponse(d), reCaptchaToken)

    return d.promise
}

// put :: Bool -> String -> Object -> Promise
function put(url, data, publicCall = false, reCaptchaToken) {
    let d = Q.defer()

    extend(
        publicCall,
        request
            .put(url)
            .send(JSON.stringify(data))
            .set('Content-Type', 'application/json')
            .set('x-fp', Cookies.get('_fprom_track')),
        processApiResponse(d),
        reCaptchaToken,
    )

    return d.promise
}

function patch(url, data, publicCall = false, reCaptchaToken) {
    let d = Q.defer()

    extend(
        publicCall,
        request
            .patch(url)
            .send(JSON.stringify(data))
            .set('Content-Type', 'application/json'),
        processApiResponse(d),
        reCaptchaToken,
    )

    return d.promise
}

// get :: Bool -> String
function delWithData(url, data, publicCall = false, reCaptchaToken) {
    let d = Q.defer()

    extend(
        publicCall,
        request
            .del(url)
            .send(JSON.stringify(data))
            .set('Content-Type', 'application/json'),
        processApiResponse(d),
        reCaptchaToken,
    )

    return d.promise
}

// get :: Bool -> String
function del(url, publicCall = false, reCaptchaToken) {
    let d = Q.defer()

    extend(
        publicCall,
        request.del(url).set('Content-Type', 'application/json'),
        processApiResponse(d),
        reCaptchaToken,
    )

    return d.promise
}

function extend(publicCall, request, callback, reCaptchaToken) {
    if (!publicCall) {
        request.set('auth', getUserId()).set('sessionId', getSessionId())
    }
    if (reCaptchaToken) {
        request.set('recaptcha', reCaptchaToken)
    }
    request.end(callback)
}

export default {
    post,
    get,
    put,
    patch,
    del,
    delWithData,
}
