import { createAction } from 'redux-actions';

import * as types from '../constants/ActionTypes';

export const activateScene = createAction(types.ACTIVATE_SCENE);
export const clearActiveScene = createAction(types.CLEAR_ACTIVE_SCENE);
export const createScene = createAction(types.CREATE_SCENE);
export const sceneStartLoading = createAction(types.SCENE_START_LOADING);
export const sceneStopLoading = createAction(types.SCENE_STOP_LOADING);
export const createSceneBySlice = createAction(types.CREATE_SCENE_BY_SLICE);
export const createSceneResp = createAction(types.CREATE_SCENE_RESP);
export const deleteScene = createAction(types.DELETE_SCENE);
export const deleteSceneResp = createAction(types.DELETE_SCENE_RESP);
export const reorderScene = createAction(types.REORDER_SCENE);
export const reorderScenePreSync = createAction(types.REORDER_SCENE_PRE_SYNC);
export const reorderSceneResp = createAction(types.REORDER_SCENE_RESP);
export const cloneSetImage = createAction(types.CLONE_SET_IMAGE);
export const setImage = createAction(types.SET_IMAGE);
export const uploadSceneImage = createAction(types.UPLOAD_SCENE_IMAGE);
export const setImageResp = createAction(types.SET_IMAGE_RESP);
export const updateScene = createAction(types.UPDATE_SCENE);
export const updateScenePreSync = createAction(types.UPDATE_SCENE_PRE_SYNC);
export const updateSceneResp = createAction(types.UPDATE_SCENE_RESP);
