export const toolGroups = {
    brush: {
        tileBrush: 'sketch-icon-tileBrush',
        mirrorBrush: 'sketch-icon-mirrorBrush',
        pencil: 'sketch-icon-pencil',
        calligraphy: 'sketch-icon-calligraphy',
        pen: 'sketch-icon-pen',
        streamer: 'sketch-icon-streamer',
        paintbrush: 'sketch-icon-paintbrush',
        crayon: 'sketch-icon-crayon',
        polyline: 'sketch-icon-polyline',
        stamp: 'sketch-icon-stamp',
        path: 'sketch-icon-path',
        spraypaint: 'sketch-icon-spraypaint',
        arrow: 'sketch-icon-arrow',
        fur: 'sketch-icon-fur',
        sketchy: 'sketch-icon-sketchy',
        web: 'sketch-icon-web',
        spirograph: 'sketch-icon-spirograph',
    },
    shape: {
        square: 'sketch-icon-square',
        rectangle: 'sketch-icon-rectangle',
        regularPolygon: 'sketch-icon-polygon',
        triangle: 'sketch-icon-triangle',
        star: 'sketch-icon-star',
        burst: 'sketch-icon-burst',
        circle: 'sketch-icon-circle',
        ellipse: 'sketch-icon-ellipse',
        pie: 'sketch-icon-pie',
        ring: 'sketch-icon-ring',
        radialBurst: 'sketch-icon-radialBurst',
        gear: 'sketch-icon-gear',
        spiral: 'sketch-icon-spiral',
        heart: 'sketch-icon-heart',
    },
    fill: {
        paintApplicator: 'sketch-icon-paintApplicator',
        floodfill: 'sketch-icon-floodfill',
    },
}
