import React from 'react'
import Modal from '../common/Modal'
import { StyledButton, StyledInput } from './styled'

const RenameFolder = ({ folderName, setFolderName, onClick, submitting }) => {
    return (
        <Modal title="Rename Folder" isCloseable={!submitting} isCancelable={!submitting}>
            <StyledInput
                label="Rename Folder"
                style={{ textAlign: 'left' }}
                defaultValue={folderName}
                onChange={ev => setFolderName(ev.target.value)}
            />
            <StyledButton
                primary
                fullwidth
                className="m-t-md"
                onClick={onClick}
                disabled={!folderName || submitting}>
                Submit
            </StyledButton>
        </Modal>
    )
}

export default RenameFolder
