import React from 'react'
import Modal from '../common/Modal'

import { StyledButton, RadioButtonContainer, MoveToFolderListContainer } from './styled'

const MoveToFolderList = ({
    folderList,
    moveFromFolderId,
    handleSubmit,
    handleOnChange,
    submitting,
}) => {
    return (
        <Modal
            title="Move Storyboards"
            subtitle="Choose a folder to move your selected projects."
            isCloseable={!submitting}
            isCancelable={!submitting}>
            <MoveToFolderListContainer>
                {folderList &&
                    folderList.map((item, index) => (
                        <RadioButtonContainer key={`mtflc_${index}`}>
                            {item.folderName}
                            <input
                                defaultChecked={index === 0}
                                disabled={moveFromFolderId === item.folderId}
                                type="radio"
                                name="radio"
                                value={item.folderId}
                                onChange={handleOnChange}
                            />
                            <span className="checkmark"></span>
                        </RadioButtonContainer>
                    ))}
            </MoveToFolderListContainer>
            <StyledButton primary fullwidth disabled={submitting} onClick={handleSubmit}>
                {submitting ? 'Moving' : 'Move'} to Folder
            </StyledButton>
        </Modal>
    )
}

export default MoveToFolderList
