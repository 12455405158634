import * as R from 'ramda'
import * as types from '../constants/ActionTypes'
import * as helpers from './helpers'
import { orderScenes } from '../utils/scene'

function updateScenes(scenes, scene) {
    return R.map(x => (x.id === scene.id ? scene : x), scenes)
}

function removeSceneById(state, scene) {
    return R.filter(x => x.id !== scene.id, state)
}

export function scenes(state = null, { type, payload }) {
    switch (type) {
        case types.FETCH_PROJECT_BY_ID_RESP:
            return orderScenes(payload.scenes || state, payload.project.sceneOrder)
        case types.CREATE_SCENE_RESP:
            return R.insert(
                typeof payload.position === 'undefined' ? state.length : payload.position,
                payload.scene,
                state,
            )
        case types.UPDATE_SCENE_RESP:
            return updateScenes(state, payload)
        case types.UPDATE_SCENE_PRE_SYNC:
            return updateScenes(state, payload)
        case types.REORDER_SCENE_PRE_SYNC:
            return orderScenes(state, payload)
        case types.REORDER_SCENE_RESP:
            return orderScenes(state, payload)
        case types.SET_IMAGE_RESP:
            return payload ? updateScenes(state, payload) : state
        case types.UPDATE_ROUTE:
            return helpers.activeProjectView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.teamPlanView(payload.pathname) ||
                helpers.CancelSubscriptionView(payload.pathname) ||
                helpers.profileView(payload.pathname)
                ? state
                : null
        case types.DELETE_SCENE_RESP:
            return removeSceneById(state, payload)
        default:
            return state
    }
}

export function scene(state = null, { type, payload }) {
    switch (type) {
        case types.ACTIVATE_SCENE:
            return payload
        case types.CLEAR_ACTIVE_SCENE:
            return null
        case types.UPDATE_ROUTE:
            return helpers.activeProjectView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.profileView(payload.pathname)
                ? state
                : null
        default:
            return state
    }
}

export function sceneLoading(state = false, { type }) {
    switch (type) {
        case types.SCENE_START_LOADING:
            return true
        case types.SCENE_STOP_LOADING:
            return false
        default:
            return state
    }
}
