import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import actions from '../../../actions'
// import UpdateCard from '../components/Billing/UpdateCard'
import UpdateCard from './UpdateCard'

const UpdateCardContainer = ({ subscriptionId, fetchSubscriptionPlan, ...props }) => {
    React.useEffect(() => {
        fetchSubscriptionPlan()
    }, [subscriptionId])
    return <UpdateCard {...props} subscriptionId={subscriptionId} />
}

export default withRouter(
    connect(
        state => {
            let { session, user: userState, subscriptionPlanInfo } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            return {
                user,
                subscriptionPlanInfo,
                subscriptionId: user
                    ? user.paymentGateway
                        ? user.paymentGateway.subscriptionId
                        : null
                    : null,
            }
        },
        {
            ...actions.session,
            ...actions.subscriptionPlan,
        },
    )(UpdateCardContainer),
)
