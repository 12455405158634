import settings from './config/Settings.json';
import defaults from './config/Defaults.json';
import hosts from './config/Hosts.json';
import theme from './config/Theme.json';
import tools from './config/Tools.json';

import clipart from './config/assets/Clipart.json';
import color from './config/assets/Color.json';
import font from './config/assets/Font.json';
import gradient from './config/assets/Gradient.json';
import pattern from './config/assets/Pattern.json';
import shape from './config/assets/Shape.json';
import stamp from './config/assets/Stamp.json';
import tileBrush from './config/assets/TileBrush.json';

export const sketchConfig = {
  globals: {
    snapTo: true,
    snapToLayers: 5,
  },
  settings,
  defaults,
  hosts,
  theme,
  tools,
  assets: {
    clipart,
    color,
    font,
    gradient,
    pattern,
    shape,
    stamp,
    tileBrush,
  },
};
