import * as actionTypes from '../constants/Folders'
import { REORDER_STORYBOARDS_RESP, MOVE_STORYBOARDS_RESP } from '../constants/Projects'
import {
    CREATE_PROJECT_RESP,
    UPDATE_ROUTE,
    DELETE_PROJECT_RESP,
    UPDATE_PROJECT_RESP,
    CREATE_SCENE_RESP,
    DELETE_SCENE_RESP,
} from '../constants/ActionTypes'
import * as R from 'ramda'
import * as helpers from './helpers'

const addCreatedFolderToState = (state, payload) => {
    return R.prepend(payload, state)
}

const removeFolderFromState = (state, payload) => {
    return R.filter(R.compose(R.not, R.propEq('id', payload.userFoldersId)), state)
}

const addCreatedProjectToFolder = (state, payload) => {
    payload['permissions'] = 7
    return R.map(
        R.when(R.propEq('folderId', payload.folderId), R.modify('storyboards', R.prepend(payload))),
        state,
    )
}

const renameFolder = (state, payload) => {
    return R.map(
        R.when(R.propEq('folderId', payload.folderId), R.assoc('folderName', payload.folderName)),
        state,
    )
}

const reorderStoryboards = (state, payload) => {
    const { currentFolder, storyboardsOrder } = payload
    return R.map(
        R.when(
            R.propEq('folderId', currentFolder.folderId),
            R.assoc('storyboards', storyboardsOrder),
        ),
        state,
    )
}

const removeNestedObject = (payload, state) =>
    R.map(
        R.evolve({ storyboards: xs => removeNestedObject(payload, xs) }),
        R.reject(R.propEq('id', payload), state),
    )

const removeStoryBoardFromFolder = (state, payload) => {
    return removeNestedObject(payload, state)
}

const moveStoryboards = (state, payload) => {
    const { moveToFolderData, moveFromFolderData, projectIds } = payload

    const moveFromFolderUsing = moveFromFolderData
        ? R.propEq('folderId', moveFromFolderData.folderId)
        : R.propEq('id', 'default')

    let selectedFolderProjects = R.pipe(
        R.find(moveFromFolderUsing),
        R.path(['storyboards']),
        // R.find(R.propEq('id', projectIds[0])),
    )(state)

    const joinByType = R.innerJoin(R.flip(R.propEq('id')))
    const results = joinByType(selectedFolderProjects, projectIds)

    results.map(result => (result['folderId'] = moveToFolderData?.folderId))

    return state.map(folder => {
        if (folder.folderId === moveToFolderData.folderId) {
            folder['storyboards'].splice(0, 0, ...results)
        }
        if (
            (moveFromFolderData && folder.folderId === moveFromFolderData.folderId) ||
            folder.id === 'default'
        ) {
            results.forEach(selectedProject => {
                const index = R.findIndex(R.propEq('id', selectedProject.id))(folder['storyboards'])
                if (index !== -1) {
                    folder['storyboards'].splice(index, 1)
                }
            })
        }
        return folder
    })
}

const updateProjectInDashboard = (state, payload) => {
    const updateValue = {
        // currently these are reflected in the dashboard
        title: payload.title,
        // these are useless for now, might find a use case later :D
        sceneOrder: [...payload.sceneOrder],
        lastModified: payload.lastModified,
        visibility: payload.visibility,
        description: payload.description,
        permissions: payload.permissions,
    }

    return R.map(
        R.over(
            R.lensProp('storyboards'),
            R.map(project =>
                project.id === payload.id ? R.mergeLeft(updateValue, project) : project,
            ),
        ),
        state,
    )
}

const updateSceneCountFn = R.curry((state, fn) =>
    R.map(R.over(R.lensProp('storyboards'), R.map(fn)), state),
)

const addSceneCountInDashboard = (state, payload) => {
    const fn = project =>
        project.id === payload.projectId
            ? R.evolve(
                  {
                      sceneOrder: R.insert(-1, payload.id),
                  },
                  project,
              )
            : project

    return updateSceneCountFn(state, fn)
}

const deleteSceneCountInDashboard = (state, payload) => {
    const fn = project =>
        project.id === payload.projectId
            ? R.evolve(
                  {
                      sceneOrder: R.without([payload.id]),
                  },
                  project,
              )
            : project

    return updateSceneCountFn(state, fn)
}

export function folders(state = null, { type, payload }) {
    switch (type) {
        case UPDATE_ROUTE:
            return helpers.activeProjectView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.teamPlanView(payload.pathname) ||
                helpers.CancelSubscriptionView(payload.pathname) ||
                helpers.profileView(payload.pathname) ||
                helpers.projectDeleteView(payload.pathname) ||
                helpers.projectLockedView(payload.pathname) ||
                helpers.dashboardView(payload.pathname) ||
                helpers.folderCreateView(payload.pathname) ||
                helpers.folderDeleteView(payload.pathname) ||
                helpers.folderRenameView(payload.pathname) ||
                helpers.folderShareView(payload.pathname) ||
                helpers.folderMoveView(payload.pathname)
                ? state
                : null
        case actionTypes.CREATE_FOLDER:
            return state
        case actionTypes.CREATE_FOLDER_RESP:
            return state ? addCreatedFolderToState(state, payload) : state
        case actionTypes.DELETE_FOLDER:
            return state
        case actionTypes.DELETE_FOLDER_RESP:
            return state ? removeFolderFromState(state, payload) : state
        case actionTypes.UPDATE_FOLDER:
            return state
        case actionTypes.UPDATE_FOLDER_RESP:
            return state ? renameFolder(state, payload) : state
        case actionTypes.FETCH_FOLDERS:
            return state
        case actionTypes.FETCH_FOLDERS_RESP:
            return payload || null
        case actionTypes.REORDER_FOLDERS:
            return state
        case actionTypes.REORDER_FOLDERS_RESP:
            return payload ? payload : state
        case DELETE_PROJECT_RESP:
            return state ? removeStoryBoardFromFolder(state, payload) : state
        case CREATE_PROJECT_RESP:
            return state ? addCreatedProjectToFolder(state, payload) : state
        case REORDER_STORYBOARDS_RESP:
            return state ? reorderStoryboards(state, payload) : state
        case MOVE_STORYBOARDS_RESP:
            return state ? moveStoryboards(state, payload) : state
        case UPDATE_PROJECT_RESP:
            return state ? updateProjectInDashboard(state, payload) : state
        case CREATE_SCENE_RESP:
            const { scene } = payload
            return state ? addSceneCountInDashboard(state, scene) : state
        case DELETE_SCENE_RESP:
            return state ? deleteSceneCountInDashboard(state, payload) : state
        default:
            return state
    }
}

export function currentFolder(state = null, { type, payload }) {
    switch (type) {
        case actionTypes.DELETE_FOLDER:
            return null
        case actionTypes.UPDATE_FOLDER_RESP:
            return state ? renameFolder([state], payload)[0] : state
        case actionTypes.SET_CURRENT_FOLDER_TO:
            return payload || null
        default:
            return state
    }
}
