import PropTypes from 'prop-types'

import React, { Component } from 'react'
import ProjectTileImage from './projectTileImage'
import { has, isTrialExpired, PERMISSIONS } from '../../utils/permissions'
import DropdownMenu from '../Dropdown'
import FolderIcon from '../../icons/FolderIcon'
import PrintIcon from '../../icons/PrintIcon'
import DuplicateIcon from '../../icons/DuplicateIcon'
import LeaveProjectIcon from '../../icons/LeaveIcon'
import ShareIcon from '../../icons/ShareIcon'

import {
    StyledLink,
    StyledProjectTile,
    StyledProjectDetailContainer,
    StyledLockWrapper,
    StyledLockedProject,
    StyledProjectsActionContainer,
    StyledProjectTileCheckbox,
} from './styled'

const IMAGE_HEIGHT = 130

const ActionButtons = props => {
    if (props.storyboard.isOwner) {
        return (
            <StyledProjectsActionContainer>
                {/* DELETE BTN */}
                <div className="btn-right">
                    <StyledLink
                        trash={+true}
                        className="icon-link cp inline-block"
                        to={{
                            pathname: `/projects/delete/${props.storyboard.id}`,
                            state: { modal: true, closeable: true },
                        }}>
                        <i className="fa fa-trash-o trash" aria-hidden="true" />
                    </StyledLink>
                </div>
            </StyledProjectsActionContainer>
        )
    }
    return null
}

export default class ProjectTile extends Component {
    render() {
        let { storyboard, user } = this.props
        const { locked } = storyboard
        let isOwner = has(PERMISSIONS.OWNER, storyboard.permissions)
        let isEditor = has(PERMISSIONS.EDIT, storyboard.permissions)
        let isCommenter = has(PERMISSIONS.COMMENT, storyboard.permissions)
        let thumbnail__style = { height: IMAGE_HEIGHT }

        const checkboxChecked = this.props.selectedProjects.includes(storyboard.id)
        const checkboxDisplayStyle = checkboxChecked ? { display: 'block' } : {}

        const ITEMS = []
        if (storyboard.permissions && (isEditor || isCommenter)) {
            ITEMS.push({
                value: 'Print',
                Icon: PrintIcon,
                to: {
                    pathname: `/projects/${storyboard.id}/print`,
                    state: { modal: true, closeable: true },
                },
            })
        }
        if (storyboard.permissions && isOwner) {
            ITEMS.push(
                {
                    value: 'Move To Folder',
                    Icon: FolderIcon,
                    to: {
                        pathname: `/projects/move`,
                        state: {
                            modal: true,
                            closeable: true,
                            projectIds: [storyboard.id],
                            moveFromFolderId: storyboard.folderId,
                        },
                    },
                },
                {
                    value: 'Share',
                    Icon: ShareIcon,
                    to: {
                        pathname: `/projects/${storyboard.id}/invite`,
                        state: { modal: true, closeable: true },
                    },
                },
                {
                    value: 'Duplicate',
                    Icon: DuplicateIcon,
                    to: {
                        pathname: `/projects/${storyboard.id}/duplicate`,
                        state: { modal: true, closeable: true },
                    },
                },
                // {
                //     value: 'Cover',
                //     Icon: DuplicateIcon,
                //     to: {
                //         pathname: `/projects/${storyboard.id}/coverImage`,
                //         state: { modal: true, closeable: true },
                //     },
                // },
            )
        }

        if (storyboard.permissions && (isEditor || isCommenter) && !isOwner) {
            ITEMS.push({
                value: 'Leave Project',
                Icon: LeaveProjectIcon,
                to: {
                    pathname: `/projects/${storyboard.id}/leave-project`,
                    state: { modal: true, closeable: true },
                },
            })
        }

        return (
            <StyledProjectTile className="m-b-md">
                <div className={`ProjectItem pos-rlt`}>
                    <div className="thumb-container" style={thumbnail__style}>
                        <ProjectTileImage storyboard={storyboard} />
                        {/* {storyboard?.isOwner && !storyboard?.locked && (
                            <div className="fill curtain">
                                <div className="fill content" style={{ zIndex: '100' }}>
                                    <DropdownMenu
                                        items={ITEMS}
                                        // style={{ visibility: !active ? 'hidden' : '' }}
                                        style={{
                                            zIndex: '999',
                                            right: 10,
                                            top: 10,
                                            position: 'absolute',
                                        }}
                                        className="project-dropdown project-header-dropdown"
                                        dropdownButtonStyle="project-header-dropdown__btn"
                                        dropdownBodyWrapperStyle="project-dropdown__body-wrapper m-l--180"
                                        dropdownBodyStyle="project-dropdown__body"
                                    />
                                </div>
                            </div>
                        )} */}
                        {(!locked || !isOwner) && (
                            <div className="fill curtain" style={checkboxDisplayStyle}>
                                {' '}
                                {/* set display condition here */}
                                <div className="fill overlay" />
                                <div className="fill content">
                                    {isOwner && (
                                        <StyledProjectTileCheckbox>
                                            <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                        this.props.handleUpdateSelectedProjects(
                                                            storyboard.id,
                                                        )
                                                    }
                                                    checked={checkboxChecked}
                                                    tabIndex={-1}
                                                    disabled={false}
                                                />
                                                <span className="checkmark" />
                                            </label>
                                        </StyledProjectTileCheckbox>
                                    )}
                                    <DropdownMenu
                                        items={ITEMS}
                                        // style={{ visibility: !active ? 'hidden' : '' }}
                                        style={{
                                            zIndex: '999',
                                            right: 10,
                                            top: 10,
                                            position: 'absolute',
                                        }}
                                        className="project-dropdown project-header-dropdown"
                                        dropdownButtonStyle="project-header-dropdown__btn"
                                        dropdownBodyWrapperStyle="project-dropdown__body-wrapper m-l--180"
                                        dropdownBodyStyle="project-dropdown__body"
                                    />
                                    <StyledLink
                                        projecttileimageviewbtn={+true}
                                        className="r3"
                                        to={`/projects/${storyboard.id}`}>
                                        View Project
                                    </StyledLink>
                                </div>
                            </div>
                        )}
                    </div>
                    <StyledProjectDetailContainer>
                        <div className="header">
                            <div className="title">{storyboard.title}</div>
                            <div className="scene-count">
                                {storyboard.sceneOrder ? storyboard.sceneOrder.length : 0} Scenes
                            </div>
                        </div>
                        <div className="footer">
                            <div className="owner-detail">{storyboard.ownerName}</div>
                        </div>
                    </StyledProjectDetailContainer>
                </div>
                {locked && isOwner && <StyledLockedProject className="fill" />}
                {locked && isOwner && (
                    <StyledLockWrapper className="pos-abt">
                        {isTrialExpired(user) ? (
                            <StyledLink
                                lockicon={+true}
                                className="pos-abt"
                                to={{
                                    pathname: '/billing',
                                    state: {
                                        modal: true,
                                        closeable: true,
                                        upgrade: true,
                                    },
                                }}
                            />
                        ) : (
                            <StyledLink
                                lockicon={+true}
                                className="pos-abt"
                                to={{
                                    pathname: '/project-locked',
                                    state: {
                                        modal: true,
                                        closeable: true,
                                        upgrade: true,
                                    },
                                }}
                            />
                        )}
                    </StyledLockWrapper>
                )}
                {!locked && <ActionButtons {...this.props} />}
            </StyledProjectTile>
        )
    }
}

ProjectTile.propTypes = {
    storyboard: PropTypes.object,
}

ProjectTile.displayName = 'ProjectTile'
