import React from 'react'

const SceneNumber = ({ isActive, printing, number, className = '' }) => (
    <div
        data-tip={`Scene ${number}`}
        className={`p-l-xs p-r-xs ${
            isActive && !printing ? 'bg-primary' : 'bg-light'
        } font-bold text-center scene-number ${className}`}>
        {number}
    </div>
)

export default SceneNumber
