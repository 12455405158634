import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html {
        overflow-x: hidden;
        height: 100%;
        touch-action: manipulation;
        -ms-overflow-style: none;
    }

    body {
        font-family: 'Open Sans', sans-serif, Helvetica, Arial;
        font-size: 14px;
        color: #6b7379;
        -webkit-font-smoothing: antialiased;
        line-height: 1.4;
        height: 100%;
        background-color: #fafafa;
    }

    button {
        outline: none;
    }

    /* Hide placeholder when focused */
    input:focus::placeholder, textarea:focus::placeholder {
        color: transparent;
    }

`;
