import PropTypes from 'prop-types'
import React from 'react'
import * as R from 'ramda'

import Modal from '../common/Modal'
import PLAN from '../../constants/Plan'
import Messages from './Messages'
import { StyledButton } from './styled'

import AccountDetails from './AccountDetails'
import SubscriptionPlan from './SubscriptionPlan'
import Team from '../Team'
import SubscriptionButton from './SubscriptionButton'
import BillingSuccess from './BillingSuccess'

/**
 * @module Billing
 */

class Billing extends React.Component {
    constructor(props) {
        super(props)
        const showUpgradeFormFromHistory = R.path(
            ['history', 'location', 'state', 'showUpgradeForm'],
            props,
        )
        // state
        this.state = {
            processing: false,
            showCancellationConfirm: false,
            showCompletionMessage: props.showCompletionMessage,
            showCardUpdatedMessage: props.showCardUpdatedMessage,
            showUpgradeForm: props.showUpgradeForm || showUpgradeFormFromHistory,
            showInstantCancelMessage: props.showInstantCancelMessage,
        }
    }

    componentWillReceiveProps({
        showUpgradeForm,
        showCompletionMessage,
        showCardUpdatedMessage,
        showInstantCancelMessage,
    }) {
        // user's subscription plan changed?
        if (
            showCompletionMessage !== this.props.showCompletionMessage ||
            showCardUpdatedMessage !== this.props.showCardUpdatedMessage ||
            showUpgradeForm !== this.props.showUpgradeForm ||
            showInstantCancelMessage !== this.props.showInstantCancelMessage
        ) {
            // show completion message!
            this.setState({
                processing: false,
                showCancellationConfirm: false,
                showCompletionMessage,
                showCardUpdatedMessage,
                showUpgradeForm,
                showInstantCancelMessage,
            })
        }
    }

    goBack = e => {
        e && e.preventDefault()
        this.props.history.goBack
            ? this.props.history.goBack()
            : this.props.history.replace('/projects')
    }

    onDone = err => {
        if (err == null) {
            this.setState({ showUpgradeForm: false })
        }
    }

    render = () => {
        if (this.state.showUpgradeForm) {
            return (
                <SubscriptionPlan
                    {...this.props}
                    currentPaymentCycle={this.props.paymentCycle}
                    onDone={this.onDone}
                />
            )
        } else {
            const completion =
                this.props.showCompletionMessage &&
                !this.props.isFreeAccount &&
                !this.props.isCancelled
            if (completion) {
                return <BillingSuccess coupon={this.props.coupon} />
            } else {
                const SHOW_UPGRADE_PLAN_FOR_SUBSCRIPTION = [
                    PLAN.SOLO_PLAN_MONTH,
                    PLAN.SOLO_PLAN_YEAR,
                    PLAN.PRO_PLAN_MONTH,
                    PLAN.PRO_PLAN_YEAR,
                    PLAN.BUSINESS_PLAN_MONTH,
                    PLAN.BUSINESS_PLAN_YEAR,
                ]
                const showUpgradePlan =
                    !this.props.isCancelled &&
                    SHOW_UPGRADE_PLAN_FOR_SUBSCRIPTION.includes(this.props.paymentCycle)
                return (
                    <Modal isCloseable title="Billing" goBack={this.goBack}>
                        <div className="content text-center">
                            <AccountDetails
                                userLoading={this.props.userLoading}
                                user={this.props.user}
                                team={this.props.teamInfo}
                                isCancelled={this.props.isCancelled}
                                isTrial={this.props.isTrial}
                                isExtended={this.props.isExtended}
                                isTrialExpired={this.props.isTrialExpired}
                                isFreeAccount={this.props.isFreeAccount}
                                isTeamMember={this.props.isTeamMember}
                            />
                            <div className="m-t-lg">
                                {showUpgradePlan && (
                                    <StyledButton
                                        disabled={R.isEmpty(this.props.productPlans)}
                                        primary
                                        fullwidth
                                        className="text-u-c"
                                        onClick={() => this.setState({ showUpgradeForm: true })}>
                                        {R.isEmpty(this.props.productPlans)
                                            ? 'Loading Plan'
                                            : 'Upgrade Plan'}
                                    </StyledButton>
                                )}
                            </div>
                            <div className="m-t-sm">
                                {!this.props.isFreeAccount &&
                                    !this.props.isCancelled &&
                                    !this.props.isTeamMember &&
                                    this.props.hasAccessToTeamPlan && (
                                        <Team
                                            hasTeamPlan={this.props.isTeamOwner}
                                            createTeamPlan={this.props.createTeamPlan}
                                            user={this.props.user}
                                            history={this.props.history}
                                        />
                                    )}
                                <SubscriptionButton
                                    processing={this.state.processing}
                                    isDisabled={R.isEmpty(this.props.productPlans)}
                                    history={this.props.history}
                                    isTeamMember={this.props.isTeamMember}
                                    isFreeAccount={this.props.isFreeAccount}
                                    isCancelled={this.props.isCancelled}
                                    showUpgradeForm={this.state.showUpgradeForm}
                                    leaveTeamPlan={this.leaveTeamPlanHandler}
                                    setState={this.setState.bind(this)}
                                />
                            </div>
                            {!this.state.processing && (
                                <Messages
                                    {...this.props}
                                    showCardUpdatedMessage={this.state.showCardUpdatedMessage}
                                    showInstantCancelMessage={this.state.showInstantCancelMessage}
                                />
                            )}
                        </div>
                    </Modal>
                )
            }
        }
    }
}

Billing.propTypes = {
    cancel: PropTypes.func,
    cancelSubscription: PropTypes.func,
    createTeamPlan: PropTypes.func,
    leaveTeamPlan: PropTypes.func,
    isFreeAccount: PropTypes.bool,
    isCancelled: PropTypes.bool,
    isTrial: PropTypes.bool,
    showUpgradeForm: PropTypes.bool,
    showCompletionMessage: PropTypes.bool,
    showCardUpdatedMessage: PropTypes.bool,
    session: PropTypes.object,
    user: PropTypes.object,
}

Billing.displayName = 'Billing'

export default Billing
