import { select, take } from 'redux-saga/effects';
import { TOGGLE_PAINT } from '../../constants/ActionTypes';

/**
 * I enable/disable the Sketch API
 *
 * When the Sketch API is not being used it should be frozen, to avoid using
 * any resources.
 *
 * @return {Generator}
 */
export function* statusManager() {
  yield updateDocState();
  while (true) {
    yield take(TOGGLE_PAINT);
    yield updateDocState();
  }

  function* updateDocState() {
    const sketch = window.sketch;
    const doc = sketch.doc;
    const showPaintCanvas = yield select(state => state.showPaintCanvas);
    const $sketchUploader = document.querySelector('.sketch-Uploader');
    if (showPaintCanvas) {
      doc.unfreeze();
      $sketchUploader.style.pointerEvents = '';
    } else {
      doc.orient.centerDocInView();
      doc.freeze();
      $sketchUploader.style.pointerEvents = 'none';
    }
  }
}
