import 'react-app-polyfill/ie11'
import 'core-js/es/array/includes'
import 'core-js/es/array/find-index'
import 'core-js/es/string/repeat'
import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import { Application } from './containers'
import configureStore from './store'
import { registerSketchReadyHandler } from './sketch'
import actions from './actions'

import './resources/css/styles.scss'
import { startBusyWarningService } from './utils/busyWarning'

import { ThemeProvider } from 'styled-components'
import GlobalStyle from './theme/GlobalStyle'
import Theme from './theme/Theme'

if (process.env.NODE_ENV === 'production') {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT_ID)
    setupLogRocketReact(LogRocket)
}

// if (process.env.NODE_ENV === 'development') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//     whyDidYouRender(React, {
//         trackAllPureComponents: true,
//     })
// }

const store = configureStore()
window.plotStore = store
window.actions = actions
registerSketchReadyHandler(store)
startBusyWarningService(store)

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={Theme}>
                <GlobalStyle />
                <Application />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
)
