import React, { useRef } from 'react'
import * as R from 'ramda'

import Modal from './common/Modal'
import Scene from './scene/SceneContainer'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Keyboard } from 'swiper'

// Import Swiper styles
import 'swiper/swiper.min.css'

function NextArrow(props) {
    const { className, style, onClick, isMobile } = props
    return (
        <div
            onClick={onClick}
            style={{ ...style }}
            className={` slick-arrow ${
                isMobile
                    ? 'slick-arrow-below'
                    : ' swiper-next slick-next Project__action-btns-right'
            } `}>
            <span className="slick-next-angle">
                <i className="fa fa-angle-right" aria-hidden="true" />
            </span>
        </div>
    )
}

function PrevArrow(props) {
    const { className, style, onClick, isMobile } = props
    return (
        <div
            onClick={onClick}
            style={{ ...style }}
            className={` slick-arrow ${
                isMobile ? 'slick-arrow-below' : 'swiper-prev slick-prev Project__action-btns-right'
            } `}>
            <span className="slick-prev-angle">
                <i className="fa fa-angle-left" aria-hidden="true" />
            </span>
        </div>
    )
}

export default props => {
    const swiperRef = useRef()

    const next = () => swiperRef.current.slideNext()

    const previous = () => swiperRef.current.slidePrev()

    const prevSlideNumber = R.findIndex(R.propEq('id', props.scene?.id), props.scenes) || 0

    return (
        <Modal
            className="project-preview-modal"
            presentationpreview="true"
            isCloseable
            noModalHeader>
            <Swiper
                onSwiper={swiper => (swiperRef.current = swiper)}
                initialSlide={prevSlideNumber < 0 ? 0 : prevSlideNumber}
                spaceBetween={50}
                slidesPerView={1}
                shortSwipes={true}
                centeredSlides
                autoHeight
                observer={true}
                observeParents={true}
                loop
                keyboard={{
                    enabled: true,
                    onlyInViewport: false,
                }}
                // onSlideChange={() => console.log('slide change')}
                modules={[Navigation, Keyboard]}>
                {props.scenes.map((scene, i, row) => {
                    if (i < row.length) {
                        return (
                            <SwiperSlide key={i}>
                                <Scene
                                    presentationpreview="true"
                                    scene={scene}
                                    printing
                                    sceneNumber={i + 1}
                                />
                            </SwiperSlide>
                        )
                    }
                })}
            </Swiper>
            <PrevArrow onClick={previous} />
            <NextArrow onClick={next} />
            <div
                className="project-preview-modal__bottom-btn"
                style={{ textAlign: 'center', marginTop: '2rem' }}>
                <PrevArrow isMobile={true} onClick={previous} />
                <div style={{ width: '15%', height: '0' }} />
                <NextArrow isMobile={true} onClick={next} />
            </div>
        </Modal>
    )
}
