import React from 'react';

export default ({ projectTitle, className = '' }) => {
  return (
    <div className={`${className} project-meta`}>
      <div className="b-b b-primary b-2x outline-none project-meta__info max-width-85">
        <span
          style={{
            alignSelf: 'flex-end',
            fontSize: '16px',
            color: '#fff',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            height: '32px',
            paddingBottom: '10px',
            maxWidth: '100%',
          }}
        >
          {projectTitle}
        </span>
      </div>
    </div>
  );
};
