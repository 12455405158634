import React from 'react'
import { withRouter } from 'react-router-dom'
import RegisterForm from './CognitoRegisterForm'
import SocialButtons from './lib/SocialButtons'

export default withRouter(
    class CognitoRegister extends React.Component {
        constructor(props) {
            super(props)
        }

        render() {
            return (
                <div className="registration">
                    <RegisterForm
                        title="Create Your Free Account"
                        description="Use your email or sign-in with social media. You're just seconds
                away from better storyboards!"
                    />
                    <SocialButtons {...this.props} />
                </div>
            )
        }
    },
)
