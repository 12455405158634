import React from 'react'
import { connect } from 'react-redux'

import CreateFolder from './CreateFolder'
import { createFolderAction } from '../../actions/FolderActions'

const CreateFolderContainer = ({ user, createFolderAction }) => {
    return <CreateFolder createFolderAction={createFolderAction} />
}

export default connect(
    state => {
        const { user: userState, session } = state
        const user = userState && userState.loading ? session.user || session : userState.user
        return {
            user,
        }
    },
    {
        createFolderAction,
    },
)(CreateFolderContainer)
