import styled, { css } from 'styled-components'
import CommonStyledButton from '../../common/styled/StyledButton'
import CommonStyledInput from '../../common/styled/StyledInput'

export const StyledInput = styled(CommonStyledInput)`
    text-align: left !important;
    ::placeholder {
        text-align: left;
    }
    :focus::placeholder {
        color: #686f75 !important;
    }
`
export const StyledButton = styled(CommonStyledButton)``

export const StyledLabel = styled.label`
    right: 0;
    bottom: 0;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 0.7em;
    position: absolute;
    text-align: right;
    color: #686f75;
    text-transform: uppercase;
    font-weight: bold;
`

export const PaymentButton = styled(CommonStyledButton)`
    background: ${props => props.theme.color.plot_green};
    line-height: unset;
    padding: 10px 20px;
    font-weight: normal;
    color: #fff;
    span {
        &:first-child {
            text-transform: uppercase;
        }
        display: block;
    }
    .price {
        font-weight: bold;
        text-transform: lowercase;
        font-size: large;
    }
`

export const CouponButton = styled(CommonStyledButton)`
    ${props =>
        props.coupon &&
        css`
            background: ${props.theme.color.plot_green};
            text-align: center;
            width: 55%;
            margin-left: 10px;
            padding: 0 10px;
        `}
`

export const ErrorDiv = styled.div`
    padding-bottom: 1rem;
`

export const UpgradeDiv = styled.div`
    text-align: center;
`
