import React from 'react';

export default props => {
  return (
    <span>
      {Array.from({ length: props.fileCount }).map((_, i) => {
        return (
          <div key={i} className="toolbar__thumbnail__container">
            <div className="pos-rlt toolbar__thumbnail">
              <div className="sn" draggable="false">
                <div className="imageloader loaded">
                  <div className="vc">
                    <div className="loading" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </span>
  );
};
