import React from 'react'
import Modal from '../common/Modal'
import { StyledButton } from './styled'

export default props => {
    const goBack = e => {
        e && e.preventDefault()
        window.location.replace('/')
    }
    return (
        <Modal
            isCloseable
            title="Great Choice!"
            subtitle="Your account has been upgraded successfully."
            goBack={goBack}>
            {props.coupon.message && (
                <div className="p-sm text-center">
                    <div className="text-white">{`Enjoy 
                     ${props.coupon.message.slice(9)}
                    After this period, you will be billed at regular price.`}</div>
                </div>
            )}
            <div className="m-t-md">
                <StyledButton primary alignCenterHorizontal className="m-b-sm" onClick={goBack}>
                    Let's Go
                </StyledButton>
            </div>
        </Modal>
    )
}
