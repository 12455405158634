import * as R from 'ramda';
import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { register } from './lib/cognito';
import FormInput from './lib/FormInput';
import SubmitButton from './lib/SubmitButton';

export default withRouter(
  class CognitoInvitation extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        email: props.match.params.email,
        password: '',
        firstName: '',
        lastName: '',
        verificationNumber: '',
        errorMessage: null,
      };

      R.forEach(fn => (this[fn] = this[fn].bind(this)), [
        'handleChange',
        'handleSubmit',
      ]);

      this.query = queryString.parse(this.props.location.search);
    }

    handleChange(e) {
      this.setState({
        [e.target.name]: e.target.value,
        errorMessage: '',
      });
    }

    handleSubmit(e) {
      e.preventDefault();

      this.setState({ loading: true });

      register({
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password,
      })
        .then(result => {
          this.setState({ loading: false });
          this.props.history.push('/authenticate/unverified');
        })
        .catch(err => {
          this.setState({
            loading: false,
            errorMessage: err.message,
          });
        });
    }

    canSubmit() {
      return (
        this.state.email.length > 0 &&
        this.state.password.length > 0 &&
        this.state.firstName.length > 0 &&
        this.state.lastName.length > 0
      );
    }

    render() {
      return (
        <div className="registration">
          <h1 className="animated fadeIn sn signup__title">
            {`Your ${this.query.team ? 'Team' : 'Project'} is Waiting...`}
          </h1>
          <div className="signup__desc m-b-lg">
            Let's give your account a name and password first.
          </div>

          <div className="m-b-lg signup__note">
            Account Email:
            <br />
            {this.props.match.params.email}
          </div>

          <div className="inline-block">
            <form onSubmit={this.handleSubmit}>
              <FormInput
                label="First Name"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
              />

              <FormInput
                label="Last Name"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
              />

              <FormInput
                label="Password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />

              <SubmitButton disabled={!this.canSubmit()}>
                {this.state.loading ? 'Authenticating...' : 'Register'}
              </SubmitButton>
            </form>
          </div>

          <div>
            {this.state.errorMessage ? (
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
);
