import $ from 'jquery'
import { scrollToScene } from '../../../utils/scene'
import ActionButtons from './ActionButtons'
import Avatar from 'react-avatar'
import InlineComment from '../../commentInline'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { COMMENT_STATUS } from '../../../constants/Comments'

const TOP_OFFSET = 20

const toDate = val => {
    let date = new Date(val)
    return date.toLocaleDateString()
}

/**
 * @module Comment
 */

class Comment extends React.Component {
    constructor(props) {
        super(props)
        // state
        this.state = {
            replyByCommentId: null,
        }
    }

    toggleReply = commentId => {
        let replyByCommentId = commentId === this.state.replyByCommentId ? null : commentId
        this.setState({ replyByCommentId })
    }

    resetReply = () => {
        this.setState({ replyByCommentId: null })
    }

    onClick = (ev, commentId, sceneId) => {
        ev.stopPropagation()
        // Activate scene
        let scene = R.find(x => x.id === sceneId, this.props.scenes)
        this.props.activateScene(scene)
        // Scroll to element
        scrollToScene(sceneId)
    }

    render() {
        let { comment, active, sceneIsActivated, isReply, isProjectPublic } = this.props
        let display__class = '' // (active ? '' : 'dn') : '';
        let isResolved = R.equals(COMMENT_STATUS.RESOLVED, comment.status)
        let highlight__class = sceneIsActivated && active ? 'hightlight-comment' : ''
        let reply__class = isReply ? 'reply' : ''

        return (
            <div
                ref={this.props.innerRef}
                key={comment.id}
                className={`pos-rlt b-b p-t-md m-l-n-md m-r-n-md comment ${display__class} ${highlight__class} ${reply__class}`}
                onClick={ev => this.onClick(ev, comment.id, comment.sceneId)}>
                <div className="m-l-xl p-b pos-rlt p-l p-r-md">
                    <div className="pos-abt" style={{ top: 0, left: -25 }}>
                        <Avatar
                            name={comment.fullName}
                            size={30}
                            color="#EEE"
                            fgColor="#333"
                            round={true}
                        />
                    </div>
                    <div className="clearfix">
                        <div
                            className={`text-black m-b-xs text-normal pull-left ${
                                isResolved ? 'resolved' : ''
                            }`}>
                            {comment.fullName}
                        </div>
                        {!isProjectPublic && (
                            <ActionButtons
                                getGroupedCommentThread={this.props.getGroupedCommentThread}
                                comment={comment}
                                delete={this.props.delete}
                                permissions={this.props.permissions}
                                resolve={this.props.resolve}
                                session={this.props.session}
                                toggleReply={this.toggleReply}
                                unresolve={this.props.unresolve}
                            />
                        )}
                    </div>
                    <div className={`${isResolved ? 'resolved' : 'text-dark'}`}>{comment.body}</div>
                    <div className="m-t-xs text-sm clearfix">
                        <span className="text-light pull-left">{toDate(comment.dateCreated)}</span>
                        <span className="text-light pull-right">
                            {active ? (
                                <span className="text-primary">Scene {comment.sceneNumber}</span>
                            ) : (
                                <span>Scene {comment.sceneNumber}</span>
                            )}
                        </span>
                    </div>

                    {this.state.replyByCommentId === comment.id && (
                        <InlineComment
                            reply={payload => {
                                this.props.reply(payload)
                                this.resetReply()
                            }}
                            sceneId={comment.sceneId}
                            replyToComment={this.state.replyByCommentId}
                        />
                    )}
                </div>
            </div>
        )
    }
}

Comment.defaultProps = {}

Comment.propTypes = {
    activateScene: PropTypes.func,
    sceneIsActivated: PropTypes.bool,
    active: PropTypes.bool,
    comment: PropTypes.object,
    delete: PropTypes.func,
    permissions: PropTypes.object,
    reply: PropTypes.func,
    resolve: PropTypes.func,
    scenes: PropTypes.array,
    getGroupedCommentThread: PropTypes.func,
    session: PropTypes.object,
    project: PropTypes.object,
    unresolve: PropTypes.func,
}

Comment.displayName = 'Comment'

const CommentWithRef = React.forwardRef((props, ref) => <Comment innerRef={ref} {...props} />)
CommentWithRef.displayName = 'Comment'

export default CommentWithRef
