import moment from 'moment'
import * as R from 'ramda'
import { fork, call, select } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga'

import * as types from '../constants/ActionTypes'
import { helpcrunchTrackEvent, helpcrunchUpdateUser, helpcrunchUpdateUserData } from '../helpcrunch'
import { has, PERMISSIONS } from '../utils/permissions.js'

const getIsSketchCanvasOpen = state => state.showPaintCanvas
const getProjects = state => state.projects

function* runTrackProjects({ payload }) {
    const projects = yield select(getProjects)
    const ownerOf = projects.filter(proj => has(PERMISSIONS.OWNER, proj.permissions))
    const collabOf = projects.filter(proj => !has(PERMISSIONS.OWNER, proj.permissions))

    yield call(helpcrunchUpdateUserData, {
        num_projects_owned: ownerOf.length,
        num_projects_collab: collabOf.length,
    })
}

function* trackClickedDrawingTool() {
    yield* takeLatest(types.TOGGLE_PAINT, function*({ payload }) {
        const isSketchCanvasOpen = yield select(getIsSketchCanvasOpen)

        if (isSketchCanvasOpen) {
            yield call(helpcrunchTrackEvent, 'Clicked Drawing Tool')
        }
    })
}

function* trackClickedMakePrivateButton() {
    yield* takeLatest(types.UPDATE_PROJECT, function*({ payload }) {
        if (payload.visibility === 'private') {
            yield call(helpcrunchTrackEvent, 'Clicked Make Private Button')
        }
    })
}

function* trackClickedMakePublicButton() {
    yield* takeLatest(types.UPDATE_PROJECT, function*({ payload }) {
        if (payload.visibility === 'public') {
            yield call(helpcrunchTrackEvent, 'Clicked Make Public Button')
        }
    })
}

function* trackClickedPrintButton() {
    yield* takeLatest(types.UPDATE_ROUTE, function*({ payload }) {
        if (payload.pathname.indexOf('/print') > -1 && payload.pathname.indexOf('/projects') > -1) {
            yield call(helpcrunchTrackEvent, 'Clicked Print Button')
        }
    })
}

function* trackClickedShareButton() {
    yield* takeLatest(types.UPDATE_ROUTE, function*({ payload }) {
        if (
            payload.pathname.indexOf('/invite') > -1 &&
            payload.pathname.indexOf('/projects') > -1
        ) {
            yield call(helpcrunchTrackEvent, 'Clicked Share Button')
        }
    })
}

function* trackCreatedComment() {
    yield* takeLatest(types.CREATE_COMMENT_RESP, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Created Comment')
    })
}

function* trackCreatedProject() {
    yield* takeLatest(types.CREATE_PROJECT, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Created Project')
    })
}

function* trackCreatedScene() {
    yield* takeLatest(types.CREATE_SCENE_RESP, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Created Scene')
    })
}

function* trackDeletedComment() {
    yield* takeLatest(types.DELETE_COMMENT_RESP, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Deleted Comment')
    })
}

function* trackDeletedProject() {
    yield* takeLatest(types.DELETE_PROJECT_RESP, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Deleted Project')
    })
}

function* trackDeletedScene() {
    yield* takeLatest(types.DELETE_SCENE_RESP, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Deleted Scene')
    })
}

function* trackDuplicatedProject() {
    yield* takeLatest(types.DUPLICATE_PROJECT, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Duplicated Project')
    })
}

function* trackHitFreeLimit() {
    yield* takeLatest(types.UPDATE_ROUTE, function*({ payload }) {
        if (payload.pathname.indexOf('/billing') > -1 && payload.state && payload.state.upgrade) {
            yield call(helpcrunchTrackEvent, 'Hit Free Limit')
            yield call(helpcrunchUpdateUserData, {
                free_limit_hit_at: moment().format(),
            })
        }
    })
}

function* trackSavedDrawingTool() {
    yield* takeLatest(types.SAVE_SCENE_ASSETS, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Saved Drawing Tool')
    })
}

function* trackSentCollaboratorInvite() {
    yield* takeLatest(types.ADD_MEMBER_TO_PROJECT, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Sent Collaborator Invite')
    })
}

function* trackShortcutCtrlEnter() {
    yield* takeLatest(types.CREATE_SCENE_BY_SLICE, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Used Ctrl+Enter Shortcut')
    })
}

function* trackSubscriptionCanceled() {
    yield* takeLatest(types.CANCEL_SUBSCRIPTION, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Subscription Canceled')
        yield call(helpcrunchUpdateUserData, {
            paymentCycle: 'free',
            subscription_canceled_at: moment().format(),
        })
    })
}

function* trackSubscriptionCreated() {
    yield* takeLatest(types.UPGRADE, function*({ payload }) {
        yield call(helpcrunchTrackEvent, 'Subscription Created')
        yield call(helpcrunchUpdateUserData, {
            coupon: payload.coupon,
            paymentCycle: payload.paymentCycle,
            subscription_created_at: moment().format(),
        })
    })
}

function* trackUploadedImage() {
    yield* takeLatest(types.UPLOAD_IMG, function*({ payload }) {
        const isImageUpload = R.compose(R.test(/^blob:/), R.propOr('', 'preview'), R.head)

        if (isImageUpload(payload)) {
            yield call(helpcrunchTrackEvent, 'Uploaded Image')
        }
    })
}

function* updateProjectCount() {
    yield* takeLatest(
        [
            // TODO: This won't work until we actually fire a createProjectResp action
            types.CREATE_PROJECT_RESP,
            types.DELETE_PROJECT_RESP,
            types.FETCH_PROJECTS_RESP,
        ],
        runTrackProjects,
    )
}

function* updateSceneCount() {
    // If we take the CREATE_SCENE, etc. actions, then we risk not having the full
    // list of projects loaded, which means that we may end up counting only the
    // current project's scenes, which is obviously incorrect. So for now,
    // to be safe, only load the count when you fetch all projects
    yield* takeLatest([types.FETCH_PROJECTS_RESP], function*({ payload }) {
        const projects = yield select(getProjects)
        const sceneCount = R.compose(
            R.length,
            R.uniq,
            R.flatten,
            R.map(R.prop('sceneOrder')),
        )(projects)

        yield call(helpcrunchUpdateUserData, {
            num_scenes_owned: sceneCount,
        })
    })
}

function* updateUser() {
    yield* takeLatest(types.FETCH_USER_SUCCESS, function*({ payload }) {
        yield call(helpcrunchUpdateUser, {
            custom_data: {
                paymentCycle: payload.paymentCycle,
            },
            email: payload.email,
            name: `${payload.firstName} ${payload.lastName}`,
            user_id: payload.id,
        })
    })
}

// x Free Limit Hit (High Priority) => UPDATE_ROUTE, pathname include profile with upgrade=true
// x Creates Project => CREATE_PROJECT
// x Deletes Project => DELETE_PROJECT
// x Creates Scene => CREATE_SCENE_RESP
// x Uploads Images => UPLOAD_IMG
// x Clicks Drawing Tool = TOGGLE_PAINT
// x Saves Drawing Tool => SAVE_SCENE_ASSETS
// x User Ctrl+Enter Shortcut => CREATE_SCENE_BY_SLICE
// x Clicks Share Button => UPDATE_ROUTE, pathname include /invite
// x Sends Collaborator Invite => ADD_MEMBER_TO_PROJECT_RESP
// x Clicks Print Button => UPDATE_ROUTE, pathname include /print

// x Projects Owned => CREATE_PROJECT_RESP / DELETE_PROJECT_RESP
// x Scenes Owned => CREATE_SCENE_RESP / DELETE_SCENE_RESP
// ? Comments Owned => CREATE_COMMENT_RESP / DELETE_COMMENT_RESP
// ? Subscription Upgrade Date => UPGRADE (_RESP?)
// ? Subscription Cancel Date => CANCEL_SUBSCRIPTION_RESP
// Map firstName => First Name to consolidate with Intercom's attributes.
// User ID from Database

export default function*() {
    yield fork(trackClickedDrawingTool)
    yield fork(trackClickedMakePrivateButton)
    yield fork(trackClickedMakePublicButton)
    yield fork(trackClickedPrintButton)
    yield fork(trackClickedShareButton)
    yield fork(trackCreatedComment)
    yield fork(trackCreatedProject)
    yield fork(trackCreatedScene)
    yield fork(trackDeletedComment)
    yield fork(trackDeletedProject)
    yield fork(trackDeletedScene)
    yield fork(trackDuplicatedProject)
    yield fork(trackHitFreeLimit)
    yield fork(trackSavedDrawingTool)
    yield fork(trackSentCollaboratorInvite)
    yield fork(trackShortcutCtrlEnter)
    yield fork(trackSubscriptionCanceled)
    yield fork(trackSubscriptionCreated)
    yield fork(trackUploadedImage)
    yield fork(updateProjectCount)
    yield fork(updateSceneCount)
    yield fork(updateUser)
}
