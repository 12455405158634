import { createAction } from 'redux-actions'

import * as types from '../constants/ActionTypes'

export const addMemberToProject = createAction(types.ADD_MEMBER_TO_PROJECT)
export const addMemberToProjectResp = createAction(types.ADD_MEMBER_TO_PROJECT_RESP)
export const createProject = createAction(types.CREATE_PROJECT)
export const duplicateProject = createAction(types.DUPLICATE_PROJECT)
export const createProjectResp = createAction(types.CREATE_PROJECT_RESP)
export const deleteImageInProject = createAction(types.DELETE_IMAGE_IN_PROJECT)
export const deleteImageInProjectResp = createAction(types.DELETE_IMAGE_IN_PROJECT_RESP)
export const deleteMemberInProject = createAction(types.DELETE_MEMBER_IN_PROJECT)
export const deleteMemberInProjectResp = createAction(types.DELETE_MEMBER_IN_PROJECT_RESP)
export const deleteProject = createAction(types.DELETE_PROJECT)
export const deleteProjectResp = createAction(types.DELETE_PROJECT_RESP)
export const fetchProjectAlbum = createAction(types.FETCH_PROJECT_ALBUM)
export const fetchProjectAlbumError = createAction(types.FETCH_PROJECT_ALBUM_ERR)
export const fetchProjectAlbumResp = createAction(types.FETCH_PROJECT_ALBUM_RESP)
export const fetchProjectById = createAction(types.FETCH_PROJECT_BY_ID)
export const fetchPublicProjectById = createAction(types.FETCH_PUBLIC_PROJECT_BY_ID)
export const fetchProjectByIdResp = createAction(types.FETCH_PROJECT_BY_ID_RESP)
export const fetchProjectMembers = createAction(types.FETCH_PROJECT_MEMBERS)
export const fetchProjectMembersResp = createAction(types.FETCH_PROJECT_MEMBERS_RESP)
export const fetchProjects = createAction(types.FETCH_PROJECTS)
export const fetchProjectsResp = createAction(types.FETCH_PROJECTS_RESP)
export const setActiveProject = createAction(types.SET_ACTIVE_PROJECT)
export const setUserProjectPermissions = createAction(types.SET_USER_PROJECT_PERMISSIONS)
export const setProjectFilter = createAction(types.SET_PROJECT_FILTER)
export const updateProject = createAction(types.UPDATE_PROJECT)
export const updateProjectResp = createAction(types.UPDATE_PROJECT_RESP)
export const updateMemberInProjectResp = createAction(types.UPDATE_MEMBER_IN_PROJECT_RESP)
export const uploadImage = createAction(types.UPLOAD_IMG)
export const uploadImageResp = createAction(types.UPLOAD_IMG_RESP)
export const searchProjectsByTitle = createAction(types.SEARCH_PROJECTS_BY_TITLE)
