import React, { useState, useEffect } from 'react'
import * as R from 'ramda'

import { Elements, StripeProvider } from '@stripe/react-stripe-js'

import config from '../../../config'
import Modal from '../../common/Modal'
import CheckoutForm from '../CheckoutForm'

import { StyledLink } from './styled'

const PastDue = props => {
    const [stripe, setStripe] = useState(null)
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        if (R.isNil(stripe)) {
            setStripe(window.Stripe(config.stripeKey))
        }
    }, [])

    const {
        user: { paymentGateway },
    } = props

    return (
        <Modal
            isCloseable={false}
            title="Account Past Due"
            subtitle="Please update your credit card information to continue.">
            {!R.isEmpty(paymentGateway) &&
                paymentGateway?.paymentError &&
                !paymentGateway.paymentError.includes('No error') && (
                    <div className="text-center m-t-sm">
                        <div className="card-error">{`${paymentGateway.cardBrand} (${paymentGateway.last4}) shows error: ${paymentGateway.paymentError}`}</div>
                    </div>
                )}
            <Elements stripe={stripe}>
                <CheckoutForm showSubscriptionPaymentInfo={true} />
            </Elements>
            {!props.isCancelled && (
                <StyledLink
                    button
                    textmuted
                    fullwidth
                    key="cancel"
                    type="submit"
                    disabled={processing}
                    to={{
                        pathname: '/cancel-subscription',
                        state: { modal: true, closeable: true, redirectTo: '/pastdue' },
                    }}>
                    No thanks, cancel my subscription.
                </StyledLink>
            )}
        </Modal>
    )
}

export default PastDue
