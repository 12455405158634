import React from 'react'
import Modal from '../common/Modal'
import * as R from 'ramda'
import http from '../../utils/http'
import config from '../../config'
import { isValidEmail } from '../../utils'
import Loader from '../common/loader'
import { StyledButton, StyledInput } from './styled'

const eqValues = R.compose(R.isEmpty, R.symmetricDifference)

const PAYMENT_CYCLE_DISPLAY_DICT = {
    yearly: 'yr',
    monthly: 'mo',
    business_month: 'mo',
    business_year: 'yr',
    pro_month: 'mo',
    pro_year: 'yr',
    solo_month: 'mo',
    solo_year: 'yr',
}

const useMembers = (existingMembers = []) => {
    const [members, setMembers] = React.useState(existingMembers)
    const existingMembersStr = JSON.stringify(existingMembers)

    React.useEffect(() => {
        const existingMbrs = JSON.parse(existingMembersStr)
        setMembers(existingMbrs)
    }, [existingMembersStr])

    const addMember = member => {
        setMembers([member, ...members])
    }

    const removeMember = member => {
        setMembers(members.filter(m => m !== member))
    }

    return [members, addMember, removeMember]
}

const usePlanInfo = (
    productPlans,
    numMembers,
    paymentCycle,
    enabled,
    isLoading,
    subscriptionPlanInfo,
) => {
    let teamPlanPrice = '---'
    let planNote = ''
    const discount = R.flatten(R.pluck('discount', productPlans))
    if (isLoading) {
        return <Loader className="inverse" />
    }

    const { percent_off_precise, amount_off, name } = R.pipe(
        R.pluck('coupon'),
        R.uniq,
        R.mergeAll,
    )(discount)

    if (!R.isNil(subscriptionPlanInfo['plan']) && numMembers + 1) {
        const baseCost = (subscriptionPlanInfo['plan'].amount / 100) * (numMembers + 1)
        const percentCalcOff = (baseCost * (percent_off_precise || 0)) / 100
        const amountOff = amount_off / 100 || 0
        const baseCostWithDiscount = baseCost - percentCalcOff - amountOff
        const total = baseCostWithDiscount.toFixed(2)

        teamPlanPrice = `$${total}/${PAYMENT_CYCLE_DISPLAY_DICT[paymentCycle]}`
        planNote = R.pathOr('', ['metadata', 'note'], subscriptionPlanInfo['plan'])
    }

    return (
        <>
            <p className="team-plan-subscription__status">
                {enabled ? 'Approve Team For' : 'Current Plan'}
            </p>
            <span className="team-plan-subscription__price">{teamPlanPrice}</span>
            {(name && (
                <div className="team-plan-subscription__note m-t-xs">
                    {name ? `Discount "${name}"` : planNote}
                </div>
            )) ||
                planNote}
        </>
    )
}

const MemberRow = ({ member, removeMember }) => (
    <div className="clearfix m-b-sm" key={member}>
        <div className="pull-left text-white">
            <span style={{ verticalAlign: 'sub' }}>{member}</span>
        </div>
        <div className="pull-right remove-column">
            <i
                onClick={() => removeMember(member)}
                className={`fa fa-close cp`}
                style={{ verticalAlign: 'sub' }}
            />
        </div>
    </div>
)

const TeamPlanButton = ({ children, onClick, enabled }) => (
    <button
        className={`team-plan-subscription m-t-xl wrapper-sm m-b-md block m-l-auto m-r-auto ${
            enabled ? 'modified' : ''
        }`}
        disabled={!enabled}
        onClick={onClick}>
        {children}
    </button>
)

/**
 * @module TeamPlan
 */
export default ({
    user: { paymentCycle },
    oldProductId,
    productSoloId,
    productProId,
    productBusinessId,
    userLoading,
    fetchProductPlans,
    productPlans = { plans: [] },
    updateTeamPlan,
    teamInfo,
    isTeamOwner,
    history,
    fetchSubscriptionPlan,
    subscriptionPlanInfo,
}) => {
    const existingMembers = teamInfo.members.filter(m => m.role === 'MEMBER').map(m => m.user.email)
    const [members, addMember, removeMember] = useMembers(existingMembers, history)
    const emailInputRef = React.useRef(null)
    const [dirtyEmailInput, setDirtyEmailInput] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(userLoading)
    const dirty = !eqValues(existingMembers, members)
    const planInfo = usePlanInfo(
        productPlans,
        members.length,
        paymentCycle,
        dirty,
        isLoading,
        subscriptionPlanInfo,
    )
    const hasPlans = R.not(R.isEmpty(productPlans))
    const isSubscriptionPlanEmpty = R.isNil(subscriptionPlanInfo['plan'])
    React.useEffect(() => {
        if (isSubscriptionPlanEmpty) {
            fetchSubscriptionPlan()
        }
    }, [subscriptionPlanInfo, fetchSubscriptionPlan, isSubscriptionPlanEmpty])
    React.useEffect(() => {
        if (!hasPlans) {
            fetchProductPlans({
                oldProductPlan: oldProductId,
                productSoloPlan: productSoloId,
                productProPlan: productProId,
                productBusinessPlan: productBusinessId,
            })
        }
    }, [oldProductId, productSoloId, productProId, productBusinessId, hasPlans, fetchProductPlans])
    React.useEffect(() => {
        setIsLoading(userLoading)
    }, [userLoading])

    const addTeamMemberHandler = async event => {
        event.preventDefault()
        const member = emailInputRef.current.value
        if (member === '') {
            window.$N.error(`Team member email cannot be blank`)
            setError(true)
            return
        }
        if (members.includes(member)) {
            window.$N.error(`Cannot add duplicate ${member} email to the team.`)
            setError(true)
            return
        }
        if (!isValidEmail(member)) {
            window.$N.error(`${member} is not a valid email address.`)
            setError(true)
            return
        }
        setIsLoading(true)
        const URL = `${config.apiUrl}/users/subscriptions/payment-cycle/${member}`
        try {
            const newMember = await http.get(URL)
            if (newMember && newMember.paymentCycle === 'free') {
                addMember(member)
            } else {
                window.$N.error(
                    `${member} is already a paying user and was not added to your team.`,
                )
            }
        } catch (err) {
            window.$N.error(`There was an error checking the team eligibility of: ${member}`)
        }
        emailInputRef.current.value = ''
        setDirtyEmailInput(false)
        setIsLoading(false)
    }

    const save = () => {
        const payload = {
            id: teamInfo.id,
            memberships: members,
            onDone: () => {
                setIsLoading(false)
                window.$N.info('Team members successfully added with unlimited access.')
            },
        }
        setIsLoading(true)
        updateTeamPlan(payload)
    }

    const checkEmailInputHandler = e => {
        e.preventDefault()
        if (error) {
            setError(false)
        }
        if (emailInputRef.current.value === '') {
            setDirtyEmailInput(false)
        } else {
            setDirtyEmailInput(true)
        }
    }

    return (
        <Modal
            isCancelable
            isCloseable
            wrapperClassName="width-600px"
            title="Manage Team Members"
            subtitle="Team members can create their own projects and will not count toward collaborator limits."
            wrapperLightBoxClassName="team-members">
            <div className="content">
                <form>
                    <fieldset disabled={isLoading}>
                        <button
                            type="button"
                            className="profile__Team-row-btn profile__row-btn m-b"
                            disabled={isLoading}
                            onClick={e => {
                                e.preventDefault()
                                history.replace({
                                    pathname: `/teamPlan/name`,
                                    state: {
                                        modal: true,
                                        closeable: true,
                                    },
                                })
                            }}>
                            {teamInfo.name || 'Team Name'}
                            <label className="profile__label">Team Name</label>
                        </button>
                        <div className="profile__row text m-b">
                            <StyledInput
                                name="email"
                                type="text"
                                placeholder="Team member email"
                                className={`text-white profile__value ${error ? 'error' : ''}`}
                                onChange={checkEmailInputHandler}
                                ref={emailInputRef}
                                label="Email"></StyledInput>
                        </div>
                    </fieldset>
                    <StyledButton
                        button
                        primary
                        type="submit"
                        className="w100 wrapper-sm m-b-sm block m-l-auto m-r-auto"
                        disabled={isLoading || !dirtyEmailInput}
                        onClick={addTeamMemberHandler}>
                        {isLoading ? 'Loading...' : 'Add Team Member'}
                    </StyledButton>
                </form>

                {/* Team Members */}
                <div className="members on-scrollbar">
                    {members.map(member => (
                        <MemberRow key={member} member={member} removeMember={removeMember} />
                    ))}
                </div>
                <TeamPlanButton onClick={save} enabled={dirty && !isLoading}>
                    {planInfo}
                </TeamPlanButton>
            </div>
        </Modal>
    )
}
