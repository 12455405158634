import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Loader from '../../components/common/loader'
import { PaintActions } from './PaintActions'
import { SketchRoot } from './SketchRoot'

/**
 * I contain the SketchRoot and some buttons to manage my state
 */
const PaintContainer = connect(({ sketch }) => ({ isReady: sketch.isReady }))(
    ({ isReady, isActive }) => {
        const ref = useRef(null)
        const animation = isActive ? 'fadeIn' : 'fadeOut dn'

        useEffect(() => {
            const $container = ref.current
            $container.addEventListener('animationend', onAnimationEnd)
            return () => $container.removeEventListener('animationend', onAnimationEnd)

            function onAnimationEnd() {
                $container.style.pointerEvents = isActive ? 'auto' : 'none'
            }
        }, [isActive])

        return (
            <div ref={ref} className={`animated-fast ${animation}`}>
                {!isReady && <Loader message="Confabulating..." />}
                {isReady && (
                    <div className="paint-canvas h100 pos-rlt">
                        <PaintActions />
                        <SketchRoot />
                    </div>
                )}
            </div>
        )
    },
)

PaintContainer.propTypes = {
    isActive: PropTypes.bool,
}

export default PaintContainer
