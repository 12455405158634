import { connect } from 'react-redux'
import { isTrialExpired } from '../../utils/permissions'

import actions from '../../actions'
import ProjectsHeader from './projectsHeader'

const FILTERS = ['Show All', 'My Projects', 'Shared With Me']

export default connect(
    (state, ownProps) => {
        let { session, user: userState, projectsFilter, projects } = state
        const user = userState && userState.loading ? session.user || session : userState.user
        return {
            ...ownProps,
            isTrialExpired: isTrialExpired(user),
            projectsFilter: projectsFilter,
            projects: projects,
            session: session,
            dropdownFilters: FILTERS,
        }
    },
    {
        ...actions.projects,
    },
)(ProjectsHeader)
