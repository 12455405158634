import { call, put, fork, select } from 'redux-saga/effects'
import { takeEvery, takeLatest } from 'redux-saga'

import * as api from './api'
import config from '../config'
import * as types from '../constants/TeamConstant'
import * as teamAction from '../actions/TeamAction'
import { fetchUser } from '../actions/SessionActions'

const getUser = state => state.user.user || state.session.user

function* createTeamPlan({ payload }) {
    const URL = `${config.apiUrl}/teams`
    const { error, response: team } = yield call(() => api.post(URL, payload))

    if (error) {
        window.notify.error('There was a problem creating your team.', error)
        return
    }

    yield [put(fetchUser()), put(teamAction.fetchTeamPlanResp(team))]
    window.sagaHistory.push({
        pathname: '/teamPlan',
        state: { modal: true, closeable: true },
    })
}

function* leaveTeamPlan({ payload }) {
    const user = yield select(getUser)
    const team = yield select(state => state.team)
    if (user && team) {
        if (team && team.ownerId === user.id) {
            const { error } = yield call(() =>
                api.deleteResource(`${config.apiUrl}/teams/${user.team.id}`),
            )
            if (error) {
                window.notify.error('There was a problem deleting the team.', error)
                return
            }
        } else if (team && team.ownerId !== user.id) {
            const { error } = yield call(() =>
                api.deleteResource(`${config.apiUrl}/teams/${user.team.id}/member/${user.id}`),
            )
            if (error) {
                window.notify.error('There was a problem leaving the team.', error)
                return
            }
        }
    }
    yield [put(fetchUser()), put(teamAction.fetchTeamPlanResp({}))]

    if (typeof payload.onDone === 'function') {
        payload.onDone()
    }
    window.sagaHistory.replace({
        pathname: payload.returnTo.pathname,
        state: { modal: true, closeable: true, ...payload.returnTo.state },
    })
}

function* updateTeamPlan({ payload }) {
    const URL = `${config.apiUrl}/teams/${payload.id}`
    const { error, response: team } = yield call(() => api.patch(URL, payload))

    if (error) {
        window.notify.error('There was a problem saving your team.', error)
        return
    }

    yield [put(teamAction.fetchTeamPlanResp(team))]

    payload.onDone && payload.onDone()
}

function* fetchTeamPlan({ payload }) {
    const URL = `${config.apiUrl}/teams/${payload}`
    const { error, response: team } = yield call(() => api.get(URL))

    if (error) {
        window.notify.error('There was a problem fetching your team.', error)
        return
    }

    yield put(teamAction.fetchTeamPlanResp(team))
}

function* fetchUserByEmail({ payload }) {
    const URL = `${config.apiUrl}/users/subscriptions/payment-cycle/${payload}`
    const { error, response: user } = yield call(() => api.get(URL))

    if (error) {
        window.notify.error('There was a verifying this email.', error)
        return
    }

    yield put(teamAction.fetchUserByEmailResp(user))
}

function* watchCreateTeamPlan() {
    yield* takeLatest(types.CREATE_TEAM_PLAN, createTeamPlan)
}

function* watchLeaveTeamPlan() {
    yield* takeEvery(types.LEAVE_TEAM_PLAN, leaveTeamPlan)
}

function* watchUpdateTeamPlan() {
    yield* takeEvery(types.UPDATE_TEAM_PLAN, updateTeamPlan)
}

function* watchFetchTeamPlan() {
    yield* takeEvery(types.FETCH_TEAM_PLAN, fetchTeamPlan)
}

function* watchFetchUserByEmail() {
    yield* takeLatest(types.FETCH_USER_BY_EMAIL, fetchUserByEmail)
}

export default function*() {
    yield fork(watchFetchTeamPlan)
    yield fork(watchCreateTeamPlan)
    yield fork(watchLeaveTeamPlan)
    yield fork(watchUpdateTeamPlan)
    yield fork(watchFetchUserByEmail)
}
