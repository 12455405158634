import {
  STATUS_FAIL,
  SCENE_START_LOADING,
  SCENE_STOP_LOADING,
  UPDATE_PROJECT,
  UPDATE_PROJECT_RESP,
  UPDATE_SCENE,
  UPDATE_SCENE_RESP,
  DELETE_SCENE,
  DELETE_SCENE_RESP,
  SET_IMAGE,
  CLONE_SET_IMAGE,
  REORDER_SCENE,
  REORDER_SCENE_RESP,
  SET_IMAGE_RESP,
  UPDATE_ROUTE,
} from '../constants/ActionTypes';

export default function globalActivityStatus(state = 'initial', { type }) {
  switch (type) {
    case UPDATE_ROUTE:
      return 'initial';
    case UPDATE_PROJECT:
    case UPDATE_SCENE:
    case DELETE_SCENE:
    case REORDER_SCENE:
    case SET_IMAGE:
    case CLONE_SET_IMAGE:
    case SCENE_START_LOADING:
      return 'loading';
    case SET_IMAGE_RESP:
    case REORDER_SCENE_RESP:
    case UPDATE_SCENE_RESP:
    case DELETE_SCENE_RESP:
    case UPDATE_PROJECT_RESP:
    case SCENE_STOP_LOADING:
      return 'success';
    case STATUS_FAIL:
      return 'failed';
    default:
      return state;
  }
}
