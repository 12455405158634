import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import Scene from '../../components/scene/SceneContainer'
import Toolbar from '../../containers/ToolbarContainer'
import {
    ProjectWrapper,
    ProjectContentWrapper,
    GridWrapper,
    ToolbarWrapper,
    ScenesWrapper,
} from './styled'

const PublicProject = ({ scenes }) => {
    const commentRefs = useRef({})

    const onActivateScene = id => {
        if (commentRefs.current[id]) {
            commentRefs.current[id].scrollIntoView({ behavior: 'smooth' })
        }
    }

    const scenesWithAddButton = [...scenes, { type: 'button' }]

    return (
        <ProjectWrapper>
            <ProjectContentWrapper>
                <GridWrapper>
                    {scenes && (
                        <ScenesWrapper>
                            {scenesWithAddButton.map((scene, key) => {
                                if (scene.type === 'button') return null
                                return (
                                    <Scene
                                        publicProject
                                        key={scene.id || scene.type}
                                        scene={scene}
                                        sceneNumber={key + 1}
                                        onActivateScene={onActivateScene}
                                    />
                                )
                            })}
                        </ScenesWrapper>
                    )}
                </GridWrapper>
            </ProjectContentWrapper>
            <ToolbarWrapper>
                <Toolbar
                    showTabs={['textareas', 'comments']}
                    commentRefs={commentRefs}
                    isProjectPublic={true}
                />
            </ToolbarWrapper>
        </ProjectWrapper>
    )
}

PublicProject.propTypes = {
    scenes: PropTypes.array,
}

export default PublicProject
