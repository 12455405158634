import PropTypes from 'prop-types'
import React from 'react'
import PLANS from '../../../constants/Plan'
import * as R from 'ramda'
import {
    PaymentSubscriptionTypeSection,
    PaymentSubscriptionPlanSection,
    StyledButton,
} from './styled'

/**
 * @module Plans
 */

const NO_COLLABORATORS = 'no'
const UNLIMITED_PROJECTS = 'unlimited projects'

function Plans({
    paymentCycle,
    onPaymentPlanChange,
    onSubscriptionTypeChange,
    planPriceCalculate,
    sortedProductPlans,
    selectedProductId,
}) {
    const intervalPaymentCycleMap = R.keys(sortedProductPlans)

    return (
        <div className="subscription-profile">
            <div className="subscription-plan">
                {intervalPaymentCycleMap.map(paymentCyclePlan => (
                    <PaymentSubscriptionPlanSection
                        key={paymentCyclePlan}
                        active={paymentCyclePlan === paymentCycle}>
                        {paymentCyclePlan === PLANS.PLAN_YEARLY ? (
                            <div className="subscription-plan__text">25% off</div>
                        ) : null}
                        <StyledButton primary onClick={() => onPaymentPlanChange(paymentCyclePlan)}>
                            {paymentCyclePlan === 'yearly' ? 'annually' : paymentCyclePlan}
                        </StyledButton>
                    </PaymentSubscriptionPlanSection>
                ))}
            </div>
            <div className="subscription-type">
                {sortedProductPlans &&
                    sortedProductPlans[paymentCycle].length &&
                    sortedProductPlans[paymentCycle].map(plan => {
                        const isActive = selectedProductId === plan.product
                        let collaborators = NO_COLLABORATORS
                        if (plan.metadata && plan.metadata.collaborators) {
                            collaborators = plan.metadata.collaborators
                        }
                        return (
                            <PaymentSubscriptionTypeSection
                                key={plan.product}
                                onClick={() =>
                                    onSubscriptionTypeChange(
                                        plan.product,
                                        plan.id,
                                        plan.subscriptionPlanName,
                                    )
                                }
                                active={isActive}>
                                <div className="plan-name">{plan.planName}</div>
                                <div className="plan-price">
                                    <span>${planPriceCalculate(plan.amount)}</span>
                                    /mo
                                </div>
                                <div className="plan-collaborator-limit m-b-xs m-t-xs">
                                    {UNLIMITED_PROJECTS}
                                </div>
                                <div className="plan-collaborator-limit">
                                    {collaborators} Collaborators
                                </div>
                            </PaymentSubscriptionTypeSection>
                        )
                    })}
            </div>
        </div>
    )
}

Plans.propTypes = {
    paymentCycle: PropTypes.string.isRequired,
    onPaymentPlanChange: PropTypes.func.isRequired,
}

Plans.displayName = 'Plans'

export default Plans
