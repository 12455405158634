import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'

import MoveToFolderList from './MoveToFolderList'
import { moveStoryboards } from '../../actions/ProjectsActions'

const getFolderList = folders => {
    return R.pipe(
        R.map(folder => {
            if (folder.folderId && folder.isOwner) {
                return {
                    folderName: folder.folderName,
                    folderId: folder.folderId,
                    userId: folder.userId,
                }
            }
        }),
        R.reject(R.isNil),
    )(folders)
}

const MoveToFolderListContainer = ({
    folders,
    currentFolder,
    moveStoryboards,
    history,
    projectIds,
    moveFromFolderId,
    setSelectedProjects,
}) => {
    if (
        !folders ||
        !currentFolder ||
        (!projectIds.length && currentFolder?.folderId !== moveFromFolderId)
    ) {
        history.replace({ pathname: '/projects' })
        return null
    }

    const folderList = getFolderList(folders)

    const [selectedFolderId, setSelectedFolderId] = useState(folderList[0]?.folderId)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = ev => {
        ev.preventDefault()
        if (selectedFolderId === moveFromFolderId) {
            window.notify.error('Cannot move storyboard to same folder.')
            return
        }
        if (!currentFolder.isOwner) return
        setSubmitting(true)
        const onDone = () => {
            setSubmitting(false)
            setSelectedProjects([])
        }
        moveStoryboards({
            moveToFolderId: selectedFolderId,
            moveFromFolderId: moveFromFolderId,
            projectIds: projectIds,
            currentFolderId: currentFolder.folderId || null,
            onDone: onDone,
        })
    }

    const handleOnChange = ev => {
        setSelectedFolderId(ev.target.value)
    }

    return (
        <MoveToFolderList
            folderList={folderList}
            handleSubmit={handleSubmit}
            handleOnChange={handleOnChange}
            moveFromFolderId={moveFromFolderId}
            submitting={submitting}
        />
    )
}

export default connect(
    (state, ownProps) => {
        const { folders, currentFolder } = state
        const projectIds = ownProps.location.state?.projectIds || []
        const moveFromFolderId = ownProps.location.state?.moveFromFolderId || null
        const setSelectedProjects = ownProps.location.data?.setSelectedProjects || null
        return {
            moveFromFolderId,
            folders,
            projectIds,
            currentFolder,
            history: ownProps.history,
            setSelectedProjects,
        }
    },
    {
        moveStoryboards,
    },
)(MoveToFolderListContainer)
