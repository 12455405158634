import * as R from 'ramda';
import * as types from '../constants/ActionTypes';
import * as helpers from './helpers';

const findDifference = (from, by) => from.id === by.id;

function deleteComment(state, payload) {
  return R.differenceWith(findDifference, state, payload);
}

function updateComment(state, payload) {
  return R.map(
    comment => (comment.id === payload.id ? payload : comment),
    state
  );
}

export function comments(state = [], { type, payload }) {
  switch (type) {
    case types.FETCH_PROJECT_BY_ID_RESP:
      return payload.comments;
    case types.UPDATE_ROUTE:
      return helpers.activeProjectView(payload.pathname) ||
        helpers.billingView(payload.pathname)
        ? state
        : [];
    case types.CREATE_COMMENT_RESP:
      return R.append(payload, state);
    case types.DELETE_COMMENT_RESP:
      return deleteComment(state, payload);
    case types.UPDATE_COMMENT_RESP:
      return updateComment(state, payload);
    default:
      return state;
  }
}

export function submittingComment(state = false, { type }) {
  switch (type) {
    case types.CREATE_COMMENT:
      return true;
    case types.CREATE_COMMENT_RESP:
      return false;
    default:
      return state;
  }
}

export function commentsFilteredBySceneId(state = null, { type }) {
  switch (type) {
    case types.FILTER_COMMENTS_BY_SCENE_ID:
      return true;
    case types.CLEAR_COMMENTS_SCENE_ID_FILTER:
      return false;
    default:
      return state;
  }
}
