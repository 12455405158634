import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    margin: 10px 0;
`
const Loader = ({ className = '', paddingSize = 'lg', message, style }) => (
    <Wrapper>
        <div className={`col-xs-12 text-center custom-loader ${className}`} style={style}>
            <div className={`m-t-${paddingSize}`}>
                <span className="text-u-c">{message}</span>
                <span className="l-1" />
                <span className="l-2" />
                <span className="l-3" />
                <span className="l-4" />
                <span className="l-5" />
                <span className="l-6" />
            </div>
        </div>
    </Wrapper>
)

Loader.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
}

export default Loader
