import React from 'react'
import Portal from '@reach/portal'
import VisuallyHidden from '@reach/visually-hidden'
import { useRect } from '@reach/rect'

const ModalTip = React.forwardRef(function ModalTip(
    {
        presentationpreview,
        // The component you want to provide "ModalTip" for
        children,
        // The ModalTipContent to render in the popup
        render = () => {
            throw new Error('You must provide a render prop that renders the ModalTip content')
        },
        ariaLabel,
        position = presentationpreview ? positionPresentationPreview : positionDefault,
        modalTipName = 'tip',
        ...rest
    },
    forwardRef,
) {
    const [dismissed, setDismiss] = React.useState(true)
    const dismiss = React.useCallback(() => {
        setDismiss(true)
    }, [setDismiss])

    // Don't show modalTip if it's been dismissed
    const open = !dismissed
    // Ref for modalTip
    const triggerRef = React.useRef()
    const triggerRect = useRect(triggerRef, open)
    // Because the portal is only rendered when open,
    // we need to unconditionally render the modalTip children so any
    // hooks inside it will be executed.
    const modalTipChildren = render({ dismiss })

    return (
        <React.Fragment>
            {/* Clone the children we're providing modalTip for and pass some helpful props to them */}
            {React.cloneElement(React.Children.only(children), {
                ref: triggerRef,
                open,
                toggleOpen: () => setDismiss(dismissed => !dismissed),
            })}
            {open && (
                <Portal>
                    <ModalTipContent
                        ariaLabel={ariaLabel}
                        position={position}
                        open={open}
                        id={modalTipName}
                        triggerRect={triggerRect}
                        ref={forwardRef}
                        {...rest}>
                        {modalTipChildren}
                    </ModalTipContent>
                </Portal>
            )}
        </React.Fragment>
    )
})

const OFFSET_LEFT = 5
const OFFSET_TOP = 8

const getStyles = (position, triggerRect, tooltipRect) => {
    const haventMeasuredTooltipYet = !tooltipRect
    if (haventMeasuredTooltipYet) {
        return { visibility: 'hidden' }
    }
    return position(triggerRect, tooltipRect)
}

const positionPresentationPreview = (triggerRect, tooltipRect) => {
    // to top
    const styles = {
        // left: `${triggerRect.left + window.scrollX + OFFSET_LEFT}px`,
        left: `${triggerRect.left +
            window.scrollX +
            OFFSET_LEFT +
            (triggerRect.left < 400 ? 0 : 1000)}px`,
        top: `${triggerRect.top - triggerRect.height * 2 - OFFSET_TOP}px`,
    }

    return {
        ...styles,
    }
}

const positionDefault = (triggerRect, tooltipRect) => {
    // to right
    //   const styles = {
    //     left: `${triggerRect.left + window.scrollX}px`,
    //     top: `${triggerRect.top + triggerRect.height + window.scrollY}px`,
    //   };

    //   return {
    //     ...styles,
    //     left: `${triggerRect.right + window.scrollX + OFFSET_LEFT}px`,
    //     top: `${triggerRect.top + window.scrollY + OFFSET_TOP}px`,
    //   };
    // to bottom
    const styles = {
        left: `${triggerRect.left + window.scrollX + OFFSET_LEFT}px`,
        top: `${triggerRect.top + triggerRect.height + window.scrollY + OFFSET_TOP}px`,
    }

    return {
        ...styles,
        right: `${triggerRect.right + window.scrollX}px`,
        bottom: `${triggerRect.top + window.scrollY}px`,
    }
}

const ModalTipContent = React.forwardRef(function ModalTipContent(
    { children, dismiss, ariaLabel, position, open, id, triggerRect, style, ...rest },
    forwardRef,
) {
    const useAriaLabel = ariaLabel != null
    const tooltipRef = React.useRef()
    const tooltipRect = useRect(tooltipRef, open)

    return (
        <React.Fragment>
            <div
                data-modaltip
                role={useAriaLabel ? undefined : 'tooltip'}
                id={useAriaLabel ? undefined : id}
                style={{
                    ...style,
                    ...getStyles(position, triggerRect, tooltipRect),
                }}
                ref={node => {
                    tooltipRef.current = node
                    if (forwardRef) forwardRef(node)
                }}
                {...rest}>
                {children}
            </div>
            {useAriaLabel && (
                <VisuallyHidden role="tooltip" id={id}>
                    {ariaLabel}
                </VisuallyHidden>
            )}
        </React.Fragment>
    )
})

export default ModalTip
