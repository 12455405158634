import React from 'react'
import Modal from '../common/Modal'
import { StyledButton } from './styled'

export default ({ history }) => {
    return (
        <Modal
            isCloseable
            goBack={() => {
                history.replace('/projects')
            }}
            title="Project Locked"
            subtitle="The owner of this project has cancelled their subscription.">
            <StyledButton
                primary
                fullwidth
                className="wrapper-sm"
                onClick={() => {
                    history.push('/projects')
                }}>
                Okay
            </StyledButton>
        </Modal>
    )
}
