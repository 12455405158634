import { connect } from 'react-redux';
import actions from '../actions';
import CommentOverlay from '../components/commentOverlay';

export default connect(
  state => ({
    submittingComment: state.submittingComment,
  }),
  {
    ...actions.comments,
    ...actions.focusedScriptType,
  }
)(CommentOverlay);
