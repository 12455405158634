import $ from 'jquery'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import { scrollToScriptInToolbar } from '../../utils/scene'
import SceneImage from './sceneImage'
import SceneNumber from './SceneNumber'
import TextArea from '../customTextArea'
import SpinnerIcon from '../../icons/SpinnerIcon'
import * as S from './styled'
import config from '../../config'

const VOICE_OVER = 'voiceOver'

const DESCRIPTION = 'description'

const Scene = props => {
    const {
        album,
        activateScene,
        onActivateScene,
        createScene,
        hasComments,
        isActive,
        permissions,
        scene,
        sceneNumber,
        cloneSetImage,
        sceneLoading,
        wrapperClasses,
        printing,
        imageOnly,
        activateToolbarTab,
        focusedScriptType,
        changeSceneScriptFocus,
        isLastScene,
        columnIndex,
        presentationpreview,
        publicProject,
    } = props

    const voiceOverRef = useRef()
    const [state, setState] = useState({
        activateRightRearrangeDZ: false,
        activateLeftRearrangeDZ: false,
        dragging: false,
    })

    const canEdit = () => {
        return permissions?.edit && !scene.locked
    }

    const onClick = () => {
        if (!isActive && !presentationpreview && !printing) {
            activateScene(scene)
            onActivateScene(scene.id)
        }
    }

    const renderImage = () => {
        if (printing) {
            return (
                <S.SceneImageWrapper className={`w100 b-b pos-rlt image-container`}>
                    {scene.image ? (
                        <div className={`clear cover-image-thumb fill _lr-hide`}>
                            <img
                                style={{ display: 'block' }}
                                src={`${config.s3}/${encodeURI(scene.image)}`}
                                alt={`Scene ${sceneNumber}`}
                            />
                        </div>
                    ) : null}
                </S.SceneImageWrapper>
            )
        } else {
            return (
                <SceneImage
                    printing={printing}
                    publicProject={publicProject}
                    presentationpreview={presentationpreview}
                    isDragging={state.dragging}
                    onCommentSubmit={() => {
                        activateToolbarTab('comments')
                        voiceOverRef.current?.focus()
                    }}
                    activateScene={activateScene}
                    sceneNumber={sceneNumber}
                    cloneSetImage={cloneSetImage}
                    album={album}
                    isActive={isActive}
                    scene={scene}
                    permissions={permissions}
                    dragging={state.dragging}>
                    {sceneLoading && (
                        <div className="vc">
                            <SpinnerIcon className="fill w50 h50 m-auto" style={{ zIndex: 99 }} />
                        </div>
                    )}
                </SceneImage>
            )
        }
    }

    const handleFocus = scene => () => {
        if (!isActive) {
            changeSceneScriptFocus('block')
            onActivateScene(scene.id)
        }
    }

    const handleDescriptionFocus = scene => () => {
        changeSceneScriptFocus('initial')
    }

    const renderVoiceOver = () => {
        return (
            <S.SceneVoiceOverWrapper
                presentationpreview={presentationpreview}
                className="scene__title">
                <div className="b-b p-sm scene__voice-over">
                    <div className="m-b-xs text-light text-u-c text-sm scene__voice-over_title">
                        script
                    </div>
                    {/* SCENE NUMBER */}
                    <div className="pos-abt" style={{ top: 0, right: 0, height: 19, zIndex: 1 }}>
                        <S.SceneNumberWrapper onClick={handleSceneNumberClick}>
                            <SceneNumber
                                printing={printing}
                                isActive={isActive}
                                number={sceneNumber}
                            />
                        </S.SceneNumberWrapper>
                    </div>
                    {printing && !presentationpreview ? (
                        // {printing ? (
                        <div>{scene[VOICE_OVER]}</div>
                    ) : (
                        <TextArea
                            {...props}
                            innerRef={voiceOverRef}
                            readOnly={!canEdit()}
                            scene={scene}
                            tabIndex={sceneNumber * 2 - 1}
                            field={VOICE_OVER}
                            focusedScriptType={focusedScriptType}
                            onFocus={handleFocus(scene)}
                            focusOnActive={true}
                            tag={false}
                        />
                    )}
                </div>
            </S.SceneVoiceOverWrapper>
        )
    }

    const renderDescription = () => {
        return (
            <S.SceneDescription
                presentationpreview={presentationpreview}
                className="scene-description">
                <div className="p-sm scene__description">
                    <div className="m-b-xs text-light text-u-c text-sm scene__description-title">
                        action
                    </div>
                    {printing && !presentationpreview ? (
                        // {printing ? (
                        <div>{scene[DESCRIPTION]}</div>
                    ) : (
                        <TextArea
                            readOnly={!canEdit()}
                            onFocus={handleDescriptionFocus(scene)}
                            slicable={false}
                            tabIndex={sceneNumber * 2}
                            scene={scene}
                            field={DESCRIPTION}
                            tag={false}
                            {...props}
                        />
                    )}
                </div>
            </S.SceneDescription>
        )
    }

    const renderCreateBtn = () => {
        if (!canEdit()) {
            return null
        }
        return (
            <S.SceneWrapper>
                <div className={`scene__new-scene-container`}>
                    <S.CreateSceneCard onClick={() => !sceneLoading && createScene({})}>
                        {sceneLoading ? (
                            <i className="font-thin text-md">Creating Scene ...</i>
                        ) : (
                            <i
                                className="fa fa-plus font-thin scene_new-scene-icon"
                                aria-hidden="true"
                            />
                        )}
                    </S.CreateSceneCard>
                </div>
            </S.SceneWrapper>
        )
    }

    const renderLock = () => {
        return scene.locked ? (
            <div className="lock-wrapper pos-abt">
                <Link
                    className="lock-icon pos-abt"
                    to={{
                        pathname: '/billing',
                        state: { modal: true, closeable: true, upgrade: true },
                    }}
                />
            </div>
        ) : null
    }

    const handleCommentIconClick = () => {
        //TODO: clean jquery
        const selector = $('.control-panel-tab .toolbar-comments-icon')
        if (!selector.hasClass('active')) {
            setTimeout(() => selector.click())
            setTimeout(() => onActivateScene(scene.id), 100)
        } else {
            //todo: move scroll responsibility to redux or saga
            onActivateScene(scene.id)
        }
    }

    const handleSceneNumberClick = () => {
        //TODO: clean jquery
        const selector = $('.control-panel-tab .script-icon')
        if (!selector.hasClass('active')) {
            setTimeout(() => selector.click())
            setTimeout(() => scrollToScriptInToolbar(scene.id), 100)
        } else {
            scrollToScriptInToolbar(scene.id)
        }
    }

    const [{ opacity }] = useSpring(() => ({
        from: { opacity: 0 },
        to: { opacity: 1 },
    }))

    let { activateLeftRearrangeDZ, activateRightRearrangeDZ, dragging } = state

    if (scene.type === 'button') {
        return renderCreateBtn()
    }

    return (
        <S.SceneWrapper
            className="scene"
            printing={printing}
            presentationpreview={presentationpreview}
            onClick={onClick}
            data-sceneid={scene.id}>
            {/* Gutter-create-scene-buttons */}
            {canEdit() && !presentationpreview && !printing && (
                <S.GutterLeft
                    leftEdge={columnIndex === 0}
                    className="scene-gutter"
                    onClick={() => createScene({ position: sceneNumber - 1 })}>
                    <i className="fa fa-plus pos-abt" aria-hidden="true" />
                </S.GutterLeft>
            )}

            {/* Gutter-create-scene-buttons */}
            {canEdit() && !presentationpreview && !printing && isLastScene && (
                <S.GutterRight
                    className="scene-gutter"
                    onClick={() => createScene({ position: sceneNumber })}>
                    <i className="fa fa-plus pos-abt" aria-hidden="true" />
                </S.GutterRight>
            )}

            {/* scene-content */}
            <div
                className={`h100 bg-white scene-inner-container
          ${
              isActive || (dragging && !printing)
                  ? 'b b-primary b-2x scene-box-shadow'
                  : 'scene-border'
          }
          ${dragging ? 'scene-dragging' : ''}`}>
                {/* LOADING? show overlay */}
                {sceneLoading && (
                    <div
                        className="fill text-center o50"
                        style={{
                            zIndex: 999,
                            backgroundColor: '#EFEFEF',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                )}
                {/* DRAG COVER */}
                {hasComments && !printing && (
                    <div
                        onDragStart={e => e.preventDefault()}
                        className="comments-icon active pos-abt cp"
                        style={{ top: 0, right: 8, zIndex: 9 }}
                        onClick={handleCommentIconClick}
                    />
                )}
                {scene.locked && <div className="locked-scene fill" />}
                {renderLock()}
                {renderImage()}
                {printing && imageOnly ? <SceneNumber number={sceneNumber} /> : null}
                {!imageOnly && renderVoiceOver()}
                {!imageOnly && renderDescription()}
            </div>
        </S.SceneWrapper>
    )
}

Scene.defaultProps = {
    onActivateScene: () => {},
    wrapperClasses: 'col-lg-4 col-md-6 col-sm-12 scene m-b-lg',
    printing: false,
    imageOnly: false,
}

Scene.propTypes = {
    album: PropTypes.array,
    activateScene: PropTypes.func,
    onActivateScene: PropTypes.func.isRequired,
    createScene: PropTypes.func,
    deleteScene: PropTypes.func,
    hasComments: PropTypes.bool,
    filterCommentsBySceneId: PropTypes.func,
    isActive: PropTypes.bool,
    permissions: PropTypes.object,
    locked: PropTypes.bool,
    project: PropTypes.object,
    reorderScene: PropTypes.func,
    scene: PropTypes.object,
    sceneNumber: PropTypes.number,
    cloneSetImage: PropTypes.func,
    updateScene: PropTypes.func,
    sceneLoading: PropTypes.bool,
    wrapperClasses: PropTypes.string,
    printing: PropTypes.bool,
    imageOnly: PropTypes.bool,
    toggleToolbarTab: PropTypes.func,
}

export default React.memo(Scene)
