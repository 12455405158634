import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImageLoader from 'react-load-image';
import config from '../../config';

export default class Image extends Component {
  static propTypes = {
    deleteImageInProject: PropTypes.func,
    permissions: PropTypes.object,
    url: PropTypes.string,
    sketchJsonUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { dragging: false };
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragStart({ dataTransfer: dt }) {
    // HACK! Should move to redux
    window.$APP.dragee = {
      url: this.props.url,
      sketchJsonUrl: this.props.sketchJsonUrl,
    };
    window.$APP.disableDropzone = true;
    // dt.setData('disableDropzone', true);
    dt.setData('url', this.props.url);
    this.setState({ dragging: true });
  }

  onDragEnd() {
    // HACK! Should move to redux
    window.$APP.disableDropzone = false;
    this.setState({ dragging: false });
  }

  render() {
    const { url } = this.props;
    const drag__style = this.state.dragging ? 'b-primary b-2x' : '';
    const canEdit = this.props.permissions.edit;

    if (!url) {
      return (
        <div className="vcc bg-default text-center col-sm-6">
          <div className="vc font-bold">Upload Photo</div>
        </div>
      );
    }

    return (
      <div className="toolbar__thumbnail__container">
        <div
          className={`pos-rlt toolbar__thumbnail ${canEdit ? 'canEdit' : ''}`}
        >
          <div
            className={`sn ${drag__style}`}
            draggable={canEdit}
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
          >
            <ImageLoader src={`${config.s3}/${encodeURI(url)}`}>
              <img alt="" className="h100 w100" draggable={false} />
              Image load failed!
              <div className="card-thumbnail-preloader" />
            </ImageLoader>
          </div>
          {canEdit && (
            <div
              className="pos-abt cp"
              style={{ bottom: '5px', right: '5px' }}
              onClick={() => this.props.deleteImageInProject(url)}
            >
              <span className="fa-stack fa-lg delete-image">
                <i className="fa fa-stack-2x text-light" />
                <i className="fa fa-trash fa-stack-1x text-white" />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
