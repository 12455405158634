import * as types from '../constants/ActionTypes'
import * as userAccountAction from '../constants/UserAccountConstant'

export default function(state = { loading: true }, action) {
    switch (action.type) {
        case types.FETCH_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false }
        case types.FETCH_USER_LOADING:
            return { ...state, loading: true }
        case types.DELETE_SESSION:
            return { ...state, user: undefined, loading: false }
        case userAccountAction.DELETE_USER_ACCOUNT_SUCCESS:
            // return { ...state, user: undefined, deleteAccountStatus: false }
            return { ...state, deleteAccountStatus: false }
        case userAccountAction.DELETE_USER_ACCOUNT_FAIL:
            return { ...state, deleteAccountStatus: false }
        case userAccountAction.DELETE_USER_ACCOUNT_INIT:
            return { ...state, deleteAccountStatus: true }
        default:
            return state
    }
}
