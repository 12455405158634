import * as R from 'ramda'
import { call, put, fork, select } from 'redux-saga/effects'
import { takeEvery, takeLatest } from 'redux-saga'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
    has,
    PERMISSIONS,
    computePermissionsValue,
    isFreeAccount,
    isTrial,
} from '../utils/permissions'
import * as api from './api'
import * as types from '../constants/ActionTypes'
import http from '../utils/http'
import config from '../config'
import { deleteCommentResp } from '../actions/CommentActions'
import {
    addMemberToProjectResp,
    deleteImageInProjectResp,
    deleteMemberInProjectResp,
    deleteProjectResp,
    fetchProjectAlbumResp,
    fetchProjectByIdResp,
    fetchProjectMembersResp,
    fetchProjectsResp,
    updateMemberInProjectResp,
    updateProjectResp,
    createProjectResp,
    setActiveProject,
    setUserProjectPermissions,
} from '../actions/ProjectActions'
import { fetchFoldersAction } from '../actions/FolderActions'
import { createCommentResp, updateCommentResp } from '../actions/CommentActions'
import { statusFail } from '../actions/GlobalActivityStatusActions'

import { PROJECT_TEMPLATE, SCENE_TEMPLATE } from '../constants'

// Functions
// ----------------

const getUser = state => state.user.user || state.session.user

const getUserId = state => state.session.userId

const getProject = state => state.project

const getComments = state => state.comments

const getMembers = state => state.members

const clientIdLens = R.lensProp('clientId')

const setClientId = R.set(clientIdLens, 'demo')

const SceneFactory = data => setClientId(data)

const ProjectFactory = data => setClientId(data)

// Sagas!
// ----------------

function* fetchProjects({ payload }) {
    const URL = `${config.apiUrl}/projects`
    const projects = yield call(() => http.get(URL))

    yield put(fetchProjectsResp(R.sort(R.descend(R.prop('dateCreated')), projects)))
}

function* fetchProjectScenes(projectId) {
    const URL = `${config.apiUrl}/projects/${projectId}/scenes`
    const scenes = yield call(() => http.get(URL))
    return scenes
}

function* fetchProjectAlbum(projectId) {
    const URL = `${config.apiUrl}/projects/${projectId}/album`
    const album = yield call(() => http.get(URL))
    return album
}

function* fetchProjectComments(projectId) {
    const URL = `${config.apiUrl}/projects/${projectId}/comments`
    const { error, response } = yield call(() => api.get(URL))

    if (error) {
        window.notify.error(window.$N.MESSAGES.FETCH_PROJECTS_COMMENT_ERR, error)
        return []
    }

    return response
}

function* fetchProjectMembers(projectId) {
    const URL = `${config.apiUrl}/projects/${projectId}/members`
    const { error, response } = yield call(() => api.get(URL))

    if (error) {
        window.notify.error(window.$N.MESSAGES.FETCH_PROJECT_MEMBERS_ERR, error)
        return []
    }

    return response
}

function* fetchPublicProject(projectId) {
    const URL = `${config.apiUrl}/public-project/${projectId}`
    const { error, response } = yield call(() => api.get(URL))

    if (error) {
        window.notify.error(window.$N.MESSAGES.FETCH_PROJECT_BY_ID_ERR)
        window.sagaHistory.push('/authenticate')
        return
    }

    return response
}

function* getProjectId() {
    // look up project Id
    const pathname = window.location.pathname
    let project = yield select(getProject)

    return project ? project.id : R.last(pathname.split('/'))
}

function* runAddMemberToProject({ payload }) {
    // Premium Feature Check!!
    // If free account AND members count > 2 (i.e. owner and another user)
    // Redirect to upgrade page and show upgrade message
    let [members, user] = yield [select(getMembers), select(getUser)]
    // If a trial account and # members is equal to or
    // more than maximum allowable amount
    let promptToUpgrade = isTrial(user) && members.length >= config.maximumMemberCountPerProject

    // Prompt to upgrade
    if (promptToUpgrade) {
        window.sagaHistory.push({
            pathname: '/billing',
            state: { modal: true, closeable: true, upgrade: true },
        })
        return
    }

    let data = R.mergeRight(
        {
            permissions: computePermissionsValue([PERMISSIONS.COMMENT]),
            listingPreference: 0,
        },
        payload,
    )
    // look up project Id
    const projectId = yield getProjectId()
    const URL = `${config.apiUrl}/projects/${projectId}/members`

    const token = yield call(() => api.getRecaptchaToken('invite'))
    if (!token) {
        window.notify.error(null, 'Unable to verify user authenticity. Please contact support.')
    }

    // API call
    const { error, response } = yield call(() => api.post(URL, data, false, token))
    if (error) {
        window.notify.error(window.$N.MESSAGES.ADD_MEMBER_ERR, error)
        return
    }

    yield put(addMemberToProjectResp(R.head(response)))
}

function* runUpdateMemberPermission({ payload }) {
    let data = {
        permissions: payload.permissions,
        listingPreference: 0,
        userId: payload.member.userId,
    }
    const token = yield call(() => api.getRecaptchaToken('invite'))
    if (!token) {
        window.notify.error(null, 'Unable to verify user authenticity. Please contact support.')
    }
    // look up project Id
    const projectId = yield getProjectId()
    const URL = `${config.apiUrl}/projects/${projectId}/members/permission`
    // API call
    const { error, response } = yield call(() => api.post(URL, data, false, token))
    if (error) {
        window.notify.error(window.$N.MESSAGES.UPDATE_MEMBER_ERR, error)
        return
    }

    yield put(updateMemberInProjectResp(R.head(response)))
}

function* runDeleteMemberInProject({ payload }) {
    const projectId = yield getProjectId()
    const URL = `${config.apiUrl}/projects/${projectId}/members/${payload.userId}`
    // API call
    const { error } = yield call(() => api.deleteResource(URL))

    if (error) {
        window.notify.error(window.$N.MESSAGES.DELETE_MEMBER_ERR, error)
        return
    }

    yield put(deleteMemberInProjectResp(payload))
    yield put(deleteProjectResp(projectId))
}

function* fetchProjectResourceById(projectId) {
    const URL = `${config.apiUrl}/projects/${projectId}`
    const { error, response } = yield call(() => api.get(URL))

    if (error) {
        return null
    }

    return response
}

function* fetchPublicProjectById({ payload: projectId }) {
    yield put(showLoading())
    // HTTP GET resources
    const actualProject = yield call(fetchProjectResourceById, projectId)
    if (actualProject) {
        window.sagaHistory.push(`/projects/${projectId}`)
        return
    }

    const { project, comments, album, scenes } = yield call(fetchPublicProject, projectId)
    if (!project) {
        window.notify.error(window.$N.MESSAGES.FETCH_PROJECT_BY_ID_ERR)
        window.sagaHistory.push('/authenticate')
        return
    }
    // change route
    window.sagaHistory.push(`/public-projects/${projectId}`)

    // update rdx store
    yield put(
        fetchProjectByIdResp({
            album,
            comments,
            members: [],
            project,
            scenes,
            permissions: {
                owner: false,
                edit: false,
                comment: false,
            },
        }),
    )

    yield put(hideLoading())
}

function* fetchProjectById({ payload: projectId }) {
    yield put(showLoading())
    // HTTP GET resources
    const [album, comments, members, project, scenes] = yield [
        call(fetchProjectAlbum, projectId),
        call(fetchProjectComments, projectId),
        call(fetchProjectMembers, projectId),
        call(fetchProjectResourceById, projectId),
        call(fetchProjectScenes, projectId),
    ]

    if (!project) {
        window.sagaHistory.push('/projects')
        return
    }
    // compute user permissions in project
    let permissions = yield projectPermissions(members)
    // update rdx store
    yield put(
        fetchProjectByIdResp({
            album,
            comments,
            members,
            project,
            scenes,
            permissions,
        }),
    )

    yield put(hideLoading())
}

function* projectPermissions(members) {
    let userId = yield select(getUserId)
    let member = R.compose(R.head, R.filter(R.propEq('userId', userId)))(members)

    return {
        owner: has(PERMISSIONS.OWNER, member.permissions),
        edit: has(PERMISSIONS.EDIT, member.permissions),
        comment: has(PERMISSIONS.COMMENT, member.permissions),
    }
}

function* createProject({ payload }) {
    // Proceed with project creation
    let { values } = payload
    let projectData = ProjectFactory(R.mergeRight(PROJECT_TEMPLATE, values))
    let { error, response: project } = yield call(() =>
        api.post(`${config.apiUrl}/projects`, projectData),
    )
    // Handle error
    if (error) {
        window.notify.error(window.$N.MESSAGES.CREATE_PROJECT_ERR, error)
        return
    }
    // Create scene
    const { response: newScene, error: sceneError } = yield call(() => createScene(project.id))
    if (newScene) {
        project?.sceneOrder?.push(newScene?.id)
    }
    yield put(createProjectResp(project))
    yield call(() => window.sagaHistory.push(`/projects`))
    // Redirect to project
    // yield call(() => window.sagaHistory.push(`/projects/${project.id}`))

    // TODO: We need to fire CREATE_PROJECT_RESP so that we can properly count
    // the number of projects that a user has & update HelpCrunch
    // yield put(createProjectResp({ payload }));
}

function* duplicateProject({ payload }) {
    let originalProject = yield select(getProject)
    let { values } = payload
    let projectData = ProjectFactory(R.mergeRight(PROJECT_TEMPLATE, values))
    let { error } = yield call(() =>
        api.post(`${config.apiUrl}/projects/${originalProject.id}`, projectData),
    )

    if (!error) {
        yield call(() =>
            window.sagaHistory.push(`/projects/${originalProject.id}/duplicate-delayed`),
        )
        yield put(fetchFoldersAction())
        return
    }
}

// Original, a scene was created via put(createScene({ scene, projectId })) call;
// however, that was not a sync call and as such the subsequent call to fetch project resources
// was missing new scene id in project.sceneOrder key
// This call is special purpose and can only be called from createProject function
function* createScene(projectId) {
    // payload
    let sceneData = SceneFactory(R.mergeRight(SCENE_TEMPLATE, { projectId }))
    // http call
    let URL = `${config.apiUrl}/projects/${projectId}/scenes`
    const { response, error } = yield call(() => api.post(URL, sceneData))

    if (error) {
        window.notify.error(window.$N.MESSAGES.CREATE_FIRST_SCENE_ERR, error)
        return { error }
    }

    return { response }
}

function* runUploadImage({ payload: files }) {
    // 1. Get upload key
    const project = yield select(getProject)
    let { error, response } = yield call(() => api.getUploadKey(project.id))
    if (error) {
        // window.notify.error(window.$N.MESSAGES.GET_UPLOAD_KEY_ERR, error);
        return
    }
    // 2. Begin upload!
    yield api.batchUploadImages(project.id, response, files)
    // 4. Re-fetch project (to have new album content)
    let album = yield fetchProjectAlbum(project.id)

    yield put(fetchProjectAlbumResp(album))
}

// Create comment
// ----------------

function* watchCreateComment() {
    yield* takeEvery(types.CREATE_COMMENT, runCreateComment)
}

function* runCreateComment({ payload }) {
    const [user, project] = yield [select(getUser), select(getProject)]
    const data = R.mergeRight(
        {
            status: 'active',
            userId: user.id,
        },
        payload,
    )
    let { error, response } = yield call(() =>
        api.post(`${config.apiUrl}/projects/${project.id}/comments`, data),
    )
    if (error) {
        window.notify.error(window.$N.MESSAGES.CREATE_COMMENT_ERR, error)
        return
    }
    yield put(createCommentResp(response))
}

function* runUpdateComment({ payload }) {
    const comments = yield select(getComments)
    yield put(updateCommentResp(payload))
    const comment = R.find(comment => comment.id === payload.id, comments)

    const sanitize = R.omit(['dateCreatedEpoch', 'fullName', 'user', 'sceneNumber'])

    const sanitizedPayload = sanitize(payload)
    const project = yield select(getProject)
    const URL = `${config.apiUrl}/projects/${project.id}/comments/${payload.id}`
    const { error } = yield call(() => api.put(URL, sanitizedPayload))

    if (error) {
        window.notify.error(window.$N.MESSAGES.UPDATE_COMMENT_ERR, error)
        yield put(updateCommentResp(comment))
        return
    }
}

function* runDeleteImageInAlbum({ payload }) {
    const project = yield select(getProject)
    const URL = `${config.apiUrl}/projects/${project.id}/album/${payload.split(/\//).pop()}`
    const { error } = yield call(() => api.deleteResource(URL))

    if (error) {
        window.notify.error(window.$N.MESSAGES.DELETE_IMAGE_IN_ALBUM_ERR, error)
        return
    }

    yield put(deleteImageInProjectResp(payload))
}

function* runDeleteProject({ payload }) {
    const URL = `${config.apiUrl}/projects/${payload}`
    const { error } = yield call(() => api.deleteResource(URL))

    if (error) {
        window.notify.error(window.$N.MESSAGES.DELETE_PROJECT_ERR, error)
        return
    }

    yield put(deleteProjectResp(payload))
    // redirect to projects
    window.sagaHistory.push('/projects')
}

function* runDeleteComment({ payload }) {
    const project = yield select(getProject)
    const URLS = R.map(
        comment => `${config.apiUrl}/projects/${project.id}/comments/${comment.id}`,
        payload,
    )
    const { response } = yield call(() => api.batchDeleteResources(URLS))

    const hasError = R.pipe(R.prop('error'), R.isNil, R.not)
    const error = R.find(hasError, response)
    if (error) {
        window.notify.error(window.$N.MESSAGES.DELETE_COMMENT_ERR, error.message)
    } else {
        yield put(deleteCommentResp(payload))
    }
}

function* runFetchProjectMembers({ payload: projectId }) {
    const [members, project] = yield [
        call(fetchProjectMembers, projectId),
        call(fetchProjectResourceById, projectId),
    ]

    let permissions = yield projectPermissions(members)

    yield put(fetchProjectMembersResp(members))
    yield put(setActiveProject(project))
    yield put(setUserProjectPermissions(permissions))
}

// Update Project Meta
// ----------------

function* updateProjectRun({ payload }) {
    let [project, user] = yield [select(getProject), select(getUser)]
    const data = R.mergeRight(project, payload)
    // If a free account and # members is equal to or
    // more than maximum allowable amount
    const URL = `${config.apiUrl}/projects/${project.id}`
    const { error, response } = yield call(() => api.put(URL, data))

    if (error) {
        window.notify.error(window.$N.MESSAGES.UPDATE_PROJECT_ERR, error)
        yield put(statusFail())
        return
    }

    yield put(updateProjectResp(response))
}

function* watchUpdateProject() {
    yield* takeEvery(types.UPDATE_PROJECT, updateProjectRun)
}

// forks
// ----------------

function* watchUploadImage() {
    yield* takeEvery(types.UPLOAD_IMG, runUploadImage)
}

function* watchFetchProjects() {
    yield* takeLatest(types.FETCH_PROJECTS, fetchProjects)
}

function* watchProjectFetchById() {
    yield* takeLatest(types.FETCH_PROJECT_BY_ID, fetchProjectById)
}

function* watchPublicProjectFetchById() {
    yield* takeLatest(types.FETCH_PUBLIC_PROJECT_BY_ID, fetchPublicProjectById)
}

function* watchProjectCreate() {
    yield* takeLatest(types.CREATE_PROJECT, createProject)
}

function* watchProjectDuplicate() {
    yield* takeLatest(types.DUPLICATE_PROJECT, duplicateProject)
}

function* watchAddMemberToProject() {
    yield* takeLatest(types.ADD_MEMBER_TO_PROJECT, runAddMemberToProject)
}

function* watchDeleteMemberInProject() {
    yield* takeLatest(types.DELETE_MEMBER_IN_PROJECT, runDeleteMemberInProject)
}

function* watchUpdateComment() {
    yield* takeLatest(types.UPDATE_COMMENT, runUpdateComment)
}

function* watchDeleteComment() {
    yield* takeEvery(types.DELETE_COMMENT, runDeleteComment)
}

function* watchDeleteImageInAlbum() {
    yield* takeLatest(types.DELETE_IMAGE_IN_PROJECT, runDeleteImageInAlbum)
}

function* watchDeleteProject() {
    yield* takeLatest(types.DELETE_PROJECT, runDeleteProject)
}

function* watchFetchProjectMembers() {
    yield* takeLatest(types.FETCH_PROJECT_MEMBERS, runFetchProjectMembers)
}

function* watchUpdateMemberPermission() {
    yield* takeLatest(types.UPDATE_MEMBER_PERMISSION, runUpdateMemberPermission)
}

// Fork away
// ----------------

export default function*() {
    yield fork(watchUpdateMemberPermission)
    yield fork(watchFetchProjects)
    yield fork(watchProjectFetchById)
    yield fork(watchPublicProjectFetchById)
    yield fork(watchProjectCreate)
    yield fork(watchProjectDuplicate)
    yield fork(watchUploadImage)
    yield fork(watchAddMemberToProject)
    yield fork(watchDeleteMemberInProject)
    yield fork(watchCreateComment)
    yield fork(watchUpdateComment)
    yield fork(watchDeleteImageInAlbum)
    yield fork(watchDeleteProject)
    yield fork(watchDeleteComment)
    yield fork(watchFetchProjectMembers)
    yield fork(watchUpdateProject)
}
