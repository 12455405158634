import { createAction } from 'redux-actions'

import {
    REORDER_STORYBOARDS,
    REORDER_STORYBOARDS_RESP,
    MOVE_STORYBOARDS,
    MOVE_STORYBOARDS_RESP,
} from '../constants/Projects'

export const reorderStoryboards = createAction(REORDER_STORYBOARDS)
export const reorderStoryboardsResp = createAction(REORDER_STORYBOARDS_RESP)
export const moveStoryboards = createAction(MOVE_STORYBOARDS)
export const moveStoryboardsResp = createAction(MOVE_STORYBOARDS_RESP)
