import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from '../actions';
import Authenticate from '../components/authenticate';

export default withRouter(
  connect(
    state => {
      return state;
    },
    {
      ...actions.session,
    }
  )(Authenticate)
);
