import styled, { css } from 'styled-components'

export const StyledButton = styled.button`
    font-weight: bold;
    line-height: 46px;
    outline: none;
    border-radius: ${props => props.theme.border.plot_default_border_radius};
    border: 0;
    padding: 0 20px;
    user-select: none;
    color: #fff;

    ${props =>
        props.transparent &&
        css`
            padding: 1px;
            cursor: pointer;
            background: transparent;
            border: 0;
            -webkit-appearance: none;
        `}
    ${props =>
        props.setGrayBackground &&
        css`
            background-color: gray !important;
        `}
    ${props =>
        props.fullwidth &&
        css`
            width: 100%;
        `}
    ${props =>
        props.alignCenterHorizontal &&
        css`
            margin-left: auto;
            margin-right: auto;
            display: block;
        `}
    ${props =>
        props.primary &&
        css`
            background-color: ${props.theme.color.plot_green};
            &:hover {
                background-color: ${props => props.theme.color.plot_green_hover};
            }
            svg {
                width: 15px;
            }
        `}
    ${props =>
        props.danger &&
        css`
            background-color: ${props.theme.color.plot_red};
            &:hover {
                background-color: ${props => props.theme.color.plot_red_hover};
            }
            svg {
                width: 15px;
            }
        `}
    ${props =>
        props.disabled &&
        css`
            ${!props.primary &&
                !props.danger &&
                !props.modalTransparentBtn &&
                css`
                    background-color: grey !important;
                `}
            color: #fff;
            opacity: 0.65;
            &:hover {
                ${!props.primary &&
                    !props.danger &&
                    !props.modalTransparentBtn &&
                    css`
                        background-color: grey !important;
                    `}
                cursor: not-allowed;
                box-shadow: none;
            }
        `}

    ${props =>
        props.modalTransparentBtn &&
        css`
            width: auto;
            background-color: transparent;
            line-height: 30px;
            color: #fff;
            opacity: 0.6;
            &:hover {
                opacity: 1;
            }
        `}
`
