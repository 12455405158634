import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Route, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import PublicHeaderLinks from '../PublicHeaderLinks'
import PrivateHeaderLinks from '../PrivateHeaderLinks'
import HeaderProjectMeta from '../headerProjectMeta'
import HamburgerMenu from '../../icons/HamburgerMenu'
import CloseMenu from '../../icons/CloseMenu'
import Downshift from 'downshift'
import PublicProjectName from '../PublicProject/publicProjectName'

class Header extends Component {
    static propTypes = {
        logout: PropTypes.func,
        params: PropTypes.object,
        project: PropTypes.object,
        user: PropTypes.object,
        route: PropTypes.func,
        style: PropTypes.object,
        public: PropTypes.bool,
        updateProject: PropTypes.func,
    }

    static defaultProps = {
        public: false,
    }

    componentDidUpdate() {
        document.title = this.props.project ? this.props.project.title : 'Plot'
    }

    renderProjectHeaderMeta = () => (
        <HeaderProjectMeta
            project={this.props.project}
            updateProject={this.props.updateProject}
            permissions={this.props.permissions}
            history={this.props.history}
            route={this.props.route}
        />
    )

    render() {
        const projectHeaderMeta = this.props.project ? (
            this.props.public || !this.props.permissions ? (
                <PublicProjectName projectTitle={this.props.project.title} className="public" />
            ) : (
                <Route exact path="/projects/:projectId" render={this.renderProjectHeaderMeta} />
            )
        ) : null
        return (
            <Downshift
                onSelect={(item, { closeMenu }) => closeMenu()}
                onOuterClick={({ closeMenu }) => closeMenu()}>
                {({ getItemProps, getMenuProps, getToggleButtonProps, isOpen, closeMenu }) => (
                    <div>
                        <div className="container-fluid header-container">
                            <div className="logo-container">
                                <Link to="/projects">
                                    <div className="logo" />
                                </Link>
                            </div>
                            <div className="header-meta-container">
                                {projectHeaderMeta}
                                {this.props.public ? (
                                    <PublicHeaderLinks route={this.props.route} />
                                ) : (
                                    <PrivateHeaderLinks
                                        route={this.props.route}
                                        user={this.props.user}
                                        logout={this.props.logout}
                                    />
                                )}
                            </div>
                            <div className="header-menu-button-container">
                                <button {...getToggleButtonProps()} className="menu-toggle">
                                    {isOpen ? <CloseMenu /> : <HamburgerMenu />}
                                </button>
                            </div>
                        </div>
                        {isOpen ? ( // Mobile menu link
                            <div className="header-menu-container">
                                <div className="header-menu-links">
                                    {this.props.public ? (
                                        <PublicHeaderLinks
                                            getMenuProps={getMenuProps}
                                            getItemProps={item =>
                                                getItemProps({ onClick: closeMenu, item })
                                            }
                                            route={this.props.route}
                                        />
                                    ) : (
                                        <PrivateHeaderLinks
                                            getMenuProps={getMenuProps}
                                            getItemProps={item =>
                                                getItemProps({ onClick: closeMenu, item })
                                            }
                                            route={this.props.route}
                                            user={this.props.user}
                                            logout={this.props.logout}
                                        />
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}
            </Downshift>
        )
    }
}
export default withRouter(Header)
