import React from 'react';

export default ({
  fill = 'white',
  enableBackground = 'new 0 0 1024 1024',
  className,
  ...style
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 15 15"
    style={{ fill, enableBackground, ...style }}
    className={className}
  >
    <g>
      <g>
        <path d="M13.51,3,10.62.15a.5.5,0,0,0-.71,0L7.85,2.21h0L1.5,8.56a.49.49,0,0,0-.11.18v0L0,13a.51.51,0,0,0,.13.51.5.5,0,0,0,.35.14.39.39,0,0,0,.15,0L4.9,12.28h0a.55.55,0,0,0,.19-.12l8.41-8.41A.51.51,0,0,0,13.51,3ZM4.74,11.09,2.56,8.91,8.21,3.27l2.18,2.18ZM2.08,9.84l1.73,1.73-2.54.81Zm9-5.1L8.91,2.56l1.36-1.35,2.18,2.18Z" />
      </g>
    </g>
  </svg>
);
