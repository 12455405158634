import styled from 'styled-components/macro'
import CommonStyledButton from '../../common/styled/StyledButton'
import CommonStyledInput from '../../common/styled/StyledInput'
import CommonStyledLink from '../../common/styled/StyledLink'

export const StyledInput = styled(CommonStyledInput)``
export const StyledButton = styled(CommonStyledButton)``
export const StyledLink = styled(CommonStyledLink)`
    display: block;
    color: ${props => props.theme.color.plot_green};
`

export const Wrapper = styled.div`
    input {
        margin-bottom: 10px !important;
    }
`

export const MembersWrapper = styled.div`
    padding: 25px;
    background: #293331;
    border-radius: 7px;
    margin-top: 10px;
    margin-bottom: 30px;
`
