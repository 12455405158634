import { createAction } from 'redux-actions';

import * as types from '../constants/ActionTypes';

export const authenticate = createAction(types.AUTHENTICATE);
export const createSession = createAction(types.CREATE_SESSION);
export const deleteSession = createAction(types.DELETE_SESSION);
export const logout = createAction(types.LOGOUT);
export const upgrade = createAction(types.UPGRADE);
export const upgradeResp = createAction(types.UPGRADE_RESP);
export const cancelSubscription = createAction(types.CANCEL_SUBSCRIPTION);
export const cancelSubscriptionResp = createAction(
  types.CANCEL_SUBSCRIPTION_RESP
);
export const updateUser = createAction(types.UPDATE_USER);
export const updateUserSuccess = createAction(types.UPDATE_USER_SUCCESS);
export const updateUserLoading = createAction(types.UPDATE_USER_LOADING);
export const fetchUser = createAction(types.FETCH_USER);
export const fetchUserSuccess = createAction(types.FETCH_USER_SUCCESS);
export const fetchUserLoading = createAction(types.FETCH_USER_LOADING);
