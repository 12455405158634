import { createAction } from 'redux-actions';

import * as types from '../constants/ActionTypes';

export const routeLocationDidUpdate = createAction(types.UPDATE_ROUTE);
export const setReturnTo = createAction(types.SET_RETURN_TO);
export const togglePaint = createAction(types.TOGGLE_PAINT);
export const fetchProductPlans = createAction(types.FETCH_PLANS);
export const fetchProductPlansResp = createAction(types.FETCH_PLANS_RESP);
export const activateToolbarTab = createAction(types.ACTIVATE_TOOLBAR_TAB);
