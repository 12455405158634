import React, { useState } from 'react'
import { connect } from 'react-redux'

import { deleteFolderAction } from '../../actions/FolderActions'
import DeleteFolder from './DeleteFolder'

const DeleteFolderContainer = ({ history, deleteFolderAction, folderId, userFoldersId }) => {
    if (!folderId || !userFoldersId) {
        history.replace({ pathname: '/projects' })
    }

    const [loading, setLoading] = useState(false)
    const handleFolderDelete = () => {
        setLoading(true)
        deleteFolderAction({ userFoldersId: userFoldersId, history: history })
    }

    return <DeleteFolder onClick={handleFolderDelete} loading={loading} />
}

export default connect(
    (state, ownProps) => {
        const folderId = ownProps.location.state?.folderId || null
        const userFoldersId = ownProps.location.state?.userFoldersId || null
        const history = ownProps.history
        return {
            folderId,
            userFoldersId,
            history,
        }
    },
    {
        deleteFolderAction,
    },
)(DeleteFolderContainer)
