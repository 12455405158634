import React from 'react'
import PropTypes from 'prop-types'

const PrintStep = ({ children, title, subtitle }) => (
    <div>
        <div>
            <div
                className={`text-white text-center text-title font-bolder ${
                    subtitle ? '' : 'm-b-md'
                }`}>
                {title}
            </div>
            {subtitle && (
                <p className="text-light text-md font-lighter m-b-md text-center">
                    <i className="text-muted new-line">{subtitle}</i>
                </p>
            )}
        </div>
        <div>{children}</div>
    </div>
)

PrintStep.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
}

export default PrintStep
