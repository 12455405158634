import React from 'react';
import style from './strokeStyle';

export default () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1082 722"
    style={style.svg}
  >
    <path
      style={style.st0}
      d="M864.4,464H214.2c-1.6,0-2.9-1.3-2.9-2.9V99.4c0-1.6,1.3-2.9,2.9-2.9h650.2c1.6,0,2.9,1.3,2.9,2.9v361.7
		C867.3,462.7,866,464,864.4,464z"
    />
    <line style={style.st1} x1="210.8" y1="537" x2="867.8" y2="537" />
    <line style={style.st1} x1="210.8" y1="602" x2="867.8" y2="602" />
  </svg>
);
