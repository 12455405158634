import React from 'react'
import moment from 'moment'
import messages from '../../constants/Messages'
import { MessagesWrapper, MessageContent } from './styled'

export default props => {
    const UPGRADE_MESSAGE = messages.UPGRADE_MESSAGE
    let {
        isFreeAccount,
        isTrial,
        isExtended,
        isTrialExpired,
        isCancelled,
        isTeamMember,
        session,
        showCardUpdatedMessage,
        showInstantCancelMessage,
        user: { cancellationDate },
    } = props
    const hasPaymentErr = Boolean(session.paymentError)
    const hasUpgradeMessage = (isTrialExpired || isCancelled) && !isTeamMember
    const hasCancelledMessage =
        !showCardUpdatedMessage && !showInstantCancelMessage && !isFreeAccount && isCancelled
    const displayMessage =
        hasPaymentErr ||
        hasUpgradeMessage ||
        isTrial ||
        hasCancelledMessage ||
        showCardUpdatedMessage ||
        showInstantCancelMessage
    if (!displayMessage) {
        return null
    }
    const d = new Date(cancellationDate * 1000)
    const formattedBillingEndPeriod = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
    const cancelledMessage = `Your subscription has been cancelled and will end on ${formattedBillingEndPeriod}.`
    const cancelledInstantMessage = `Your subscription has been cancelled`
    const updatedMessage = `Your card has been updated successfully.`
    const capitalize = s => {
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    return (
        <MessagesWrapper>
            {showCardUpdatedMessage && <MessageContent success>{updatedMessage}</MessageContent>}
            {showInstantCancelMessage && (
                <MessageContent error>{cancelledInstantMessage}</MessageContent>
            )}
            {/* Upgrade message */}
            {hasUpgradeMessage && !hasCancelledMessage && (
                <MessageContent error>{UPGRADE_MESSAGE}</MessageContent>
            )}
            {isExtended && (
                <MessageContent error>{`${capitalize(
                    moment(d).fromNow(true),
                )} until your extended access ends.`}</MessageContent>
            )}
            {isTrial && !isExtended && (
                <MessageContent error>{`${capitalize(
                    moment(d).fromNow(true),
                )} until your trial expires.`}</MessageContent>
            )}
            {/* Cancelled message */}
            {hasCancelledMessage && <MessageContent error>{cancelledMessage}</MessageContent>}
            {/* Payment error message; from API side */}
            {hasPaymentErr && <MessageContent error>{session.paymentError}</MessageContent>}
        </MessagesWrapper>
    )
}
