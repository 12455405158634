import React from 'react';

export default ({ fill = 'white', className, ...style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    x="0px"
    y="0px"
    style={{ fill, ...style }}
    className={className}
  >
    <g>
      <path d="M77.1,14.9H39.9a8,8,0,0,0-8,8V60.1a8,8,0,0,0,8,8H77.1a8,8,0,0,0,8-8V22.9A8,8,0,0,0,77.1,14.9Zm0,45.2H39.9V22.9H77.1Z" />
      <path d="M22.9,85.1H60.1a8,8,0,0,0,8-8V74.7a4,4,0,0,0-8,0v2.4H22.9V39.9h2.2a4,4,0,0,0,0-8H22.9a8,8,0,0,0-8,8V77.1A8,8,0,0,0,22.9,85.1Z" />
    </g>
  </svg>
);
