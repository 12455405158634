const ENV = (process.env.REACT_APP_ENV || 'beta').toUpperCase()

export default {
    uiBucketPrefix: process.env.REACT_APP_UI_BUCKET_PREFIX || '',
    apiUrl: process.env[`REACT_APP_API_URL_${ENV}`],
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
    s3: process.env.REACT_APP_S3,
    stripeKey: process.env[`REACT_APP_STRIPE_KEY_${ENV}`],
    maximumProjectCount: process.env.REACT_APP_MAX_PROJECT_COUNT,
    maximumSceneCount: process.env.REACT_APP_MAX_SCENE_COUNT,
    maximumMemberCountPerProject: process.env.REACT_APP_MAX_MEMBER_PER_PROJECT,
    sessionCookie: process.env.REACT_APP_SESSION_COOKIE,
    stripeProductSoloId: process.env[`REACT_APP_STRIPE_SOLO_PRODUCT_ID_${ENV}`],
    stripeProductProId: process.env[`REACT_APP_STRIPE_PRO_PRODUCT_ID_${ENV}`],
    stripeProductBusinessId: process.env[`REACT_APP_STRIPE_BUSINESS_PRODUCT_ID_${ENV}`],
    stripeOldProductId: process.env[`REACT_APP_STRIPE_OLD_PRODUCT_ID_${ENV}`],
    isSketchAPIServerAvailable: false,
    apiHelpCrunchUrl: process.env.REACT_APP_API_HELPCRUNCH,
}
