import { sketchConfig } from './sketchConfig'
import { toolGroups } from './toolGroups'

/**
 * Setup the SketchAPI
 * SketchAPI config, miscellaneous routines, etc. go here!
 * @return {Promise}
 */
export async function setupSketchApi() {
    const sketch = window.sketch

    await sketch.config(sketchConfig)
    // SET sketch global paintMode to PAINT_INTO_LAYER
    // await sketch.configGlobals({
    //     paintMode: sketch.PAINT_INTO_LAYER,
    // })
    sketch.timer =
        sketch.timer ||
        new sketch.UITimer({
            renderer: 'rainbow',
        })
}

/**
 * Ensure that SketchAPI has a document
 * @return {Promise}
 */
export async function setupDocument() {
    const sketch = window.sketch

    const $element = document.querySelector('#sketch-api')
    const docSize = { width: 1024, height: 576 }
    const docUnits = 'px'
    const doc = await sketch.createDocument({
        element: $element,
        width: docSize.width,
        height: docSize.height,
        units: docUnits,
    })
    sketch.$container = doc.$element
}

/**
 * Create the SketchAPI UI elements
 * Any UI elements that use SketchAPI for rendering go here. React components
 * go elsewhere (@see PaintContainer).
 */
export function setupSketchUi() {
    const sketch = window.sketch
    const ui = sketch.ui

    ui.icons = toolGroups
    ui.toolGroups = toolGroups
    window.app = {}

    sketch.UIContextMenu.createDefaultMenus()
    sketch.UIFloatingToolbar({
        $parent: sketch.$container,
        windowOptions: {
            top: 150,
            right: 15,
        },
    })

    Galactic.assignDeep(sketch.toolConfigs, {
        eraser: {
            blur: 0,
            stroke: new sketch.Stroke({ lineWidth: 30 }),
            // paintMode: sketch.PAINT_ATOP, // remove paintMode to enable a toggle button for paintMode
            UILineWidth: new sketch.UIConfigPane.UILineWidth(1, 50),
            UISubTool: undefined,
        },
    })

    const $primaryToolbar = sketch.UIPrimaryToolbar({
        $parent: sketch.$container,
        toolGroups: {
            top: [
                {
                    id: 'select',
                    icon: 'sketch-icon-select',
                    tooltip: 'select',
                    linkedAs: 'tool',
                },
                {
                    icon: 'sketch-icon-pencil',
                    id: 'pencil',
                    linkedAs: 'tool',
                    onClick: 'PopoutToolConfig',
                    toolGroup: 'brush',
                    tooltip: 'pencil',
                },
                {
                    icon: 'sketch-icon-eraser',
                    tooltip: 'eraser',
                    onClick: 'PopoutToolConfig',
                    id: 'eraser',
                    linkedAs: 'tool',
                },
                {
                    icon: 'sketch-icon-star',
                    id: 'star',
                    linkedAs: 'tool',
                    onClick: 'PopoutToolConfig',
                    toolGroup: 'shape',
                    tooltip: 'star',
                },
                {
                    icon: 'sketch-icon-clipart',
                    id: 'clipart',
                    linkedAs: 'tool',
                    onClick: 'PopoutToolConfig',
                    tooltip: 'clipart',
                },
                {
                    icon: 'sketch-icon-text',
                    id: 'fancyText',
                    linkedAs: 'tool',
                    onClick: 'PopoutToolConfig',
                    tooltip: 'text',
                },
                {
                    icon: 'sketch-icon-paintEditor',
                    id: 'paintEditor',
                    linkedAs: 'tool',
                    onClick: 'PopoutToolConfig',
                    toolGroup: 'fill',
                    tooltip: 'paintEditor',
                },
            ],
            bottom: [
                {
                    icon: 'sketch-icon-refresh',
                    id: 'clear',
                    tooltip: 'Clear',
                },
            ],
        },
    })

    $primaryToolbar.registerCommand({
        id: 'clear',
        type: 'confirm',
        content: 'Are you sure you want to clear the document? You can undo this action.',
        labels: {
            ok: 'yes',
            cancel: 'no',
        },
        transient: true,
        onResolve({ ok }) {
            if (!ok) {
                return
            }

            resetPage()
        },
    })

    $primaryToolbar.registerCommand({
        id: 'eraser',
        type: 'popout',
        className: 'toolConfig',
        content({ refreshView }) {
            sketch.setTool('eraser')
            return sketch.UIToolConfig({
                refreshView,
                toolConfig: sketch.toolConfigs.eraser,
            })
        },
    })

    $primaryToolbar.refreshView()
    sketch.exec('select')

    function resetPage() {
        sketch.doc.selectAll()
        sketch.doc.selection.remove()
        sketch.setTool('select')
    }
}
