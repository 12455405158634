import { connect } from 'react-redux';
import Script from '../components/script';
import actions from '../actions';

export default connect(
  (state, ownProps) => {
    const { project, scenes, scene, focusedScriptType } = state;
    return {
      focusedScriptType,
      activeScene: scene,
      permissions: state.permissions,
      project,
      scenes,
      ...ownProps,
    };
  },
  {
    ...actions.scenes,
    ...actions.projects,
    ...actions.focusedScriptType,
  }
)(Script);
