import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import Comment from './Comment'
import * as S from './styled'
class Comments extends React.PureComponent {
    repliedComments = R.filter(comment => comment.replyToComment, this.props.comments)
    parentCommentIdArray = []
    groupedCommentThread = []

    componentDidUpdate() {
        this.repliedComments = R.filter(comment => comment.replyToComment, this.props.comments)
        this.parentCommentIdArray = []
        this.groupedCommentThread = []
    }

    filterAllReplies = parentCommentId => {
        this.parentCommentIdArray.push(parentCommentId)
        const currentParentId = this.parentCommentIdArray[this.parentCommentIdArray.length - 1]
        R.forEach(comment => {
            if (comment && currentParentId === comment.replyToComment) {
                this.groupedCommentThread.push(comment)
                this.filterAllReplies(comment.id)
            }
            return this.groupedCommentThread
        }, this.repliedComments)
    }

    getAssociatedReplies = comment => {
        this.filterAllReplies(comment.id)
        // Add parent to group
        this.groupedCommentThread.push(comment)
        return this.groupedCommentThread
    }

    render = () => {
        const { activeScene, comments, commentRefs, ...rest } = this.props

        let renderedComments = R.map(
            comment => (
                <Comment
                    ref={ref => {
                        if (!commentRefs.current[comment.sceneId]) {
                            commentRefs.current[comment.sceneId] = ref
                        }
                    }}
                    {...rest}
                    getGroupedCommentThread={this.getAssociatedReplies}
                    key={comment.id}
                    isReply={Boolean(comment.replyToComment)}
                    sceneIsActivated={Boolean(activeScene)}
                    active={(activeScene && activeScene.id === comment.sceneId) || false}
                    comment={comment}
                />
            ),
            comments,
        )

        let emptySceneComment = (
            <div className="m-t">
                <i>This scene has no comments.</i>
            </div>
        )

        let nullState = (
            <S.EmptyStateContainer className="comments-empty-state">
                <div className="title">No Comments</div>
                Hover on a scene and click{' '}
                <span className="icon">
                    <span className="fa fa-comment"></span>
                </span>{' '}
                to add a comment.
            </S.EmptyStateContainer>
        )

        let content = R.length(renderedComments) ? renderedComments : nullState // No active scene selected

        if (!comments || !comments.length) return nullState

        if (this.props.activeScene) {
            content = comments ? renderedComments : emptySceneComment // Have active scene
        }
        return <div className="m-t-n-md wrapper-md">{content}</div>
    }
}

Comments.propTypes = {
    activateScene: PropTypes.func,
    activeScene: PropTypes.object,
    comments: PropTypes.array,
    delete: PropTypes.func,
    getSceneNumber: PropTypes.func,
    permissions: PropTypes.object,
    project: PropTypes.object,
    reply: PropTypes.func,
    resolve: PropTypes.func,
    scenes: PropTypes.array,
    session: PropTypes.object,
    unresolve: PropTypes.func,
}

Comments.displayName = 'Comments'

export default Comments
