import * as types from '../constants/ActionTypes'

export function returnTo(state = null, action) {
    switch (action.type) {
        case types.SET_RETURN_TO:
            return action.payload
        default:
            return state
    }
}

export function showPaintCanvas(state = false, action) {
    switch (action.type) {
        case types.TOGGLE_PAINT:
            return !state
        default:
            return state
    }
}

export function savingPaint(state = false, action) {
    switch (action.type) {
        case types.SAVE_SCENE_ASSETS:
            return true
        case types.FETCH_PROJECT_ALBUM_RESP:
            return false
        default:
            return state
    }
}

export function productPlans(state = {}, { type, payload }) {
    switch (type) {
        case types.FETCH_PLANS_RESP:
            return payload
        default:
            return state
    }
}

export function toolbar(state = { activeTab: 'textareas' }, action) {
    switch (action.type) {
        case types.ACTIVATE_TOOLBAR_TAB:
            return { ...state, activeTab: action.payload }
        default:
            return state
    }
}
