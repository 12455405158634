import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchProjectById } from '../../actions/ProjectActions'

import ProjectCreate from '../../components/projects/projectCreate'
import Loader from '../../components/common/loader'

const ProjectDuplicateContainer = ({
    history,
    project,
    currentFolder,
    projectId,
    fetchProjectById,
}) => {
    if (!currentFolder?.folderId) {
        history.replace({ pathname: '/projects' })
    }

    React.useEffect(() => {
        if (!project) {
            fetchProjectById(projectId)
        }
    }, [])

    if (!project) {
        return (
            <div className="custom-loader-container">
                <Loader message="Loading..." style={{ top: '100px' }} />
            </div>
        )
    }
    const projectTitle = project ? project.title : ''
    return (
        <ProjectCreate
            duplicate
            initialValues={{ title: `${projectTitle} (Copy)` }}
            currentFolderId={currentFolder?.folderId}
        />
    )
}

ProjectDuplicateContainer.propTypes = {
    createProject: PropTypes.func,
    onCreate: PropTypes.func,
    router: PropTypes.object,
    project: PropTypes.object,
    returnTo: PropTypes.string,
    scene: PropTypes.object,
}

export default withRouter(
    connect(
        (state, ownProps) => {
            const {
                projectFilter,
                project,
                projects,
                session,
                user: userState,
                currentFolder,
            } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            let projectId = project?.id
            if (!projectId && ownProps?.match?.params?.projectId) {
                projectId = ownProps?.match?.params?.projectId
            }
            return {
                currentFolder,
                projectFilter,
                project,
                projects,
                projectId,
                session,
                user,
            }
        },
        {
            fetchProjectById,
        },
    )(ProjectDuplicateContainer),
)
