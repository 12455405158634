// request animation frame polyfill
import '../utils/rAF'
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { ScrollManager } from 'react-scroll-manager'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import jQuery from 'jquery'

import { PrivateRoute } from '../routes'
import config from '../config'
import Application from '../components/Application'
import actions from '../actions'

import VerifyEmailInterstitial from '../components/MyAccount/VerifyEmailInterstitial'
import TrialExpired from '../components/MyAccount/TrialExpired'
import ProjectLocked from '../components/projects/ProjectLocked'
import LeaveProject from '../components/project/leaveProject'

import {
    Folders,
    PastDue,
    MyAccount,
    ChangeEmail,
    ChangePassword,
    Project,
    ProjectCreate,
    ProjectPreview,
    PublicProjectPreview,
    ProjectDelete,
    Authenticate,
    Invite,
    Billing,
    ProjectDuplicate,
    PrintModal,
    PublicProject,
    DeleteSceneConfirm,
    TeamPlan,
    ChangeTeamName,
    CancelSubscription,
    UpdateCard,
    BillingSuccess,
    DeleteAccount,
    CreateFolder,
    DeleteFolder,
    RenameFolder,
    MoveToFolderList,
} from './'

import ProjectDupliateDelayedNotice from '../components/projects/ProjectDupliateDelayedNotice'

window.$ = jQuery
window.$APP = {}
// window.$APP.config = config

// Constants
// ----------------

const AUTH_REGEX = /authenticate/
const PUBLIC_REGEX = /public-projects/

// fns
// ----------------

function readSessionFromCookie() {
    let data = localStorage.getItem(config.sessionCookie)
    let parsedData
    try {
        parsedData = JSON.parse(data)
    } catch (e) {
        return null
    }
    return parsedData
}

// ApplicationContainer Module
// ----------------

class ApplicationContainer extends Component {
    messageHubRef = React.createRef()
    constructor(props) {
        super(props)
        // bindings
        this.previousLocation = this.props.location
        // hack to allow sagas to route using the Routers history, not the dom.
        window.sagaHistory = this.props.history
    }

    componentDidMount() {
        // configure stripe
        // window.onload = () => {
        //     if (window.Stripe) {
        //         window.Stripe(config.stripeKey)
        //     }
        // }
        // Load google api at the global level
        window.onload = () => {
            if (window.gapi) {
                window.gapi.load('auth2', () => window.gapi.auth2.init())
            }
        }

        this.unlisten = this.props.history.listen(location => {
            this.props.routeLocationDidUpdate(location)
        })

        if (
            this.props.history.location.pathname !== '/' &&
            !AUTH_REGEX.test(this.props.history.location.pathname) &&
            !PUBLIC_REGEX.test(this.props.history.location.pathname)
        ) {
            this.props.fetchUser()
        }
    }

    componentWillUpdate(nextProps) {
        let { location } = this.props
        // set previousLocation if props.location is not modal
        if (nextProps.history.action !== 'POP' && (!location.state || !location.state.modal)) {
            this.previousLocation = this.props.location
        }
    }

    componentWillUnmount() {
        this.unlisten()
    }

    render() {
        let { location } = this.props

        let isModal = !!(
            location.state &&
            location.state.modal &&
            this.previousLocation !== location
        )

        return (
            <ScrollManager history={this.props.history}>
                <Application
                    {...this.props}
                    hasSession={!!this.props.session && !!readSessionFromCookie()}>
                    <div>
                        <Switch location={isModal ? this.previousLocation : location}>
                            <Route
                                exact
                                path="/"
                                render={() => (
                                    <Redirect
                                        to={
                                            this.props.session
                                                ? '/projects'
                                                : '/authenticate/signin'
                                        }
                                    />
                                )}
                            />

                            <PrivateRoute exact path="/projects" component={Folders} />
                            <PrivateRoute
                                path="/projects/:projectId/duplicate-delayed"
                                component={ProjectDupliateDelayedNotice}
                            />
                            <PrivateRoute path="/projects/:projectId" component={Project} />

                            <PrivateRoute path="/pastdue" component={PastDue} />

                            <Route path="/public-projects/:projectId" component={PublicProject} />

                            <Route path="/authenticate" component={Authenticate} />
                        </Switch>
                        <Switch>
                            <PrivateRoute exact path="/trial-expired" component={TrialExpired} />
                            <PrivateRoute exact path="/project-locked" component={ProjectLocked} />
                            <PrivateRoute
                                exact
                                path="/cancel-subscription"
                                component={CancelSubscription}
                            />

                            <Route
                                path="/public-projects/:projectId/preview"
                                component={PublicProjectPreview}
                            />

                            <PrivateRoute exact path="/folders/create" component={CreateFolder} />
                            <PrivateRoute exact path="/folders/rename" component={RenameFolder} />
                            <PrivateRoute exact path="/folders/delete" component={DeleteFolder} />

                            <PrivateRoute exact path="/profile" component={MyAccount} />
                            <PrivateRoute exact path="/profile/email" component={ChangeEmail} />
                            <PrivateRoute
                                exact
                                path="/profile/password"
                                component={ChangePassword}
                            />
                            <PrivateRoute
                                exact
                                path="/profile/verify-email"
                                component={VerifyEmailInterstitial}
                            />
                            <PrivateRoute
                                exact
                                path="/profile/verify-email-warning"
                                component={VerifyEmailInterstitial}
                            />
                            <PrivateRoute
                                exact
                                path="/profile/delete-account"
                                component={DeleteAccount}
                            />

                            <PrivateRoute exact path="/projects/create" component={ProjectCreate} />
                            <PrivateRoute
                                exact
                                path="/projects/move"
                                component={MoveToFolderList}
                            />
                            <PrivateRoute path="/projects/:projectId/invite" component={Invite} />
                            <PrivateRoute
                                path="/projects/:projectId/leave-project"
                                component={LeaveProject}
                            />
                            <PrivateRoute
                                path="/projects/:projectId/duplicate"
                                component={ProjectDuplicate}
                            />
                            <PrivateRoute
                                path="/projects/:projectId/preview"
                                component={ProjectPreview}
                            />
                            <PrivateRoute
                                path="/projects/:projectId/print"
                                component={PrintModal}
                            />

                            <PrivateRoute
                                path="/projects/delete/:projectId"
                                component={ProjectDelete}
                            />
                            <PrivateRoute
                                path="/projects/:projectId/scenes/:sceneId/delete"
                                component={DeleteSceneConfirm}
                            />

                            <PrivateRoute exact path="/teamPlan" component={TeamPlan} />
                            <PrivateRoute exact path="/teamPlan/name" component={ChangeTeamName} />

                            <PrivateRoute exact path="/billing" component={Billing} />
                            <PrivateRoute exact path="/billing/card" component={UpdateCard} />
                            <PrivateRoute
                                exact
                                path="/billing/success"
                                component={BillingSuccess}
                            />
                        </Switch>
                    </div>
                </Application>
            </ScrollManager>
        )
    }
}

ApplicationContainer.propTypes = {
    add: PropTypes.func,
    location: PropTypes.object,
    user: PropTypes.object,
    session: PropTypes.object,
    redirect: PropTypes.func,
    fetchUser: PropTypes.func,
}

export default withRouter(
    connect(
        state => {
            window.$APP.state = state
            window.$APP.session = null
            return state
        },
        {
            ...actions.global,
            ...actions.session,
        },
    )(ApplicationContainer),
)
