import React from 'react'

import { StyledInput } from './styled'

const CommonStyledInput = React.forwardRef(({ type = 'text', label, ...props }, ref) => (
    <StyledInput>
        <input ref={ref} type={type} {...props} />
        {label && <label>{label}</label>}
    </StyledInput>
))

export default CommonStyledInput
