import styled, { css } from 'styled-components'
import CommonStyledButton from '../common/styled/StyledButton'
import CommonStyledInput from '../common/styled/StyledInput'
import CommonStyledLink from '../common/styled/StyledLink'

export const StyledInput = styled(CommonStyledInput)`
    ${props =>
        props.textLeft &&
        css`
            text-align: left;
        `}
`
export const StyledButton = styled(CommonStyledButton)``
export const StyledLink = styled(CommonStyledLink)``

export const StyledLabel = styled.label`
    right: 0;
    bottom: 0;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 0.7em;
    position: absolute;
    text-align: right;
    color: #686f75;
    text-transform: uppercase;
    font-weight: bold;
`

export const PaymentButton = styled(CommonStyledButton)`
    background: ${props => props.theme.color.plot_green};
    line-height: unset;
    padding: 10px 20px;
    font-weight: normal;
    color: #fff;
    span {
        &:first-child {
            text-transform: uppercase;
        }
        display: block;
    }
    .price {
        font-weight: bold;
        text-transform: lowercase;
        font-size: large;
    }
`

export const AccountDetailsWrapper = styled.div`
    padding: 0 26px;
    display: grid;
    grid-template-columns: 100px 90%;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    color: #fff;
    i {
        justify-self: end;
    }
    div {
        justify-self: start;
    }
`

export const MessagesWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
`

export const MessageContent = styled.div`
    margin-top: 15px;
    color: ${props => props.theme.color.plot_red};
    ${props =>
        props.success &&
        css`
            color: ${props.theme.color.plot_green};
        `}
`
