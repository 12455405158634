import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import actions from '../../../actions'

import { CheckoutForm } from './CheckoutForm'

export default withRouter(
    connect(
        state => {
            let { session, user: userState, subscriptionPlanInfo } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            return {
                user,
                subscriptionPlanInfo,
                subscriptionId: user
                    ? user.paymentGateway
                        ? user.paymentGateway.subscriptionId
                        : null
                    : null,
            }
        },
        {
            ...actions.session,
        },
    )(CheckoutForm),
)
