import PropTypes from 'prop-types'
import React from 'react'
import { scrollToScene } from '../utils/scene'
import TextArea from './customTextArea'
import SceneNumber from './scene/SceneNumber'

const VOICE_OVER = 'voiceOver'
export default class Script extends React.Component {
    static propTypes = {
        activeScene: PropTypes.object,
        printing: PropTypes.bool,
        project: PropTypes.object,
        scenes: PropTypes.array,
    }

    handleFocus = sceneId => () => {
        this.props.changeSceneScriptFocus('tag')
    }
    handleClick = sceneId => () => {
        scrollToScene(sceneId)
    }

    render() {
        const { activeScene, scenes, ...rest } = this.props
        if (!Array.isArray(scenes) || !scenes.length) {
            return null
        }

        return (
            <div className="toolbar-script wrapper-md">
                {scenes.map((scene, index) => {
                    return this.props.printing ? (
                        <div key={scene.id} className="print-script">
                            <SceneNumber number={index + 1} />
                            {scene[VOICE_OVER]}
                        </div>
                    ) : (
                        <span
                            key={`printing-${scene['id']}`}
                            data-script-sceneid={scene.id}
                            onClick={this.handleClick(scene.id)}>
                            <TextArea
                                field="voiceOver"
                                focusedScriptType={this.props.focusedScriptType}
                                onFocus={this.handleFocus(scene.id)}
                                focusOnActive={true}
                                isActive={activeScene ? activeScene.id === scene.id : false}
                                key={`printing-${scene['id']}`}
                                scene={scene}
                                tag={true}
                                {...rest}
                                sceneNumber={index + 1}
                            />
                        </span>
                    )
                })}
            </div>
        )
    }
}
