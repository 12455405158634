import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateTeamPlan } from '../../actions/TeamAction';
import ChangeTeamName from './ChangeTeamName';

export default withRouter(
    connect(
        state => {
            let { session, user: userState, team } = state;
            const user =
                userState && userState.loading
                    ? session.user || session
                    : userState.user;
            return { user, userLoading: userState.loading, teamInfo: team };
        },
        {
            updateTeamPlan,
        }
    )(ChangeTeamName)
);
