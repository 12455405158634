import PropTypes from 'prop-types'
import * as R from 'ramda'
import { withRouter } from 'react-router'
import React from 'react'
import { connect } from 'react-redux'

import Projects from './'
import { has, PERMISSIONS, isTrialExpired } from '../../utils/permissions'
import { escapeSpecialChars } from '../../utils'
import { reorderStoryboards } from '../../actions/ProjectsActions'

const owner = R.compose(has(PERMISSIONS.OWNER), R.prop('permissions'))

const notOwner = R.compose(R.not, has(PERMISSIONS.OWNER), R.prop('permissions'))

const filters = {
    'Show All': projects => R.filter(R.identity, projects),
    'My Projects': projects => R.filter(owner, projects),
    'Shared With Me': projects => R.filter(notOwner, projects),
}

const StoryboardsContainer = ({
    reorderStoryboards,
    projectFolderWidth,
    storyBoards,
    projectFilter,
    search,
    user,
    showTrialExpire,
    currentFolder,
    history,
    showProjectFolder,
    isExtraSmallDevice,
}) => {
    const leftProjectId = history.location.state?.leaveProjectId
    let storyboards
    if (!storyBoards) {
        storyboards = null
    } else {
        if (leftProjectId) {
            storyboards = R.filter(storyboard => storyboard.id != leftProjectId)(storyBoards)
        }
        storyboards = filters[projectFilter](storyBoards)
    }
    // add ownerName to storyBoards
    storyboards =
        storyboards &&
        user &&
        R.map(storyboard => {
            return {
                ...storyboard,
                ownerName: storyboard.ownerName || `${user.firstName} ${user.lastName}`,
            }
        })(storyboards)
    // filter by search value
    storyboards =
        storyboards &&
        R.filter(storyboard =>
            new RegExp(escapeSpecialChars(search), 'ig').test(
                `${storyboard.ownerName} ${storyboard.title}`,
            ),
        )(storyboards)

    return (
        <Projects
            isExtraSmallDevice={isExtraSmallDevice}
            showProjectFolder={showProjectFolder}
            projectFolderWidth={projectFolderWidth}
            search={search}
            currentFolder={currentFolder}
            user={user}
            reorderStoryboards={reorderStoryboards}
            storyBoards={storyboards}
            trialExpired={isTrialExpired(user)}
        />
    )
}

StoryboardsContainer.propTypes = {
    reorderStoryboards: PropTypes.func,
    // projectFilter: PropTypes.string,
    storyBoards: PropTypes.array,
    search: PropTypes.string,
}

export default withRouter(
    connect(
        R.pick([
            'reorderStoryboards',
            'projectFolderWidth',
            'storyBoards',
            'projectFilter',
            'search',
            'user',
            'showTrialExpire',
            'currentFolder',
            'showProjectFolder',
            'isExtraSmallDevice',
        ]),
        {
            reorderStoryboards,
        },
    )(React.memo(StoryboardsContainer)),
)
