import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as R from 'ramda'

import CancelSubscription from './CancelSubscription'
import { cancelSubscription } from '../../actions/SessionActions'
import { leaveTeamPlan } from '../../actions/TeamAction'

const PLAN_MONTHLY = 'monthly'
const PLAN_YEARLY = 'yearly'

const teamRole = (user, role) => R.path(['team', 'role'], user) === role
const oldSubscriptionPlan = [PLAN_MONTHLY, PLAN_YEARLY]

export default withRouter(
    connect(
        state => {
            let { session, user: userState, team } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            return {
                teamInfo: team,
                hasTeam: Boolean(user.team),
                isTeamOwner: user && teamRole(user, 'OWNER'),
                isOldPlanSubscriber: R.includes(R.prop('paymentCycle', user), oldSubscriptionPlan),
            }
        },
        {
            cancelSubscription,
            leaveTeamPlan,
        },
    )(CancelSubscription),
)
