import styled, { css } from 'styled-components'

import CommonStyledButton from '../common/styled/StyledButton'
import CommonStyledInput from '../common/styled/StyledInput'
import CommonStyledLink from '../common/styled/StyledLink'

export const StyledButton = styled(CommonStyledButton)``
export const StyledInput = styled(CommonStyledInput)``
export const StyledLink = styled(CommonStyledLink)``

// Index

export const ProjectsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const StyledProjectsContainer = styled.div`
    position: relative;
`

export const StyledProjectsBody = styled.div`
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
`

// Index End

// ProjectFolder
export const ProjectFolderWrapper = styled.div`
    background: #fff;
    height: calc(100vh - 80px);
    min-width: 350px;
    // width: 350px;
    position: relative;
    z-index: 1001;

    @media (max-width: 1250px) {
        min-width: 300px;
    }

    @media (max-width: 768px) {
        min-width: 50px;
        position: absolute;
    }

    ${props =>
        props.isMobileView &&
        !props.showProjectFolder &&
        css`
            width: 50px;
        `}
    ${props =>
        props.isMobileView &&
        props.showProjectFolder &&
        css`
            position: fixed;
            width: 300px;
        `}
`

export const ProjectFolderHamburgerMenu = styled.div`
    position: absolute;
    top: 40px;
    right: 10px;
`

export const NewProjectFolderButton = styled(CommonStyledLink)`
    padding: 0 20px;
    // margin-top: 80px;
    margin-left: -30px;

    &:focus {
        color: #fff;
        outline: none;
    }

    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
            ${!props.primary &&
                !props.danger &&
                !props.modalTransparentBtn &&
                css`
                    background-color: grey !important;
                `}
            color: #fff;
            opacity: 0.65;
            &:hover {
                ${!props.primary &&
                    !props.danger &&
                    !props.modalTransparentBtn &&
                    css`
                        background-color: grey !important;
                    `}
                cursor: not-allowed;
                box-shadow: none;
            }
        `}
`
// ProjectFolder

// ProjectFolderList
export const ProjectFolderListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);
    overflow-y: scroll;

    h3 {
        text-align: center;
    }
`

export const FolderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${props =>
        props.active &&
        css`
            background-color: #e2e2e2;
        `}
    margin-top: 20px;
    margin-right: 5px;
    border-radius: 0 15px 15px 0;

    .folder-wrapper__folderIcon {
        padding: 0 10px 0 10px;

        @media (max-width: 1250px) {
            padding: 0 5px 0 5px;
        }
    }

    .folder-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px;
        min-width: 200px;
    }

    .folder-wrapper__info {
        width: 100%;
        max-width: 150px;
        min-width: 100px;
        padding-right: 10px;
        cursor: pointer;
        p {
            padding: 0;
            margin: 0;
        }

        @media (max-width: 1250px) {
            padding-right: 20px;
        }
    }

    .hamburger__menu {
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-left: 20px;
    }
    .project-dropdown {
        padding-right: 20px;
        padding-left: 20px;

        @media (max-width: 1250px) {
            padding-left: 0px;
            padding-right: 0px;
            left: -10px;
        }
    }
`
// ProjectFolderList
