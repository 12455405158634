import React from 'react'
import variables from '../resources/css/variables'

export default ({
    fill = variables.plotRed,
    enableBackground = 'new 0 0 100 100',
    className,
    ...style
}) => (
    <svg
        height="125px"
        width="125px"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 2"
        viewBox="0 0 64 64"
        x="0px"
        y="0px"
        style={{ fill, enableBackground, ...style }}
        className={className}>
        <path d="M35.46,9.23a3.99293,3.99293,0,0,0-6.92,0L4.55,50.76A4.00128,4.00128,0,0,0,8.01,56.77H55.99A4.00128,4.00128,0,0,0,59.45,50.76ZM32,49.58a2.155,2.155,0,1,1,2.16-2.15A2.15518,2.15518,0,0,1,32,49.58Zm2.16-7.55H29.84l-.8311-18.32h5.98214Z"></path>
    </svg>
)
