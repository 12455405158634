import * as types from '../constants/ActionTypes'
import { combineReducers } from 'redux'
import * as helpers from './helpers'

function discount(state = null, { type, payload }) {
    switch (type) {
        case types.APPLY_COUPON_RESP:
            return payload.discount || null
        case types.UPDATE_ROUTE:
            return helpers.billingView(payload.pathname) ||
                helpers.billingSuccessView(payload.pathname)
                ? payload.discount || state
                : null
        default:
            return state
    }
}

function id(state = null, { type, payload }) {
    switch (type) {
        case types.APPLY_COUPON_RESP:
            return payload.id || null
        case types.UPDATE_ROUTE:
            return helpers.billingView(payload.pathname) ||
                helpers.billingSuccessView(payload.pathname)
                ? payload.id || state
                : null
        default:
            return state
    }
}

function applying(state = false, { type }) {
    switch (type) {
        case types.APPLY_COUPON:
            return true
        case types.APPLY_COUPON_RESP:
        case types.UPDATE_ROUTE:
            return false
        default:
            return state
    }
}

function valid(state = null, { type, payload }) {
    switch (type) {
        case types.APPLY_COUPON_RESP:
            return payload.valid
        case types.UPDATE_ROUTE:
            return helpers.billingView(payload.pathname) ||
                helpers.billingSuccessView(payload.pathname)
                ? payload.valid || state
                : null
        default:
            return state
    }
}

function message(state = null, { type, payload }) {
    switch (type) {
        case types.APPLY_COUPON_RESP:
            return payload.message
        case types.UPDATE_ROUTE:
            return helpers.billingView(payload.pathname) ||
                helpers.billingSuccessView(payload.pathname)
                ? payload.message || state
                : null
        default:
            return state
    }
}

export default combineReducers({
    id,
    applying,
    discount,
    valid,
    message,
})
