import React from 'react';

import * as S from './styled';

const CommonStyledButton = React.forwardRef(({ children, ...props }, ref) => {
    return (
        <S.StyledButton ref={ref} {...props}>
            {children}
        </S.StyledButton>
    );
});

export default CommonStyledButton;
