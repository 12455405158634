import React, { useRef, useState, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { connect } from 'react-redux'

import { reorderFoldersAction } from '../../actions/FolderActions'

import HamburgerMenu from '../../icons/HamburgerMenu'
import DropdownMenu from '../Dropdown'
import FolderIcon from '../../icons/FolderIcon'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import { ProjectFolderListWrapper, FolderWrapper } from './styled'
import ItemTypes from '../../constants/ItemTypes'
import Loader from '../common/loader'

const Folder = ({ index, handleFolderClick, folder, active, moveFolders, isMobile }) => {
    const dragRef = useRef(null)
    const [{ handlerId, isOver, canDrop }, drop] = useDrop({
        accept: ItemTypes.FOLDER,
        drop: (item, monitor) => {
            // do something on drop!!!!
            console.log({ run: 'when finally dropped here', item })
        },
        collect: monitor => ({
            handlerId: monitor.getHandlerId(),
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        // Override monitor.canDrop() function
        canDrop: folder => {
            // console.log({formFolder: 'from folder', folder})
        },
        hover: (item, monitor) => {
            if (!dragRef.current || isMobile) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dragRef.current?.parentElement?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveFolders(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const getBackgroundColor = () => {
        return ''
        if (isOver) {
            if (canDrop) {
                return 'rgb(188,251,255)'
            } else if (!canDrop) {
                return 'rgb(255,188,188)'
            }
        } else {
            return ''
        }
    }
    const [{ isDragging }, drag, preview] = useDrag({
        // type: ItemTypes.FOLDER,
        type: ItemTypes.FOLDER,
        item: () => {
            handleFolderClick(folder)
            return { index, folder, type: ItemTypes.FOLDER }
        },
        // start: (item, monitor) => {
        //     console.log('start', monitor)
        // },
        // end: (item, monitor) => {
        //     const dropResult = monitor.getDropResult()

        //     if (dropResult) {
        //         // console.log(dropResult)
        //         //   const { name } = dropResult;
        //         //   const { DO_IT, IN_PROGRESS, AWAITING_REVIEW, DONE } = COLUMN_NAMES;
        //         //   switch (name) {
        //         //     case IN_PROGRESS:
        //         //       changeItemColumn(item, IN_PROGRESS);
        //         //       break;
        //         //     case AWAITING_REVIEW:
        //         //       changeItemColumn(item, AWAITING_REVIEW);
        //         //       break;
        //         //     case DONE:
        //         //       changeItemColumn(item, DONE);
        //         //       break;
        //         //     case DO_IT:
        //         //       changeItemColumn(item, DO_IT);
        //         //       break;
        //         //     default:
        //         //       break;
        //         //   }
        //     }
        // },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const dragStyle = {
        opacity: 0.3,
        border: '1px solid green',
        background: 'purple',
        backgroundColor: getBackgroundColor(),
    }
    const allowOptions = folder && folder?.folderId

    drag(drop(dragRef))
    const ITEMS = [
        {
            value: 'Rename',
            Icon: EditIcon,
            to: {
                pathname: `/folders/rename`,
                state: {
                    modal: true,
                    closeable: true,
                    folderId: folder.folderId,
                    userFoldersId: folder.id,
                },
            },
        },
        // {
        //     value: 'Share',
        //     Icon: FolderIcon,
        //     to: {
        //         pathname: `/folders/share`,
        //         state: {
        //             modal: true,
        //             closeable: true,
        //             folderId: folder.folderId,
        //             userFoldersId: folder.id,
        //         },
        //     },
        // },
        {
            value: 'Delete',
            Icon: DeleteIcon,
            style: { width: '1.2em', marginTop: '-2px', marginRight: '.7em' },
            to: {
                pathname: `/folders/delete`,
                state: {
                    modal: true,
                    closeable: true,
                    folderId: folder.folderId,
                    userFoldersId: folder.id,
                },
            },
        },
    ]
    let setStyle = isDragging ? { ...dragStyle } : {}
    setStyle = {
        ...setStyle,
        justifyContent: allowOptions || isMobile ? 'space-between' : 'center',
    }
    return (
        <FolderWrapper
            active={active}
            style={{ ...setStyle }}
            ref={preview}
            data-handler-id={handlerId}
            // onClick={() => handleFolderClick(folder)}
            // style={{ opacity: opacity, backgroundColor: getBackgroundColor() }}
        >
            {allowOptions && !isMobile && (
                <div
                    ref={folder?.folderId ? dragRef : null}
                    className={`${folder?.folderId ? 'cursor-move' : ''} hamburger__menu`}>
                    <HamburgerMenu size="30px" fill="#9fa3ae" viewBox="0 10 35 5" />
                </div>
            )}
            <div className="folder-info">
                <div className="folder-wrapper__folderIcon">
                    <FolderIcon
                        fill="black"
                        style={{ width: '50px', height: '50px' }}
                        viewBox="0 -2 28 28"
                    />
                </div>
                <div className="folder-wrapper__info" onClick={() => handleFolderClick(folder)}>
                    <p
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: 'black',
                        }}>
                        {folder.folderName}
                    </p>
                    <p>{folder?.storyboards?.length} storyboards</p>
                </div>
            </div>
            {allowOptions && (
                <DropdownMenu
                    items={ITEMS}
                    style={{ visibility: !active ? 'hidden' : '' }}
                    className="project-dropdown project-header-dropdown"
                    dropdownButtonStyle="project-header-dropdown__btn"
                    dropdownBodyWrapperStyle="project-dropdown__body-wrapper m-l--180"
                    dropdownBodyStyle="project-dropdown__body"
                />
            )}
        </FolderWrapper>
    )
}

const ProjectFolderList = ({
    folders,
    handleFolderClick,
    currentFolder,
    reorderFoldersAction,
    isMobile,
}) => {
    const [items, setItems] = useState(folders)

    const getReorderedIds = items => {
        const ids = []
        items.forEach(item => {
            if (item?.folderId) {
                ids.push(item.id)
            }
        })
        return ids
    }

    useEffect(() => {
        setItems(folders)
    }, [folders])

    // when the folder is finally dropped, run this
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ItemTypes.FOLDER,

        // collect: monitor => ({
        //     isOver: monitor.isOver(),
        //     canDrop: monitor.canDrop(),
        // }),
        // Override monitor.canDrop() function
        canDrop: item => {
            // console.log({canDrop: 'canDrop', item })
            return true
        },
        drop: (item, monitor) => {
            // do something on drop!!!!
            // console.log({ run: 'when finally dropped here', item })
            if (item['folder']['id'] === folders[item['index']]['id']) {
                return
            }
            const foldersOrder = getReorderedIds(items)
            reorderFoldersAction({ items, foldersOrder, folders })
        },
    })

    const moveFolders = (dragIndex, hoverIndex) => {
        const dragItem = items[dragIndex]

        if (dragItem) {
            setItems(prevState => {
                // console.log(prevState)
                const coppiedStateArray = [...prevState]
                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem)
                // remove item by "dragIndex" and put "prevItem" Instead
                coppiedStateArray.splice(dragIndex, 1, prevItem[0])
                return coppiedStateArray
            })
        }
    }

    return (
        <ProjectFolderListWrapper ref={drop}>
            {items ? (
                items.length ? (
                    items.map((folder, i) => (
                        <Folder
                            key={folder.id}
                            index={i}
                            isMobile={isMobile}
                            moveFolders={moveFolders}
                            active={folder?.id === currentFolder?.id}
                            handleFolderClick={handleFolderClick}
                            folder={folder}
                        />
                    ))
                ) : (
                    <h3 style={isMobile ? { padding: '10px 20px', fontSize: '24px' } : {}}>
                        Create new folder
                    </h3>
                )
            ) : (
                <Loader message="Loading Folders" style={{ width: '100%' }} />
            )}
        </ProjectFolderListWrapper>
    )
}

export default connect(
    (state, ownProps) => {
        return {
            ...ownProps,
            ...state,
        }
    },
    {
        reorderFoldersAction,
    },
)(ProjectFolderList)
