import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { has, PERMISSIONS } from '../../../utils/permissions'

import Dropdown from '../../Dropdown'
import EditIcon from '../../../icons/EditIcon'
import CommentIcon from '../../../icons/CommentIcon'

// Fn's
// ---

const memberToUser = R.compose(R.prop('email'), R.prop('user'))

const sortedMembers = R.sort(memberToUser)

const permissionsFromMember = m => ({
    comment: has(PERMISSIONS.COMMENT, m.permissions),
    edit: has(PERMISSIONS.EDIT, m.permissions),
    owner: has(PERMISSIONS.OWNER, m.permissions),
    value: m.permissions.toString(),
})

// Component
// ---

class members extends React.Component {
    constructor(props) {
        super(props)
        this.renderRow = this.renderRow.bind(this)
        this.state = {
            memberRoleMap: {},
        }
    }

    ITEMS = [
        {
            name: 'Can Edit',
            value: 6,
            Icon: EditIcon,
        },
        {
            name: 'Can Comment',
            value: 4,
            Icon: CommentIcon,
        },
    ]

    roleChanged = (value, member) => {
        let permissionValue = parseInt(value, 10)
        let payload = {
            permissions: permissionValue,
            member,
        }
        this.props.updateMemberPermission(payload)
        this.setState(({ memberRoleMap }) => ({
            memberRoleMap: {
                ...memberRoleMap,
                [member.user.id]: permissionValue,
            },
        }))
    }

    renderRow(member) {
        let { owner, comment, edit, value } = permissionsFromMember(member)

        // Only project owners can manage members:
        let readOnly = !this.props.permissions?.owner

        if (readOnly || owner) {
            return (
                <div className="clearfix m-b-sm" key={member.user.id}>
                    <div className="pull-left text-white">
                        <span className="us-all">{member.user.email}</span>
                    </div>
                    <div className="pull-right m-r">
                        {owner && <span>OWNER</span>}
                        {!owner && edit && <span>CAN EDIT</span>}
                        {!edit && comment && <span>CAN COMMENT</span>}
                    </div>
                </div>
            )
        }

        const items = this.ITEMS.map(item => ({
            Icon: item.Icon,
            name: item.name,
            value: item.value,
            onClick: () => this.roleChanged(item.value, member),
        }))

        const selectedItem = items.find(item => parseInt(item.value, 10) === parseInt(value, 10))

        return (
            <div className="clearfix m-b-sm" key={member.user.id}>
                <div className="pull-left text-white">
                    <span style={{ verticalAlign: 'sub' }}>{member.user.email}</span>
                    {this.props.hasTeam &&
                        this.props.teamMembers &&
                        this.props.teamMembers.includes(member.user.email) && (
                            <span className="info-tag">Team</span>
                        )}
                </div>
                <div className="pull-right remove-column">
                    <i
                        className={`fa fa-close cp ${owner ? 'invisible' : ''}`}
                        style={{ verticalAlign: 'sub' }}
                        onClick={() => this.props.deleteMember(member)}
                    />
                </div>
                <div className="pull-right m-r">
                    <Dropdown
                        items={items}
                        showCurrent
                        selectedItem={selectedItem}
                        className="project-dropdown project-member-dropdown "
                        dropdownButtonStyle="project-member-dropdown__btn"
                        dropdownBodyWrapperStyle="project-dropdown__body-wrapper"
                        dropdownBodyStyle="project-dropdown__body"
                    />
                </div>
            </div>
        )
    }

    render() {
        let items = R.map(this.renderRow.bind(this), sortedMembers(this.props.members))

        return <div className="members m-b-md m-t-md">{items}</div>
    }
}

members.propTypes = {
    deleteMember: PropTypes.func,
    members: PropTypes.array,
    permissions: PropTypes.object,
    updateMemberPermission: PropTypes.func,
}

export default members
