import React from 'react';
import PropTypes from 'prop-types';
import SixScene from '../../icons/SixScene';
import ThreeScene from '../../icons/ThreeScene';
import NineScene from '../../icons/NineScene';
import FourScene from '../../icons/FourScene';
import OneScene from '../../icons/OneScene';
import TextOnlyScene from '../../icons/TextOnlyScene';
import className from 'classnames';

const LAYOUTS = {
  three: ThreeScene,
  six: SixScene,
  nine: NineScene,
  four: FourScene,
  one: OneScene,
  text: TextOnlyScene,
};

const PrintLayoutButton = ({ layout, selected = false, onClick }) => {
  const LayoutIcon = LAYOUTS[layout];
  return (
    <button
      className={className('plain-button', 'print-layout-button', { selected })}
      onClick={onClick}
    >
      <LayoutIcon />
    </button>
  );
};

PrintLayoutButton.propTypes = {
  layout: PropTypes.oneOf(Object.keys(LAYOUTS)).isRequired,
  selected: PropTypes.bool,
};

export default PrintLayoutButton;
