import styled, { css } from 'styled-components'

export const StyledInfoBox = styled.div`
    width: 100%;
    height: 100%;
    ${props =>
        props.fullScreen
            ? css`
                  margin: 0 auto;
                  margin-top: 10vh;
              `
            : css`
                  margin: 40px auto;
              `}
    position: relative;
    max-width: 400px;
    .info-box__content {
        display: inline-block;
        transform: translateX(-50%);
        position: relative;
        left: 50%;
    }
    .projects__null-state {
        background-color: #2a2d33;
        width: 400px;
        margin: auto;
        position: relative;
        padding: 30px;
        border-radius: 5px;

        @media (max-width: 500px) {
            max-width: 300px;
        }
    }
    .projects__null-state-title {
        color: #fff;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 0;
    }
    .projects__null-state-desc {
        color: #fff;
    }
`
