import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ProjectCreate from '../components/projects/projectCreate'

const ProjectCreateContainer = ({ history, currentFolder }) => {
    if (!currentFolder?.folderId) {
        history.replace({ pathname: '/projects' })
    }

    return <ProjectCreate currentFolderId={currentFolder?.folderId} />
}

ProjectCreateContainer.propTypes = {
    createProject: PropTypes.func,
    project: PropTypes.object,
    returnTo: PropTypes.string,
    scene: PropTypes.object,
}

export default withRouter(
    connect((state, ownProps) => {
        const { projectFilter, projects, session, user: userState, currentFolder } = state
        const user = userState && userState.loading ? session.user || session : userState.user
        return {
            currentFolder,
            currentFolderId: ownProps?.location?.state?.currentFolderId,
            projectFilter,
            projects,
            session,
            user,
        }
    })(ProjectCreateContainer),
)
