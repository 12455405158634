import React from 'react';
import PropTypes from 'prop-types';

function selectInputText(element) {
  let range = document.createRange();
  range.selectNodeContents(element);
  let sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

const MINLENGTH = 1;
const MAXLENGTH = 256;

export default class InlineEdit extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    paramName: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    validate: PropTypes.func,
    tabIndex: PropTypes.number,
  };

  static defaultProps = {
    tabIndex: 0,
  };

  state = {
    editing: false,
    text: this.props.text,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.editing && this.state.editing === nextState.editing) {
      return false;
    }
    return true;
  }

  finishEditing = event => {
    if (event.target.classList.contains('drtl')) {
      event.target.classList.remove('drtl');
    } else {
      event.target.classList.add('drtl');
    }
    if (!this.state.editing || event.target.innerText === this.props.text) {
      this.setState({ editing: false });
      this.inputRef.current.blur();
      return;
    }
    if (
      this.isInputValid(this.state.text) &&
      this.props.text !== this.state.text
    ) {
      this.commitEditing();
    } else if (
      !this.isInputValid(this.state.text) ||
      this.props.text === this.state.text
    ) {
      this.cancelEditing(event);
    }
  };

  cancelEditing = event => {
    this.setState({ editing: false });
    event.target.innerText = this.props.text;
    this.inputRef.current.blur();
  };

  commitEditing = () => {
    this.setState({ editing: false });
    let newProp = {};
    newProp[this.props.paramName] = this.state.text;
    this.props.change(newProp);
    this.inputRef.current.blur();
  };

  clickWhenEditing = e => {
    e.stopPropagation();
    if (!this.state.editing) {
      selectInputText(e.target);
    }
    this.setState({ editing: true });
  };

  isInputValid = text => {
    return text.length >= MINLENGTH && text.length <= MAXLENGTH;
  };

  keyDown = event => {
    if (event.which === 13) {
      event.preventDefault();
      this.finishEditing(event);
    } else if (event.which === 27) {
      this.cancelEditing(event);
    }
  };

  textChanged = ev => {
    if (ev.target.innerText) {
      this.setState({
        text: ev.target.innerText.trim(),
      });
    }
  };

  render() {
    const { editing } = this.state;
    const myClassName = ['project-meta-title'];
    if (editing) {
      myClassName.push('editing');
    }
    return (
      <span
        contentEditable="true"
        className={myClassName.join(' ')}
        onClick={this.clickWhenEditing}
        onKeyDown={this.keyDown}
        onBlur={this.finishEditing}
        onKeyUp={this.textChanged}
        tabIndex={this.props.tabIndex}
        suppressContentEditableWarning={true}
        ref={this.inputRef}
      >
        {this.props.text}
      </span>
    );
  }
}
