import React from 'react'
import { changePassword } from '../Auth/lib/cognito'
import { StyledButton, StyledInput } from './styled'
import Modal from '../common/Modal'

export default function ChangePassword({ history }) {
    const oldPwdRef = React.useRef()
    const pwdRef = React.useRef()
    const [isProcessing, setIsProcessing] = React.useState(false)
    const handleSubmit = e => {
        const oldPassword = oldPwdRef.current.value
        const password = pwdRef.current.value
        e.preventDefault()
        setIsProcessing(true)
        changePassword({ oldPassword, password })
            .then(() => {
                window.$N.info('Password changed successfully')
                history.push({
                    pathname: `/profile`,
                    state: { modal: true, closeable: true },
                })
                setIsProcessing(false)
            })
            .catch(e => {
                window.$N.error(`Password change failed. Reason: ${e.message}`)
                setIsProcessing(false)
            })
    }
    return (
        <Modal
            isCancelable
            isCloseable
            title="New Password"
            subtitle="What would you like your new password to be?"
            isProcessing={isProcessing}>
            <form className="content" onSubmit={handleSubmit}>
                <fieldset>
                    <div className="profile__row text m-b">
                        <StyledInput
                            ref={oldPwdRef}
                            autoFocus
                            name="current-password"
                            type="password"
                            label="current password"
                        />
                    </div>
                    <div className="profile__row text m-b">
                        <StyledInput
                            ref={pwdRef}
                            name="password"
                            type="password"
                            label="new password"
                        />
                    </div>
                </fieldset>
                <StyledButton
                    fullwidth
                    disabled={isProcessing}
                    primary
                    type="submit"
                    className="m-t-md">
                    Submit
                </StyledButton>
            </form>
        </Modal>
    )
}
