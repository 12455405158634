import * as R from 'ramda';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import FormInput from './lib/FormInput';
import SubmitButton from './lib/SubmitButton';
import { forgot, resend } from './lib/cognito';

export default withRouter(
  class CognitoForgot extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        email: '',
        errorMessage: null,
      };

      R.forEach(fn => (this[fn] = this[fn].bind(this)), []);
    }

    handleChange = e => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    canSubmit() {
      return this.state.email.length > 0;
    }

    handleForgotSubmit = e => {
      e.preventDefault();
      const { email } = this.state;
      const success = () => {
        this.props.history.push('/authenticate/reset');
      };
      forgot({ email })
        .then(success)
        // Retry with lowercase @TODO refactor pool to be case insensitive
        .catch(() => {
          return forgot({ email: email.toLowerCase() });
        })
        .then(success)
        .catch(error => {
          this.setState({ errorMessage: error.message });
        });
    };

    handleResendSubmit = e => {
      e.preventDefault();
      const success = () => {
        this.props.history.push(`/authenticate/verify/${email}`);
      };
      const { email } = this.state;
      resend({ email })
        .then(success)
        // Retry with lowercase @TODO refactor pool to be case insensitive
        .catch(() => {
          return resend({ email: email.toLowerCase() });
        })
        .then(success)
        .catch(error => {
          this.setState({ errorMessage: error.message });
        });
    };

    render() {
      return (
        <div className="registration">
          <h1 className="animated fadeIn sn signup__title">Forgot Password</h1>
          <div className="signup__desc m-b-md">
            Enter your email to have a code delivered.
          </div>

          <div className="inline-block">
            <form>
              <FormInput
                label="Email"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />

              <SubmitButton
                onClick={this.handleForgotSubmit}
                disabled={!this.canSubmit()}
              >
                Email Reset Link
              </SubmitButton>
            </form>
          </div>

          <p className="m-t-sm">
            Don't have an account? <Link to="/authenticate">Try For Free</Link>
          </p>

          <p>
            Already have an account?{' '}
            <Link to="/authenticate/signin">Log in</Link>
          </p>

          <div>
            {this.state.errorMessage ? (
              <div className="alert alert-danger">
                {JSON.stringify(this.state.errorMessage)}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
);
