import { actionChannel, take } from 'redux-saga/effects'
import { buffers } from 'redux-saga'
import config from '../../config'
import { ACTIVATE_SCENE } from '../../constants/ActionTypes'

async function trySceneImageAsBackground(scene, doc) {
    console.log('trySceneImageAsBackground')
    if (!scene.image) {
        throw new Error('no-scene-image')
    }

    const legacyScene = await doc.layers.find(layer => layer.id === 'legacyScene')
    if (!legacyScene) {
        await doc.addLayer({
            id: 'legacyScene',
            name: `My Scene`,
            type: 'clipart',
            objectFit: 'contain',
            src: `${config.s3}/${scene.image}?${new Date().getTime()}`,
            width: doc.pixelWidth,
            height: doc.pixelHeight,
            x: 0,
            y: 0,
        })
    }
}

/**
 * Create a new scene manager
 *
 * A scene manager is responsible for updating the contents of the SketchAPI
 * document when the scene is changed.
 * @return {Generator}
 */
export function* newSceneManager() {
    const chanACTIVATE_SCENE = yield actionChannel(ACTIVATE_SCENE, buffers.sliding(1))

    return function*() {
        while (true) {
            const { payload: scene } = yield take(chanACTIVATE_SCENE)
            yield updateSceneContents(scene)
        }
    }
}

/**
 * Update the SketchAPI scene
 * @param {Object} scene
 * @return {Promise}
 */
async function updateSceneContents(scene) {
    const sketch = window.sketch
    const doc = sketch.doc
    const timer = sketch.timer
    const taskOptions = { onprogress: timer.setProgress }

    if (!doc.reset) {
        return
    }
    doc.reset()

    try {
        openSketchJson()
    } catch (err) {
        try {
            await trySceneImageAsBackground(scene, doc)
        } catch (err) {
            await doc.setBackground('white', taskOptions)
        }
    }

    /** private methods **/

    function openSketchJson() {
        if (!scene.sketchJsonUrl) {
            throw new Error('no-sketch-json')
        }

        const sketchUrl = `${config.s3}/${scene.sketchJsonUrl}?${new Date().getTime()}`

        return sketch.open.json(sketchUrl, taskOptions)
    }
}
