import React from 'react';

const SubmitButton = ({ disabled, children, onClick }) => (
  <div className="row form-group">
    <button
      onClick={onClick}
      type="submit"
      className="btn bg-primary btn-submit w100"
      disabled={disabled}
    >
      {children}
    </button>
  </div>
);

export default SubmitButton;
