export default {
    collaboratorLimit_solo_month: 'no',
    collaboratorLimit_solo_year: 'no',
    collaboratorLimit_yearly: 'unlimited',
    collaboratorLimit_monthly: 'unlimited',
    collaboratorLimit_pro_month: 5,
    collaboratorLimit_pro_year: 5,
    collaboratorLimit_business_month: 'unlimited',
    collaboratorLimit_business_year: 'unlimited',
    UNLIMITED_COLLABORATORS: 'unlimited',
}
