import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import LoadingBar from 'react-redux-loading-bar'
import { ElementScroller } from 'react-scroll-manager'
import Header from '../../containers/HeaderContainer'
import MessageHub from './MessageHub'
import UploadProgress from '../UploadProgress'
import Paint from '../../sketch/components/PaintContainer'
import notification from '../../utils/notification'

const warn = (msg, err = '') => {
    let errorMsg = typeof err === 'object' ? JSON.stringify(err) : err
    msg ? window.$N.warn(`${msg}\n\n${errorMsg}`) : window.$N.warn(errorMsg)
}

const error = (msg, err = '') => {
    let errorMsg = typeof err === 'object' ? JSON.stringify(err) : err
    msg ? window.$N.error(`${msg}\n\n${errorMsg}`) : window.$N.error(errorMsg)
}

function Application(props) {
    const messageRef = React.useRef(null)
    React.useEffect(() => {
        window.$N = notification(messageRef.current)
        window.notify = { error, warn }
    })
    let { showPaintCanvas, location } = props
    let loader__style = { backgroundColor: 'rgb(10, 223, 166)', height: 3 }
    const pathClassNames = location.pathname.split('/').join('')
    if (!props.hasSession) {
        return (
            <div className={`fill ${pathClassNames}`}>
                <div className="application__header">
                    <Header {...props} public={true} />
                </div>
                <div className="application__body">
                    {props.children && React.cloneElement(props.children, {})}
                </div>
            </div>
        )
    }

    return (
        <div className={`application__root fill ${pathClassNames}`}>
            <div className="application__loader">
                <LoadingBar style={loader__style} />
            </div>
            <div className="application__header">
                <Header {...props} />
            </div>
            <div className="application__body">
                <ElementScroller scrollKey="bodyScroll">
                    <div
                        className="bodyScroll"
                        style={{ visibility: showPaintCanvas ? 'hidden' : 'visible' }}>
                        {props.children}
                    </div>
                </ElementScroller>
                {<Paint isActive={showPaintCanvas} />}
            </div>
            {!showPaintCanvas && <UploadProgress {...R.pick(['imageUploading'], props)} />}
            <MessageHub children={add => (messageRef.current = add)} />
        </div>
    )
}

Application.displayName = 'Application'

Application.propTypes = {
    children: PropTypes.object,
    hasSession: PropTypes.bool,
    location: PropTypes.object,
    session: PropTypes.object,
    setReturnTo: PropTypes.func,
    showPaintCanvas: PropTypes.bool,
}

export default Application
