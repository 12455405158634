import React from 'react';
import style from './strokeStyle';

export default () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1080 720"
    style={style.svg}
  >
    <line style={style.st0} x1="174" y1="152" x2="894" y2="152" />
    <line style={style.st0} x1="174" y1="235.6" x2="793" y2="235.6" />
    <line style={style.st0} x1="174" y1="319.2" x2="894" y2="319.2" />
    <line style={style.st0} x1="174" y1="402.8" x2="793" y2="402.8" />
    <line style={style.st0} x1="174" y1="486.4" x2="894" y2="486.4" />
    <line style={style.st0} x1="174" y1="570" x2="793" y2="570" />
  </svg>
);
