import React from 'react'
import Modal from '../common/Modal'
import { StyledButton } from './styled'

const DeleteFolder = ({ loading, onClick }) => {
    return (
        <Modal
            title="Delete Folder?"
            subtitle="Delete this folder and all project inside? This cannot be undone."
            isCloseable={!loading}
            isCancelable={!loading}>
            <StyledButton danger fullwidth disabled={loading} onClick={onClick}>
                {loading ? 'Deleting Folder...' : 'Delete'}
            </StyledButton>
        </Modal>
    )
}

export default DeleteFolder
