import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import { StyledButton } from './styled'

import PrintStep from './PrintStep'
import PrintLayoutButton from './PrintLayoutButton'

class PrintFlow extends Component {
    state = {
        step: 0,
        layout: '',
        description: '',
    }

    static propTypes = {
        onComplete: PropTypes.func.isRequired,
    }

    static defaultProps = {
        onComplete: () => {},
    }

    LayoutStep = () => (
        <PrintStep title="Choose a Layout" subtitle="How would you like your storyboard to look?">
            <div className="centerize print-layout-buttons">
                <PrintLayoutButton
                    layout="six"
                    onClick={() => this.setState({ step: 1, layout: 'six' })}
                />
                <PrintLayoutButton
                    layout="three"
                    onClick={() => this.setState({ step: 1, layout: 'three' })}
                />
                <PrintLayoutButton
                    layout="nine"
                    onClick={() => this.setState({ step: 1, layout: 'nine' })}
                />
                <PrintLayoutButton
                    layout="four"
                    onClick={() => this.setState({ step: 1, layout: 'four' })}
                />
                <PrintLayoutButton
                    layout="one"
                    onClick={() => this.setState({ step: 1, layout: 'one' })}
                />
                <PrintLayoutButton
                    layout="text"
                    onClick={() => this.setState({ step: 1, layout: 'text' })}
                />
            </div>
        </PrintStep>
    )

    DescriptionStep = () => (
        <PrintStep title="Add a Description">
            <div className="text-center">
                <div className="content">
                    <TextareaAutosize
                        onChange={({ target: { value: description } }) =>
                            this.setState({ description })
                        }
                        placeholder="Project description"
                        className="textarea outline-none w100 b r r-2x p-xs m-b dialog-modal__auto-resize-textarea"
                    />
                    <StyledButton
                        primary
                        fullwidth
                        onClick={() => {
                            this.props.onComplete({
                                layout: this.state.layout,
                                description: this.state.description.trim(),
                            })
                        }}>
                        Preview
                    </StyledButton>
                </div>
            </div>
        </PrintStep>
    )

    STEPS = [this.LayoutStep, this.DescriptionStep]

    render() {
        const Step = this.STEPS[this.state.step]
        return <Step />
    }
}

export default PrintFlow
