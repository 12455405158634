import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import ModalTip from './ModalTip'

const CommentPopover = React.forwardRef(
    ({ onClick, dismiss, setChildOpen, setHovered, onFocus }, ref) => {
        React.useEffect(() => {
            const timer = setTimeout(() => {
                ref.current.focus()
            }, 100)
            return () => clearTimeout(timer)
        }, [ref])
        const [btnActive, setBtnActive] = React.useState(false)
        let btn__style = btnActive ? 'bg-primary' : 'bg-light'
        return (
            <div className="popover-content pos-abt" id="popover-positioned-right">
                <TextareaAutosize
                    ref={ref}
                    className="outline-none w100 b r"
                    onChange={() => setBtnActive(!!ref.current.value.trim())}
                    onFocus={onFocus}
                    onKeyDown={e => {
                        if (e.key === 'Escape') {
                            setChildOpen(false)
                            setHovered(false)
                            dismiss()
                        }
                    }}
                />
                <button
                    disabled={!btnActive}
                    className={`m-t-sm btn w100 text-white font-bold ${btn__style}`}
                    onClick={onClick}>
                    Add Comment
                </button>
            </div>
        )
    },
)

class CommentOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            btnActive: false,
        }
        this.comment = React.createRef()
    }

    onClick = () => {
        let body = this.comment.current.value.trim()

        if (!body) {
            return
        }
        // Close the overlay
        this.comment.current.value = ''
        this.setState({ btnActive: false })
        this.props.createComment({ body, sceneId: this.props.scene.id })
        this.props.setChildOpen(false)
        this.props.setHovered(false)

        this.props.onCommentSubmit()
    }

    render = () => {
        return (
            <ModalTip
                presentationpreview={this.props.presentationpreview}
                modalTipName="commentmodal"
                render={({ dismiss }) => (
                    <CommentPopover
                        ref={this.comment}
                        dismiss={dismiss}
                        setChildOpen={this.props.setChildOpen}
                        setHovered={this.props.setHovered}
                        onFocus={() => this.props.changeSceneScriptFocus('initial')}
                        onClick={() => {
                            this.onClick()
                            dismiss()
                        }}
                    />
                )}
                className={`${
                    this.props.presentationpreview ? 'presentationComment' : ''
                } comment-popover-modaltip`}>
                {this.props.children}
            </ModalTip>
        )
    }
}

CommentOverlay.propTypes = {
    children: PropTypes.object,
    close: PropTypes.func,
    container: PropTypes.object,
    placement: PropTypes.string,
    scene: PropTypes.object,
    createComment: PropTypes.func,
    submittingComment: PropTypes.bool,
    onCommentSubmit: PropTypes.func,
}

CommentOverlay.displayName = 'CommentOverlay'

export default CommentOverlay
