import { eventChannel } from 'redux-saga';
import { actionChannel, fork, put, take } from 'redux-saga/effects';
import plotConfig from '../config';
import {
  TOGGLE_PAINT,
  UPDATE_PAINT_ACTION_STATE,
} from '../constants/ActionTypes';
import { version } from '../sketchFile.json';
import appConfig from './config/App.json';
import { newSceneManager } from './sagaActions/sceneManager';
import { sceneSaver } from './sagaActions/sceneSaver';
import { statusManager } from './sagaActions/statusManager';
import { setupDocument, setupSketchUi } from './setup';

export function* sketchSaga() {
  const sceneManager = yield newSceneManager();

  // 1. The Sketch API startup process!
  const chanSKETCH_MOUNT = yield actionChannel('SKETCH_ROOT_MOUNT');
  yield take(TOGGLE_PAINT);
  if (plotConfig.isSketchAPIServerAvailable) {
    addJs(
      `http://${window.location.hostname}:1338/?${appConfig.api.join('&')}`
    );
  } else {
    addJs(`${plotConfig.uiBucketPrefix}/sketch/babelHelpers.js?${version}`);
    addJs(
      `${plotConfig.uiBucketPrefix}/sketch/min/sketch-api.min.js?${version}`
    );
    addCss(
      `${plotConfig.uiBucketPrefix}/sketch/min/sketch-api.min.css?${version}`
    );
  }

  // 2. Create a document and build a UI
  yield take(chanSKETCH_MOUNT);
  yield setupDocument();
  yield setupSketchUi();

  // 3. SketchAPI <-> Plot
  yield fork(sceneManager);
  yield fork(statusManager);
  yield fork(sceneSaver);
  yield fork(paintActions);
}

function addJs(src) {
  const tag = document.createElement('script');
  tag.src = src;
  document.body.appendChild(tag);
}

function addCss(href) {
  const tag = document.createElement('link');
  tag.setAttribute('rel', 'stylesheet');
  tag.setAttribute('href', href);
  document.body.appendChild(tag);
}

function* paintActions() {
  const sketch = window.sketch;
  const events = eventChannel(emit => {
    const onShow = sketch.on('assetFinder:show', () => emit(true));
    const onHide = sketch.on('assetFinder:hide', () => emit(false));

    return () => {
      onShow.detach();
      onHide.detach();
    };
  });

  while (true) {
    const hidePaintActions = yield take(events);
    yield put({
      type: UPDATE_PAINT_ACTION_STATE,
      hidePaintActions,
    });
  }
}
