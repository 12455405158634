import swap from 'lodash-move'
import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { animated, config, useSpring, useSprings } from 'react-spring'
import { useDrag, useGesture, useScroll } from 'react-use-gesture'
import { IS_MOBILE } from '../../constants/metadata'
import useWindowDimensions from '../../utils/hooks/useWindowDimensions'
import Scene from '../scene/SceneContainer'
import * as S from './styled'
import {
    calculateYLocation,
    get2DCoordinatesFromArrayIndex,
    getArrayIndexFrom2DCoordinates,
    getColumnIndex,
    getGridConfig,
    getMaxHeightByRow,
    MIN_HEIGHT,
} from './utils'

const OFFSET_TO_CONTAINER = 91
const SCROLL_TICK = 25

window.swap = swap

let scrollInterval

const calculateDraggableStyle = ({
    order,
    down,
    windowDimensions: { width, height },
    originalIndex,
    curIndex,
    movement,
    rowHeights = [],
}) => index => {
    const { columnWidth } = getGridConfig(width, height)

    let targetIndex = order.indexOf(index)

    const { x, y, rowIndex } = get2DCoordinatesFromArrayIndex({
        index: targetIndex,
        ...getGridConfig(width, height),
    })

    const yOffset = calculateYLocation({
        rowIndex,
        rowHeights,
    })

    const baseStyles = {
        position: 'absolute',
        maxWidth: 550,
        width: columnWidth,
        minHeight: rowHeights[rowIndex] || MIN_HEIGHT,
        zIndex: 5,
        transform: `translate3d(${x}px, ${yOffset || y}px, 0) rotate(0deg) scale(1)`,
        immediate: false,
        config: config.stiff,
        opacity: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        boxSizing: 'border-box',
    }

    if (down) {
        if (
            index === originalIndex &&
            originalIndex !== order.length - 1 /* last item = add button */
        ) {
            // Being dragged
            const { x, rowIndex } = get2DCoordinatesFromArrayIndex({
                index: curIndex,
                ...getGridConfig(width, height),
            })

            const y = calculateYLocation({ rowIndex, rowHeights })

            return {
                ...baseStyles,
                zIndex: 88,
                transform: `
          translate3d(${x + movement[0]}px, ${y + movement[1]}px, 0)
          rotate(${Math.abs(movement[0] + movement[1]) > 10 ? 0 : 0}deg)
          scale(${Math.abs(movement[0] + movement[1]) > 10 ? 1 : 1}deg)
        `,
                immediate: n => ['transform', 'zIndex'].includes(n),
            }
        }

        // not being dragged but another draggable is being dragged
        return {
            ...baseStyles,
            opacity: 0.4,
        }
    }

    // nothing being dragged
    return baseStyles
}

const ScenesGrid = ({ onOrderChange, items, containerClass, onActivateScene, isDraggable }) => {
    const { width, height } = useWindowDimensions()

    const arrLength = items.length
    const elRefs = React.useRef([])
    let isDragging = React.useRef(false)
    let activelyDraggedIndex = React.useRef(-1)
    let draggedMovement = React.useRef([])
    let originalScrollTop = React.useRef(0)
    const originalScrollHeight = React.useRef(0)
    if (elRefs.current.length !== arrLength) {
        // add or remove refs
        elRefs.current = Array(arrLength)
            .fill()
            .map((_, i) => elRefs.current[i] || createRef())
    }

    // Store indicies as a local ref, this represents the item order
    const order = useRef(items.map((_, index) => index))

    if (order.current.length !== arrLength) {
        // add or remove refs
        order.current = Array(arrLength)
            .fill()
            .map((_, i) => i)
    }

    const ref = useRef(null)

    const { columnWidth, columnCount } = getGridConfig(width, height)

    const [rowHeights, setRowHeights] = useState([])
    const [scrollPostion, setScrollPostion] = useState(0)

    const [springs, setSprings] = useSprings(
        [...items].length,
        calculateDraggableStyle({
            order: order.current,
            down: false,
            windowDimensions: { width, height },
            rowHeights,
        }),
    )

    useEffect(() => {
        setTimeout(() => {
            const rh = getMaxHeightByRow({
                parentRef: ref,
                columnCount,
                columnWidth,
                children: elRefs.current,
                order,
            })
            setRowHeights(rh)
        }, 300)
    }, [width, items, order.current])

    useEffect(() => {
        setSprings(
            calculateDraggableStyle({
                order: order.current,
                down: false,
                windowDimensions: { width, height },
                rowHeights,
            }),
        )
    }, [rowHeights, width])

    //Scroll spring
    const [y, setContainerScrollTop, stopScroll] = useSpring(() => ({
        immediate: true,
        from: {
            scrollTop: ref?.current?.scrollTop || 0,
        },
        onFrame: props => {
            const { scrollTop } = props
            ref.current.scrollTop = scrollTop
        },
    }))

    const clearScrollInterval = () => {
        // console.log('clearing interval');
        clearInterval(scrollInterval)
        stopScroll()
        scrollInterval = null
    }

    const handleDrag = ({ args: [originalIndex], active, movement, xy, offset, event }) => {
        //Disable dragging for child textareas
        if (
            event.target.type === 'textarea' ||
            event.target.classList.contains('comments-icon') || // comments icon top right
            !isDraggable
        ) {
            return
        }

        activelyDraggedIndex.current = originalIndex
        draggedMovement.current = movement

        const curIndex = order.current.indexOf(originalIndex)

        const x = xy[0]
        const y = xy[1] + ref.current.scrollTop //add scrolled offset to captured y coordinate

        const { targetIndex } = getArrayIndexFrom2DCoordinates({
            columnCount,
            columnWidth,
            rowHeights,
            itemsCount: order.current.length,
            x,
            y: y - OFFSET_TO_CONTAINER,
        })

        const parentRect = ref.current.getBoundingClientRect()
        const maxScroll = ref.current.scrollHeight

        //scroll container while dragging
        if (xy[1] - OFFSET_TO_CONTAINER > parentRect.height - 110) {
            //scroll down
            const hittingBottomEdge = ref.current.scrollTop + parentRect.height >= maxScroll

            if (hittingBottomEdge) {
                ref.current.scrollTop = maxScroll
                clearScrollInterval()
                stopScroll()
            } else if (!scrollInterval) {
                scrollInterval = setInterval(() => {
                    setContainerScrollTop({
                        scrollTop: Math.min(maxScroll, ref.current.scrollTop + SCROLL_TICK),
                    })
                }, 1)
            }
        } else if (xy[1] - OFFSET_TO_CONTAINER < 110) {
            //scroll up
            if (!scrollInterval) {
                scrollInterval = setInterval(() => {
                    setContainerScrollTop({
                        scrollTop: ref.current.scrollTop - SCROLL_TICK,
                    })
                }, 1)
            }
        } else {
            clearScrollInterval()
        }

        if (originalIndex === order.current.length - 1) return

        // Sensitivity. (TODO: Improve)
        let newOrder = order.current
        const xSensitivityFactor = (x / columnWidth) % 1
        let shouldReorder = false
        if (xSensitivityFactor > 0.2 && xSensitivityFactor < 0.8) {
            newOrder = swap(order.current, curIndex, targetIndex)
            shouldReorder = true
        }

        setSprings(
            calculateDraggableStyle({
                order: newOrder,
                down: active,
                windowDimensions: { width, height },
                originalIndex,
                curIndex,
                movement: [
                    movement[0],
                    movement[1] + (ref.current.scrollTop - originalScrollTop.current),
                ],
                rowHeights,
            }),
        )

        // Release drag, update order
        if (!active) {
            clearScrollInterval()
            if (shouldReorder) {
                order.current = newOrder
                onOrderChange(curIndex, targetIndex)
            }
        }
    }

    // Drag hook, fires
    const bind = useGesture(
        {
            onDragStart: () => {
                originalScrollTop.current = ref.current.scrollTop
                originalScrollHeight.current = ref.current.scrollHeight
                isDragging.current = true
            },
            onDragEnd: () => {
                activelyDraggedIndex.current = -1
                draggedMovement.current = []
                isDragging.current = false
                clearScrollInterval()
            },
            onDrag: handleDrag,
        },
        {
            enabled: !IS_MOBILE,
            drag: {
                filterTaps: true,
                rubberband: true,
            },
        },
    )

    const scrollHook = useScroll(state => {
        // console.log('scroll distance ', (ref.current.scrollTop - originalScrollTop.current), 'idx: ', activelyDraggedIndex.current)
        if (isDragging.current) {
            const scrolleeY =
                draggedMovement.current[1] + (ref.current.scrollTop - originalScrollTop.current)
            setSprings(
                calculateDraggableStyle({
                    order: order.current,
                    down: true,

                    windowDimensions: { width, height },
                    originalIndex: activelyDraggedIndex.current,
                    curIndex: order.current.indexOf(activelyDraggedIndex.current),
                    movement: [
                        draggedMovement.current[0],
                        Math.min(scrolleeY, originalScrollHeight.current),
                    ],
                    rowHeights,
                }),
            )
        }
    })

    return (
        <>
            <S.Wrapper className={containerClass} scroll>
                <animated.div ref={ref} {...scrollHook()}>
                    {springs.map((props, i) => {
                        return (
                            <animated.div
                                ref={elRefs.current[i]}
                                key={i}
                                {...bind(i)}
                                style={{ ...props }}>
                                <Scene
                                    columnIndex={getColumnIndex(order.current.indexOf(i))}
                                    scene={items[order.current.indexOf(i)]}
                                    sceneNumber={order.current.indexOf(i) + 1}
                                    onActivateScene={onActivateScene}
                                />
                            </animated.div>
                        )
                    })}
                </animated.div>
            </S.Wrapper>
        </>
    )
}

export default ScenesGrid
