import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import actions from '../../actions'
import Scene from './scene'

// constants

const ACTIVE_STATUS = 'ACTIVE'

export default connect(
    (state, ownProps) => {
        const {
            album,
            project,
            scene: activeScene,
            permissions,
            sceneLoading,
            comments,
            focusedScriptType,
        } = state

        const { scene, sceneNumber, printing, columnIndex, presentationpreview } = ownProps

        const isActive = (activeScene && activeScene.id === scene.id) || false
        const hasComments = comments.some(
            x => x.sceneId === scene.id && R.toLower(x.status) === R.toLower(ACTIVE_STATUS),
        )
        return {
            printing,
            album,
            comments,
            hasComments,
            isActive,
            permissions,
            project,
            sceneNumber,
            sceneLoading,
            focusedScriptType,
            columnIndex,
            presentationpreview,
        }
    },
    {
        ...actions.global,
        ...actions.scenes,
        ...actions.comments,
        ...actions.focusedScriptType,
    },
)(React.memo(Scene))
