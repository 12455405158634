import { FETCH_SUBSCRIPTION_PLAN_RESP } from '../constants/ActionTypes'

export default function subscriptionPlanInfo(state = {}, { type, payload }) {
    switch (type) {
        case FETCH_SUBSCRIPTION_PLAN_RESP:
            return payload
        default:
            return state
    }
}
