import React from 'react'
import { connect } from 'react-redux'
import Modal from '../common/Modal'
import { StyledButton } from './styled'
import { deleteMemberInProject, fetchProjectMembers } from '../../actions/ProjectActions'

const LeaveProject = ({
    deleteMemberInProject,
    fetchProjectMembers,
    member,
    members,
    history,
    projectId,
}) => {
    const [isProcessing, setProcessing] = React.useState(false)
    React.useEffect(() => {
        if (!members || !projectId) {
            fetchProjectMembers(projectId)
        }
    }, [])

    React.useEffect(() => {
        if (!member && members) {
            setProcessing(false)
            history.push({
                pathname: '/projects',
                state: {
                    leaveProjectId: projectId,
                },
            })
        }
    }, [members])

    return (
        <Modal
            isCloseable
            isCancelable
            title="Leave Project?"
            subtitle="Are you sure you want to leave this project?">
            <StyledButton
                danger={+true}
                fullwidth={+true}
                disabled={isProcessing || !member}
                onClick={() => {
                    setProcessing(true)
                    deleteMemberInProject(member)
                }}>
                {`${isProcessing ? 'Leaving Project ...' : 'Leave Project'}`}
            </StyledButton>
        </Modal>
    )
}

export default connect(
    (state, ownProps) => {
        let { members, user: userState, session, project } = state
        const user = userState && userState.loading ? session.user || session : userState.user
        const member = members && members.find(member => member.user.id == user.id)
        let projectId = project?.id
        if (ownProps?.match?.params?.projectId) {
            projectId = ownProps?.match?.params?.projectId
        }
        return {
            member,
            members,
            projectId,
        }
    },
    {
        deleteMemberInProject,
        fetchProjectMembers,
    },
)(React.memo(LeaveProject))
