import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useTransition, animated } from 'react-spring'

import CloseMenu from '../../icons/CloseMenu'

const Container = styled('div')`
    position: fixed;
    z-index: 10001;
    width: 0 auto;
    top: ${props => (props.top ? 0 : 'unset')};
    bottom: ${props => (props.top ? 'unset' : 0)};
    margin: 30px;
    ${({ right }) =>
        right
            ? css`
                  right: 0;
              `
            : css`
                  left: 0;
              `};
    display: flex;
    flex-direction: ${props => (props.top ? 'column-reverse' : 'column')};
    pointer-events: none;
    align-items: ${props =>
        props.position === 'center' ? 'center' : `flex-${props.position || 'end'}`};
    @media (max-width: 680px) {
        align-items: center;
    }
`

const Message = styled(animated.div)`
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    width: 40ch;
    @media (max-width: 680px) {
        width: 100%;
    }
`

export const Content = styled('div')`
    color: white;
    background-color: ${props => props.color};
    padding: 18px;
    font-size: 1em;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    overflow: hidden;
    height: auto;
    border-radius: 3px;
    margin-top: ${props => (props.top ? '0' : '10px')};
    margin-bottom: ${props => (props.top ? '10px' : '0')};
`

const Button = styled('button')`
    cursor: pointer;
    pointer-events: all;
    outline: 0;
    border: none;
    background: transparent;
    display: flex;
    align-self: flex-start;
    overflow: hidden;
    margin: 0;
    padding: 0;
    padding-bottom: 14px;
    color: rgba(255, 255, 255, 0.8);
    :hover {
        color: rgba(255, 255, 255, 0.9);
    }
`

const Life = animated(styled('div')`
    position: absolute;
    bottom: ${props => (props.top ? '10px' : '0')};
    left: 0px;
    width: auto;
    background-image: linear-gradient(
        130deg,
        ${({ color }) => color},
        ${({ lightColor }) => lightColor}
    );
    height: 5px;
`)

export default function MessageHub({
    config = { tension: 125, friction: 20, precision: 0.1 },
    timeout = 3000,
    top = false,
    right = false,
    children,
}) {
    const id = React.useRef(0)
    const refMap = React.useRef(new WeakMap())
    const cancelMap = React.useRef(new WeakMap())
    const [items, setItems] = React.useState([])
    const transitions = useTransition(items, item => item.key, {
        from: { opacity: 0, height: 0, life: '100%' },
        enter: item => async next =>
            await next({ opacity: 1, height: refMap.current.get(item).offsetHeight }),
        leave: item => async (next, cancel) => {
            cancelMap.current.set(item, cancel)
            await next({ life: '0%' })
            await next({ opacity: 0 })
            await next({ height: 0 })
        },
        onRest: item => setItems(state => state.filter(i => i.key !== item.key)),
        config: (item, state) =>
            state === 'leave'
                ? [{ duration: item.options.timeout || timeout }, config, config]
                : config,
    })

    React.useEffect(
        () => void children(msg => setItems(state => [...state, { key: id.current++, ...msg }])),
        [children],
    )
    return (
        <Container>
            {transitions.map(({ key, item, props: { life, ...style } }) => (
                <Message key={key} style={style}>
                    <Content {...item.options} ref={ref => ref && refMap.current.set(item, ref)}>
                        <Life {...item.options} style={{ right: life }} />
                        <p>{item.text}</p>
                        <Button
                            onClick={e => {
                                e.stopPropagation()
                                cancelMap.current.has(item) && cancelMap.current.get(item)()
                            }}>
                            <CloseMenu fill="#FFFFFF" size="15" />
                        </Button>
                    </Content>
                </Message>
            ))}
        </Container>
    )
}
