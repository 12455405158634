import React from 'react';
import style from './strokeStyle';

export default () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1080 720"
    style={style.svg}
  >
    <g>
      <g>
        <path
          style={style.st0}
          d="M337.8,212.5h-268c-0.3,0-0.5-0.2-0.5-0.5V62.5c0-0.3,0.2-0.5,0.5-0.5h268c0.3,0,0.5,0.2,0.5,0.5V212
			C338.3,212.3,338,212.5,337.8,212.5z"
        />
        <line style={style.st1} x1="86.6" y1="268" x2="320.9" y2="268" />
        <line style={style.st1} x1="86.6" y1="319.2" x2="320.9" y2="319.2" />
        <line style={style.st1} x1="86.6" y1="370.3" x2="320.9" y2="370.3" />
        <line style={style.st1} x1="86.6" y1="421.5" x2="320.9" y2="421.5" />
        <line style={style.st1} x1="86.6" y1="472.7" x2="320.9" y2="472.7" />
        <line style={style.st1} x1="86.6" y1="523.8" x2="320.9" y2="523.8" />
        <line style={style.st1} x1="86.6" y1="575" x2="320.9" y2="575" />
      </g>
      <g>
        <path
          style={style.st0}
          d="M1016.3,212.5h-268c-0.3,0-0.5-0.2-0.5-0.5V62.5c0-0.3,0.2-0.5,0.5-0.5h268c0.3,0,0.5,0.2,0.5,0.5V212
			C1016.8,212.3,1016.5,212.5,1016.3,212.5z"
        />
        <g>
          <line style={style.st1} x1="765.1" y1="268" x2="999.4" y2="268" />
          <line style={style.st1} x1="765.1" y1="318.7" x2="999.4" y2="318.7" />
          <line style={style.st1} x1="765.1" y1="369.4" x2="999.4" y2="369.4" />
          <line style={style.st1} x1="765.1" y1="420.1" x2="999.4" y2="420.1" />
          <line style={style.st1} x1="765.1" y1="470.9" x2="999.4" y2="470.9" />
          <line style={style.st1} x1="765.1" y1="521.6" x2="999.4" y2="521.6" />
          <line style={style.st1} x1="765.1" y1="572.3" x2="999.4" y2="572.3" />
          <line style={style.st1} x1="765.1" y1="623" x2="999.4" y2="623" />
        </g>
      </g>
      <g>
        <path
          style={style.st0}
          d="M677,212.5H409c-0.3,0-0.5-0.2-0.5-0.5V62.5c0-0.3,0.2-0.5,0.5-0.5h268c0.3,0,0.5,0.2,0.5,0.5V212
			C677.5,212.3,677.3,212.5,677,212.5z"
        />
        <line style={style.st1} x1="425.9" y1="268" x2="660.1" y2="268" />
        <line style={style.st1} x1="425.9" y1="319.3" x2="660.1" y2="319.3" />
        <line style={style.st1} x1="425.9" y1="370.7" x2="660.1" y2="370.7" />
        <line style={style.st1} x1="425.9" y1="422" x2="660.1" y2="422" />
        <line style={style.st1} x1="425.9" y1="473.3" x2="660.1" y2="473.3" />
        <line style={style.st1} x1="425.9" y1="524.7" x2="660.1" y2="524.7" />
      </g>
    </g>
  </svg>
);
