/**
 * @typedef {Object} ReduxStore
 * @property {Function} getState
 */

/**
 * Start the busy warning service
 *
 * The busy warning service will ensure that one does not accidentally
 * unload the page while uploading something to S3.
 *
 * @param {ReduxStore} store
 */
export function startBusyWarningService(store) {
  window.onbeforeunload = function() {
    const { imageUploading } = store.getState();
    if (imageUploading) {
      return 'You are uploading an image. Please wait.';
    }
  };
}
