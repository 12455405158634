import React from 'react';

export default ({
  fill = 'white',
  enableBackground = 'new 0 0 100 100',
  className,
  ...style
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style={{ fill, enableBackground, ...style }}
    className={className}
  >
    <path d="M78.4,82v5.3c0,2.3-2,3.6-4.3,3.6H5.5c-2.3,0-3.8-1.3-3.8-3.6V35.4c0-2.3,1.5-4.4,3.8-4.4h12.3  c2.3,0,4.2,1.9,4.2,4.2c0,2.3-1.9,4.2-4.2,4.2h-7.7v43.1H70V82c0-2.3,1.9-4.2,4.2-4.2C76.5,77.8,78.4,79.7,78.4,82z M97.1,42.8  L71,69.4c-1.2,1.2-3.1,1.6-4.7,0.9c-1.6-0.7-2.7-2.2-2.7-3.9V54.9c-8.4-0.2-28.6,0.6-35.9,13.6c-0.8,1.4-2.2,2.2-3.7,2.2  c-0.4,0-0.7,0-1.1-0.1c-1.9-0.5-3.2-2.2-3.2-4.1c0-0.7,0.1-16.9,12.1-29c7.7-7.7,18-12,31.6-12.7V13.3c0-1.7,1.1-3.3,2.7-3.9  c1.6-0.7,3.4-0.3,4.6,0.9L97,36.9C98.7,38.5,98.7,41.2,97.1,42.8z" />
  </svg>
);
