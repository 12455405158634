import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import RenameFolder from './RenameFolder'
import { updateFolderAction } from '../../actions/FolderActions'

const RenameFolderContainer = ({ history, updateFolderAction, folderId, currentFolder }) => {
    if (!currentFolder || !folderId) {
        history.replace({ pathname: '/projects' })
    }
    const [folderName, setFolderName] = useState(
        currentFolder?.folderId === folderId ? currentFolder.folderName : '',
    )
    const [submitting, setSubmitting] = useState(false)

    const onClick = ev => {
        ev.preventDefault()
        setSubmitting(true)
        updateFolderAction({ folderName: folderName, folderId: folderId })
    }

    return (
        <RenameFolder
            folderName={folderName}
            onClick={onClick}
            setFolderName={setFolderName}
            submitting={submitting}
        />
    )
}

export default withRouter(
    connect(
        (state, ownProps) => {
            const { currentFolder } = state
            return {
                currentFolder,
                folderId: ownProps?.location?.state?.folderId,
            }
        },
        {
            updateFolderAction,
        },
    )(RenameFolderContainer),
)
