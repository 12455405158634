import $ from 'jquery'
import 'jquery-match-height'
import ScenesGrid from '../DragAndDrop/ScenesGrid'
import FileUploadDropZone from '../fileUploadDropZone'
import PropTypes from 'prop-types'
import React from 'react'
import Toolbar from '../../containers/ToolbarContainer'
import { ProjectWrapper, ProjectContentWrapper, DndGridWrapper, ToolbarWrapper } from './styled'
import { useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'

const SHOW_COLOR = false

const Project = ({
    clearCommentsSceneIdFilter,
    hasMultipleEditors,
    members,
    project,
    scenes,
    uploadImage,
    setImageUploading,
    imageUploading,
    showPaintCanvas,
    reorderScene,
    allowEdit,
}) => {
    const [dropZoneActive, setDropZoneActive] = useState(false)
    const [fileCount, _setFileCount] = useState(0)
    const commentRefs = React.createRef({})
    commentRefs.current = {}

    const maybeWarnOfMultipleEditors = () => {
        hasMultipleEditors && window.$N.info(window.$N.MESSAGES.MULTIPLE_EDITORS)
    }

    const activateDropZone = ({ dataTransfer: dt }) => {
        // Dragging an image file from within the application? bail!
        if (window.$APP.disableDropzone) {
            return
        }
        setDropZoneActive(true)
    }

    const deactivateDropZone = () => {
        setDropZoneActive(false)
    }

    const setFileUploadStatusToDefault = () => {
        _setFileCount(0)
    }

    const setFileCount = count => {
        _setFileCount(imageUploading ? fileCount + count : count)
    }

    const clearActiveScene = ev => {
        if (!ev) return
        // Clear active scene if the click event is not on a scene
        let el = $(ev.target)
        let istarget =
            el.closest('.scene').length ||
            el.closest('.text-area').length ||
            el.closest('.comment').length ||
            el.closest('#commentmodal').length
        if (!istarget && !showPaintCanvas) {
            clearActiveScene()
        }
    }

    const onActivateScene = id => {
        if (commentRefs.current[id]) {
            commentRefs.current[id].scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    useEffect(() => {
        maybeWarnOfMultipleEditors()
        document.addEventListener('click', clearActiveScene)

        // cleanup on unmount
        return () => {
            document.removeEventListener('click', clearActiveScene)
        }
    }, [])

    const handleSceneDrop = (sourceIndex, targetIndex) => {
        // target id will only be set if dragging from one dropzone to another.
        if (!scenes[targetIndex] || !scenes[sourceIndex]) return

        // swap(scenes, sourceIndex, targetIndex);
        const sourceScene = scenes[sourceIndex]
        const targetScene = scenes[targetIndex]
        if (sourceScene.id !== targetScene.id)
            reorderScene({
                sourceSceneId: sourceScene.id,
                targetSceneId: targetScene.id,
            })
    }

    const scenesWithAddButton = [...scenes]
    if (allowEdit) {
        scenesWithAddButton.push({ type: 'button' })
    }
    return (
        <ProjectWrapper onDragEnter={activateDropZone}>
            <FileUploadDropZone
                deactivateDropZone={deactivateDropZone}
                visible={dropZoneActive}
                uploadImage={uploadImage}
                setImageUploading={setImageUploading}
                setFileCount={setFileCount}
            />
            <ProjectContentWrapper>
                <DndGridWrapper>
                    {scenes && (
                        <ScenesGrid
                            isDraggable={allowEdit}
                            onOrderChange={handleSceneDrop}
                            items={scenesWithAddButton}
                            containerClass="scenes-container"
                            onActivateScene={onActivateScene}
                        />
                    )}
                </DndGridWrapper>

                <ToolbarWrapper>
                    {/* For mobile screen */}
                    <Toolbar
                        isProjectPublic={false}
                        commentRefs={commentRefs}
                        fileCount={fileCount}
                        setFileUploadStatusToDefault={setFileUploadStatusToDefault}
                    />
                </ToolbarWrapper>
            </ProjectContentWrapper>
        </ProjectWrapper>
    )
}

Project.propTypes = {
    clearActiveScene: PropTypes.func,
    clearCommentsSceneIdFilter: PropTypes.func,
    hasMultipleEditors: PropTypes.bool,
    members: PropTypes.array,
    project: PropTypes.object,
    scenes: PropTypes.array,
    uploadImage: PropTypes.func,
    setImageUploading: PropTypes.func,
    setFileUploadStatusToDefault: PropTypes.func,
    setFileCount: PropTypes.func,
}

export default React.memo(Project)
