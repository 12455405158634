import React from 'react'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import RegisterForm from './CognitoRegisterForm'

const PROVIDERS = {
    appsumo: {
        title: 'Welcome Sumo-ling!',
        description: "You're just seconds away from better storyboards!",
        label: 'AppSumo Coupon',
    },
}

const defaultValue = {
    title: 'Create Your Special Account',
    description: 'Enter the coupon from your provider for extended access.',
    label: 'Coupon',
}

export default withRouter(
    class CognitoProviderRegister extends React.Component {
        constructor(props) {
            super(props)
        }

        render() {
            const providerName = this.props.match.params.provider_name
            const providerDetail = providerName ? PROVIDERS[providerName] : defaultValue
            if (!providerDetail) return <Redirect {...this.props} to={'/authenticate/coupon'} />
            const { title, description, label } = providerDetail
            return (
                <div className="registration">
                    <RegisterForm
                        providerRegister
                        title={title}
                        description={description}
                        label={label}
                    />
                </div>
            )
        }
    },
)
