import { connect } from 'react-redux';
import actions from '../actions';
import Toolbar from '../components/project/toolbar';

export default connect(
  (state, ownProps) => {
    let {
      album,
      comments,
      permissions,
      project,
      scene: activeScene,
      scenes,
      imageUploading,
      toolbar: { activeTab },
    } = state;

    return {
      activeTab,
      activeScene,
      album,
      comments,
      permissions,
      project,
      scenes,
      imageUploading,
      ...ownProps,
    };
  },
  {
    ...actions.global,
    ...actions.projects,
    ...actions.scenes,
  }
)(Toolbar);
