import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/**
 * I am the DOM element that the SketchAPI uses
 */
export const SketchRoot = connect(
  null,
  dispatch => ({
    mount: () => dispatch({ type: 'SKETCH_ROOT_MOUNT' }),
    unmount: () => dispatch({ type: 'SKETCH_ROOT_UNMOUNT' }),
  })
)(({ mount, unmount }) => {
  useEffect(() => {
    mount();
    return unmount;
  }, [mount, unmount]);
  return (
    <div className="paint-canvas__body">
      <div id="sketch-api" className="no-animate sketch-api"></div>
    </div>
  );
});
