import React from 'react';
import FacebookAuth from '../FacebookAuth';
import GoogleAuth from '../GoogleAuth';

const SocialButtons = props => (
  <div>
    <br />
    <div>
      <GoogleAuth {...props} />
    </div>
    <div>
      <FacebookAuth {...props} />
    </div>
  </div>
);
export default SocialButtons;
