import React from 'react';
import style from './strokeStyle';

export default () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1079.3 720"
    style={style.svg}
  >
    <path
      style={style.st0}
      d="M507.5,323.4H73c-0.3,0-0.5-0.2-0.5-0.5V80.1c0-0.3,0.2-0.5,0.5-0.5h434.5c0.3,0,0.5,0.2,0.5,0.5v242.8
			C508,323.2,507.8,323.4,507.5,323.4z"
    />
    <path
      style={style.st0}
      d="M1011.5,323.4H577c-0.3,0-0.5-0.2-0.5-0.5V80.1c0-0.3,0.2-0.5,0.5-0.5h434.5c0.3,0,0.5,0.2,0.5,0.5v242.8
			C1012,323.2,1011.8,323.4,1011.5,323.4z"
    />
    <path
      style={style.st0}
      d="M507.5,637.4H73c-0.3,0-0.5-0.2-0.5-0.5V394.1c0-0.3,0.2-0.5,0.5-0.5h434.5c0.3,0,0.5,0.2,0.5,0.5v242.8
			C508,637.2,507.8,637.4,507.5,637.4z"
    />
    <path
      style={style.st0}
      d="M1011.5,637.4H577c-0.3,0-0.5-0.2-0.5-0.5V394.1c0-0.3,0.2-0.5,0.5-0.5h434.5c0.3,0,0.5,0.2,0.5,0.5v242.8
			C1012,637.2,1011.8,637.4,1011.5,637.4z"
    />
  </svg>
);
