import React from 'react'
import { isValidEmail } from '../../utils'
import { StyledButton, StyledInput } from './styled'
import Modal from '../common/Modal'

export default function ChangeEmail({
    user: { email: oldEmail },
    userLoading,
    updateUser,
    history,
}) {
    const inputRef = React.useRef()
    const [isProcessing, setIsProcessing] = React.useState(false)
    const handleSubmit = e => {
        e.preventDefault()
        if (!isValidEmail(inputRef.current.value)) {
            window.$N.error('Invalid email address')
            return
        }
        if (inputRef.current.value.toLowerCase() === oldEmail) {
            window.$N.error('New email is same as old email. Please choose a new email address')
            return
        }

        setIsProcessing(true)

        updateUser({
            oldEmail,
            email: inputRef.current.value,
            onDone: () =>
                history.replace({
                    pathname: `/profile/verify-email`,
                    state: { modal: true, closeable: true, oldEmail },
                }),
        })
    }

    return (
        <Modal
            isCancelable
            isCloseable
            isProcessing={isProcessing}
            title="New Email"
            subtitle="What would you like to change your email to?">
            <form className="content" onSubmit={handleSubmit}>
                <fieldset>
                    <div className="profile__row text m-b">
                        <StyledInput
                            ref={inputRef}
                            autoFocus
                            name="firstName"
                            className="text-white profile__value"
                            label="e-mail"
                        />
                    </div>
                </fieldset>
                <StyledButton
                    fullwidth
                    primary
                    disabled={userLoading}
                    type="submit"
                    className="m-t-md">
                    {userLoading ? 'Loading...' : 'Submit'}
                </StyledButton>
            </form>
        </Modal>
    )
}
