import PropTypes from 'prop-types'
import React from 'react'
import * as R from 'ramda'
import { StyledLink, AccountDetailsWrapper } from './styled'

const PLANS = {
    free: 'Free',
    monthly: 'Monthly',
    yearly: 'Annual',
    business_month: 'Business Monthly',
    business_year: 'Business Annual',
    pro_month: 'Pro Monthly',
    pro_year: 'Pro Annual',
    solo_month: 'Solo Monthly',
    solo_year: 'Solo Annual',
}

/**
 * @module AccountDetails
 */

class AccountDetails extends React.Component {
    render() {
        let {
            isCancelled,
            isFreeAccount,
            isTeamMember,
            isTrial,
            isExtended,
            userLoading,
            user: { firstName, lastName, email, paymentCycle, paymentGateway },
            team,
        } = this.props
        let plan
        const hasTeam = !R.isEmpty(team) && team.name
        if (isCancelled) {
            plan = 'Cancelled'
        } else if (isExtended) {
            plan = 'Extended'
        } else if (isTrial) {
            plan = 'Trial'
        } else {
            const joinPlanWith = hasTeam ? ' Team ' : ' '
            plan = `${
                isTeamMember
                    ? PLANS[team.teamPaymentCycle]
                    : PLANS[paymentCycle].split(' ').join(joinPlanWith)
            }`
        }

        return (
            <AccountDetailsWrapper>
                <i>Full Name</i>
                <div>{`${firstName} ${lastName}`}</div>
                <i>Email Address</i>
                <div>{email}</div>
                {userLoading ? null : (
                    <>
                        <i> {hasTeam ? 'Plan' : 'Subscription'} </i>
                        <div>
                            {plan}{' '}
                            {!isCancelled && (
                                <i className="text-muted">
                                    {team && team.name ? ` (${team.name})` : ''}
                                </i>
                            )}
                        </div>
                    </>
                )}
                {!isFreeAccount && paymentGateway.last4 && (
                    <>
                        <i>Credit Card</i>
                        <div>
                            {!R.isEmpty(paymentGateway)
                                ? `${paymentGateway.cardBrand} ${paymentGateway.last4}`
                                : ''}
                            <StyledLink
                                textmuted={+true}
                                replace
                                className="p-l-xs"
                                to={{
                                    pathname: '/billing/card',
                                    state: {
                                        modal: true,
                                        closeable: true,
                                    },
                                }}>
                                Update
                            </StyledLink>
                        </div>
                    </>
                )}
            </AccountDetailsWrapper>
        )
    }
}

AccountDetails.defaultProps = {}

AccountDetails.propTypes = {
    user: PropTypes.object,
}

AccountDetails.displayName = 'AccountDetails'

export default AccountDetails
