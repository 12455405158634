import * as R from 'ramda';
import MESSAGES from '../constants/Messages';

const options = {
  warn: {
    timeout: 3000,
    color: '#F7A336',
    lightColor: '#FFDA6C',
  },
  error: {
    timeout: 7000,
    color: '#DB6A64',
    lightColor: '#FFA097',
  },
  info: {
    timeout: 7000,
    color: '#0ADFA6',
    lightColor: '#6DFFDD',
  },
};

const notify = R.curry((fn, type, options, str) => {
  // Don't show errors when offline
  if (type === 'error' && !window.navigator.onLine) {
    return;
  }
  fn({ text: MESSAGES[str] || str, type, options });
});

export default function(fn) {
  return {
    MESSAGES,
    error: notify(fn, 'error', options.error),
    warn: notify(fn, 'warning', options.warn),
    info: notify(fn, 'info', options.info),
  };
}
