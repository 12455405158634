import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import actions from '../../actions'
import { createTeamPlan, leaveTeamPlan } from '../../actions/TeamAction'
import { fetchProductPlans } from '../../actions/ApplicationActions'
import config from '../../config'
import Billing from './index'
import {
    isFreeAccount,
    isCancelled,
    isTrial,
    isExtended,
    isTrialExpired,
    teamRole,
} from '../../utils/permissions'

const paymentCycle = user => R.prop(['paymentCycle'], user)
const appliedCoupon = user => R.path(['paymentGateway', 'coupon'], user)

const BillingContainer = ({ fetchProductPlans, productPlans, appliedCoupon, ...props }) => {
    const hasPlans = R.not(R.isEmpty(productPlans))
    React.useEffect(() => {
        if (appliedCoupon) {
            props.defaultCoupon(appliedCoupon)
        }
    }, [appliedCoupon])
    React.useEffect(() => {
        if (!hasPlans) {
            fetchProductPlans({
                oldProductPlan: config.stripeOldProductId,
                productSoloId: config.stripeProductSoloId,
                productProId: config.stripeProductProId,
                productBusinessId: config.stripeProductBusinessId,
            })
        }
    }, [
        config.stripeOldProductId,
        config.stripeProductSoloId,
        config.stripeProductProId,
        config.stripeProductBusinessId,
        hasPlans,
        fetchProductPlans,
    ])
    return <Billing {...props} productPlans={productPlans} />
}

export default withRouter(
    connect(
        (state, ownProps) => {
            let { coupon, session, user: userState, team, productPlans } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            return {
                coupon,
                session,
                showUpgradeForm: !!ownProps.location.state && !!ownProps.location.state.upgrade,
                showCardUpdatedMessage:
                    !!ownProps.location.state && !!ownProps.location.state.fixCard,
                showInstantCancelMessage:
                    !!ownProps.location.state && !!ownProps.location.state.cancel,
                user,
                userLoading: userState.loading,
                isFreeAccount: isFreeAccount(user),
                isCancelled: isCancelled(user),
                isTrial: isTrial(user),
                isExtended: isExtended(user),
                isTrialExpired: isTrialExpired(user),
                isTeamOwner: user && teamRole(user, 'OWNER'),
                isTeamMember: user && teamRole(user, 'MEMBER'),
                paymentCycle: user && paymentCycle(user),
                appliedCoupon: user && appliedCoupon(user),
                teamInfo: team,
                hasAccessToTeamPlan: user.hasAccessToTeamPlan,
                productPlans,
            }
        },
        {
            ...actions.coupons,
            ...actions.session,
            createTeamPlan,
            leaveTeamPlan,
            fetchProductPlans,
        },
    )(BillingContainer),
)
