import { UPDATE_PAINT_ACTION_STATE } from '../constants/ActionTypes';
import * as types from '../constants/ActionTypes';

export default function(
  state = {
    isReady: false,
    hidePaintActions: false,
  },
  { type, hidePaintActions }
) {
  switch (type) {
    case types.SKETCH_IS_READY:
      return { ...state, isReady: true };
    case UPDATE_PAINT_ACTION_STATE:
      return { ...state, hidePaintActions };
    default:
      return state;
  }
}
