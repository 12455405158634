import * as helpers from './helpers'
import * as types from '../constants/ActionTypes'
import * as R from 'ramda'

const memberToEmail = R.compose(R.prop('email'), R.prop('user'))

const sortMembersByEmail = R.sortBy(memberToEmail)

function addMemberToProject(state, member) {
    return sortMembersByEmail(R.append(member, state))
}

function updateMemberInProject(state, member) {
    let cleanList = R.filter(m => m.userId !== member.userId, state)
    return sortMembersByEmail(R.append(member, cleanList))
}

function deleteMemberInProject(state, member) {
    return R.filter(m => m.userId !== member.userId, state)
}

export function members(state = null, { type, payload }) {
    switch (type) {
        case types.FETCH_PROJECT_BY_ID_RESP:
            return sortMembersByEmail(payload.members)
        case types.ADD_MEMBER_TO_PROJECT_RESP:
            return addMemberToProject(state, payload)
        case types.UPDATE_MEMBER_IN_PROJECT_RESP:
            return updateMemberInProject(state, payload)
        case types.DELETE_MEMBER_IN_PROJECT_RESP:
            return deleteMemberInProject(state, payload)
        case types.FETCH_PROJECT_MEMBERS_RESP:
            return sortMembersByEmail(payload)
        case types.UPDATE_ROUTE:
            return helpers.activeProjectView(payload.pathname) ||
                helpers.billingView(payload.pathname) ||
                helpers.teamPlanView(payload.pathname) ||
                helpers.CancelSubscriptionView(payload.pathname) ||
                helpers.profileView(payload.pathname)
                ? state
                : null
        default:
            return state
    }
}
