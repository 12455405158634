export default {
    color: {
        text_dark: '#5a6a7a',
        text_black: '#000',
        default_blue_border_color: '#005cbf',

        text_icon: '#9fa3ae',
        plot_green: '#0adfa6',
        plot_green_hover: '#0aa57c',
        plot_black: '#1f2725',
        plot_red: '#f05050',
        plot_red_hover: '#c73737',
        plot_menu_hover_grey: '#646b73',
    },
    padding: {
        scene_top_padding: '30px',
    },
    border: {
        default_toolbar_scene_border: '2px solid #eee',
        plot_default_border_radius: '4px',
        plot_default_box_shadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',

        plot_scene_box_shadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
    },
};
