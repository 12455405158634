import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const PublicHeaderLinks = ({ route, getMenuProps = () => ({}), getItemProps = () => ({}) }) => (
    <ul {...getMenuProps()} className="header-right-menu">
        <li {...getItemProps('signin')}>
            <Link className="topMenuItem" to={'/authenticate/signin'}>
                Login
            </Link>
        </li>
        <li {...getItemProps('support')}>
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="topMenuItem"
                href="https://theplot.io/support">
                Support
            </a>
        </li>
        <li {...getItemProps('faq')}>
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="topMenuItem"
                href="https://theplot.io/faq/">
                FAQ
            </a>
        </li>
        <li {...getItemProps('signup')}>
            <Link className="topMenuItem" to={'/authenticate'}>
                Sign Up
            </Link>
        </li>
    </ul>
)

PublicHeaderLinks.propTypes = {
    route: PropTypes.func,
}

export default PublicHeaderLinks
