import { call, put, fork, select } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga'

import {
    createFolderAction,
    createFolderRespAction,
    deleteFolderAction,
    deleteFolderRespAction,
    updateFolderAction,
    updateFolderRespAction,
    fetchFoldersAction,
    fetchFoldersRespAction,
    reorderFoldersAction,
    reorderFoldersRespAction,
    setCurrentFolderTo,
} from '../actions/FolderActions'

import * as api from './api'
import config from '../config'
import * as types from '../constants/Folders'
import http from '../utils/http'

function* runFetchFolders({ payload }) {
    const URL = `${config.apiUrl}/folders`
    const folders = yield call(() => http.get(URL))

    yield put(fetchFoldersRespAction(folders))
}

function* runCreateFolder({ payload }) {
    const URL = `${config.apiUrl}/folders`
    const { error, response } = yield call(() => api.post(URL, payload))
    if (error) {
        window.notify.error(error.message)
        yield call(() => window.sagaHistory.push(`/projects/`))
        return
    }

    yield put(createFolderRespAction(response))
    yield put(setCurrentFolderTo(response))
    yield call(() => window.sagaHistory.push(`/projects/`))
}

function* runDeleteFolder({ payload }) {
    const URL = `${config.apiUrl}/folders`
    const filteredPayload = {
        userFoldersId: payload.userFoldersId,
    }
    const { error, response } = yield call(() => api.delWithPayload(URL, filteredPayload))
    if (error) {
        window.notify.error(error.message)
        payload.history.goBack()
        return
    }
    yield put(deleteFolderRespAction(filteredPayload))
    payload.history.goBack()
}

function* runUpdateFolder({ payload }) {
    const URL = `${config.apiUrl}/folders`
    const { error, response } = yield call(() => api.put(URL, payload))
    if (error) {
        window.notify.error(error.message)
        yield call(() => window.sagaHistory.push(`/projects/`))
        return
    }
    yield put(updateFolderRespAction(payload))
    yield call(() => window.sagaHistory.push(`/projects/`))
}

function* runReorderFolders({ payload }) {
    yield put(reorderFoldersRespAction(payload.items))
    const URL = `${config.apiUrl}/folders/reorder`
    const { error } = yield call(() => api.patch(URL, payload))
    if (error) {
        window.notify.error(error.message)
        yield put(reorderFoldersRespAction(payload.folders))
    }
}

function* watchFetchFolders() {
    yield* takeLatest(types.FETCH_FOLDERS, runFetchFolders)
}

function* watchCreateFolder() {
    yield* takeLatest(types.CREATE_FOLDER, runCreateFolder)
}

function* watchDeleteFolder() {
    yield* takeLatest(types.DELETE_FOLDER, runDeleteFolder)
}

function* watchUpdateFolder() {
    yield* takeLatest(types.UPDATE_FOLDER, runUpdateFolder)
}

function* watchReorderFolders() {
    yield* takeLatest(types.REORDER_FOLDERS, runReorderFolders)
}

export default function*() {
    yield fork(watchFetchFolders)
    yield fork(watchCreateFolder)
    yield fork(watchDeleteFolder)
    yield fork(watchUpdateFolder)
    yield fork(watchReorderFolders)
}
