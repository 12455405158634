import * as R from 'ramda';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import FormInput from './lib/FormInput';
import SubmitButton from './lib/SubmitButton';
import { reset } from './lib/cognito';

export default withRouter(
  class CognitoReset extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        email: '',
        code: '',
        password: '',
        errorMessage: null,
      };

      R.forEach(fn => (this[fn] = this[fn].bind(this)), []);
    }

    handleChange = e => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    canSubmit() {
      return (
        this.state.email.length > 0 &&
        this.state.code.length > 0 &&
        this.state.password.length > 0
      );
    }

    handleSubmit = e => {
      e.preventDefault();
      const { email, code, password } = this.state;

      reset({ email, code, password })
        .then(result => this.props.history.push('/authenticate/signin'))
        .catch(err => this.setState({ errorMessage: err.message }));
    };

    render() {
      return (
        <div className="registration">
          <h1 className="animated fadeIn sn signup__title">Password Reset</h1>
          <div className="signup__desc m-b-lg">
            Enter verification code and new password.
          </div>

          <div className="inline-block">
            <form onSubmit={this.handleSubmit}>
              <FormInput
                label="Email"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
              <FormInput
                label="Verification Code"
                name="code"
                value={this.state.code}
                onChange={this.handleChange}
              />
              <FormInput
                label="New Password"
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <SubmitButton disabled={!this.canSubmit()}>
                Change Password
              </SubmitButton>
            </form>
          </div>

          <p>
            Don't have an account? <Link to="/authenticate">Try For Free</Link>
          </p>

          <p>
            Already have an account?{' '}
            <Link to="/authenticate/signin">Log in</Link>
          </p>

          <div>
            {this.state.errorMessage ? (
              <div className="alert alert-danger">
                {JSON.stringify(this.state.errorMessage)}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
);
