import { connect } from 'react-redux'
import actions from '../actions'
import SceneActionButtons from '../components/scene/sceneActionButtons'

export default connect(
    (state, ownProps) => {
        const { project, scenes, permissions } = state
        const { activateScene, scene, presentationpreview } = ownProps
        return {
            allowDelete: scenes && scenes.length > 1,
            scene,
            activateScene,
            project,
            permissions,
            presentationpreview,
        }
    },
    {
        ...actions.global,
        ...actions.scenes,
    },
)(SceneActionButtons)
