import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { CouponButton, StyledInput, StyledLabel } from './styled'

/**
 * @module Coupon
 */

const Coupon = ({ coupon: { id, applying, valid, message }, applyCoupon, processing }) => {
    const [disabled, setDisabled] = useState(true)
    const couponRef = useRef(null)

    const onClick = ev => {
        ev.preventDefault()
        setDisabled(true)
        applyCoupon(couponRef.current.value)
    }

    const handleChange = ev => {
        setDisabled(!ev.target.value)
    }

    const hasError = message && !valid

    let applyBtnLabel = applying ? 'Applying...' : 'Apply Coupon'

    return (
        <>
            <div className="coupon profile__row text m-b-sm">
                <div className={`pos-rlt w100 ${hasError ? 'profile__row-error' : ''}`}>
                    <StyledInput
                        id="coupon"
                        ref={couponRef}
                        autoComplete="off"
                        placeholder="Enter coupon code"
                        label={hasError ? message : 'Coupon'}
                        onChange={handleChange}
                    />
                </div>
                <CouponButton
                    coupon
                    onClick={onClick}
                    disabled={disabled || processing || applying}
                    className="m-l-sm">
                    {applyBtnLabel}
                </CouponButton>
            </div>

            {valid && message && (
                <div className="text-primary">
                    {`Enjoy 
            ${message.slice(9)}.
            After this period, you will be billed at regular price.`}
                </div>
            )}
        </>
    )
}

Coupon.propTypes = {
    applyCoupon: PropTypes.func.isRequired,
    coupon: PropTypes.object.isRequired,
}

export default Coupon
