export default {
    ADD_MEMBER_ERR: 'Failed to add collaborator to the project.',
    COPY_PASTED: 'Copied to clipboard.',
    AUTH_FAIL: 'Login failed. Please try again.',
    CANCEL_SUBSCRIPTION_ERR: 'Failed to cancel subscription. Please contact support.',
    CREATE_COMMENT_ERR: 'Failed to create comment.',
    CREATE_FIRST_SCENE_ERR: 'Failed to create the first scene for a new project.',
    CREATE_PROJECT_ERR: 'Failed to create project',
    CREATE_SCENE_ERR: 'Failed to create a scene.',
    CREATE_SCENE_VIA_SHORTCUT_ERR: 'Failed to create a new scene via keyboard shortcut',
    PROJECT_DUPLICATED: 'Project duplicated sucessfully',
    CREATE_USER_ERR: 'Failed to create a user record',
    DELETE_COMMENT_ERR: 'Failed to delete comment.',
    DELETE_IMAGE_IN_ALBUM_ERR: 'Failed to delete image.',
    DELETE_MEMBER_ERR: 'Failed to remove collaborator from the project.',
    DELETE_PROJECT_ERR: 'Failed to delete project.',
    DELETE_SCENE_ERR: 'Failed to delete a scene.',
    EMPTY_COUPON: 'Please provide a valid coupon.',
    ERR_GENERAL: 'An error occured. Please report this to the site admin.',
    FETCH_ALBUMS_ERR: 'Failed to retrieve albums',
    FETCH_CHANNELS_ERR: 'Something went wrong when retreiving channels',
    FETCH_PLAYLISTS_ERR: 'Something went wrong when retreiving playlists',
    FETCH_PROJECT_BY_ID_ERR: 'Failed to retrieve project.',
    FETCH_PROJECT_MEMBERS_ERR: 'Failed to fetch project members.',
    FETCH_PROJECTS_COMMENT_ERR: 'Failed to retrieve project comments.',
    FETCH_PROJECTS_MEMBERS_ERR: 'Failed to retrieve project members.',
    FETCH_USERS_ERR: 'Failed to fetch user records',
    FETCH_VIDEOS_ERR: 'Something went wrong when retreiving videos',
    GET_UPLOAD_KEY_ERR: 'Failed to create the first scene for a new project.',
    INCOMPLETE_PLAYLIST:
        'This playlist is work in progress. You can check out other playlists for the time being ;)',
    NOT_AUTHORIZED: 'You are not authroized to use this application',
    RE_ORDER_SCENE_ERR: 'Failed to re-order scenes.',
    SAVE_DIRTY_SCENES_ERR: 'Failed to save updated scenes',
    SET_SCENE_IMAGE_ERR: 'Failed to set scene image.',
    UPDATE_COMMENT_ERR: 'Failed to update comment.',
    UPDATE_PROJECT_ERR: 'Failed to update project.',
    UPDATE_MEMBER_ERR: 'Failed to update member.',
    MULTIPLE_EDITORS:
        'There is more than one editor on this project. Be careful when making changes at the same time.',

    STATUS_LOADING: 'Saving...',
    STATUS_SUCCESS: 'All changes saved.',
    STATUS_FAIL: 'Unable to save. Check your internet connection.',

    CREATE_TEAM_SUCCESS: 'Team members successfully added with unlimited access.',
    UPGRADE_MESSAGE: 'Your trial has expired. Please upgrade for unlimited access.',
    DOWNGRADE_OWNER_MESSAGE:
        'You will be downgraded to a free account and lose access to your created projects.',
    LEAVE_TEAM_SELF_MESSAGE:
        'By leaving the team, you will be downgraded to a free account and removed as collaborator from all team projects.',
    LEAVE_TEAM_BY_OWNER_MESSAGE:
        'By removing this user from the team, they will be downgraded to a free account and removed as collaborator from all team projects',
    DOWNGRADE_TEAM_OWNER_MESSAGE:
        'All team members will be downgraded to a free account and lose access to their created projects.',
    DELETE_USER_ACCOUNT_ERR: 'Failed to delete user account.',
}
