import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PrintModal from '../components/Print/PrintModal'
import config from '../config'
import http from '../utils/http'
import { orderScenes } from '../utils/scene'

import Loader from '../components/common/loader'

/**
 * @module PrintModalContainer
 */

class PrintModalContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = { scenes: props.scenes }
    }

    componentWillMount() {
        // User may attempt to print the project from the projects page (not an InProject view)
        // Must fetch project scenes in this case
        if (!this.state.scenes) {
            const URL = `${config.apiUrl}/projects/${this.props.projectId}/scenes`
            http.get(URL)
                .then(scenes =>
                    this.setState({ scenes: orderScenes(scenes, this.props.project?.sceneOrder) }),
                )
                .catch(error => console.error(error))
        }
    }

    render() {
        //   No scenes? bail!
        if (!this.state.scenes) {
            return (
                <div className="custom-loader-container">
                    <Loader message="Loading..." style={{ top: '100px' }} />
                </div>
            )
        }
        // Else, got all data, show print modal
        return (
            <PrintModal
                scenes={this.state.scenes}
                returnTo={this.props.returnTo}
                {...R.omit(['scenes'], this.props)}
            />
        )
    }
}

PrintModalContainer.propTypes = {
    fetchProjectById: PropTypes.func,
    project: PropTypes.object,
    projectId: PropTypes.string,
    returnTo: PropTypes.string,
    scenes: PropTypes.array,
    session: PropTypes.object,
}

PrintModalContainer.displayName = 'PrintModalContainer'

export default withRouter(
    connect((state, ownProps) => {
        let { album, permissions, members, session, scenes } = state
        const projectId = ownProps.match.params.projectId
        let getActiveProject = R.find(R.propEq('id', projectId))

        return {
            project: state.project || getActiveProject(state.projects || []),
            projectId,
            album,
            permissions,
            scenes,
            members,
            session,
        }
    })(PrintModalContainer),
)
