import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StyledButton } from './styled'
import Modal from '../common/Modal'

export default class DeleteSceneConfirm extends Component {
    static propTypes = {
        cancel: PropTypes.func,
        confirm: PropTypes.func,
        deleteScene: PropTypes.func,
        scene: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = { processing: false }
        this.delete = this.delete.bind(this)
    }

    delete() {
        if (this.state.processing) {
            return
        }
        this.setState({ processing: true })
        this.props.confirm()
    }

    render() {
        return (
            <Modal
                isCloseable
                isCancelable
                isProcessing={this.state.processing}
                title="Delete Scene?"
                subtitle="This scene will be removed from the project.">
                <StyledButton
                    danger
                    fullwidth
                    disabled={this.state.processing}
                    className="m-b-xs"
                    onClick={() => this.delete()}>
                    {this.state.processing ? 'Deleting Scene ...' : 'Delete'}
                </StyledButton>
            </Modal>
        )
    }
}
