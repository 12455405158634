import React from 'react';

const FormInput = ({ value, label, name, type = 'text', onChange }) => (
  <div className="row form-group">
    <input
      className="form-control"
      type={type}
      value={value}
      name={name}
      onChange={onChange}
      placeholder={label}
    />
  </div>
);

export default FormInput;
