import { assign } from 'lodash';
import * as R from 'ramda';

const conditions = R.compose(R.prop('conditions'), R.prop('policy'));

const getConditions = uploadKeyRaw => {
  return R.mergeAll(
    R.filter(R.compose(R.not, R.is(Array)), conditions(uploadKeyRaw))
  );
};

export default (uploadKeyRaw, contentType = 'image/jpeg') => {
  return R.omit(
    ['bucket'],
    assign(
      {
        acl: 'public-read',
        'Content-Type': contentType,
        'x-amz-meta-tag': '',
        Policy: R.prop('base64Policy', uploadKeyRaw),
        'X-Amz-Signature': R.prop('signature', uploadKeyRaw),
      },
      getConditions(uploadKeyRaw)
    )
  );
};
