import React from 'react'
import { Route, Redirect } from 'react-router'
import CognitoSignIn from './Auth/CognitoSignIn'
import CognitoRegister from './Auth/CognitoRegister'
import CognitoProviderRegister from './Auth/CognitoProviderRegister'
import CognitoForgot from './Auth/CognitoForgot'
import CognitoReset from './Auth/CognitoReset'
import CognitoInvitation from './Auth/CognitoInvitation'
import CognitoUnverified from './Auth/CognitoUnverified'
import CognitoVerify from './Auth/CognitoVerify'
import AuthResendVerificationCode from './Auth/AuthResendVerificationCode'

export default props =>
    props.session ? (
        <Redirect {...props} to={'/projects'} />
    ) : (
        <div className="fill vcc text-center">
            <div className="vc signup">
                <div className="logo animated fadeInUp" />
                <Route exact path="/authenticate" render={() => <CognitoRegister {...props} />} />
                <Route
                    exact
                    path="/authenticate/coupon/:provider_name?"
                    render={() => <CognitoProviderRegister {...props} />}
                />
                <Route path="/authenticate/signin" render={() => <CognitoSignIn {...props} />} />
                <Route path="/authenticate/forgot" render={() => <CognitoForgot {...props} />} />
                <Route
                    path="/authenticate/resend"
                    render={() => <AuthResendVerificationCode {...props} />}
                />
                <Route path="/authenticate/reset" render={() => <CognitoReset {...props} />} />
                <Route
                    path="/authenticate/invitation/:email"
                    render={() => <CognitoInvitation {...props} />}
                />
                <Route
                    path="/authenticate/unverified"
                    render={() => <CognitoUnverified {...props} />}
                />
                <Route
                    path="/authenticate/verify/:email/:code?"
                    render={() => <CognitoVerify {...props} />}
                />
                <div className="text-center text-white" style={{ width: 300, margin: '40px auto' }}>
                    By using Plot you agree to our{' '}
                    <a href="https://theplot.io/terms/" target="_blank" rel="noopener noreferrer">
                        terms and conditions
                    </a>{' '}
                    and{' '}
                    <a href="https://theplot.io/privacy/" target="_blank" rel="noopener noreferrer">
                        privacy policy
                    </a>
                </div>
            </div>
        </div>
    )
