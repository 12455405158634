import React from 'react'

import ProjectFolderList from './projectFolderList'
import { ProjectFolderWrapper, NewProjectFolderButton, ProjectFolderHamburgerMenu } from './styled'
import CreateFolderIcon from '../../icons/CreateFolderIcon'
import HamburgerMenu from '../../icons/HamburgerMenu'
import CloseMenu from '../../icons/CloseMenu'

const ProjectFolder = ({
    folders,
    handleFolderClick,
    currentFolder,
    isMobile,
    handleProjectFolderClick,
    showProjectFolder,
}) => {
    const trialExpired = false
    return (
        <ProjectFolderWrapper isMobileView={isMobile} showProjectFolder={showProjectFolder}>
            <ProjectFolderHamburgerMenu onClick={handleProjectFolderClick} className="cp">
                {isMobile ? (
                    showProjectFolder ? (
                        <CloseMenu />
                    ) : (
                        <HamburgerMenu viewBox="0 0 25 25" size="30px" />
                    )
                ) : null}
            </ProjectFolderHamburgerMenu>
            {(!isMobile || showProjectFolder) && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            height: '80px',
                        }}>
                        <NewProjectFolderButton
                            button={+true}
                            primary={+true}
                            disabled={!folders}
                            className="project-new-folder"
                            to={{
                                pathname: trialExpired ? '/billing' : '/folders/create',
                                state: { modal: true, closeable: true, upgrade: trialExpired },
                            }}>
                            <CreateFolderIcon
                                viewBox="0 1 24 24"
                                style={{ width: '50px', height: '45px', paddingRight: '10px' }}
                            />
                            New Folder
                        </NewProjectFolderButton>
                    </div>
                    <ProjectFolderList
                        isMobile={isMobile}
                        currentFolder={currentFolder}
                        handleFolderClick={handleFolderClick}
                        folders={folders}
                    />
                </>
            )}
        </ProjectFolderWrapper>
    )
}

export default ProjectFolder
