import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import DeleteAccount from '../components/MyAccount/DeleteAccount'
import * as userAccountActions from '../actions/UserAccountAction'
import Modal from '../components/common/Modal'

const FREE = 'free'

const goBack = history => {
    history.replace({
        pathname: history?.goBack(),
        state: {
            modal: true,
            closable: true,
        },
    })
}

const DeleteAccountContainer = props => {
    return props?.user?.paymentCycle === FREE || props?.user?.cancellationDate !== 0 ? (
        <DeleteAccount goBack={() => goBack(props.history)} {...props} />
    ) : (
        <Modal
            isCloseable
            title="Access Denied"
            subtitle="Cannot delete account unless you cancel your subscription"
        />
    )
}

export default withRouter(
    connect(
        state => {
            let { session, user: userState } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            const cognitoUser = (session && session.cognitoUser) || {}
            return {
                user,
                userLoading: userState.loading,
                cognitoUser,
                deleteAccountStatus: userState.deleteAccountStatus,
            }
        },
        {
            ...userAccountActions,
        },
    )(DeleteAccountContainer),
)
