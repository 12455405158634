import { createAction } from 'redux-actions';
import * as types from '../constants/TeamConstant';

export const createTeamPlan = createAction(types.CREATE_TEAM_PLAN);
export const leaveTeamPlan = createAction(types.LEAVE_TEAM_PLAN);
export const updateTeamPlan = createAction(types.UPDATE_TEAM_PLAN);
export const updateTeamPlanResp = createAction(types.UPDATE_TEAM_PLAN_RESP);
export const fetchTeamPlan = createAction(types.FETCH_TEAM_PLAN);
export const fetchTeamPlanResp = createAction(types.FETCH_TEAM_PLAN_RESP);
export const fetchUserByEmail = createAction(types.FETCH_USER_BY_EMAIL);
export const fetchUserByEmailResp = createAction(types.FETCH_USER_BY_EMAIL);
