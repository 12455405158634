import { call, fork, put } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga'
import * as api from './api'
import config from '../config'
import { FETCH_SUBSCRIPTION_PLAN } from '../constants/ActionTypes'
import * as actions from '../actions/SubscriptionPlanActions'

function* fetchSubscriptionPlan({ payload }) {
    const URL = `${config.apiUrl}/users/me/subscription`
    const { response, error } = yield call(() => api.get(URL))
    if (error) {
        window.notify.error('There was a problem fetching subscription plan.', error)
        return
    }
    yield put(actions.fetchSubscriptionPlanResp(response))
}

function* watchFetchSubscriptionPlan() {
    yield* takeLatest(FETCH_SUBSCRIPTION_PLAN, fetchSubscriptionPlan)
}

export default function*() {
    yield fork(watchFetchSubscriptionPlan)
}
