import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { verifyAttribute, sendAttributeVerificationCode } from '../Auth/lib/cognito'
import actions from '../../actions'
import { StyledButton, StyledInput } from './styled'

import Modal from '../common/Modal'

function VerifyEmailInterstitial({ user = {}, userLoading, updateUser, fetchUser, history }) {
    const inputRef = React.useRef()

    const resendVerificationCode = e => {
        e.preventDefault()
        sendAttributeVerificationCode({ attribute: 'email' })
            .then(() => {
                window.$N.info('A verification code was sent to your email')
            })
            .catch(e => {
                window.$N.error(`Resending verification code failed. Reason: ${e.message}`)
            })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const onDone = () => {
            window.$N.info('Email successfully verified')
            history.replace({
                pathname: `/profile`,
                state: { modal: true, closeable: true },
            })
        }
        const payload = {
            attribute: 'email',
            email: user.oldEmail,
            code: inputRef.current.value,
        }
        try {
            await verifyAttribute(payload)
            fetchUser({ onDone })
        } catch (e) {
            window.$N.error(
                `Email verification failed. Try resending the verification code. Reason: ${e.message}`,
            )
        }
    }

    const revertToOldEmail = e => {
        e.preventDefault()
        updateUser({
            email: user.oldEmail,
            onDone: () =>
                history.replace({
                    pathname: `/profile/verify-email`,
                    state: {
                        modal: true,
                        closeable: false,
                        oldEmail: user.email,
                    },
                }),
        })
    }

    return (
        <Modal
            isCloseable={false}
            title="Verify New Email"
            subtitle="To continue, enter the code we sent to your new e-mail address.">
            <form className="content" onSubmit={handleSubmit}>
                <p className="text-warning text-center m-b-md">{user.email}</p>
                <StyledInput ref={inputRef} autoFocus name="code" label="Enter Verification Code" />
                <StyledButton
                    primary
                    disabled={userLoading}
                    type="submit"
                    className="wrapper-sm m-t-md m-b-sm block m-l-auto m-r-auto">
                    {userLoading ? 'Loading...' : 'Submit'}
                </StyledButton>
            </form>
            <p className="text-center m-sm">or</p>
            <StyledButton
                disabled={userLoading}
                onClick={revertToOldEmail}
                className="bg-light wrapper-sm text-muted m-t-sm m-b-sm block m-l-auto m-r-auto">
                {`Use ${user.oldEmail}`}
            </StyledButton>
            <StyledButton
                modalTransparentBtn
                disabled={userLoading}
                onClick={resendVerificationCode}
                className="wrapper-sm m-t-md block m-l-auto m-r-auto">
                Resend verification code
            </StyledButton>
        </Modal>
    )
}

export default withRouter(
    connect(
        state => {
            let { session, user: userState } = state
            const user = userState && userState.loading ? session.user || session : userState.user
            return { user, userLoading: userState.loading }
        },
        { ...actions.session },
    )(VerifyEmailInterstitial),
)
