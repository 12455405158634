import styled, { css } from 'styled-components'

import CommonStyledButton from '../../common/styled/StyledButton'
import CommonStyledInput from '../../common/styled/StyledInput'

export const StyledInput = styled(CommonStyledInput)`
    text-align: left !important;
`
export const StyledButton = styled(CommonStyledButton)``

export const PaymentWrapper = styled.div`
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-wrap: nowrap;
    h2 {
        color: #fff;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 20px;
    }

    .subscription-profile {
        margin-right: 25px;
        .subscription-plan,
        .subscription-type {
            display: flex;
        }
        .subscription-plan {
            flex-direction: row;
        }
        .subscription-type {
            flex-direction: column;
        }
    }
`

export const PaymentSubscriptionPlanSection = styled.section`
    position: relative;
    &:not(:first-child) {
        padding-left: 15px;
    }
    button {
        line-height: unset;
        padding: 10px 20px;
        background: #383e3e;
        font-weight: bold;
        border-color: transparent;
        margin-top: 5px;
        text-transform: capitalize;
    }
    .subscription-plan__text {
        font-weight: bold;
        text-align: center;
        font-style: italic;
        text-transform: uppercase;
        position: absolute;
        top: -15px;
        left: 0;
        right: 0;
    }
    ${props =>
        props.active &&
        css`
            .subscription-plan__text {
                color: ${props => props.theme.color.plot_green};
            }
            button {
                background: ${props => props.theme.color.plot_green};
            }
        `}
`

export const PaymentSubscriptionTypeSection = styled.section`
    padding: 15px 20px;
    border: 2px solid #686f75;
    margin-top: 15px;
    text-align: center;
    border-radius: 4px;
    background: #383e3e;
    color: #9c9c9c;
    .plan-name {
        font-weight: bold;
        text-transform: uppercase;
    }
    .plan-price {
        span {
            font-size: 20px;
        }
    }
    .plan-collaborator-limit {
        text-transform: capitalize;
    }
    ${props =>
        !props.disabled &&
        css`
            &:hover {
                cursor: pointer;
                border-color: ${props => props.theme.color.plot_green};
            }
        `}

    ${props =>
        props.active &&
        css`
            background: #1f4339;
            border-color: ${props => props.theme.color.plot_green};
            .plan-name,
            .plan-price {
                color: ${props => props.theme.color.plot_green};
            }
            .plan-collaborator-limit {
                color: #fff;
            }
        `}
`

export const StyledLabel = styled.label`
    right: 0;
    bottom: 0;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 0.7em;
    position: absolute;
    text-align: right;
    color: #686f75;
    text-transform: uppercase;
    font-weight: bold;
`
