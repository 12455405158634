import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import BillingSuccess from './BillingSuccess'
import { isFreeAccount, isCancelled } from '../../utils/permissions'

export default withRouter(
    connect((state, ownProps) => {
        let { coupon, session, user: userState } = state
        const user = userState && userState.loading ? session.user || session : userState.user
        return {
            coupon,
            showCompletionMessage: !!ownProps.location.state && !!ownProps.location.state.complete,
            isFreeAccount: isFreeAccount(user),
            isCancelled: isCancelled(user),
        }
    })(BillingSuccess),
)
