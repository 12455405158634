import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { COMMENT_STATUS } from '../../../constants/Comments';
/**
 * @module ActionButtons
 */

class ActionButtons extends React.Component {
  render() {
    let { comment, permissions, session, resolve, unresolve } = this.props;
    let isResolved = R.equals(COMMENT_STATUS.RESOLVED, comment.status);
    let userIsCommentOwner = session.userId === comment.userId;
    let btn__cls = isResolved ? 'text-primary-important' : '';
    return (
      <div className="pull-right action-buttons">
        <i
          className="fa fa-reply m-r-sm cp"
          aria-hidden="true"
          onClick={() => this.props.toggleReply(comment.id)}
        />
        {(permissions.owner || permissions.edit) && (
          <i
            className={`fa fa-check cp ${btn__cls}`}
            aria-hidden="true"
            onClick={() => (isResolved ? unresolve(comment) : resolve(comment))}
          />
        )}
        {userIsCommentOwner && (
          <i
            className="fa fa-times m-l-sm cp"
            aria-hidden="true"
            onClick={() => {
              const groupedCommentThread = this.props.getGroupedCommentThread(
                comment
              );
              this.props.delete(groupedCommentThread);
            }}
          />
        )}
      </div>
    );
  }
}

ActionButtons.defaultProps = {
  defaultPropGoesHere: 'default prop',
};

ActionButtons.propTypes = {
  comment: PropTypes.object,
  delete: PropTypes.func,
  permissions: PropTypes.object,
  resolve: PropTypes.func,
  session: PropTypes.object,
  toggleReply: PropTypes.func,
  unresolve: PropTypes.func,
};

ActionButtons.displayName = 'ActionButtons';

export default ActionButtons;
