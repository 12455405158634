import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import Modal from '../common/Modal'

import config from '../../config'
import { isFreeAccount, isTrial, ownProjectCount } from '../../utils/permissions'
import { duplicateProject, createProject } from '../../actions/ProjectActions'

import { StyledInput, StyledButton } from './styled'

const ProjectCreate = ({ duplicate, currentFolderId, initialValues }) => {
    const [title, setTitle] = useState(initialValues ? initialValues.title : '')
    const [error, setError] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const status = duplicate ? 'Duplicate' : 'New'
    const action = duplicate ? 'Duplicate' : 'Create'

    const dispatch = useDispatch()
    const projects = useSelector(state => state.projects)
    const user = useSelector(({ session, user: userData }) => ({
        user: userData.user && userData.user.loading ? session.user || session : userData.user,
    }))

    const validate = value => {
        if (!value) {
            setError('Please enter project title')
        } else {
            setError(null)
        }
        return value
    }

    const handleSubmit = e => {
        e.preventDefault()
        setSubmitting(true)

        const createOrDuplicateAction = duplicate ? duplicateProject : createProject
        dispatch(
            createOrDuplicateAction({
                values: { title, folderId: currentFolderId },
            }),
        )
    }

    const handleChange = ({ target: { value } }) => setTitle(validate(value.trim()))

    if (
        !isTrial(user) &&
        !user.team &&
        isFreeAccount(user) &&
        ownProjectCount(projects) >= config.maximumProjectCount
    )
        return (
            <Redirect
                to={{
                    pathname: '/billing',
                    state: { modal: true, closeable: true, upgrade: true },
                }}
            />
        )
    return (
        <Modal
            isCloseable={!submitting}
            isCancelable={!submitting}
            wrapperTitleClassName="m-b-xs"
            title={`${status} Project`}>
            <div className="content">
                <form onSubmit={handleSubmit}>
                    <div className="clearfix">
                        <div className="profile__row text m-b-md">
                            <StyledInput
                                id="project-title"
                                key="title"
                                name="title"
                                type="text"
                                disabled={submitting}
                                label={error ? error : 'Project Name'}
                                defaultValue={title}
                                className={`_lr-hide text-white profile__value ${
                                    error ? 'invalid' : ''
                                }`}
                                onChange={handleChange}
                                autoFocus
                            />
                        </div>
                        <StyledButton
                            primary
                            fullwidth
                            type="submit"
                            disabled={submitting || error || !title.length}>
                            {submitting
                                ? duplicate
                                    ? 'Duplicating Project...'
                                    : 'Creating Project...'
                                : `${action} Project`}
                        </StyledButton>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default React.memo(ProjectCreate)
