import { createAction } from 'redux-actions'

import * as types from '../constants/ActionTypes'
import * as focusedScriptType from './FocusedScriptTypeActions'
import * as TeamAction from './TeamAction'
import * as applicationActions from './ApplicationActions'
import * as subscriptionPlanAction from './SubscriptionPlanActions'
import * as userAccountAction from './UserAccountAction'
import * as folderAction from './FolderActions'
import * as projectsAction from './ProjectsActions'

export default {
    global: applicationActions,
    comments: {
        clearCommentsSceneIdFilter: createAction(types.CLEAR_COMMENTS_SCENE_ID_FILTER),
        createComment: createAction(types.CREATE_COMMENT),
        createCommentResp: createAction(types.CREATE_COMMENT_RESP),
        deleteComment: createAction(types.DELETE_COMMENT),
        deleteCommentResp: createAction(types.DELETE_COMMENT_RESP),
        filterCommentsBySceneId: createAction(types.FILTER_COMMENTS_BY_SCENE_ID),
        updateComment: createAction(types.UPDATE_COMMENT),
        updateCommentResp: createAction(types.UPDATE_COMMENT_RESP),
    },
    coupons: {
        applyCoupon: createAction(types.APPLY_COUPON),
        applyCouponResp: createAction(types.APPLY_COUPON_RESP),
        defaultCoupon: createAction(types.DEFAULT_COUPON),
    },
    projects: {
        addMemberToProject: createAction(types.ADD_MEMBER_TO_PROJECT),
        addMemberToProjectResp: createAction(types.ADD_MEMBER_TO_PROJECT_RESP),
        createProject: createAction(types.CREATE_PROJECT),
        duplicateProject: createAction(types.DUPLICATE_PROJECT),
        createProjectResp: createAction(types.CREATE_PROJECT_RESP),
        deleteImageInProject: createAction(types.DELETE_IMAGE_IN_PROJECT),
        deleteImageInProjectResp: createAction(types.DELETE_IMAGE_IN_PROJECT_RESP),
        deleteMemberInProject: createAction(types.DELETE_MEMBER_IN_PROJECT),
        deleteMemberInProjectResp: createAction(types.DELETE_MEMBER_IN_PROJECT_RESP),
        deleteProject: createAction(types.DELETE_PROJECT),
        deleteProjectResp: createAction(types.DELETE_PROJECT_RESP),
        fetchProjectAlbum: createAction(types.FETCH_PROJECT_ALBUM),
        fetchProjectAlbumError: createAction(types.FETCH_PROJECT_ALBUM_ERR),
        fetchProjectAlbumResp: createAction(types.FETCH_PROJECT_ALBUM_RESP),
        fetchProjectById: createAction(types.FETCH_PROJECT_BY_ID),
        fetchPublicProjectById: createAction(types.FETCH_PUBLIC_PROJECT_BY_ID),
        fetchProjectByIdResp: createAction(types.FETCH_PROJECT_BY_ID_RESP),
        fetchProjectMembers: createAction(types.FETCH_PROJECT_MEMBERS),
        fetchProjectMembersResp: createAction(types.FETCH_PROJECT_MEMBERS_RESP),
        fetchProjects: createAction(types.FETCH_PROJECTS),
        fetchProjectsResp: createAction(types.FETCH_PROJECTS_RESP),
        searchProjectsByTitle: createAction(types.SEARCH_PROJECTS_BY_TITLE),
        setActiveProject: createAction(types.SET_ACTIVE_PROJECT),
        setProjectFilter: createAction(types.SET_PROJECT_FILTER),
        updateProject: createAction(types.UPDATE_PROJECT),
        updateProjectResp: createAction(types.UPDATE_PROJECT_RESP),
        uploadImage: createAction(types.UPLOAD_IMG),
        uploadImageResp: createAction(types.UPLOAD_IMG_RESP),
        updateMemberPermission: createAction(types.UPDATE_MEMBER_PERMISSION),
    },
    scenes: {
        activateScene: createAction(types.ACTIVATE_SCENE),
        clearActiveScene: createAction(types.CLEAR_ACTIVE_SCENE),
        clearScenesWithDirtyFlag: createAction(types.CLEAR_SCENES_WITH_DIRTY_FLAG),
        createScene: createAction(types.CREATE_SCENE),
        createSceneBySlice: createAction(types.CREATE_SCENE_BY_SLICE),
        createSceneResp: createAction(types.CREATE_SCENE_RESP),
        deleteScene: createAction(types.DELETE_SCENE),
        deleteSceneResp: createAction(types.DELETE_SCENE_RESP),
        reorderScene: createAction(types.REORDER_SCENE),
        reorderScenePreSync: createAction(types.REORDER_SCENE_PRE_SYNC),
        reorderSceneResp: createAction(types.REORDER_SCENE_RESP),
        setImage: createAction(types.SET_IMAGE),
        uploadSceneImage: createAction(types.UPLOAD_SCENE_IMAGE),
        cloneSetImage: createAction(types.CLONE_SET_IMAGE),
        setImageResp: createAction(types.SET_IMAGE_RESP),
        updateScene: createAction(types.UPDATE_SCENE),
        updateScenePreSync: createAction(types.UPDATE_SCENE_PRE_SYNC),
        updateSceneResp: createAction(types.UPDATE_SCENE_RESP),
    },
    session: {
        authenticate: createAction(types.AUTHENTICATE),
        cancelSubscription: createAction(types.CANCEL_SUBSCRIPTION),
        cancelSubscriptionResp: createAction(types.CANCEL_SUBSCRIPTION_RESP),
        fixCard: createAction(types.FIX_CARD),
        fixCardResp: createAction(types.FIX_CARD_RESP),
        createSession: createAction(types.CREATE_SESSION),
        deleteSession: createAction(types.DELETE_SESSION),
        logout: createAction(types.LOGOUT),
        upgrade: createAction(types.UPGRADE),
        upgradeResp: createAction(types.UPGRADE_RESP),
        fetchUserLoading: createAction(types.FETCH_USER_LOADING),
        fetchUser: createAction(types.FETCH_USER),
        fetchUserSuccess: createAction(types.FETCH_USER_SUCCESS),
        updateUser: createAction(types.UPDATE_USER),
        updateUserSuccess: createAction(types.UPDATE_USER_SUCCESS),
        updateUserLoading: createAction(types.UPDATE_USER_SUCCESS),
    },
    userAccountAction,
    trial: {
        showTrialExpire: createAction(types.SHOW_TRIAL_EXPIRE),
    },
    focusedScriptType,
    teamPlan: TeamAction,
    subscriptionPlan: subscriptionPlanAction,
    folderAction,
    projectsAction,
}
