import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { Elements, StripeProvider } from '@stripe/react-stripe-js'

import config from '../../../config'
import PLAN from '../../../constants/Plan'
import Modal from '../../common/Modal'
import SubscriptionPlanSelector from './SubscriptionPlanSelector'
import SeriousWarningIcon from '../../../icons/SeriousWarningIcon'
import CheckoutForm from '../CheckoutForm'

import { PaymentWrapper, StyledButton } from './styled'

const DEFAULT_CYCLE = 'monthly'
const PLAN_MONTHLY = 'monthly'
const DEFAULT_PLAN = PLAN.PRO_PLAN_MONTH

const PLAN_INTERVAL = {
    monthly: 'month',
    yearly: 'year',
}

function Stripe(props) {
    const [state, setState] = useState({
        paymentCycle: DEFAULT_CYCLE,
        subscriptionPaymentPrice: 0,
        selectedPlanId: null,
        selectedProductId: config.stripeProductProId || null,
        productWithPaymentCycleName: null,
        processing: false,
        showDowngradeModal: false,
        subscriptionError: null,
    })
    const [stripe, setStripe] = useState(null)

    React.useEffect(() => {
        if (R.isNil(stripe)) {
            setStripe(window.Stripe(config.stripeKey))
        }
    }, [])

    React.useEffect(() => {
        onPaymentPlanChange(state.paymentCycle)
    }, [props.coupon, state.productWithPaymentCycleName])

    const getRankFromPlan = paymentCycle => {
        return R.compose(
            R.path(['metadata', 'rank']),
            R.find(R.propEq('subscriptionPlanName', paymentCycle)),
        )(props.productPlans)
    }

    const checkPlanRankIsDowngrade = () => {
        const currentPlanRank = parseInt(getRankFromPlan(props.currentPaymentCycle))
        const selectedPlanRank = parseInt(getRankFromPlan(state.productWithPaymentCycleName))
        if (currentPlanRank > selectedPlanRank) {
            return true //downgrade
        }
        return false //upgrade
    }

    const teamMemberCount =
        !checkPlanRankIsDowngrade() && props.teamInfo && R.length(props.teamInfo.members) > 0
            ? R.length(props.teamInfo.members)
            : 1

    const sortedProductPlans = {
        yearly: R.filter(
            plan => plan.product !== config.stripeOldProductId && plan.interval === 'year',
            props.productPlans,
        ),
        monthly: R.filter(
            plan => plan.product !== config.stripeOldProductId && plan.interval === 'month',
            props.productPlans,
        ),
    }

    const onPaymentPlanChange = paymentCyclePlan => {
        const plan = R.compose(
            R.prop([0]),
            R.filter(
                plan =>
                    plan.product === state.selectedProductId &&
                    plan.interval === PLAN_INTERVAL[paymentCyclePlan],
            ),
            R.prop(paymentCyclePlan),
        )(sortedProductPlans)
        if (!R.isNil(plan)) {
            onSubscriptionTypeChange(
                state.selectedProductId,
                plan.id,
                plan.subscriptionPlanName,
                paymentCyclePlan,
            )
        }
    }

    const calculateSubscriptionPaymentPrice = (paymentCycle, productId) => {
        const amount = R.pipe(
            R.filter(plan => plan.product === productId),
            R.pluck('amount'),
        )(sortedProductPlans[paymentCycle])
        let totalAmount = (Number(amount) * teamMemberCount) / 100
        if (props.coupon.valid && props.coupon.discount) {
            let discountAmount
            if (props.coupon.discount.indexOf('%') > -1) {
                const discount = parseInt(props.coupon.discount, 10)
                discountAmount = (discount / 100) * totalAmount
            } else if (props.coupon.discount.indexOf('$') > -1) {
                discountAmount = Number(props.coupon.discount.replace(/[^0-9.-]+/g, ''))
            }
            totalAmount = totalAmount - discountAmount
        }
        return totalAmount
    }

    const onSubscriptionTypeChange = (
        productId,
        planId,
        productWithPaymentCycleName = DEFAULT_PLAN,
        paymentCyclePlan = state.paymentCycle,
    ) => {
        setState({
            ...state,
            paymentCycle: paymentCyclePlan,
            selectedPlanId: planId,
            productWithPaymentCycleName: productWithPaymentCycleName,
            selectedProductId: productId,
            subscriptionPaymentPrice: calculateSubscriptionPaymentPrice(
                paymentCyclePlan,
                productId,
            ),
        })
    }

    const planPriceCalculate = price => {
        const calc_price = state.paymentCycle === PLAN_MONTHLY ? price : Number(price) / 12
        return (Number(calc_price) / 100).toFixed(2)
    }

    const handleSubscriptionSubmit = (ev, stripePayload) => {
        ev.preventDefault()
        if (checkPlanRankIsDowngrade() && props.isTeamOwner) {
            setState({ ...state, showDowngradeModal: true })
        } else {
            onUpgrade(ev, stripePayload)
        }
    }

    const closeWarning = ev => {
        ev && ev.preventDefault()
        setState({ ...state, showDowngradeModal: false })
    }

    const onUpgrade = (ev, stripePayload = {}) => {
        ev.preventDefault()
        // Get stripe call payload
        if (state.showDowngradeModal) {
            setState({ ...state, processing: true })
        } else {
            setState({ ...state, showDowngradeModal: false, processing: true })
        }

        let coupon = ''
        if (props.coupon.id) {
            coupon = props.coupon.id
        }

        // Complete subscription; Must send coupon here
        props.upgrade({
            stripePayload,
            coupon: coupon,
            paymentCycle: state.productWithPaymentCycleName,
            subscriptionPlanId: state.selectedPlanId, // maybe try to use stripe product plan id and plan interval to refactor.
            subscriptionProductId: state.selectedProductId,
            upgrade: true,
            onDone: err => {
                if (err === state.subscriptionError) {
                    // This is a 'hack' to force React to trigger a hook even if the error is the same
                    // as the last time we submitted the CC info
                    err = err + ' '
                }
                setState({
                    ...state,
                    showDowngradeModal: false,
                    processing: false,
                    subscriptionError: err,
                })
                props.onDone(err)
            },
        })
    }
    if (state.showDowngradeModal) {
        let subtitle = `All collaborators on your existing projects will be removed. \n This cannot be undone!`
        if (props.isTeamOwner) {
            subtitle = `Your team will be deleted and all existing project invites from your team will be removed. \n This cannot be undone!`
        }
        return (
            <Modal
                isCloseable={!state.processing}
                goBack={closeWarning}
                title="WARNING"
                subtitle={subtitle}>
                <div className="text-center">
                    <SeriousWarningIcon />
                </div>
                <div className="m-t-md">
                    <StyledButton
                        disabled={state.processing}
                        danger
                        alignCenterHorizontal
                        onClick={onUpgrade}>
                        {state.processing ? 'Processing Payment' : 'Delete Team & Downgrade'}
                    </StyledButton>
                </div>
                {!state.processing && (
                    <div className="m-t-md">
                        <StyledButton
                            alignCenterHorizontal
                            modalTransparentBtn
                            onClick={closeWarning}>
                            Nevermind
                        </StyledButton>
                    </div>
                )}
            </Modal>
        )
    }

    return (
        <Modal isCloseable goBack={props.goBack}>
            <PaymentWrapper>
                <SubscriptionPlanSelector
                    paymentCycle={state.paymentCycle}
                    onPaymentPlanChange={onPaymentPlanChange}
                    onSubscriptionTypeChange={onSubscriptionTypeChange}
                    sortedProductPlans={sortedProductPlans}
                    planPriceCalculate={planPriceCalculate}
                    selectedProductId={state.selectedProductId}
                />
                <div className="subscriber-profile">
                    <div className="text-center">
                        <h2>Upgrade to Continue</h2>
                        <p>
                            Select your plan and input your information. In just a few clicks you'll
                            be saving time and building better storyboards!
                        </p>
                    </div>
                    <Elements stripe={stripe}>
                        <CheckoutForm
                            teamMemberCount={teamMemberCount}
                            handleSubscriptionSubmit={handleSubscriptionSubmit}
                            selectedProductId={state.selectedProductId}
                            subscriptionPaymentPrice={state.subscriptionPaymentPrice}
                            showSubscriptionBtn={true}
                            paymentCycle={state.paymentCycle}
                            showCouponField={true}
                            coupon={props.coupon}
                            applyCoupon={props.applyCoupon}
                            showSubscriptionPaymentInfo={false}
                            subscriptionError={state.subscriptionError}
                        />
                    </Elements>
                </div>
            </PaymentWrapper>
        </Modal>
    )
}

Stripe.propTypes = {
    confirm: PropTypes.func,
    fields: PropTypes.array,
    // Boolean value computed from state.session
    // On subscription, if successful, the auth saga updates state.session
    // with the new subscription info and key turn to false
    isFreeAccount: PropTypes.bool,
    name: PropTypes.string,
    upgrade: PropTypes.func,
}

Stripe.displayName = 'Stripe'

export default Stripe
