import React from 'react';
import style from './strokeStyle';

export default () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1079 720"
    style={style.svg}
  >
    <g>
      <path
        style={style.st0}
        d="M335.7,213.8H66.8c-0.3,0-0.5-0.2-0.5-0.5V62.5c0-0.3,0.2-0.5,0.5-0.5h268.8c0.3,0,0.5,0.2,0.5,0.5v150.8
		C336.2,213.6,336,213.8,335.7,213.8z"
      />
      <line style={style.st1} x1="85.2" y1="268.5" x2="317.2" y2="268.5" />
      <line style={style.st1} x1="85.2" y1="319.5" x2="317.2" y2="319.5" />
    </g>
    <g>
      <path
        style={style.st0}
        d="M674.7,213.8H405.8c-0.3,0-0.5-0.2-0.5-0.5V62.5c0-0.3,0.2-0.5,0.5-0.5h268.8c0.3,0,0.5,0.2,0.5,0.5v150.8
		C675.2,213.6,675,213.8,674.7,213.8z"
      />
      <line style={style.st1} x1="424.2" y1="268.5" x2="656.2" y2="268.5" />
      <line style={style.st1} x1="424.2" y1="319.5" x2="656.2" y2="319.5" />
    </g>
    <g>
      <path
        style={style.st0}
        d="M1013.7,213.8H744.8c-0.3,0-0.5-0.2-0.5-0.5V62.5c0-0.3,0.2-0.5,0.5-0.5h268.8c0.3,0,0.5,0.2,0.5,0.5v150.8
		C1014.2,213.6,1014,213.8,1013.7,213.8z"
      />
      <line style={style.st1} x1="763.2" y1="268.5" x2="995.2" y2="268.5" />
      <line style={style.st1} x1="763.2" y1="319.5" x2="995.2" y2="319.5" />
    </g>
    <g>
      <path
        style={style.st0}
        d="M335.7,549.8H66.8c-0.3,0-0.5-0.2-0.5-0.5V398.5c0-0.3,0.2-0.5,0.5-0.5h268.8c0.3,0,0.5,0.2,0.5,0.5v150.8
		C336.2,549.6,336,549.8,335.7,549.8z"
      />
      <line style={style.st1} x1="85.2" y1="604.5" x2="317.2" y2="604.5" />
      <line style={style.st1} x1="85.2" y1="655.5" x2="317.2" y2="655.5" />
    </g>
    <g>
      <path
        style={style.st0}
        d="M674.7,549.8H405.8c-0.3,0-0.5-0.2-0.5-0.5V398.5c0-0.3,0.2-0.5,0.5-0.5h268.8c0.3,0,0.5,0.2,0.5,0.5v150.8
		C675.2,549.6,675,549.8,674.7,549.8z"
      />
      <line style={style.st1} x1="424.2" y1="604.5" x2="656.2" y2="604.5" />
      <line style={style.st1} x1="424.2" y1="655.5" x2="656.2" y2="655.5" />
    </g>
    <g>
      <path
        style={style.st0}
        d="M1013.7,549.8H744.8c-0.3,0-0.5-0.2-0.5-0.5V398.5c0-0.3,0.2-0.5,0.5-0.5h268.8c0.3,0,0.5,0.2,0.5,0.5v150.8
		C1014.2,549.6,1014,549.8,1013.7,549.8z"
      />
      <line style={style.st1} x1="763.2" y1="604.5" x2="995.2" y2="604.5" />
      <line style={style.st1} x1="763.2" y1="655.5" x2="995.2" y2="655.5" />
    </g>
  </svg>
);
