import { combineReducers } from 'redux'
import { reducer as uiReducer } from 'redux-ui'
import { reducer as form } from 'redux-form'
import { loadingBarReducer } from 'react-redux-loading-bar'

import * as applicationReducers from './application'
import session from './session'
import user from './user'
import { projects, project, filter, permissions, search, imageUploading } from './project'
import { scene, scenes, sceneLoading } from './scene'
import { album } from './album'
import { comments, submittingComment, commentsFilteredBySceneId } from './comment'
import { members } from './members'
import coupon from './coupon'
import sketch from '../sketch/sketchReducer'
import globalActivityStatus from './globalActivityStatus'
import focusedScriptType from './focusedScriptType'
import subscriptionPlanInfo from './subscriptionPlan'

import team from './team'
import trialStatus from './trialStatus'

import { folders, currentFolder } from './folders'

const rootReducer = combineReducers({
    ...applicationReducers,
    album,
    comments,
    commentsFilteredBySceneId,
    coupon,
    form,
    imageUploading,
    loadingBar: loadingBarReducer,
    members,
    permissions,
    project,
    projectFilter: filter,
    projects,
    scene,
    sceneLoading,
    scenes,
    search,
    session,
    user,
    submittingComment,
    ui: uiReducer,
    sketch,
    globalActivityStatus,
    focusedScriptType,
    team,
    showTrialExpire: trialStatus,
    subscriptionPlanInfo,
    folders,
    currentFolder,
})

export default rootReducer
