export default {
    PLAN_MONTHLY: 'monthly',
    PLAN_YEARLY: 'yearly',
    SOLO_PLAN_MONTH: 'solo_month',
    SOLO_PLAN_YEAR: 'solo_year',
    PRO_PLAN_MONTH: 'pro_month',
    PRO_PLAN_YEAR: 'pro_year',
    BUSINESS_PLAN_MONTH: 'business_month',
    BUSINESS_PLAN_YEAR: 'business_year',
    TYPE_SOLO: 'solo',
    TYPE_PRO: 'pro',
    TYPE_BUSINESS: 'business',
    yearly: 'yr',
    monthly: 'mo',
}
