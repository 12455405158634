export const CREATE_FOLDER = 'CREATE_FOLDER'
export const CREATE_FOLDER_RESP = 'CREATE_FOLDER_RESP'
export const DELETE_FOLDER = 'DELETE_FOLDER'
export const DELETE_FOLDER_RESP = 'DELETE_FOLDER_RESP'
export const UPDATE_FOLDER = 'UPDATE_FOLDER'
export const UPDATE_FOLDER_RESP = 'UPDATE_FOLDER_RESP'
export const FETCH_FOLDERS = 'FETCH_FOLDERS'
export const FETCH_FOLDERS_RESP = 'FETCH_FOLDERS_RESP'
export const REORDER_FOLDERS = 'REORDER_FOLDERS'
export const REORDER_FOLDERS_RESP = 'REORDER_FOLDERS_RESP'
export const SET_CURRENT_FOLDER_TO = 'SET_CURRENT_FOLDER_TO'
