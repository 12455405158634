import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { append, intersection, keys, reduce } from 'ramda'
import { ElementScroller } from 'react-scroll-manager'

import Image from './image'
import Comments from '../../containers/CommentsContainer'
import Script from '../../containers/ScriptContainer'
import ImageUploadDisplay from '../imageUploadDisplay'

class Editor extends Component {
    static propTypes = {
        album: PropTypes.array,
        permissions: PropTypes.object,
        project: PropTypes.object,
        scene: PropTypes.object,
        scenes: PropTypes.array,
        showTabs: PropTypes.array,
    }

    static defaultProps = {
        showTabs: ['textareas', 'images', 'comments'],
    }

    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
        this.rootRef = React.createRef()
    }

    onClick(activeTab) {
        this.props.activateToolbarTab(activeTab)
        this.toolbarContainer.scrollTop = 0
    }

    render() {
        let { scene, isProjectPublic } = this.props
        const CONTENT_MAP = {
            textareas: {
                label: 'script-icon',
                content: () => <Script />,
            },
            images: {
                label: 'images-icon',
                content: () => {
                    if (!this.props.album.length && !this.props.imageUploading) {
                        return (
                            <div className="w100 h100 vcc wrapper-md">
                                <div className="vc b-2x-dashed">
                                    <div className="h4 text-center text-grey">
                                        Drag & Drop Images
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    let filteredImages = this.props.album.filter(url => !url.includes('.json'))
                    const albumDictionary = filteredImages.reduce((acc, imageUrl) => {
                        const timestamp = imageUrl.split('?')[1]
                        const imageBaseParts = imageUrl.split('.')
                        imageBaseParts.pop()
                        const imageBase = imageBaseParts.join('.')
                        const sketchJsonUrl = `${imageBase}.json?${timestamp}`
                        if (this.props.album.includes(sketchJsonUrl)) {
                            return Object.assign(acc, {
                                [imageBase]: { url: imageUrl, sketchJsonUrl },
                            })
                        }
                        return Object.assign(acc, { [imageBase]: { url: imageUrl } })
                    }, {})

                    return (
                        <div>
                            {this.props.imageUploading ? (
                                <ImageUploadDisplay fileCount={this.props.fileCount} />
                            ) : null}
                            {Object.keys(albumDictionary)
                                // Do not attempt to show sketchJson files in returned from album
                                .map((imageBase, index) => {
                                    return (
                                        <Image
                                            active={
                                                scene &&
                                                R.equals(
                                                    scene.image,
                                                    albumDictionary[imageBase].url,
                                                )
                                            }
                                            toolbarImageContainer={
                                                this.props.dropZoneStatus
                                                    ? this.toolbarImageContainer
                                                    : null
                                            }
                                            index={index}
                                            key={`toolbar-image__${index}`}
                                            url={albumDictionary[imageBase].url}
                                            sketchJsonUrl={albumDictionary[imageBase].sketchJsonUrl}
                                            permissions={this.props.permissions}
                                            {...R.pick(['deleteImageInProject'], this.props)}
                                        />
                                    )
                                })}
                        </div>
                    )
                },
            },
            comments: {
                label: 'toolbar-comments-icon',
                content: () => (
                    <Comments
                        isProjectPublic={isProjectPublic}
                        commentRefs={this.props.commentRefs}
                    />
                ),
            },
        }

        const shownTabs = intersection(keys(CONTENT_MAP), this.props.showTabs)

        let tabs = reduce(
            (r, x) => {
                return append(
                    <div
                        key={CONTENT_MAP[x].label}
                        className={`control-panel-tab col-sm-${12 / shownTabs.length} h100 ${
                            this.props.activeTab === x ? 'active' : ''
                        }`}
                        onClick={() => this.onClick(x)}>
                        <div className="vcc h100 w100">
                            <div className="vc text-center sn">
                                <span
                                    className={`${CONTENT_MAP[x].label} ${
                                        this.props.activeTab === x ? 'active' : ''
                                    }`}
                                />
                            </div>
                        </div>
                    </div>,
                    r,
                )
            },
            [],
            shownTabs,
        )

        return (
            <div className="w100 h100">
                <div className="col-xs-12 bg-white toolbar no-padder pos-abt w100">{tabs}</div>
                <div className="col-xs-12 bg-white text-area-container">
                    <div
                        ref={elem => {
                            this.toolbarContainer = elem
                        }}
                        className="toolbar-container fill scroll-y">
                        <ElementScroller scrollKey="toolbar">
                            <div className="h100 w100 container" style={{ wordWrap: 'break-word' }}>
                                {CONTENT_MAP[this.props.activeTab].content.bind(this)()}
                            </div>
                        </ElementScroller>
                    </div>
                </div>
            </div>
        )
    }
}

export default Editor
