import http from '../utils/http'
import config from '../config'
import request from 'superagent'
import * as R from 'ramda'
import Q from 'q'
import parseUploadKeyResp from './upload'

function apiGet(authenticated, url) {
    return http
        .get(authenticated, url)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

/**
 * Requests a Google reCAPTCHA token for server side validation
 * @see https://developers.google.com/recaptcha/docs/v3
 *
 * @param {string} action - the user action being verified
 * @returns {string} token to be verified
 */
export function getRecaptchaToken(action = 'default') {
    return window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
        action,
    })
}

export function getUploadKey(projectId) {
    const URL = `${config.apiUrl}/projects/${projectId}/album/upload-key`
    return apiGet(URL)
}

export function upload(kv, file, projectId) {
    let d = Q.defer()
    // Create request object
    let req = request.post(config.s3)
    // Attached form fields
    R.forEach(field => req.field(field, kv[field]), R.keys(kv))
    // finalize and upload
    req.field('key', `${projectId}/${file.name}`)
        .attach('file', file.blob ? file.blob : file, file.name)
        .end((error, response) => {
            if (error) {
                d.resolve({ error })
                return
            }
            d.resolve({ response })
        })

    return d.promise
}

export function get(url, authenticated, recaptchaToken) {
    return http
        .get(url, authenticated, recaptchaToken)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

export function patch(url, payload, authenticated, recaptchaToken) {
    return http
        .patch(url, payload, authenticated, recaptchaToken)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

export function put(url, payload, authenticated, recaptchaToken) {
    return http
        .put(url, payload, authenticated, recaptchaToken)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

export function post(url, payload, authenticated, recaptchaToken) {
    return http
        .post(url, payload, authenticated, recaptchaToken)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

export function delWithPayload(url, payload, authenticated, recaptchaToken) {
    return http
        .delWithData(url, payload, authenticated, recaptchaToken)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

export function deleteResource(url, authenticated, recaptchaToken) {
    return http
        .del(url, authenticated, recaptchaToken)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

export function batchDeleteResources(urls) {
    let d = Q.defer()
    Q.all(R.map(url => deleteResource(url), urls))
        .then(response => d.resolve({ response }))
        .catch(error => d.resolve({ error }))
    return d.promise
}

export function uploadImage(projectId, uploadKeyResponse, file) {
    const uploadKey = parseUploadKeyResp(
        uploadKeyResponse,
        file.name.endsWith('.json') ? 'application/json' : 'image/jpeg',
    )
    return upload(uploadKey, file, projectId)
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

export function batchUploadImages(projectId, uploadKeyResponse, files) {
    let d = Q.defer()
    // There is a documented error with SuperAgent
    // https://github.com/visionmedia/superagent/issues/484
    // Ignoring this error for now.
    Q.allSettled(
        R.map(file => {
            // Get the uploadKey with the correct Content-Type header
            const uploadKey = parseUploadKeyResp(
                uploadKeyResponse,
                file.name.endsWith('.json') ? 'application/json' : 'image/jpeg',
            )
            return upload(uploadKey, file, projectId)
        }, files),
    )
        .then(response => d.resolve({ response }))
        .catch(error => d.resolve({ error }))

    return d.promise
}
