import PropTypes from 'prop-types'
import React from 'react'
import ProjectFilter from './projectFilter'

import {
    StyledLink,
    SearchProjectInput,
    StoryBoardsSearchHeaderWrapper,
    SearchProjectInputWrapper,
} from './styled'

const NEW_PROJECT = 'New Project'

export default class extends React.Component {
    static propTypes = {
        dropdownFilters: PropTypes.array,
        searchProjectsByTitle: PropTypes.func,
        setProjectFilter: PropTypes.func,
    }

    constructor(props) {
        super(props)
        this.searchRef = React.createRef()
        this.state = {
            searchKeywords: '',
        }
    }

    render() {
        let setPathTo = {
            pathname: 'projects/create',
            state: { modal: true, closeable: true },
        }
        if (this.props.isTrialExpired) {
            setPathTo = {
                pathname: '/billing',
                state: {
                    modal: true,
                    closeable: true,
                    upgrade: true,
                },
            }
        }
        return (
            <StoryBoardsSearchHeaderWrapper>
                {/* <div className="">
                    <ProjectFilter
                        filters={this.props.dropdownFilters}
                        setProjectFilter={this.props.setProjectFilter}
                    />
                </div> */}
                <SearchProjectInputWrapper>
                    <SearchProjectInput
                        searching={this.state.searchKeywords !== ''}
                        onChange={() => {
                            this.props.searchProjectsByTitle(this.searchRef.current.value.trim())
                            this.setState({ searchKeywords: this.searchRef.current.value.trim() })
                        }}
                        placeholder="Search"
                        ref={this.searchRef}
                        type="text"
                    />
                    {this.state.searchKeywords && (
                        <span
                            onClick={() => {
                                this.searchRef.current.value = ''
                                this.props.searchProjectsByTitle('')
                                this.setState({ searchKeywords: '' })
                            }}>
                            x
                        </span>
                    )}
                </SearchProjectInputWrapper>
                {/* <div style={{ cursor: !this.props.currentFolder ? 'not-allowed' : '' }}>
                    <StyledLink
                        button={+true}
                        style={{ padding: '0 10px' }}
                        primary={+true}
                        disabled={!this.props.currentFolder || !this.props.currentFolder.folderId}
                        to={{
                            pathname: '/projects/create',
                            state: {
                                modal: true,
                                closeable: true,
                            },
                        }}>
                        Create New Storyboard
                    </StyledLink>
                </div> */}
            </StoryBoardsSearchHeaderWrapper>
        )
    }
}
