import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton } from './styled'

const SubscriptionButton = props => {
    let { showUpgradeForm, isFreeAccount, isCancelled, isTeamMember, history, isDisabled } = props
    let actionBtn__cls = !showUpgradeForm ? '' : 'dn'
    return (
        <div className={`${actionBtn__cls}`}>
            {(!isTeamMember && isFreeAccount) || isCancelled ? (
                <StyledButton
                    primary
                    fullwidth
                    disabled={isDisabled}
                    className="text-u-c"
                    onClick={() => props.setState({ showUpgradeForm: true })}>
                    Upgrade my Account
                </StyledButton>
            ) : (
                <StyledButton
                    danger
                    fullwidth
                    className="text-u-c"
                    onClick={e => {
                        e.preventDefault()
                        history.replace({
                            pathname: '/cancel-subscription',
                            state: {
                                modal: true,
                                closeable: true,
                                returnTo: history.location.pathname,
                            },
                        })
                    }}>
                    {isTeamMember ? 'Leave Team' : 'Cancel Subscription'}
                </StyledButton>
            )}
        </div>
    )
}

SubscriptionButton.propTypes = {
    showUpgradeForm: PropTypes.bool,
    isFreeAccount: PropTypes.bool,
    isCancelled: PropTypes.bool,
    setState: PropTypes.func,
}

export default SubscriptionButton
