import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDropZone from 'react-dropzone';
import uuid from 'uuid';

export default class globalFileUpload extends React.Component {
  static propTypes = {
    deactivateDropZone: PropTypes.func,
    visible: PropTypes.bool,
    uploadImage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }

  // onDrop :: [Blobs] -> IPC
  onDrop(files) {
    $('.control-panel-tab .images-icon').click();
    this.props.deactivateDropZone();
    // Give each file a unique id as the filename
    const renamedFiles = files.map(
      file =>
        new File(
          [file],
          `${uuid.v4()}.${file.name
            .split('?')[0]
            .split('.')
            .pop()}`,
          {
            type: file.type,
          }
        )
    );
    this.props.setFileCount(renamedFiles.length);
    this.props.uploadImage(renamedFiles);
  }

  render() {
    let visbility__style = { display: this.props.visible ? 'block' : 'none' };

    return (
      <div className="fill global-file-upload" style={visbility__style}>
        <div className="fill vcc global-file-upload__backdrop" />
        <ReactDropZone
          className="fill global-file-upload__content"
          onDragLeave={this.props.deactivateDropZone}
          onDrop={this.onDrop}
        >
          <div>
            <div className="fill vcc">
              <div className="vc text-center">
                <h3>
                  <span className="dragndropimages" />
                  <span className="text-white global-file-upload__message">
                    Drag and Drop Files to Upload
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </ReactDropZone>
      </div>
    );
  }
}
