import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export default ({ reply, sceneId, replyToComment }) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current.focus();
  }, []);
  const [active, setActive] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const onClick = React.useCallback(() => {
    reply({
      body: comment,
      sceneId,
      replyToComment,
    });
    setActive(false);
  }, [sceneId, comment, replyToComment, reply]);

  return (
    <div className="m-t inline-comment">
      <TextareaAutosize
        ref={ref}
        className="outline-none w100 b r r-2x p-xs _lr-hide"
        onChange={node => {
          setComment(node.currentTarget.value);
          setActive(!!node.currentTarget.value.trim());
        }}
      />
      <button
        disabled={!active}
        className={`m-t-xs btn w100 text-xxs font-bold text-u-c ${
          active ? 'bg-primary' : 'bg-light'
        }`}
        onClick={onClick}
      >
        Reply
      </button>
    </div>
  );
};
