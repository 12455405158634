import PropTypes from 'prop-types'
import * as R from 'ramda'
import { withRouter } from 'react-router'
import React from 'react'
import { connect } from 'react-redux'
import { has, PERMISSIONS, isTrialExpired } from '../../utils/permissions'
import Folders from '.'
import { setCurrentFolderTo, fetchFoldersAction } from '../../actions/FolderActions'
import actions from '../../actions'

import Loader from '../common/loader'
import PlotInfoBox from '../common/infoBox'
import { StyledLink } from './styled'
// const owner = R.compose(has(PERMISSIONS.OWNER), R.prop('permissions'))

// const notOwner = R.compose(R.not, has(PERMISSIONS.OWNER), R.prop('permissions'))

// const filters = {
//     'Show All': projects => R.filter(R.identity, projects),
//     'My Projects': projects => R.filter(owner, projects),
//     'Shared With Me': projects => R.filter(notOwner, projects),
// }

const FoldersContainer = ({
    // history,
    user: { user },
    folders,
    currentFolder,
    setCurrentFolderTo,
    fetchFoldersAction,
    // projects,
    // fetchProjects,
}) => {
    // const leftProjectId = history.location.state?.leaveProjectId
    React.useEffect(() => {
        // if (!projects) {
        // fetchProjects()
        // }
        if (!folders) {
            fetchFoldersAction()
        }
    }, [])

    if (!folders) {
        return (
            <div style={{ width: '100%' }}>
                <Loader message="Loading..." style={{ top: '100px' }} />
            </div>
        )
    }

    if (folders && R.isEmpty(folders)) {
        return (
            <PlotInfoBox
                fullScreen
                title="Welcome to Plot!"
                description="You're going to love it. Ready to start?">
                <StyledLink
                    button={+true}
                    primary={+true}
                    fullwidth={+true}
                    className="m-t-md"
                    to={{
                        pathname: 'folders/create',
                        state: { modal: true, closeable: true },
                    }}>
                    Create New Folder
                </StyledLink>
            </PlotInfoBox>
        )
    }

    return (
        <Folders
            trialExpired={isTrialExpired(user)}
            currentFolder={currentFolder}
            setCurrentFolderTo={setCurrentFolderTo}
            folders={folders}
            user={user}
        />
    )
}

FoldersContainer.propTypes = {
    deleteProject: PropTypes.func,
    fetchProjectById: PropTypes.func,
    fetchProjects: PropTypes.func,
    // projectFilter: PropTypes.string,
    projects: PropTypes.array,
    search: PropTypes.string,
    // session: PropTypes.object,
}

export default withRouter(
    connect(
        R.pick([
            // 'projects',
            // 'projectFilter',
            // 'session',
            // 'showTrialExpire',
            'search',
            'user',
            'folders',
            'currentFolder',
        ]),
        {
            setCurrentFolderTo,
            fetchFoldersAction,
            // ...actions.folderAction,
            // ...actions.projectsAction,
            // ...actions.projects,
            // ...actions.session,
        },
    )(React.memo(FoldersContainer)),
)
