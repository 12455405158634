import React, { useState } from 'react'
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js'

import * as R from 'ramda'

import {
    StyledLabel,
    StyledButton,
    PaymentButton,
    StyledInput,
    ErrorDiv,
    UpgradeDiv,
} from './styled'

import Coupon from './Coupon'
import config from '../../../config'

export const CheckoutForm = props => {
    const [cardNumber, setCardNumber] = useState(false)
    const [cardCvc, setCardCvc] = useState(false)
    const [cardExpiry, setCardExpiry] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [name, setName] = useState('')
    const [postal, setPostal] = useState('')
    const [upgradeCardError, setUpgradeCardError] = useState(null)

    const elements = useElements()
    const stripe = useStripe()

    React.useEffect(() => {
        setProcessing(false)
    }, [props.subscriptionError])

    const handleSubmit = async (ev, subscriptionUpgrade) => {
        ev.preventDefault()
        setProcessing(true)

        const card = elements.getElement(CardNumberElement)
        const res = await stripe.createToken(card, {
            address_zip: postal,
            name: name,
        })

        let stripePayload
        if (res) {
            if (res?.error?.message) {
                stripePayload = { stripeError: res.error.message }
                setProcessing(false)
            }
            stripePayload = { stripeRes: res.token }
            if (subscriptionUpgrade) {
                props.handleSubscriptionSubmit(ev, stripePayload)
            } else {
                stripePayload.onDone = err => {
                    if (err) {
                        setUpgradeCardError(err + ' ')
                    }
                    setProcessing(false)
                }
                props.fixCard(stripePayload)
            }
        }
    }

    const createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    'iconColor': 'pink',
                    fontSize,
                    'color': '#fff',
                    'letterSpacing': '0.025em',
                    'fontFamily': '"Open Sans", "Helvetica Neue", "Helvetica", sans-serif',
                    '::placeholder': {
                        color: '#686f75',
                    },
                    padding,
                },
                invalid: {
                    color: '#fff',
                },
            },
        }
    }

    const { showSubscriptionPaymentInfo, subscriptionId, subscriptionPlanInfo } = props

    const submitBtnEnabled =
        name !== '' &&
        name != null &&
        postal !== '' &&
        postal != null &&
        cardNumber.complete &&
        cardCvc.complete &&
        cardExpiry.complete

    let subscriptionPayment = '---'
    if (showSubscriptionPaymentInfo) {
        if (!R.isNil(subscriptionId)) {
            if (!R.isNil(subscriptionPlanInfo['plan'])) {
                subscriptionPayment =
                    '$' + (Number(subscriptionPlanInfo['plan'].amount) / 100).toFixed(2) + ' / '
                subscriptionPayment +=
                    subscriptionPlanInfo['plan'].interval === 'year'
                        ? `${subscriptionPlanInfo['plan'].interval} (25% off)`
                        : subscriptionPlanInfo['plan'].interval
            }
        } else {
            subscriptionPayment = 'free'
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="content">
                <fieldset>
                    {[
                        showSubscriptionPaymentInfo && (
                            <div key="paymentCycle" className="clearfix m-b-md">
                                <div className="text-center text-white">{subscriptionPayment}</div>
                            </div>
                        ),
                        <div key="card-name" className="clearfix">
                            <div className="cc-name profile__row text m-b-sm">
                                <StyledInput
                                    id="name"
                                    autoComplete="off"
                                    placeholder="John Doe"
                                    value={name}
                                    label="Full Name"
                                    className="cc-name _lr-hide text-white profile__value"
                                    onChange={e => {
                                        setName(e.target.value)
                                    }}
                                    autoFocus
                                />
                            </div>
                        </div>,
                        <div key="card-number" className="clearfix">
                            <div className="profile__row text m-b-sm">
                                <CardNumberElement
                                    id="cardNumber"
                                    className="cc-number _lr-hide text-white profile__value"
                                    onChange={event => setCardNumber(event)}
                                    placeholder="•••• •••• •••• ••••"
                                    options={{ ...createOptions(props.fontSize) }}
                                />
                                <StyledLabel htmlFor="cc-number">
                                    {' '}
                                    {cardNumber?.error ? 'Invalid card number' : 'Card Number'}{' '}
                                </StyledLabel>
                            </div>
                        </div>,
                        <div key="card-expiry" className="clearfix">
                            <div className="profile__row text m-b-sm">
                                <CardExpiryElement
                                    className="cc-exp _lr-hide text-white profile__value"
                                    onChange={event => setCardExpiry(event)}
                                    options={{ ...createOptions(props.fontSize) }}
                                />
                                <StyledLabel htmlFor="cc-expiry">
                                    {' '}
                                    {cardExpiry.error ? 'Invalid expiration' : 'Expiry'}{' '}
                                </StyledLabel>
                            </div>
                        </div>,
                        <div key="card-cvc" className="clearfix">
                            <div className="profile__row text m-b-sm">
                                <CardCvcElement
                                    className="cc-cvc _lr-hide text-white profile__value"
                                    onChange={event => setCardCvc(event)}
                                    placeholder="•••"
                                    options={{ ...createOptions(props.fontSize) }}
                                />
                                <StyledLabel htmlFor="cc-cvc">
                                    {' '}
                                    {cardCvc.error ? 'Invalid cvc' : 'CVC'}{' '}
                                </StyledLabel>
                            </div>
                        </div>,
                        <div key="card-postal" className="clearfix">
                            <div className="cc-postal profile__row text m-b-sm">
                                <StyledInput
                                    id="postal"
                                    autoComplete="off"
                                    placeholder="12345"
                                    value={postal}
                                    onChange={e => {
                                        setPostal(e.target.value)
                                    }}
                                    label="Postal Code"
                                />
                            </div>
                        </div>,
                        <div key="coupon">
                            {props.showCouponField && (
                                <Coupon
                                    coupon={props.coupon}
                                    applyCoupon={props.applyCoupon}
                                    processing={processing}
                                />
                            )}
                        </div>,
                        <div key="submitButtons">
                            {props.showSubscriptionBtn ? (
                                <div className="m-t-lg text-center">
                                    <ErrorDiv>
                                        {props.subscriptionError != null && processing === false ? (
                                            <span className="text-danger">
                                                {props.subscriptionError.trim()}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </ErrorDiv>
                                    <PaymentButton
                                        onClick={ev => handleSubmit(ev, true)}
                                        disabled={!submitBtnEnabled || processing}>
                                        {processing ? (
                                            <div style={{ padding: '15px 15px' }}>
                                                Processing Payment
                                            </div>
                                        ) : (
                                            <span>
                                                <span>Start Subscription</span>
                                                {props.subscriptionPaymentPrice ? (
                                                    <span className="price">
                                                        ${props.subscriptionPaymentPrice}/
                                                        {props.paymentCycle.slice(0, -2)}
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="price"
                                                        style={{ textTransform: 'capitalize' }}>
                                                        $0 Due Now
                                                    </span>
                                                )}
                                                {props.teamMemberCount > 1 && (
                                                    <span
                                                        style={{
                                                            textTransform: 'initial',
                                                            fontStyle: 'italic',
                                                            fontWeight: 'bold',
                                                        }}>
                                                        Includes {props.teamMemberCount - 1} team
                                                        members
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                    </PaymentButton>

                                    <div className="m-t-md">No contract. Cancel anytime.</div>
                                </div>
                            ) : (
                                <UpgradeDiv>
                                    <div>
                                        {upgradeCardError != null && processing === false ? (
                                            <span className="text-danger">
                                                {upgradeCardError.trim()}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <StyledButton
                                        primary
                                        fullwidth
                                        className="m-t-md"
                                        type="submit"
                                        disabled={!submitBtnEnabled || processing}>
                                        {processing ? 'Updating...' : 'Update'}
                                    </StyledButton>
                                </UpgradeDiv>
                            )}
                        </div>,
                    ]}
                </fieldset>
            </div>
        </form>
    )
}
