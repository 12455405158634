import React from 'react'
import { StyledInfoBox } from './styled'

export default ({ title = '', description = '', children = '', fullScreen }) => {
    return (
        <StyledInfoBox fullScreen={fullScreen}>
            <div className="vc text-center info-box__content">
                <div className="projects__null-state">
                    <h3 className="projects__null-state-title">{title}</h3>
                    <div className="projects__null-state-desc">{description}</div>
                    {children}
                </div>
            </div>
        </StyledInfoBox>
    )
}
