import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export default class CognitoUnverified extends React.Component {
  static propTypes = {
    router: PropTypes.object,
  };

  render() {
    return (
      <div className="registration">
        <h1 className="animated fadeIn sn signup__title">One More Step</h1>
        <div className="signup__desc m-b-lg">
          Please verify your e-mail by clicking the link we just sent you.
        </div>
        <p>
          <Link to="/authenticate/resend">Resend verification code</Link>
        </p>
      </div>
    );
  }
}
