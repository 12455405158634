import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import actions from '../actions';
import ChangePassword from '../components/MyAccount/ChangePassword';

export default withRouter(
  connect(
    state => {
      let { session, user: userState } = state;
      const user =
        userState && userState.loading
          ? session.user || session
          : userState.user;
      return { user, userLoading: userState.loading };
    },
    {
      ...actions.session,
    }
  )(ChangePassword)
);
