const variables = {
    textDark: '#5a6a7a',
    textBlack: '#000',
    defaultBlueBorderColor: '#005cbf',
    textIcon: '#9fa3ae',
    plotGreen: '#0adfa6',
    plotGreenHover: '#0aa57c',
    plotBlack: '#1f2725',
    plotRed: '#f05050',
    plotRedHover: '#c73737',
    plotMenuHoverGrey: '#646b73',
    sceneTopPadding: '30px',
    defaultToolbarSceneBorder: '2px solid #eee',
    plotDefaultBorderRadius: '4px',
    plotDefaultBoxShadow: '2px 2px 10px rgba(0,0,0,.2)',
    plotSceneBoxShadow: '2px 2px 10px rgba(0,0,0,.1)',
}

export default variables
