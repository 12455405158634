import React, { Component } from 'react'
import ProjectDuplicateContainer from './ProjectDuplicateContainer'
import Loader from '../../components/common/loader'
import Modal from '../../components/common/Modal'

class ProjectDuplicate extends Component {
    state = {
        step: 0,
    }

    DuplicateStep = () => <ProjectDuplicateContainer onCreate={() => this.setState({ step: 1 })} />

    LoadingStep = () => (
        <Modal title="Duplicating Project" wrapperTitleClassName="m-b-xs">
            <Loader paddingSize="md" className="inverse pos-rlt" />
        </Modal>
    )

    STEPS = [this.DuplicateStep, this.LoadingStep]

    render() {
        const Step = this.STEPS[this.state.step]
        return <Step />
    }
}

export default ProjectDuplicate
