export const PROJECT_TEMPLATE = {
  brandName: null,
  clientId: 'demo',
  description: null,
  email: null,
  phone: null,
  status: 'active',
  websiteURL: null,
};

export const SCENE_TEMPLATE = {
  description: null,
  voiceOver: null,
  clientId: 'demo',

  imageUrl: null,
  sketchJsonUrl: null,
};
