import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * @module UploadProgress
 */

class UploadProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.imageUploading) {
      return null;
    }

    return (
      <div className="upload-progress vcc box-shadow-z4">
        <div className="vc bg-green text-center">
          <div className="text-lg">Uploading images...</div>
        </div>
      </div>
    );
  }
}

UploadProgress.propTypes = {
  imageUploading: PropTypes.bool,
};

UploadProgress.displayName = 'UploadProgress';

export default UploadProgress;
