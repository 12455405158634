import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import DeleteSceneModal from '../components/scene/DeleteSceneConfirm'
import actions from '../actions'

class DeleteSceneConfirmContainer extends React.Component {
    confirm() {
        let sceneId = R.last(this.props.pathname.match(/scenes\/(.+)\/delete/))
        let scene = this.props.scene || R.filter(x => x.id === sceneId, this.props.scenes)[0]
        this.props.deleteScene(scene)
    }

    render() {
        return (
            <DeleteSceneModal
                {...this.props}
                confirm={this.confirm.bind(this)}
                cancel={() => this.props.history.goBack()}
            />
        )
    }
}

DeleteSceneConfirmContainer.propTypes = {
    deleteScene: PropTypes.func,
    pathname: PropTypes.string,
    returnTo: PropTypes.string,
    scene: PropTypes.object,
    scenes: PropTypes.array,
}

export default withRouter(
    connect(
        (state, ownProps) => {
            let { project, scene, scenes } = state

            return {
                project,
                pathname: ownProps.location.pathname,
                scene,
                scenes,
            }
        },
        {
            ...actions.scenes,
        },
    )(DeleteSceneConfirmContainer),
)
