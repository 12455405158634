import styled, { css } from 'styled-components/macro'
import { animated } from 'react-spring'

import CommonStyledButton from '../styled/StyledButton'

export const StyledButton = styled(CommonStyledButton)`
    line-height: 30px !important;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
`

export const Wrapper = styled(animated.div)`
    ${props =>
        props.presentationpreview &&
        `
        .scene-inner-container {
            border: none;
        }

        .slick-next,
        .slick-prev {
            position: absolute;
            top: 45vh;
        }

        @media (max-width: 1100px) {
            max-width: 90vh !important;
        }
        @media (max-width: 850px) {
            max-width: 60vh !important;
        }
        @media (max-width: 768px) {
            padding: 60px !important;
            max-width: 90vh !important;
            margin-top: 2vh;
        }
        @media (max-width: 500px) {
            padding: 60px 30px !important;
        }
    `}
    max-width: 700px;
    min-width: 600px;
    padding: 30px 60px;
    margin-top: ${props => (props.presentationpreview ? '5vh' : '10vh')};
    position: relative;
    background-color: #1f2725;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.59);
    z-index: 100000;

    @media (max-width: 600px) {
        margin: 20px 10px;
        min-width: auto;
        padding: 30px 30px;
        width: 100%;
        max-width: 100%;
        max-height: 90vh;
        margin-bottom: 40px;
    }

    .title {
        color: #fff;
        font-size: 32px;
        font-weight: bold;
        ${props =>
            !props.subtitle &&
            css`
                margin-bottom: 20px;
            `}
        text-align: center;
    }

    .subtitle {
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: lighter;
    }

    textarea,
    input {
        text-align: center;
        background-color: transparent;
        color: #fff;
    }

    .plot-btn-danger {
        background: #f05050;
        color: white;

        &:hover {
            background: #c73737;
        }
    }

    .close-modal-button,
    .btn-clean {
        padding: 1px;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        &.close-modal {
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }

    .button-input {
        margin-bottom: 10px;
    }

    textarea {
        width: 100%;
        outline: none;
        margin: 0;
        padding: 13px 20px;
        border: 2px solid #686f75;
        border-radius: 4px;
        text-align: left;
        background: transparent;
        &:focus {
            border-color: #0adfa6;
            background-color: #1a5444;
        }
        &:focus + label {
            color: white;
        }
    }

    .cancel-button {
        margin-top: 5px;
    }
`

export const Backdrop = styled.div`
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props =>
        props.presentationpreview ? 'rgba(55, 55, 55, 0.7)' : 'rgba(0, 0, 0, 0.7)'};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10000;
`

export const StyledCloseModalButton = styled.button`
    position: absolute;
    ${props =>
        props.presentationpreview
            ? `
            right: 3.3rem;
            top: 1rem;
            
            @media (min-width: 769px) {
                padding: 1px 4px;
                top: 1.8rem; 
                right: 8px;
            }
            @media (max-width: 500px) {
                right: 1.5rem;
            }

        `
            : `
            right: 20px;
            top: 20px;
        `}
    cursor: pointer;
    background: transparent;
    border: 0;

    i {
        ${props =>
            props.presentationpreview &&
            `
                opacity: 1;
                &:hover {
                    opacity: 1;
                }
                
                `}
        width: 16px;
        height: 16px;
        background-size: contain;
        display: inline-block;
    }
`
