import React from 'react';

export default ({ fill = '#EFEFEF', className = 'w100 h100', ...style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 90 90"
    style={{ fill, ...style }}
    className={className}
  >
    <g>
      <rect x="43.08132" y="63.00132" width="2.66633" height="4.41513"></rect>
      <polygon points="43.081 73.164 41.748 73.164 41.748 75.831 45.748 75.831 45.748 71.831 43.081 71.831 43.081 73.164"></polygon>
      <rect x="32.02348" y="73.16442" width="4.86234" height="2.66633"></rect>
      <rect x="22.29913" y="73.16442" width="4.86234" height="2.66633"></rect>
      <rect x="12.57477" y="73.16442" width="4.86201" height="2.66633"></rect>
      <polygon points="6.379 71.831 3.713 71.831 3.713 75.831 7.712 75.831 7.712 73.164 6.379 73.164 6.379 71.831"></polygon>
      <rect x="3.71294" y="52.37831" width="2.66633" height="4.86332"></rect>
      <rect x="3.71294" y="42.65201" width="2.66633" height="4.86299"></rect>
      <rect x="3.71294" y="62.10462" width="2.66633" height="4.86332"></rect>
      <polygon points="3.713 37.789 6.379 37.789 6.379 36.456 7.712 36.456 7.712 33.789 3.713 33.789 3.713 37.789"></polygon>
      <rect x="12.16076" y="33.7892" width="4.44833" height="2.66633"></rect>
      <path d="M86.85206,47.5969a1.33863,1.33863,0,0,0-.91321-0.96655l-9.93209-2.973V15.22767a1.33278,1.33278,0,0,0-1.33316-1.33316H23.72672a1.32857,1.32857,0,0,0-1.33316,1.33316V33.792H21.06039v2.66633h1.33316V57.25567H43.08429v1.33316h2.66633V57.25567h18.551L66.84125,65.068a1.35159,1.35159,0,0,0,.94658.87989,1.32788,1.32788,0,0,0,1.24651-.33329l5.60594-5.37932,5.01938,5.02606a1.3357,1.3357,0,0,0,1.8864,0l4.26614-4.26614a1.32379,1.32379,0,0,0,0-1.88644l-4.89273-4.89936,5.55931-5.33933A1.31506,1.31506,0,0,0,86.85206,47.5969ZM25.05989,54.58934V42.87748L36.1718,28.91925a2.49625,2.49625,0,0,1,1.79311-.95321,2.53175,2.53175,0,0,1,1.92643.66658L61.43528,48.4501l1.99307,6.13924H25.05989ZM73.34043,42.85751L60.56873,39.038a1.34914,1.34914,0,0,0-1.33984.34664,1.33544,1.33544,0,0,0-.31327,1.34651l1.25314,3.83948-6.83247-6.28587,10.85865-7.39237a3.42322,3.42322,0,0,1,4.10613.2l5.03936,4.2128v7.55239Zm0-10.15875-3.75286-3.13958a5.42557,5.42557,0,0,0-6.51915-.32L51.82318,36.89159,41.24452,27.15946a4.55278,4.55278,0,0,0-6.63917.51328L25.05989,39.66455V16.56083H73.34043V32.69876Z"></path>
      <path d="M51.72985,19.86705a5.44932,5.44932,0,1,0,5.446,5.45268A5.45354,5.45354,0,0,0,51.72985,19.86705Zm0,8.8989a3.44958,3.44958,0,1,1,3.44622-3.44622A3.45078,3.45078,0,0,1,51.72985,28.76595Z"></path>
    </g>
  </svg>
);
