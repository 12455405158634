import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import actions from '../actions';

class CreateCommentContainer extends Component {
  constructor(props) {
    super(props);
    this.commentRef = React.createRef();
  }
  componentWillMount() {
    if (!this.props.returnTo) {
      this.props.history.push('/projects');
    }
  }

  submit = () => {
    this.props.history.goBack();
    this.props.createComment(this.commentRef.current.value);
  };

  render() {
    return (
      <div>
        <div className="text-normal m-b text-u-c">New Comment</div>
        <textarea
          ref={this.commentRef}
          className="b outline-none w100 p-sm"
          rows={3}
          placeholder="Comment ..."
        />
        <div className="m-t pull-right">
          <Link
            className="m-r inline text-dark font-thin"
            to={this.props.returnTo}
          >
            Cancel
          </Link>
          <div
            className="inline bg-primary p-xs p-r p-l r-2x cp"
            onClick={this.submit}
          >
            Submit
          </div>
        </div>
      </div>
    );
  }
}

CreateCommentContainer.propTypes = {
  createComment: PropTypes.func.isRequired,
  returnTo: PropTypes.string,
};

export default withRouter(
  connect(
    state => {
      let { project, scene } = state;

      return {
        project,
        scene,
      };
    },
    {
      ...actions.comments,
    }
  )(CreateCommentContainer)
);
