import * as R from 'ramda'

const PERMISSIONS = {
    OWNER: 0,
    EDIT: 1,
    COMMENT: 2,
}

function permissionValue(power) {
    return Math.pow(2, power)
}

const has = R.curry((permission, value) => {
    return (value & permissionValue(permission)) === permissionValue(permission)
})

const any = R.curry((permissions, value) => {
    return R.any(permission => has(permission, value), permissions)
})

const computePermissionsValue = R.compose(R.sum, R.map(permissionValue))

const isCancellationDatePassed = user => Date.now() / 1000 > user.cancellationDate

const isCouponUsed = user =>
    new Date().setDate(new Date().getDate() + 15) / 1000 < user.cancellationDate

const isFreeAccount = user => user && user.paymentCycle === 'free'

const isTrialExpired = user =>
    user && !user.team && isFreeAccount(user) && Date.now() / 1000 > user.cancellationDate

const isCancelled = user =>
    !isFreeAccount(user) && !R.isNil(user.cancellationDate) && user.cancellationDate !== 0

const isTrial = user =>
    user &&
    !user.team &&
    user.paymentCycle === 'free' &&
    !R.isNil(user.cancellationDate) &&
    !isCancellationDatePassed(user)

const isExtended = user =>
    user && user.paymentCycle === 'free' && !R.isNil(user.cancellationDate) && isCouponUsed(user)

const isProjectPublic = project => project && project.visibility === 'public'

const isOwner = permissions => has(PERMISSIONS.OWNER, permissions)

const ownProjectCount = R.compose(
    R.length,
    R.filter(({ permissions }) => isOwner(permissions)),
)

const teamRole = (user, role) => R.path(['team', 'role'], user) === role

export {
    PERMISSIONS,
    has,
    any,
    computePermissionsValue,
    ownProjectCount,
    isFreeAccount,
    isOwner,
    isCancelled,
    isTrial,
    isExtended,
    isTrialExpired,
    isProjectPublic,
    teamRole,
}
