import React, { useState, useEffect } from 'react'
import * as R from 'ramda'

import ProjectsHeader from './ProjectsHeaderContainer'
import ProjectsGrid from '../DragAndDrop/ProjectsGrid'
import Loader from '../common/loader'
import PlotInfoBox from '../common/infoBox'

import ShareFolderIcon from '../../icons/ShareFolderIcon'
import { StoryBoardsWrapper, StyledLink } from './styled'

const NEW_PROJECT = 'New Project'

const Projects = ({
    projectFolderWidth,
    currentFolder,
    storyBoards,
    search,
    user,
    reorderStoryboards,
    trialExpired,
    showProjectFolder,
    isExtraSmallDevice,
}) => {
    if (!currentFolder) {
        return (
            <div style={{ width: '100%', position: 'relative' }}>
                <Loader message="Loading..." style={{ top: '100px' }} />
            </div>
        )
    }
    const handleSceneDrop = (sourceIndex, targetIndex) => {
        // target id will only be set if dragging from one dropzone to another.
        if (!storyBoards[targetIndex] || !storyBoards[sourceIndex]) return

        // swap(storyBoards, sourceIndex, targetIndex);
        const sourceStoryboard = storyBoards[sourceIndex]
        const targetStoryboard = storyBoards[targetIndex]
        if (sourceStoryboard.id !== targetStoryboard.id) {
        }

        let at = R.findIndex(R.equals(sourceStoryboard), storyBoards)
        let to = R.findIndex(R.equals(targetStoryboard), storyBoards)
        const newStoryBoardOrder = R.insert(
            to,
            R.nth(at, storyBoards),
            R.remove(at, 1, storyBoards),
        )

        reorderStoryboards({
            currentFolder,
            storyBoards,
            sourceStoryboard,
            targetStoryboard,
            newStoryBoardOrder,
        })
    }

    const [selectedProjects, setSelectedProjects] = useState([])

    useEffect(() => {
        setSelectedProjects([])
    }, [currentFolder])

    const handleUpdateSelectedProjects = projectId => {
        let currentSelectedProjects
        if (selectedProjects.includes(projectId)) {
            currentSelectedProjects = selectedProjects.filter(
                selectedProjectId => selectedProjectId !== projectId,
            )
        } else {
            currentSelectedProjects = [...selectedProjects, projectId]
        }
        setSelectedProjects(currentSelectedProjects)
    }

    return (
        <StoryBoardsWrapper showMoveSelectedProjectsBtn={selectedProjects.length > 0}>
            {showProjectFolder && <div className="set-curtain" />}
            <div className="project-storyBoards__info">
                <div className="project-storyBoards__info-title">
                    <h2 style={{ color: 'black' }}>{currentFolder?.folderName}</h2>
                </div>
                {currentFolder?.isOwner && (
                    <div
                        style={{ cursor: !currentFolder ? 'not-allowed' : '' }}
                        className="project-storyBoards__info-button">
                        <StyledLink
                            button={+true}
                            style={{ padding: '0 20px' }}
                            primary={+true}
                            disabled={!currentFolder || !currentFolder.folderId}
                            to={{
                                pathname: '/projects/create',
                                state: {
                                    modal: true,
                                    closeable: true,
                                },
                            }}>
                            {' '}
                            {NEW_PROJECT}{' '}
                        </StyledLink>
                        {selectedProjects.length > 0 && (
                            <StyledLink
                                moveselectedprojectbtn={+true}
                                button={+true}
                                disabled={!currentFolder || !selectedProjects.length}
                                to={{
                                    pathname: '/projects/move',
                                    state: {
                                        modal: true,
                                        closeable: true,
                                        projectIds: selectedProjects,
                                        moveFromFolderId: currentFolder?.folderId,
                                    },
                                    data: {
                                        setSelectedProjects: setSelectedProjects,
                                    },
                                }}>
                                Move Selected Projects
                            </StyledLink>
                        )}
                    </div>
                )}
                {/* <StyledButton primary disabled={!currentFolder || true}>
                    <ShareFolderIcon
                        style={{ paddingRight: '10px', width: '35px', height: '35px' }}
                    />{' '}
                    Share Folder
                </StyledButton> */}
            </div>
            <ProjectsHeader />
            {/* <ProjectsHeader storyBoards={storyBoards} currentFolder={currentFolder} /> */}
            <div
                className="project-storyBoards__content"
                style={R.isEmpty(storyBoards) ? { paddingLeft: 0 } : {}}>
                {currentFolder ? (
                    !storyBoards ? (
                        <Loader message="Loading Storyboards" style={{ width: '100%' }} />
                    ) : R.isEmpty(storyBoards) ? (
                        <PlotInfoBox
                            title="Welcome to Your New Folder"
                            description="Create a project to get started.">
                            <StyledLink
                                button={+true}
                                primary={+true}
                                fullwidth={+true}
                                className="m-t-md"
                                to={{
                                    pathname: trialExpired ? '/billing' : '/projects/create',
                                    state: { modal: true, closeable: true, upgrade: trialExpired },
                                }}>
                                New Project
                            </StyledLink>
                        </PlotInfoBox>
                    ) : (
                        <ProjectsGrid
                            selectedProjects={selectedProjects}
                            handleUpdateSelectedProjects={handleUpdateSelectedProjects}
                            projectFolderWidth={projectFolderWidth}
                            user={user}
                            containerClass="storyboard-container"
                            onOrderChange={handleSceneDrop}
                            items={storyBoards}
                            onActivateScene={() => console.log('onActivateScene')}
                            isDraggable={
                                currentFolder &&
                                currentFolder?.folderId &&
                                !search.length &&
                                !isExtraSmallDevice
                            }
                        />
                    )
                ) : null}
            </div>
        </StoryBoardsWrapper>
    )
}

export default Projects
