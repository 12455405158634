import styled, { css } from 'styled-components/macro'

export const Wrapper = styled.div`
    ${props =>
        props.scroll &&
        css`
            overflow: scroll;
            overflow-x: hidden;
            > div {
                width: 100%;
                overflow: scroll;
                overflow-x: hidden;
            }
        `}

    &::-webkit-scrollbar {
        display: none;
    }

    &,
    > div {
        position: relative;
        height: 100%;
        user-select: none;
        padding: 0;
    }
`

export const DraggableWrapper = styled.div`
    position: absolute;
`
