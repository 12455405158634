import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as R from 'ramda'

import config from '../../config'
import Modal from '../common/Modal'
import { StyledLink, StyledButton } from './styled'
import { isTrialExpired } from '../../utils/permissions'
import { fetchProductPlans } from '../../actions/ApplicationActions'

export default connect(
    state => {
        let { session, productPlans } = state
        const trialExpired = session && session.user ? isTrialExpired(session.user) : false

        return {
            trialExpired,
            productPlans,
        }
    },
    {
        fetchProductPlans,
    },
)(({ location, history, trialExpired, productPlans, fetchProductPlans }) => {
    if (!trialExpired) {
        return <Redirect to="/projects" />
    }
    if (!trialExpired || location.pathname !== '/trial-expired') {
        return null
    }

    const hasPlans = R.not(R.isEmpty(productPlans))
    useEffect(() => {
        if (!hasPlans) {
            fetchProductPlans({
                oldProductPlan: config.stripeOldProductId,
                productSoloId: config.stripeProductSoloId,
                productProId: config.stripeProductProId,
                productBusinessId: config.stripeProductBusinessId,
            })
        }
    }, [
        config.stripeOldProductId,
        config.stripeProductSoloId,
        config.stripeProductProId,
        config.stripeProductBusinessId,
        hasPlans,
        fetchProductPlans,
    ])

    return (
        <Modal
            isCloseable
            goBack={() => {
                history.replace('/projects')
            }}
            title="Trial Expired"
            subtitle="Please upgrade for unlimited access.">
            {hasPlans ? (
                <StyledLink
                    button={+true}
                    primary={+true}
                    fullwidth={+true}
                    className="m-t-sm"
                    to={{
                        pathname: '/billing',
                        state: { modal: true, closeable: true, upgrade: true },
                    }}
                    onClick={() => {
                        history.push('/projects')
                    }}>
                    Upgrade My Account
                </StyledLink>
            ) : (
                <StyledButton primary btn disabled fullwidth className="m-t-sm">
                    Upgrade My Account
                </StyledButton>
            )}
        </Modal>
    )
})
