import { fork } from 'redux-saga/effects'

import authentication from './authentication'
import coupon from './coupon'
import project from './project'
import scene from './scene'
import helpcrunch from './helpcrunch'
import { sketchSaga } from '../sketch'
import team from './team'
import subscriptionPlanInfo from './subscriptionPlan'
import userAccount from './userAccount'
import folder from './folder'
import projects from './projects'

export default function*() {
    yield fork(authentication)
    yield fork(coupon)
    yield fork(project)
    yield fork(scene)
    yield fork(helpcrunch)
    yield fork(sketchSaga)
    yield fork(team)
    yield fork(subscriptionPlanInfo)
    yield fork(userAccount)
    yield fork(folder)
    yield fork(projects)
}
