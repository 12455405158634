import styled, { css } from 'styled-components'

import lockGreen from '../../resources/images/lock-green.png'

import CommonStyledLink from '../common/styled/StyledLink'
import CommonStyledButton from '../common/styled/StyledButton'
import CommonStyledInput from '../common/styled/StyledInput'

export const StyledInput = styled(CommonStyledInput)`
    text-align: left !important;
`

export const StyledButton = styled(CommonStyledButton)``

export const StyledLink = styled(CommonStyledLink)`
    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
            ${!props.primary &&
                !props.danger &&
                !props.modalTransparentBtn &&
                css`
                    background-color: grey !important;
                `}
            color: #fff;
            opacity: 0.65;
            &:hover {
                ${!props.primary &&
                    !props.danger &&
                    !props.modalTransparentBtn &&
                    css`
                        background-color: grey !important;
                    `}
                cursor: not-allowed;
                box-shadow: none;
            }
        `}
    ${props =>
        props.trash &&
        css`
            &:hover {
                color: ${props => props.theme.color.plot_red};
            }
        `}
    ${props =>
        props.projecttileimageviewbtn &&
        css`
            cursor: pointer;
            border: 1px solid #fff;
            padding: 5px 15px;
            background-color: transparent;
            color: #fff;
            &:hover {
                color: #fff;
            }
        `}
    ${props =>
        props.lockicon &&
        css`
            display: block;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            padding: 30px;
            border-radius: 10px;
            border: ${props => props.theme.color.plot_black} solid 6px;
            background: ${props => props.theme.color.plot_black} url(${lockGreen}) no-repeat
                center/100%;
            box-shadow: 0 0 20px ${props => props.theme.color.plot_black};
        `}
    ${props =>
        props.moveselectedprojectbtn &&
        css`
            padding: 0 20px;
            background-color: #000;
            border-radius: 0;
            margin: 0 10px;

            @media (max-width: 368px) {
                margin-left: 0;
            }
        `}
`

export const StyledLabel = styled.label`
    right: 0;
    bottom: 0;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 0.7em;
    position: absolute;
    text-align: right;
    color: #686f75;
    text-transform: uppercase;
    font-weight: bold;
`

// ProjectStoryBoard
export const StoryBoardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;

    ::-webkit-scrollbar {
        display: none;
    }

    .set-curtain {
        display: block;
        background-color: black;
        opacity: 0.65;
        z-index: 1000;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @media (max-width: 768px) {
        margin-left: 50px;
    }

    @media (max-width: 368px) {
        padding-bottom: 40px;
    }

    .project-storyBoards__info {
        padding: 0 40px;
        height: 40px;
        width: 100%;
        margin-top: 20px;

        @media (max-width: 1000px) {
            margin-top: 20px;
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
        }

        @media (max-width: 450px) {
            padding: 0 40px 0 20px;
        }

        @media (max-width: 368px) {
            ${props =>
                props.showMoveSelectedProjectsBtn &&
                css`
                    margin-bottom: 100px;
                `}
        }

        .project-storyBoards__info-title {
            max-width: 65%;
            float: left;

            @media (max-width: 1500px) {
                max-width: 50%;
            }
            @media (max-width: 1100px) {
                max-width: 40%;
            }

            @media (max-width: 1000px) {
                max-width: 100%;
            }
            @media (max-width: 768px) {
                max-width: 100%;
            }
        }
        .project-storyBoards__info-button {
            display: flex;
            flex-direction: row-reverse;

            @media (max-width: 1000px) {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 10px;
            }

            @media (max-width: 450px) {
                a {
                    padding: 0px 10px !important;
                    font-size: 14px;
                }
            }

            @media (max-width: 368px) {
                flex-direction: column-reverse;
                align-items: flex-start;
                a {
                    padding: 0px 10px !important;
                    font-size: 12px;
                    margin-bottom: 10px;
                }
            }
        }
        h2 {
            margin-top: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: normal;
            margin-bottom: 0;
        }

        @media (max-width: 768px) {
            h2 {
                font-size: 1.5rem;
            }
        }
    }

    .project-storyBoards__content {
        position: relative;
        padding-left: 25px;
        height: calc(100vh - 220px);

        @media (max-width: 1000px) {
            height: calc(100vh - 270px);
        }

        @media (max-width: 368px) {
            margin-top: 10px;
        }
    }
`
// ProjectStoryBoard

// ProjectTile
export const StyledLockedProject = styled.div`
    margin: 0 15px;
    display: block;
    background-color: white;
    opacity: 0.75;
`

export const StyledLockWrapper = styled.div`
    padding-top: 56.25%;
    top: 0;
    right: 50%;
    z-index: 2;
`

export const StyledProjectsActionContainer = styled.div`
    display: none;
    position: absolute;
    right: 30px;
    bottom: 30px;

    @media (min-width: 600px) and (max-width: 680px) {
        bottom: 40px;
    }

    @media (max-width: 400px) {
        bottom: 35px;
    }

    @media (max-width: 368px) {
        bottom: 45px;
    }

    .btn-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
    }

    .trash:hover {
        color: ${props => props.theme.color.plot_red};
        cursor: pointer;
    }
`

export const StyledProjectTile = styled.div`
    &:hover {
        > div.ProjectItem {
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
        }
        .curtain {
            display: block;
        }
        ${StyledProjectsActionContainer} {
            display: flex;
        }
    }
    > div.ProjectItem {
        border: ${props =>
            props ? props.theme.border.default_toolbar_scene_border : '2px solid #eee'};
        > div.thumb-container {
            width: 100%;
            padding-top: 56.25%;
            border-bottom: 1px solid #ddd;
            position: relative;
        }
        .overlay {
            display: block;
            background-color: black;
            opacity: 0.65;
        }
    }
    .locked-scene {
        display: block;
        background-color: white;
        opacity: 0.65;
        z-index: 1;
    }

    .curtain {
        display: none;
    }

    .curtain .content {
        z-index: 99;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
`

export const StyledProjectTileCheckbox = styled.div`
    .checkbox-container {
        cursor: pointer;
    }

    .checkbox-container input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 10px;
        left: 10px;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    .checkbox-container:hover input ~ .checkmark {
        background-color: #e3e3e3;
    }

    .checkbox-container input:checked ~ .checkmark {
        background-color: #000;
    }

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    .checkbox-container input:checked ~ .checkmark:after {
        display: block;
    }

    .checkbox-container .checkmark:after {
        left: 10px;
        top: -3px;
        width: 11px;
        height: 25px;
        border: solid ${props => props.theme.color.plot_green};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`

export const StyledProjectDetailContainer = styled.div`
    height: 78px;
    background-color: #fff;
    .header {
        padding: 10px;
        .title {
            font-weight: 600;
            color: #000;
            white-space: nowrap;
            line-height: 1.5em;
            overflow: hidden;
            text-overflow: ellipsis;

            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
        .scene-count {
            color: #999;
            font-size: 13px;
        }
    }
    .footer {
        font-size: 13px;
        color: #999;
        position: absolute;
        height: 36px;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        .owner-detail {
            font-size: 13px;
            color: #999;
        }
    }
`
// ProjectTile End

// StoryBoardsSearchHeaderWrapper
export const StoryBoardsSearchHeaderWrapper = styled.div`
    padding: 20px 0 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        right: -5px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        color: #fff;
        background-color: #ccc;
        font: 25px monospace;
        text-align: center;
        line-height: 0.8em;
        cursor: pointer;
        &:hover {
            background-color: ${props => props.theme.color.plot_red};
        }
    }
`

export const SearchProjectInputWrapper = styled.div`
    position: relative;
    width: 50%;

    @media (max-width: 1000px) {
        width: 100%;
        padding: 0 40px;
    }

    @media (max-width: 450px) {
        padding: 0 40px 0 20px;
    }
`

export const SearchProjectInput = styled.input`
    height: 40px;
    background-color: #eee;
    color: #6b7379;
    text-align: center;
    border: ${props => (props.searching ? `2px solid ${props.theme.color.plot_green}` : 'none')};
    width: 100%;
    &:focus {
        outline: none;
    }
`

// MoveToFolderListContainer
export const MoveToFolderListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 250px;
    max-height: 500px;
    overflow-y: auto;
    padding: 0px 20px;
    margin: 25px 0;
`

// Custom Radio button
export const RadioButtonContainer = styled.label`
    /* The container */
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #eee;
        border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: #0adfa6;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkmark:after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
`
