import React from 'react'
import { Route, Redirect, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { isTrialExpired } from './utils/permissions'

export const PrivateRoute = withRouter(
    connect(({ session, showTrialExpire }) => {
        const trialExpired = session && session.user ? isTrialExpired(session.user) : false
        return {
            session,
            showTrialExpire,
            trialExpired,
        }
    })(({ component: Component, session, showTrialExpire, trialExpired, ...rest }) => (
        <Route
            {...rest}
            render={props => {
                if (
                    session &&
                    session.cognitoUser &&
                    session.cognitoUser.email_verified === 'false' &&
                    props.history.location.pathname !== '/profile/verify-email-warning' &&
                    props.history.location.pathname !== '/profile/verify-email'
                ) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/profile/verify-email-warning',
                                state: { from: props.location },
                            }}
                        />
                    )
                } else if (!session && props.history.location.pathname !== '/authenticate/signin') {
                    return <Redirect to={{ pathname: '/authenticate/signin' }} />
                } else if (showTrialExpire && trialExpired) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/trial-expired',
                                state: { from: props.location },
                            }}
                        />
                    )
                }
                // else if (
                //     isOwner &&
                //     projectLocked &&
                //     props.history.location.pathname?.match(/\/projects\/\S+/gim)
                // ) {
                //     return (
                //         <Redirect
                //             to={{
                //                 pathname: '/billing',
                //                 state: { redirectBackTo: '/projects', upgrade: true },
                //             }}
                //         />
                //     )
                // }
                else {
                    return <Component {...props} />
                }
            }}
        />
    )),
)
