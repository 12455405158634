import React from 'react'

export default ({ fill = 'white', size = '', className, ...style }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 100 100"
        x="0px"
        y="0px"
        style={{ fill, ...style }}
        className={className}>
        <circle cx="22.67" cy="50" r="8.67" />
        <circle cx="50" cy="50" r="8.67" />
        <circle cx="77.33" cy="50" r="8.67" />
    </svg>
)
